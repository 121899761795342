/* eslint-disable */
import React, { Component } from "react";
import { Link } from "react-router-dom";
/* import modules */
import Header from "../Layout/Header";
import Footer from "../Layout/Footer";
import Slider from "react-slick";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";

var Parser = require("html-react-parser");

import {
  apiUrl,
  appId,
  deliveryId,
  pickupId,
  cateringId,
} from "../Helpers/Config";
import {
  addressFormat,
  stripslashes,
  showPriceValue,
  timeToConv12,
  displayoutletTax,
  showLoader,
  hideLoader,
} from "../Helpers/SettingHelper";

import axios from "axios";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import Moment from "moment";
var qs = require("qs");
import {
  GET_CORDERDETAIL,
  GET_PORDERDETAIL,
  GET_PRINTORDER,
  GET_ORDERHISTORY,
  GET_CATERINGCORDER,
  GET_CATERINGPORDER,
  GET_CATERINGHISTORY,
  GET_CATERINGPRINT,
  GET_ACTIVITYCOUNT,
} from "../../actions";

import cookie from "react-cookies";

import scotterImg from "../../common/images/scotter-icon.png";
import mapImg from "../../common/images/map-icon.png";
import pickupImg from "../../common/images/lunch.svg";
class Orders extends Component {
  constructor(props) {
    super(props);

    this.state = {
      corderdetail: [],
      cartItems: [],
      cartData: [],
      ordergroups: "",
      cart_brktime_list: [],
      showitems: 10,
      showpitems: 10,
      showresvitems: 10,
      resviewmore: "none",
      order_all: 0,
      overall_orders: 0,
      catering_orders: 0,
      deliveryTakeaway_orders: 0,
      CateringReceiptDetails: [],
      CateringCartItems: [],
    };
  }

  componentDidMount() {
    if (cookie.load("UserId") == "" || cookie.load("UserId") == undefined) {
      cookie.save("loginpopupTrigger", "login", { path: "/" });
      this.props.history.push("/");
      return false;
    }
    /* delivery current - past orders */
    var deliveryparams =
      "&customer_id=" +
      cookie.load("UserId") +
      "&limit=" +
      this.state.showitems +
      "&except_availability=yes";
    this.props.getCorderDetail(deliveryparams);

    var deliverypastparams =
      "&customer_id=" +
      cookie.load("UserId") +
      "&limit=" +
      this.state.showitems +
      "&except_availability=yes";
    this.props.getPorderDetail(deliverypastparams);
    $("#dvLoading").fadeOut(2000);

    /* catering current- past orders */

    var cateringparams =
      "&customer_id=" +
      cookie.load("UserId") +
      "&availabilityIDs=" +
      cateringId +
      "&limit=" +
      this.state.showitems;
    this.props.getCateringOrder(cateringparams);

    var cateringpastparams =
      "&customer_id=" +
      cookie.load("UserId") +
      "&availabilityIDs=" +
      cateringId +
      "&limit=" +
      this.state.showitems;
    this.props.getCateringPast(cateringpastparams);

    $("#dvLoading").fadeOut(2000);

    //Default Action
    $(".filter_tabsec").hide(); //Hide all content
    $("ul.web_order_typecls li:first").addClass("active").show(); //Activate first tab
    $(".filter_tabsec:first").show(); //Show first tab content

    //On Click Event
    $("ul.web_order_typecls li").click(function () {
      $("ul.web_order_typecls li").removeClass("active"); //Remove any "active" class
      $(this).addClass("active"); //Add "active" class to selected tab
      $(".filter_tabsec").hide(); //Hide all tab content
      var activeTab = $(this).find("a").attr("href"); //Find the rel attribute value to identify the active tab + content
      $(activeTab).fadeIn(); //Fade in the active content
      return false;
    });

    this.getActivityCounts();
  }

  componentWillMount() {
    $(document).ready(function () {
      (function () {
        if ($(".nav-btn").length > 0) {
          var menuAdd = document.querySelector(".nav-btn");
          if (menuAdd) {
            menuAdd.addEventListener(
              "click",
              function () {
                this.parentNode.parentNode.classList.toggle("sub-nav-closed");
              },
              false
            );
          } else if (menuAdd) {
            menuAdd.on(
              "touchstart",
              function () {
                this.parentNode.parentNode.classList.toggle("sub-nav-closed");
              },
              false
            );
          } else {
            null;
          }
        }
      })();
    });
  }

  /* for order load more  button */
  loadcurrentItems() {
    var pageNext = this.state.showitems + 10;
    this.setState({ showitems: pageNext }, function () {
      this.loadcitems();
    });
  }
  loadpastItems() {
    var pagepNext = this.state.showpitems + 10;
    this.setState({ showpitems: pagepNext }, function () {
      this.loadpitems();
    });
  }

  loadcitems() {
    $(".load_more_data").append('<b class="gloading_img"></b>');
    var deliveryparams =
      "&customer_id=" +
      cookie.load("UserId") +
      "&availabilityIDs=" +
      deliveryId +
      "&limit=" +
      this.state.showitems +
      "&except_availability=yes";
    this.props.getCorderDetail(deliveryparams);
  }
  loadpitems() {
    $(".load_more_data1").append('<b class="gloading_img"></b>');
    var deliverypastparams =
      "&customer_id=" +
      cookie.load("UserId") +
      "&availabilityIDs=" +
      deliveryId +
      "&limit=" +
      this.state.showitems +
      "&except_availability=yes";
    this.props.getPorderDetail(deliverypastparams);
  }

  getActivityCounts() {
    const inputKeys = [
      "catering_orders",
      "order_all",
      "overall_orders",
      "deliveryTakeaway_orders",
    ];
    this.props.getActivityCount(JSON.stringify(inputKeys));
  }

  /* catering load more button */
  cateringloadItems() {
    var pageNext = this.state.showitems + 10;
    this.setState({ showitems: pageNext }, function () {
      this.loadcateringitems();
    });
  }
  cateringpastItems() {
    var pagepNext = this.state.showpitems + 10;
    this.setState({ showpitems: pagepNext }, function () {
      this.loadcateringpitems();
    });
  }

  loadcateringitems() {
    $(".cateringloadmore").append('<b class="gloading_img"></b>');
    var cateringparams =
      "&customer_id=" +
      cookie.load("UserId") +
      "&availabilityIDs=" +
      cateringId +
      "&limit=" +
      this.state.showitems;
    this.props.getCateringOrder(cateringparams);
  }
  loadcateringpitems() {
    $(".cateringloadmore1").append('<b class="gloading_img"></b>');
    var cateringpastparams =
      "&customer_id=" +
      cookie.load("UserId") +
      "&availabilityIDs=" +
      cateringId +
      "&limit=" +
      this.state.showitems;
    this.props.getCateringPast(cateringpastparams);
  }

  componentWillReceiveProps(nextProps) {
    /*activity count -start */
    if (Object.keys(nextProps.activitycount).length > 0) {
      if (nextProps.activitycount !== this.props.activitycount) {
        if (
          nextProps.activitycount[0].status &&
          nextProps.activitycount[0].result_set
        ) {
          this.setState({
            order_all: nextProps.activitycount[0].result_set.order_all,
            overall_orders:
              nextProps.activitycount[0].result_set.overall_orders,
            deliveryTakeaway_orders:
              nextProps.activitycount[0].result_set.deliveryTakeaway_orders,
            catering_orders:
              nextProps.activitycount[0].result_set.catering_orders,
          });
          $("#dvLoading").fadeOut(2000);
        }
      }
    } else {
      this.setState({
        order_all: 0,
        overall_orders: 0,
        catering_orders: 0,
        deliveryTakeaway_orders: 0,
      });
    }

    /*current orders */
    if (nextProps.corderdetail !== this.props.corderdetail) {
      if (nextProps.corderdetail && nextProps.corderdetail[0].status == "ok") {
        $("b").removeClass("gloading_img");
        $("#dvLoading").fadeOut(2000);
        this.setState({ corderdetail: nextProps.corderdetail[0].result_set });
        if (nextProps.corderdetail[0].common.total_records) {
          this.setState({
            totalcount: nextProps.corderdetail[0].common.total_records,
          });
        }
        if (
          this.state.showitems > nextProps.corderdetail[0].common.total_records
        ) {
          $(".load_more_data").hide();
        } else {
          $(".load_more_data").show();
        }
      } else {
        $(".load_more_data").hide();
      }
    }

    if (nextProps.porderdetail !== this.props.porderdetail) {
      if (nextProps.porderdetail && nextProps.porderdetail[0].status == "ok") {
        $("b").removeClass("gloading_img");
        $("#dvLoading").fadeOut(2000);
        this.setState({ porderdetail: nextProps.porderdetail[0].result_set });
        if (
          nextProps.porderdetail[0].common.total_records &&
          this.state.showpitems > nextProps.porderdetail[0].common.total_records
        ) {
          $(".load_more_data1").hide();
        } else {
          $(".load_more_data1").show();
        }
      } else {
        $(".load_more_data1").hide();
      }
    }

    if (nextProps.orderhistory !== this.props.orderhistory) {
      if (nextProps.orderhistory[0].status === "ok") {
        this.setState({
          CartItems: nextProps.orderhistory[0].result_set[0]["items"],
          ordergroups: nextProps.orderhistory[0].ordergroups,
          ReceiptDetails: nextProps.orderhistory[0].result_set[0],
        });

        $("#dvLoading").fadeOut(5000);
        setTimeout(function () {
          $.magnificPopup.open({
            items: {
              src: ".receipt_popup",
            },
            type: "inline",
          });
        }, 1000);
      } else {
        this.setState({ ReceiptDetails: [] });
      }
    }

    if (nextProps.printorder !== this.props.printorder) {
      if (nextProps.printorder[0].status === "ok") {
        $("#dvLoading").fadeOut(2000);
        window.open(nextProps.printorder[0].pdf_url, "_blank");
      }
    }
    /* caterign current & past order */

    if (nextProps.cateringorder !== this.props.cateringorder) {
      if (
        nextProps.cateringorder[0].result_set &&
        nextProps.cateringorder[0].status == "ok"
      ) {
        $("b").removeClass("gloading_img");
        $("#dvLoading").fadeOut(2000);
        this.setState({
          cateringorderdetail: nextProps.cateringorder[0].result_set,
        });
        if (
          nextProps.cateringorder[0].common.total_records &&
          this.state.showitems > nextProps.cateringorder[0].common.total_records
        ) {
          $(".cateringloadmore").hide();
        }
      } else {
        $(".cateringloadmore").hide();
      }
    }

    if (nextProps.cateringpast !== this.props.cateringpast) {
      if (
        nextProps.cateringpast[0].result_set &&
        nextProps.cateringpast[0].status == "ok"
      ) {
        $("b").removeClass("gloading_img");
        $("#dvLoading").fadeOut(2000);
        this.setState({
          cateringpastdetail: nextProps.cateringpast[0].result_set,
        });
        if (
          nextProps.cateringpast[0].common.total_records &&
          this.state.showitems > nextProps.cateringpast[0].common.total_records
        ) {
          $(".cateringloadmore1").hide();
        }
      } else {
        $(".cateringloadmore1").hide();
      }
    }

    if (nextProps.cateringhistory !== this.props.cateringhistory) {
      if (nextProps.cateringhistory[0].status === "ok") {
        this.setState(
          {
            CateringCartItems:
              nextProps.cateringhistory[0].result_set[0]["items"],
            CateringReceiptDetails: nextProps.cateringhistory[0].result_set[0],
          },
          function () {
            this.makeBrkTimeList();
          }.bind(this)
        );

        $("#dvLoading").fadeOut(5000);
        setTimeout(function () {
          $.magnificPopup.open({
            items: {
              src: ".receipt_cateringpopup",
            },
            type: "inline",
          });
        }, 1000);
      } else {
        this.setState({ CateringReceiptDetails: [], CateringCartItems: [] });
      }
    }

    if (nextProps.cateringprint !== this.props.cateringprint) {
      if (nextProps.cateringprint[0].status === "ok") {
        $("#dvLoading").fadeOut(2000);
        window.open(nextProps.cateringprint[0].pdf_url, "_blank");
      }
    }
  }

  makeBrkTimeList() {
    var cartDetailsSate = this.state.CateringReceiptDetails;
    var cartItems = this.state.CateringCartItems;
    var cartBrkTmList = [];
    if (
      Object.keys(cartDetailsSate).length > 0 &&
      Object.keys(cartItems).length > 0
    ) {
      var breaktimeEnable = cartDetailsSate.order_breaktime_enable;
      var breaktimeCount = cartDetailsSate.order_breaktime_count;
      if (breaktimeEnable === "Yes" && parseInt(breaktimeCount) > 0) {
        var tempKeyArr = Array();
        var tempKey = 0;
        for (var key in cartItems) {
          var chkKey = cartItems[key].item_breaktime_indexflag;
          if (!(chkKey in tempKeyArr)) {
            tempKeyArr[chkKey] = tempKey;
            var tempItemArr = Array();
            var tempItemLst = [];
            var intVl = tempKey + 1;
            var time_lable = "Break " + intVl;
            tempItemArr["cart_detaile"] = {
              break_time_lable: time_lable,
              break_time_index: cartItems[key].item_breaktime_indexflag,
              break_time_start: cartItems[key].item_breaktime_started,
              break_time_end: cartItems[key].item_breaktime_ended,
            };
            tempItemLst[0] = cartItems[key];
            tempItemArr["cart_items"] = tempItemLst;
            cartBrkTmList[tempKey] = tempItemArr;
            tempKey++;
          } else {
            var extKey = tempKeyArr[chkKey];
            cartBrkTmList[extKey]["cart_items"].push(cartItems[key]);
          }
        }
      }
    }

    this.setState({ cart_brktime_list: cartBrkTmList });

    setTimeout(function () {
      if ($(".mCustomScrollbar").length > 0) {
        $(".mCustomScrollbar").mCustomScrollbar();
      }
    }, 800);
  }

  getReceipt(orderId) {
    $("#dvLoading").fadeIn();
    var params =
      "&customer_id=" + cookie.load("UserId") + "&order_id=" + orderId;
    this.props.getOrderHistory(params);
  }

  printReceipt(orderId, availabilityId) {
    $("#dvLoading").fadeIn();
    this.props.getPrintOrder(orderId, deliveryId);
  }

  /* CATERING VIEW & PRINT */

  getCateringReceipt(orderId) {
    $("#dvLoading").fadeIn();
    var historyparams =
      "&customer_id=" + cookie.load("UserId") + "&order_id=" + orderId;
    this.props.getCateringHistory(historyparams);
  }

  printcateringReceipt(orderId) {
    $("#dvLoading").fadeIn();
    this.props.getPrintOrder(orderId, cateringId);
  }

  getOrderItemData = (dataProp) => {
    var dataProp = dataProp !== undefined ? dataProp : Array();
    if (Object.keys(dataProp).length > 0) {
      return dataProp.map((item) => {
        const orderDate = Moment(item.order_date).format("DD/MM/YYYY");
        const orderTime = Moment(item.order_date).format("h:mm A");
        return (
          <div key={item.order_id} className="current_order">
            <div className="myacc_head_sec">
              <div className="head_left">
                <div className="head-group">
                  <h4>Order No - {item.order_local_no}</h4>
                </div>
              </div>
              <div className={item.status_name.toLowerCase() + " head_right"}>
                <div className="head-group">
                  <h4>{item.status_name}</h4>
                </div>
              </div>
            </div>

            <div className="order_details_body">
              <div className="order_no_deatails">
                Order placed at :
                {Moment(item.order_created_on).format("DD/MM/YYYY") +
                  " " +
                  Moment(item.order_created_on).format("h:mm A")}
                <span>Pay by : {item.order_method_name}</span>
              </div>
              {item.order_availability_id === deliveryId ? (
                <div className="delivery_total delivery_total_text">
                  <div className="delivery_total_left delivery_total_right">
                    <h3>Delivery Location</h3>
                    <span>
                      {addressFormat(
                        item.order_customer_unit_no1,
                        item.order_customer_unit_no2,
                        item.order_customer_address_line1,
                        item.order_customer_address_line2,
                        item.order_customer_postal_code
                      )}
                    </span>
                  </div>
                </div>
              ) : (
                <div className="delivery_total delivery_total_text pickup-order-div">
                  <div className="delivery_total_left delivery_total_right">
                    <h3>Pickup Location</h3>
                    <span>
                      {item.outlet_name !== "" && item.outlet_name !== undefined
                        ? stripslashes(item.outlet_name)
                        : ""}
                    </span>
                    <span>
                      {addressFormat(
                        item.outlet_unit_number1,
                        item.outlet_unit_number2,
                        item.outlet_address_line1,
                        item.outlet_address_line2,
                        item.outlet_postal_code
                      )}
                    </span>
                  </div>
                </div>
              )}
              <div className="delivery_total delivery_total_number">
                <div className="delivery_total_left">
                  <h2>
                    {item.order_availability_id === deliveryId
                      ? "Delivery"
                      : "Pickup"}{" "}
                    Date
                  </h2>
                  <h4 className="checkoutDate">{orderDate}</h4>
                </div>
                <div className="delivery_total_left delivery_total_right">
                  <h2>
                    {item.order_availability_id === deliveryId
                      ? "Delivery"
                      : "Pickup"}{" "}
                    time
                  </h2>
                  <h4 className="checkoutTime">{orderTime}</h4>
                </div>
              </div>
            </div>

            <div className="order_details_footer">
              <div className="order_amt">
                <div className="order_amt-left">
                  <h3>Order Amount</h3>
                </div>
                <div className="order_amt-right">
                  <h3 className="text-right">
                    <sup>$</sup>
                    {item.order_total_amount}
                  </h3>
                </div>
              </div>

              {/*onClick={this.printReceipt.bind(this, item.order_primary_id)} onClick={this.getReceipt.bind(this, item.order_id)}*/}

              <div className="order_btns">
                <a
                  href="javascript:void(0)"
                  onClick={this.printReceipt.bind(this, item.order_primary_id)}
                  className="button print_invoice"
                  title=""
                >
                  Print Invoice
                </a>
                <a
                  href="javascript:void(0)"
                  onClick={this.getReceipt.bind(this, item.order_id)}
                  className="button view_recipt"
                  title=""
                >
                  View Receipt
                </a>
              </div>
            </div>
          </div>
        );
      });
    } else {
      return <div className="no-recrds-found">No records found</div>;
    }
  };

  /* catering order - start*/

  getCateringItemData = (dataProp) => {
    if (dataProp) {
      return dataProp.map((item) => {
        const orderDate = Moment(item.order_date).format("DD/MM/YYYY");
        const orderTime = Moment(item.order_date).format("h:mm A");

        return (
          <div key={item.order_id} className="current_order">
            <div className="myacc_head_sec">
              <div className="head_left">
                <div className="head-group">
                  <h4>ORDER NO - {item.order_local_no}</h4>
                </div>
              </div>
              <div className="head_right">
                <div className="head-group">
                  <h4>{item.status_name}</h4>
                </div>
              </div>
            </div>

            <div className="order_details_body">
              <div className="order_no_deatails">
                Order placed at :
                {Moment(item.order_created_on).format("DD/MM/YYYY") +
                  " " +
                  Moment(item.order_created_on).format("h:mm A")}
                <span>Our Staff will contact you for order confirmation</span>
              </div>
              <div className="delivery_total delivery_total_text">
                <div className="delivery_total_left">
                  <img src={scotterImg} />
                  <h3>Order Handling By</h3>
                  <span>{stripslashes(item.order_availability_name)}</span>
                  <span>{stripslashes(item.order_customer_address_line1)}</span>
                </div>
                <div className="delivery_total_left delivery_total_right">
                  <h3>Event Location</h3>
                  <span>
                    {stripslashes(item.order_customer_billing_address_line1)}
                  </span>
                </div>
              </div>
              <div className="delivery_total delivery_total_number">
                <div className="delivery_total_left">
                  <h2>Event Date</h2>
                  <h4 className="checkoutDate">{orderDate}</h4>
                </div>
                <div className="delivery_total_left delivery_total_right">
                  <h2>Event time</h2>
                  <h4 className="checkoutTime">{orderTime}</h4>
                </div>
              </div>
            </div>

            <div className="order_details_footer">
              <div className="order_amt">
                <div className="order_amt-left">
                  <h3>Order Amount</h3>
                </div>
                <div className="order_amt-right">
                  <h3 className="text-right">
                    <sup>$</sup>
                    {item.order_total_amount}
                  </h3>
                </div>
              </div>

              {/*onClick={this.printReceipt.bind(this, item.order_primary_id)} onClick={this.getReceipt.bind(this, item.order_id)}*/}

              <div className="order_btns">
                {/*<a href="javascript:void(0)" onClick={this.printcateringReceipt.bind(this, item.order_primary_id)}   className="button print_invoice" title="">Print Invoice</a>*/}

                <a
                  href="javascript:void(0)"
                  onClick={this.getCateringReceipt.bind(this, item.order_id)}
                  className="button view_recipt button_full_view"
                  title=""
                >
                  View Receipt
                </a>
              </div>
            </div>
          </div>
        );
      });
    } else {
      return <div className="no-recrds-found">No records found</div>;
    }
  };

  render() {
    var settingsMyAcc = {
      infinite: false,
      slidesToShow: 4,
      slidesToScroll: 4,
      initialSlide: 1,
      responsive: [
        {
          breakpoint: 1191,
          settings: {
            slidesToShow: 3,
            slidesToScroll: 3,
            infinite: false,
          },
        },
        {
          breakpoint: 900,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 2,
            infinite: false,
          },
        },
        {
          breakpoint: 480,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
            infinite: false,
          },
        },
      ],
    };

    /*	setTimeout(function () {
		//	console.log('render1',$('#dvLoading').length);		
            $('#dvLoading').remove();
						
					}, 500);*/

    return (
      <div className="myacc-main-div">
        <Header />
        <div className="common-inner-blckdiv">
          <div className="common-inner-banner">
            <h1>My Account</h1>
            <p>
              My Account Dashboard allows you to view your recent activities,
              check your reward points and update account information.
            </p>
          </div>
        </div>
        <div className="innersection_wrap myadmin_wrap">
          <div className="mainacc_menusec">
            {/* container div - start */}
            <div className="container accout-flex">
              <div className="mainacc_menuout">
                <i className="fa fa-bars nav-btn"></i>
                <ul className="mainacc_menulist">
                  <li>
                    <Link to="/myaccount" title="My Account">
                      <span>Account Informations</span>
                    </Link>
                  </li>
                  <li className="active">
                    <Link to="/myorders" title="My Orders">
                      <span>My Orders</span>
                      {parseFloat(this.state.overall_orders) > 0 && (
                        <span id="masterCount">
                          {this.state.overall_orders}
                        </span>
                      )}
                    </Link>
                  </li>
                  <li className="">
                    <Link to="/rewards" title="My Rewards">
                      <span>Rewards</span>
                    </Link>
                  </li>
                  <li>
                    <Link to="/mypromotions" title="My Promotions">
                      <span>Promotions</span>
                    </Link>
                  </li>
                  <li>
                    <Link to="/logout" title="Logout">
                      <span>Logout</span>
                    </Link>
                  </li>
                </ul>
              </div>
              <div className="mainacc_menucontent">
                <div className="main_tabsec">
                  <div className="order-tab-section">
                    <div className="mainacc_mobrow">
                      <div className="tab_sec main_tabsec_inner">
                        <div className="myacc_filter">
                          <div className="tab_sec filter_tabsec" id="ordertab1">
                            <ul className="nav nav-tabs text-center">
                              <li className="active">
                                <a
                                  data-toggle="tab"
                                  href="#tab-id-inn5"
                                  aria-expanded="true"
                                >
                                  <span>Current Orders</span>
                                </a>
                              </li>
                              <li className="">
                                <a
                                  data-toggle="tab"
                                  href="#tab-id-inn6"
                                  aria-expanded="false"
                                >
                                  <span>Past Orders</span>
                                </a>
                              </li>
                            </ul>
                            <div className="tab-content">
                              <div
                                id="tab-id-inn5"
                                className="tab-pane fade active in"
                              >
                                <h4 className="tab_mobtrigger inner_tab_border active">
                                  Current orders<i></i>
                                </h4>
                                <div className="tab_mobrow filter_tabin">
                                  <div className="order-delivery">
                                    <div className="ord-body">
                                      <div className="cur-order-body">
                                        <div className="myacc_order_details">
                                          {this.getOrderItemData(
                                            this.state.corderdetail
                                          )}
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div className="load_more_div">
                                  <button
                                    className="load_more_data"
                                    onClick={this.loadcurrentItems.bind(this)}
                                    style={{ display: "none" }}
                                  >
                                    Load More
                                  </button>
                                </div>
                              </div>
                              <div id="tab-id-inn6" className="tab-pane fade">
                                <h4 className="tab_mobtrigger inner_tab_border">
                                  Past Orders<i></i>
                                </h4>
                                <div className="tab_mobrow filter_tabin">
                                  <div className="pst-order-body">
                                    <div className="myacc_order_details">
                                      {this.getOrderItemData(
                                        this.state.porderdetail
                                      )}
                                    </div>
                                  </div>
                                </div>
                                <div className="load_more_div">
                                  <button
                                    className="load_more_data1"
                                    style={{ display: "none" }}
                                    onClick={this.loadpastItems.bind(this)}
                                  >
                                    Load More
                                  </button>
                                </div>
                              </div>
                            </div>
                          </div>
                          {/* next tab */}

                          <div className="tab_sec filter_tabsec" id="ordertab2">
                            <ul className="nav nav-tabs text-center">
                              <li className="active">
                                <a
                                  data-toggle="tab"
                                  href="#tab-id-inn7"
                                  aria-expanded="true"
                                >
                                  <span>Current Orders</span>
                                </a>
                              </li>
                              <li className="">
                                <a
                                  data-toggle="tab"
                                  href="#tab-id-inn8"
                                  aria-expanded="false"
                                >
                                  <span>Past Orders</span>
                                </a>
                              </li>
                            </ul>
                            <div className="tab-content">
                              <div
                                id="tab-id-inn7"
                                className="tab-pane fade active in"
                              >
                                <h4 className="tab_mobtrigger inner_tab_border active">
                                  Current orders<i></i>
                                </h4>
                                <div className="tab_mobrow filter_tabin">
                                  <div className="order-delivery">
                                    <div className="ord-body">
                                      <div className="cur-order-body">
                                        <div className="myacc_order_details">
                                          {this.getCateringItemData(
                                            this.state.cateringorderdetail
                                          )}
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div className="load_more_div">
                                  <button
                                    className="cateringloadmore"
                                    onClick={this.cateringloadItems.bind(this)}
                                  >
                                    Load More
                                  </button>
                                </div>
                              </div>
                              <div id="tab-id-inn8" className="tab-pane fade">
                                <h4 className="tab_mobtrigger inner_tab_border">
                                  Past Orders<i></i>
                                </h4>
                                <div className="tab_mobrow filter_tabin">
                                  <div className="pst-order-body">
                                    <div className="myacc_order_details">
                                      {this.getCateringItemData(
                                        this.state.cateringpastdetail
                                      )}
                                    </div>
                                  </div>
                                </div>
                                <div className="load_more_div">
                                  <button
                                    className="cateringloadmore1"
                                    onClick={this.cateringpastItems.bind(this)}
                                  >
                                    Load More
                                  </button>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* container div - end */}
          </div>
        </div>

        <Footer />

        <div
          id="receipt-popup"
          className="white-popup mfp-hide popup_sec receipt_popup catering_receipt_popup"
        >
          <div className="pouup_in">
            <Viewreceipt
              details={this.state.ReceiptDetails}
              cartItems={this.state.CartItems}
              ordergroups={this.state.ordergroups}
            />
          </div>
        </div>

        <div
          id="receipt-popup"
          className="white-popup mfp-hide popup_sec receipt_cateringpopup catering_receipt_popup"
        >
          <div className="pouup_in">
            <Viewcateringreceipt
              details={this.state.CateringReceiptDetails}
              cartItems={this.state.CateringCartItems}
              brktimeList={this.state.cart_brktime_list}
            />
          </div>
        </div>
        <div id="dvLoading"></div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    corderdetail: state.corderdetail,
    porderdetail: state.porderdetail,
    printorder: state.printorder,
    orderhistory: state.orderhistory,
    cateringorder: state.cateringorder,
    cateringpast: state.cateringpast,
    cateringhistory: state.cateringhistory,
    cateringprint: state.cateringprint,
    activitycount: state.activitycount,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getCorderDetail: (deliveryparams) => {
      dispatch({ type: GET_CORDERDETAIL, deliveryparams });
    },
    getPorderDetail: (deliverypastparams) => {
      dispatch({ type: GET_PORDERDETAIL, deliverypastparams });
    },
    getPrintOrder: (orderprimaryId, availabilityId) => {
      dispatch({ type: GET_PRINTORDER, orderprimaryId, availabilityId });
    },
    getOrderHistory: (params) => {
      dispatch({ type: GET_ORDERHISTORY, params });
    },
    getCateringOrder: (cateringparams) => {
      dispatch({ type: GET_CATERINGCORDER, cateringparams });
    },
    getCateringPast: (cateringpastparams) => {
      dispatch({ type: GET_CATERINGPORDER, cateringpastparams });
    },
    getCateringHistory: (historyparams) => {
      dispatch({ type: GET_CATERINGHISTORY, historyparams });
    },
    getCateringPrint: (orderprimaryId) => {
      dispatch({ type: GET_CATERINGPRINT, orderprimaryId });
    },
    getActivityCount: (getObject) => {
      dispatch({ type: GET_ACTIVITYCOUNT, getObject });
    },
  };
};

Orders.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }),
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Orders));

class Viewreceipt extends Component {
  constructor(props) {
    super(props);
  }

  getCartDetList(cartItems, ordergroups) {
    if (Object.keys(cartItems).length > 0) {
      return Object.keys(cartItems).map((index) => {
        var outlet = cartItems[index].outlet;
        var outletamt = cartItems[index].outlet_amount;
        return (
          <div className="outlet-product-inner" key={index}>
            <div className="outlet-name">{outlet}</div>
            {Object.keys(cartItems[index].items).map((index1) => {
              return (
                <>{this.getCartItemDetList(cartItems[index].items[index1])}</>
              );
            })}
            {outletamt !== "" && outletamt.outlet_tax_amount > 0 && (
              <div className="outlet-gst">
                <span>GST({displayoutletTax(outletamt.outlet_tax)})</span>
                <span className="outlet-gst-price">
                  ${outletamt.outlet_tax_amount}
                </span>
              </div>
            )}
          </div>
        );
      });
    }
  }

  getCartItemDetList(cartItems) {
    if (cartItems.length > 0) {
      return cartItems.map((item, index) => (
        <div className="cart_row oreder_itm_row_inner" key={index}>
          <div className="row oreder-row-inv">
            <div className="col-xs-7 cart_left">
              <div className="cart_info">
                <h4>
                  {stripslashes(item.item_name)} X {item.item_qty}
                </h4>
                {this.loadModifierItems(
                  "Component",
                  item.modifiers,
                  item.set_menu_component
                )}
              </div>
              {item.item_specification !== "" && (
                <p className="help-block">
                  {stripslashes(item.item_specification)}
                </p>
              )}
              {/*} 
				 <p className ="help-block">Special instruction for individual product items ...</p>
				 {*/}
            </div>
            <div className="col-xs-5 cart_right text-right">
              <div className="cart_price">
                <p>${parseFloat(item.item_total_amount).toFixed(2)}</p>
              </div>
            </div>
          </div>
        </div>
      ));
    }
  }

  /* this function used to load modifer items */
  loadModifierItems(itemType, modifiers, combo) {
    var len = modifiers.length;
    var comboLen = combo.length;
    var html = '<div className="cart_extrainfo">';
    if (len > 0) {
      for (var i = 0, length = len; i < length; i++) {
        var modName = modifiers[i]["order_modifier_name"];
        var modval = modifiers[i]["modifiers_values"][0]["order_modifier_name"];
        html +=
          "<p><b>" +
          stripslashes(modName) +
          ":</b></p><p> " +
          stripslashes(modval) +
          "</p>";
      }
      html += "</div>";
      var reactElement = Parser(html);
      return reactElement;
    } else if (comboLen > 0) {
      for (var i = 0, length = comboLen; i < length; i++) {
        var comboName = combo[i]["menu_component_name"];
        var comboVal = this.showComboProducts(combo[i]["product_details"]);
        html +=
          "<p><b>" +
          comboName +
          ":</b></p><p> " +
          comboVal +
          " " +
          this.showComboModifiers(combo[i]["product_details"][0]["modifiers"]) +
          "</p>";
      }
      html += "</div>";
      var reactElement = Parser(html);
      return reactElement;
    }
  }

  /* show combo products  list */
  showComboProducts(combos) {
    var lenCombo = combos.length;
    var html = " ";
    if (lenCombo > 0) {
      for (var r = 0, lengthCombo = lenCombo; r < lengthCombo; r++) {
        var comboPro = combos[r]["menu_product_name"];
        var comboQty = combos[r]["menu_product_qty"];
        var comboPrice = combos[r]["menu_product_price"];
        var newPrice = comboPrice > 0 ? " (+" + comboPrice + ")" : "";
        html += "<p>" + comboQty + " X " + comboPro + newPrice + " </p>";
      }
      return html;
    }
    return "";
  }
  /* this function used to show combo modifieirs list */
  showComboModifiers(modifiers) {
    var lenMod = modifiers.length;
    var html = "";
    if (lenMod > 0) {
      html = "<div > ";
      for (var i = 0, length = lenMod; i < length; i++) {
        var modName = modifiers[i]["order_modifier_name"];
        var modval = modifiers[i]["modifiers_values"][0]["order_modifier_name"];
        var modValPrice =
          modifiers[i]["modifiers_values"][0]["order_modifier_price"];
        var newModValPrice = modValPrice > 0 ? " (+" + modValPrice + ")" : "";
        html +=
          "<p><b>" +
          modName +
          ":</b></p><p> " +
          modval +
          newModValPrice +
          "</p>";
      }
      html += "</div>";
    }
    return html;
  }
  componentWillReceiveProps(nextProps) {}
  sendEmailReceipt(orderID, e) {
    e.preventDefault();
    showLoader("Viewreceipt", "class");
    axios
      .get(
        apiUrl +
          "ordersv1/order_email?app_id=" +
          appId +
          "&order_primary_id=" +
          orderID
      )
      .then((response) => {
        hideLoader("Viewreceipt", "class");
        $("#email_receipt").show();
        setTimeout(function () {
          $("#email_receipt").hide();
        }, 3000);
      });
  }
  render() {
    const { details, cartItems, ordergroups } = this.props;
    if (details !== undefined) {
      return (
        <div className="Viewreceipt">
          <div className="tnk-you">
            <div className="tnk-header">
              <div className="tnk-detail">
                <span
                  onClick={this.sendEmailReceipt.bind(
                    this,
                    details.order_primary_id
                  )}
                >
                  Email Receipt
                </span>
                <h2>Your Order Details</h2>
                <div
                  className="custom_center_alertcls alert-success alert_fixed success_hide alert_sucess_sticky"
                  id="email_receipt"
                  style={{ display: "none" }}
                >
                  {" "}
                  <p className="jquery-common-success-msg">
                    Receipt send successfully
                  </p>{" "}
                </div>
                <div className="tnk-order">
                  <h3>Order No - {details.order_local_no}</h3>
                  <p>
                    Order placed at :{" "}
                    {Moment(details.order_created_on).format(
                      "DD-MM-YYYY hh:mm A"
                    )}
                    <br></br>Pay by : {details.order_method_name}
                  </p>
                </div>
              </div>
            </div>
            <div className="tnk-delivery">
              {details.order_availability_id === deliveryId ? (
                <div className="delivery_total delivery_total_text">
                  <div className="delivery_total_left delivery_total_right">
                    <h3>Deliver Location</h3>
                    <span>
                      {details.order_customer_address_line1}, Singapore{" "}
                      {details.order_customer_postal_code},
                      {details.order_customer_unit_no1 != "" &&
                      details.order_customer_unit_no2 != ""
                        ? details.order_customer_unit_no1 +
                          "-" +
                          details.order_customer_unit_no2
                        : ""}
                    </span>
                  </div>
                </div>
              ) : (
                <div className="delivery_total pickup-order-div">
                  <div className="delivery_total_left delivery_total_center">
                    <img src={pickupImg} />
                    <h3>Pickup Location</h3>
                    <span>
                      {details.outlet_name !== "" &&
                      details.outlet_name !== undefined
                        ? stripslashes(details.outlet_name)
                        : ""}
                    </span>
                    <span>
                      {addressFormat(
                        details.outlet_unit_number1,
                        details.outlet_unit_number2,
                        details.outlet_address_line1,
                        details.outlet_address_line2,
                        details.outlet_postal_code
                      )}
                    </span>
                  </div>
                </div>
              )}
              <div className="delivery_total delivery_total_number">
                <div className="delivery_total_left ">
                  <h2>
                    {details.order_availability_id === deliveryId
                      ? "Delivery"
                      : "Pickup"}{" "}
                    Date
                  </h2>
                  <h4 className="checkoutDate">
                    {Moment(details.order_date).format("DD-MM-YYYY")}
                  </h4>
                </div>
                <div className="delivery_total_left delivery_total_right">
                  <h2>
                    {details.order_availability_id === deliveryId
                      ? "Delivery"
                      : "Pickup"}{" "}
                    time
                  </h2>
                  <h4 className="checkoutTime">
                    {Moment(details.order_date).format("hh:mm A")}
                  </h4>
                </div>
              </div>

              <div className="hcart_tt horder_tt">
                <div className="row-replace">
                  <div className="col-sm-cls text-left">
                    <h3>Order Items</h3>
                  </div>
                </div>
              </div>

              <div className="order-items-maindiv">
                {this.getCartDetList(cartItems, ordergroups)}
              </div>

              <div className="cart_footer tnk_cart_footer">
                <div className="row">
                  <div className="col-xs-7">
                    <p className="text-uppercase">
                      Cutlery: {details.order_cutlery}
                    </p>
                  </div>
                </div>
                <div className="row">
                  <div className="col-xs-7">
                    <p className="text-uppercase">SUBTOTAL</p>
                  </div>
                  <div className="col-xs-5 text-right">
                    <span>${details.order_sub_total}</span>
                  </div>
                </div>
                {parseFloat(details.order_discount_amount) > 0 && (
                  <div className="cart_row">
                    <div className="row">
                      <div className="col-xs-7">
                        <p className="text-uppercase">
                          {details.order_discount_type === "redeem"
                            ? "Discount (-)"
                            : details.order_promocode_name !== ""
                            ? "Promocode (" + details.order_promocode_name + ")"
                            : "Promocode (-)"}
                        </p>
                      </div>
                      <div className="col-xs-5 text-right">
                        <span>${details.order_discount_amount}</span>
                      </div>
                    </div>
                  </div>
                )}
                {parseFloat(details.order_delivery_charge) > 0 && (
                  <div className="cart_row">
                    <div className="row">
                      <div className="col-xs-7">
                        <p className="text-uppercase">Delivery Charges</p>
                      </div>
                      <div className="col-xs-5 text-right">
                        <span>
                          $
                          {(
                            parseFloat(details.order_delivery_charge) +
                            parseFloat(details.order_additional_delivery)
                          ).toFixed(2)}
                        </span>
                      </div>
                    </div>
                  </div>
                )}
                {details.additionalcharge.length > 0 &&
                  details.additionalcharge.map((item) => {
                    if (parseFloat(item.order_additionalcharge_amount) > 0) {
                      return (
                        <div className="cart_row">
                          <div className="row-replace">
                            <div className="col-xs-cls">
                              <p className="text-uppercase">
                                {item.order_additionalcharge_title}
                              </p>
                            </div>
                            <div className="col-xs-cls text-right">
                              <span>
                                $
                                {parseFloat(
                                  item.order_additionalcharge_amount
                                ).toFixed(2)}
                              </span>
                            </div>
                          </div>
                        </div>
                      );
                    }
                  })}
                {details.order_tax_calculate_amount > 0 && (
                  <div className="row">
                    <div className="col-xs-7">
                      <p className="text-uppercase">GST</p>
                    </div>
                    <div className="col-xs-5 text-right">
                      <span>${details.order_tax_calculate_amount}</span>
                    </div>
                  </div>
                )}
                <div className="cart_row cart_footer_totrow grant-total-cls">
                  <div className="row-replace">
                    <div className="col-xs-cls">
                      <p className="text-uppercase">Total</p>
                    </div>
                    <div className="col-xs-cls text-right">
                      <span>${details.order_total_amount}</span>
                    </div>
                  </div>
                </div>
                {details.charity !== "" && (
                  <div className="cart_row cart_footer_totrow charity-total-cls">
                    <div className="row">
                      <div className="col-xs-7">
                        <p className="text-uppercase">Charity</p>
                      </div>
                      <div className="col-xs-5 text-right">
                        <span>
                          {showPriceValue(details.charity.charity_amount)}
                        </span>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      );
    } else {
      return <div></div>;
    }
  }
}

class Viewcateringreceipt extends Component {
  constructor(props) {
    super(props);
  }

  getCartDetList(details, cartItems, brktimeList) {
    var cartDetailsSate = details;
    var cartBrktimeLst = brktimeList;
    if (Object.keys(cartDetailsSate).length > 0) {
      var breaktimeEnable = cartDetailsSate.order_breaktime_enable;
      if (breaktimeEnable === "Yes" && Object.keys(cartBrktimeLst).length > 0) {
        return this.cartBrkTimeList(details, cartItems, brktimeList);
      } else {
        return this.cartListOnly(details, cartItems, brktimeList);
      }
    }
  }

  cartListOnly(details, cartItems, brktimeList) {
    return (
      <div className="panel panel-default">
        <div
          className="panel-heading act"
          role="tab"
          id="headingTb0"
          onClick={this.handlerActTab.bind(this, 0)}
        >
          <h4 className="panel-title">
            <a
              role="button"
              data-toggle="collapse"
              data-parent="#accordion"
              aria-expanded="true"
              href="#collapse0"
              className=""
            >
              Order Item Details
            </a>
          </h4>
        </div>
        <div
          id="collapse0"
          className="panel-collapse collapse mCustomScrollbar in"
          aria-expanded="true"
          href="#collapse0"
        >
          <div className="panel-body">{this.cartItemlist(cartItems)}</div>
        </div>
      </div>
    );
  }

  cartBrkTimeList(details, cartItems, brktimeList) {
    var cartBrktimeLst = brktimeList;

    const cartBrktmhtml = cartBrktimeLst.map((cartBrk, indx) => (
      <div className="panel panel-default" key={indx}>
        <div
          className={indx === 0 ? "panel-heading act" : "panel-heading"}
          role="tab"
          id={"headingTb" + indx}
          onClick={this.handlerActTab.bind(this, indx)}
        >
          <h4 className="panel-title">
            <a
              role="button"
              data-toggle="collapse"
              data-parent="#accordion"
              aria-expanded={indx === 0 ? "true" : "false"}
              href={"#collapse" + indx}
              className={indx === 0 ? "" : "collapsed"}
            >
              {cartBrk["cart_detaile"].break_time_lable} :{" "}
              <span className="hr">
                {timeToConv12(cartBrk["cart_detaile"].break_time_start)}
              </span>
            </a>
          </h4>
        </div>
        <div
          id={"collapse" + indx}
          className={
            indx === 0
              ? "panel-collapse collapse mCustomScrollbar in"
              : "panel-collapse collapse mCustomScrollbar"
          }
          aria-expanded={indx === 0 ? "true" : "false"}
          href={"#collapse" + indx}
        >
          <div className="panel-body">
            {this.cartItemlist(cartBrk["cart_items"])}
          </div>
        </div>
      </div>
    ));
    return cartBrktmhtml;
  }

  handlerActTab(indx) {
    var ariaAxp = $("#headingTb" + indx)
      .find("a")
      .attr("aria-expanded");
    if (ariaAxp === "true") {
      $("#headingTb" + indx).addClass("act");
    } else {
      $("#headingTb" + indx).removeClass("act");
    }
    $(".panel-heading")
      .not($("#headingTb" + indx))
      .removeClass("act");
  }

  cartItemlist(itemLst) {
    if (Object.keys(itemLst).length > 0) {
      const cartlsthtml = itemLst.map((cartLst, indx1) => (
        <div key={indx1}>
          {/* package div start */}
          <div className="package">
            <div className="row package_cnt">
              <div className="col-sm-4 col-xs-4">
                <a href="javascript:void(0)">
                  <span className="title">{cartLst.item_name}</span>
                </a>
              </div>
              <div className="col-sm-4 col-xs-4">
                <div className="qtybxs"> {cartLst.item_qty} pax </div>
              </div>
              <div className="col-sm-4 col-xs-4">
                <div className="amt">
                  <span>{showPriceValue(cartLst.item_total_amount)}</span>
                </div>
              </div>
            </div>

            {this.showModifiers(cartLst.modifiers)}
          </div>
          {/* package div end */}

          <div className="brline"></div>

          {Object.keys(cartLst.addons_setup).length > 0 ? (
            <div className="addon">
              <h4>ADD-ONS</h4>
              {this.addonsSetupLst(cartLst)}
            </div>
          ) : (
            ""
          )}

          <div className="brline"></div>

          {Object.keys(cartLst.setup).length > 0 ? this.setupDet(cartLst) : ""}

          <div className="brline"></div>

          {cartLst.item_specification !== "" ? this.specialNotes(cartLst) : ""}
        </div>
      ));
      return cartlsthtml;
    }
  }

  showModifiers(mdfLst) {
    if (Object.keys(mdfLst).length > 0) {
      const mdflsthtml = mdfLst.map((mdfLt, indx3) => (
        <div key={indx3} className="package-list">
          <p className="one">{mdfLt.order_modifier_name}</p>
          <p className="two">{this.showMdfValues(mdfLt.modifiers_values)}</p>
        </div>
      ));
      return mdflsthtml;
    }
  }

  showMdfValues(mdfValLst) {
    var mdfValueText = "";
    if (Object.keys(mdfValLst).length > 0) {
      var tempVl = Object.keys(mdfValLst).length - 1;
      for (var key in mdfValLst) {
        var cartmdfprice =
          parseFloat(mdfValLst[key].order_modifier_price) > 0
            ? " ( " + showPriceValue(mdfValLst[key].order_modifier_price) + " )"
            : "";
        mdfValueText +=
          parseInt(tempVl) === parseInt(key)
            ? stripslashes(mdfValLst[key].order_modifier_name) + cartmdfprice
            : stripslashes(mdfValLst[key].order_modifier_name) +
              cartmdfprice +
              ", ";
      }
    }
    return mdfValueText;
  }

  addonsSetupLst(cartLstDat) {
    var addonsSet = cartLstDat.addons_setup;
    const addonhtml = addonsSet.map((addonSt, indx4) => (
      <div key={indx4} className="row addon_list">
        <div className="col-sm-6 col-xs-9">
          <h5>
            <a href="javascript:void(0)">
              <span className="title">{addonSt.as_setup_title}</span>
            </a>
          </h5>
          {this.addonsSetupValues(addonSt.addons_setup_values)}
        </div>
        <div className="col-sm-6 col-xs-3">
          {/*<div className="amt">
										  <span>{showPriceValue(addonSt.as_setup_amount)}</span>
									</div>*/}
        </div>
      </div>
    ));
    return addonhtml;
  }

  addonsSetupValues(addonsVal) {
    if (Object.keys(addonsVal).length > 0) {
      const addonValhtml = addonsVal.map((addonVl, indx5) => (
        <p key={indx5}>
          {addonVl.asv_setup_val_title} ({addonVl.asv_setup_val_qty}X{" "}
          {parseFloat(addonVl.asv_setup_val_price) > 0
            ? showPriceValue(addonVl.asv_setup_val_price)
            : ""}
          )
        </p>
      ));
      return addonValhtml;
    }
  }

  setupDet(cartLstDat) {
    var setupDt = cartLstDat.setup;
    var setupLbl = "";
    var setupPrice = 0;

    for (var key in setupDt) {
      setupLbl = setupDt[key].os_setup_type;
      setupPrice += parseFloat(setupDt[key].os_setup_total_price);
    }

    return (
      <div className="buffet_setup">
        <div className="row">
          <div className="col-sm-6 col-xs-9">
            <h5>
              <a href="javascript:void(0)">
                <span className="title">{setupLbl} Setup</span>
              </a>
            </h5>
          </div>
          <div className="col-sm-6 col-xs-3">
            {/*<div className="amt">
							<span>{showPriceValue(setupPrice)}</span>
					</div>*/}
          </div>
        </div>
        <div className="row">
          <div className="col-sm-10 col-xs-10">{this.setupList(setupDt)}</div>
        </div>
      </div>
    );
  }

  setupList(setupDats) {
    if (Object.keys(setupDats).length > 0) {
      const setupDathtml = setupDats.map((setupVl, indx6) => (
        <p key={indx6}>
          {setupVl.os_setup_name} : {setupVl.os_setup_description}{" "}
          {parseFloat(setupVl.os_setup_total_price) > 0
            ? " ( " + showPriceValue(setupVl.os_setup_total_price) + " )"
            : ""}
        </p>
      ));
      return setupDathtml;
    }
  }

  specialNotes(cartLstDat) {
    var special_not = cartLstDat.item_specification;

    return (
      <div className="buffet_setup" style={{ paddingTop: "0px" }}>
        <div className="row">
          <div className="col-sm-6 col-xs-8">
            <h5>
              <a href="">
                <span className="title">Special Notes</span>
              </a>
            </h5>
          </div>
          <div className="col-sm-6 col-xs-4"></div>
        </div>
        <div className="row">
          <div className="col-sm-10 col-xs-10">
            <p>{special_not}</p>
          </div>
        </div>
      </div>
    );
  }

  cartHallData(details, cartItems, brktimeList) {
    var cartDetailsMn = details;
    if (Object.keys(cartDetailsMn).length > 0) {
      if (cartDetailsMn.order_venue_type === "hall") {
        return (
          <div className="panel panel-default">
            <div
              className="panel-heading"
              role="tab"
              id="headingTbH1"
              onClick={this.handlerActTab.bind(this, "H1")}
            >
              <h4 className="panel-title">
                <a
                  role="button"
                  data-toggle="collapse"
                  data-parent="#accordion"
                  aria-expanded="false"
                  href="#collapseH1"
                  className="collapsed"
                >
                  Catering Hall Details
                </a>
              </h4>
            </div>
            <div
              id="collapseH1"
              className="panel-collapse fixed-height-cls collapse mCustomScrollbar"
              aria-expanded="false"
              href="#collapseH1"
            >
              <div className="panel-body">
                <div className="buffet_setup" style={{ padding: "0px" }}>
                  <div className="row">
                    <div className="col-sm-6 col-xs-8">
                      <h5>
                        <a href="javascript:void(0)">
                          <span className="title">
                            {cartDetailsMn.order_hall_name}
                          </span>
                        </a>
                      </h5>
                    </div>
                    <div className="col-sm-6 col-xs-4">
                      <div className="amt">
                        <span>
                          {showPriceValue(cartDetailsMn.order_hall_charges)}
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        );
      }
    }
  }

  sendEmailReceipt(orderID, e) {
    e.preventDefault();

    axios
      .get(
        apiUrl +
          "Ordersv1/order_email?app_id=" +
          appId +
          "&order_primary_id=" +
          orderID
      )
      .then((response) => {
        console.log(response.data.result_set, "response.data.result_set");
        /*  if (response.data.status === "ok") {
          this.setState({ alloutletlist: response.data.result_set });
        } else {
        } */
      });
  }

  render() {
    const { details, cartItems, brktimeList } = this.props;

    return (
      <div className="Viewreceipt">
        <div className="tnk-you">
          <div className="tnk-header">
            <div className="tnk-detail">
              <a href="#" onClick={this.sendEmailReceipt.bind(this, details)}>
                Email Receipt
              </a>
              <h2>Your Order Details</h2>
              <div className="tnk-order">
                <h3>Order No - {details.order_local_no}</h3>
                <p>
                  Order placed at :{" "}
                  {Moment(details.order_created_on).format(
                    "DD-MM-YYYY hh:mm A"
                  )}
                </p>
              </div>
            </div>
          </div>

          <div className="tnk-delivery">
            <div className="delivery_total">
              <div className="delivery_total_left">
                <img src={scotterImg} />
                <h3>Catering Handling By</h3>{" "}
                <span>
                  {details.outlet_name !== "" &&
                  details.outlet_name !== undefined
                    ? stripslashes(details.outlet_name)
                    : ""}
                </span>
                <span>
                  {addressFormat(
                    details.outlet_unit_number1,
                    details.outlet_unit_number2,
                    details.outlet_address_line1,
                    details.outlet_address_line2,
                    details.outlet_postal_code
                  )}
                </span>{" "}
              </div>
              <div className="delivery_total_left delivery_total_right">
                <h3>Catering Location</h3>
                {details.order_hall_name !== "" ? (
                  <span>{details.order_hall_name}</span>
                ) : (
                  ""
                )}
                <span>
                  {addressFormat(
                    details.order_customer_unit_no1,
                    details.order_customer_unit_no2,
                    details.order_customer_address_line1,
                    details.order_customer_address_line2,
                    details.order_customer_postal_code
                  )}
                </span>
              </div>
            </div>

            <div className="delivery_total delivery_total_number">
              <div className="delivery_total_left">
                <h2>Event Date</h2>
                <h4 className="checkoutDate">
                  {Moment(details.order_date).format("DD-MM-YYYY")}
                </h4>
              </div>
              <div className="delivery_total_left delivery_total_right">
                <h2>Event time</h2>
                <h4 className="checkoutTime">
                  {Moment(details.order_date).format("hh:mm A")}
                </h4>
              </div>
            </div>

            <div className="order_details">
              <div
                className="panel-group"
                id="accordion"
                role="tablist"
                aria-multiselectable="true"
              >
                {this.getCartDetList(details, cartItems, brktimeList)}
                {this.cartHallData(details, cartItems, brktimeList)}
              </div>
            </div>

            <div className="cart_footer tnk_cart_footer">
              <div className="cart_row">
                <div className="row">
                  <div className="col-xs-7">
                    <p className="text-uppercase">SUBTOTAL</p>
                  </div>
                  <div className="col-xs-5 text-right">
                    <span>${details.order_sub_total}</span>
                  </div>
                </div>
              </div>

              {parseFloat(details.order_discount_amount) > 0 && (
                <div className="cart_row">
                  <div className="row">
                    <div className="col-xs-7">
                      <p className="text-uppercase">
                        {details.order_discount_type === "redeem"
                          ? "Discount (-)"
                          : "Promocode (-)"}
                      </p>
                    </div>
                    <div className="col-xs-5 text-right">
                      <span>${details.order_discount_amount}</span>
                    </div>
                  </div>
                </div>
              )}

              {details.order_tax_calculate_amount > 0 && (
                <div className="cart_row">
                  <div className="row">
                    <div className="col-xs-7">
                      <p className="text-uppercase">
                        GST({details.order_tax_charge}%)
                      </p>
                    </div>
                    <div className="col-xs-5 text-right">
                      <span>${details.order_tax_calculate_amount}</span>
                    </div>
                  </div>
                </div>
              )}

              <div className="cart_row cart_footer_totrow grant-total-cls">
                <div className="row">
                  <div className="col-xs-7">
                    <p className="text-uppercase">Total</p>
                  </div>
                  <div className="col-xs-5 text-right">
                    <span>${details.order_total_amount}</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
