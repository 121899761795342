/* eslint-disable */
import React, { Component } from "react";
import { appId, apiUrl } from "../Helpers/Config";
import axios from "axios";
/* import modules */
import Header from "../Layout/Header";
import Footer from "../Layout/Footer";

import PropTypes from "prop-types";
import { withRouter, Link } from "react-router-dom";
import blackLogo from "../../common/images/black_logo.png";
import whiteLogo from "../../common/images/logo_white.png";
import smallTick from "../../common/images/small-tick.png";
import { showLoader, hideLoader, showAlert } from "../Helpers/SettingHelper";
import { connect } from "react-redux";
var qs = require("qs");
class Kitchenpods extends Component {
  constructor(props) {
    super(props);
    this.state = {
      firstname: "",
      lastname: "",
      email: "",
      phone: "",
      restaurant_brand: "",
      restaurant_name: "",
      error_firstname: "",
      error_lastname: "",
      error_email: "",
      error_phone: "",
      error_restaurant_brand: "",
      error_restaurant_name: "",
      mobile_number: "",
      full_name: "",
      error_mobile_number: "",
      error_full_name: "",
    };
  }

  handleChange(event) {
    var value = event.target.value;
    var name = event.target.name;
    if (value !== "") {
      if (name === "phone") {
        const re = /^[0-9\b]+$/;
        if (event.target.value === "" || re.test(event.target.value)) {
          this.setState({
            [event.target.name]: event.target.value,
            ["error_" + event.target.name]: "",
          });
        }
      } else if (name === "email") {
        const pattern = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        console.log(
          pattern.test(event.target.value),
          "pattern.test(event.target.value)"
        );
        if (pattern.test(event.target.value)) {
          this.setState({
            [event.target.name]: event.target.value,
            ["error_" + event.target.name]: "",
            ["invalid_error_" + event.target.name]: "",
          });
        } else {
          this.setState({
            [event.target.name]: event.target.value,
            ["error_" + event.target.name]: "",
            ["invalid_error_" + event.target.name]: 1,
          });
        }
      } else {
        this.setState({
          [event.target.name]: event.target.value,
          ["error_" + event.target.name]: "",
        });
      }
    } else {
      this.setState({
        [event.target.name]: "",
        ["error_" + event.target.name]: 1,
      });
    }
  }
  sendSchedule() {
    var error = 0;
    if (this.state.firstname === "") {
      this.setState({ error_firstname: 1 });
      error++;
    } else {
      this.setState({ error_firstname: "" });
    }
    if (this.state.lastname === "") {
      this.setState({ error_lastname: 1 });
      error++;
    } else {
      this.setState({ error_lastname: "" });
    }
    if (this.state.email === "") {
      this.setState({ error_email: 1 });
      error++;
    } else {
      this.setState({ error_email: "" });
    }
    if (this.state.phone === "") {
      this.setState({ error_phone: 1 });
      error++;
    } else {
      this.setState({ error_phone: "" });
    }
    if (this.state.restaurant_brand === "") {
      this.setState({ error_restaurant_brand: 1 });
      error++;
    } else {
      this.setState({ error_restaurant_brand: "" });
    }
    if (this.state.restaurant_name === "") {
      this.setState({ error_restaurant_name: 1 });
      error++;
    } else {
      this.setState({ error_restaurant_name: "" });
    }
    if (error === 0) {
      var postObject = {
        app_id: appId,
        firstname: this.state.firstname,
        lastname: this.state.lastname,
        email: this.state.email,
        phone: this.state.phone,
        restaurant_brand: this.state.restaurant_brand,
        restaurant_name: this.state.restaurant_name,
      };
      showLoader("food_business", "class");
      axios
        .post(apiUrl + "formsdetails/foodbusiness", qs.stringify(postObject))
        .then((res) => {
          this.setState({
            firstname: "",
            lastname: "",
            email: "",
            phone: "",
            restaurant_brand: "",
            restaurant_name: "",
          });
          $(".focus-out").removeClass("focused");
          $(".restaurant_brand").prop("checked", false);
          hideLoader("food_business", "class");
          if (res.data.status === "ok") {
            showAlert("Success", res.data.message);
            $.magnificPopup.open({
              items: {
                src: ".alert_popup",
              },
              type: "inline",
            });
          } else if (res.data.status === "error") {
            var errormsg = "";
            if (
              res.data.form_error !== "" &&
              typeof res.data.form_error !== undefined &&
              typeof res.data.form_error !== "undefined"
            ) {
              errormsg = res.data.form_error;
            } else {
              errormsg = res.data.message;
            }
            showAlert("Alert", errormsg);
            $.magnificPopup.open({
              items: {
                src: ".alert_popup",
              },
              type: "inline",
            });
          }
        });
    } else {
      setTimeout(() => {
        if ($(".error:first").length > 0) {
          $(".error:first").focus();
          $("html, body").animate(
            {
              scrollTop: $(".error:first").offset().top - 150,
            },
            500
          );
        }
      }, 500);
    }
  }

  joinNow() {
    var error = 0;
    if (this.state.full_name === "") {
      this.setState({ error_full_name: 1 });
      error++;
    } else {
      this.setState({ error_full_name: "" });
    }
    if (this.state.mobile_number === "") {
      this.setState({ error_mobile_number: 1 });
      error++;
    } else {
      this.setState({ error_mobile_number: "" });
    }

    if (error === 0) {
      var postObject = {
        app_id: appId,
        full_name: this.state.full_name,
        mobile_number: this.state.mobile_number,
      };
      showLoader("signupride", "class");
      axios
        .post(apiUrl + "formsdetails/signupride", qs.stringify(postObject))
        .then((res) => {
          this.setState({
            full_name: "",
            mobile_number: "",
          });
          $(".focus-out").removeClass("focused");
          hideLoader("signupride", "class");
          if (res.data.status === "ok") {
            showAlert("Success", res.data.message);
            $.magnificPopup.open({
              items: {
                src: ".alert_popup",
              },
              type: "inline",
            });
          } else if (res.data.status === "error") {
            var errormsg = "";
            if (
              res.data.form_error !== "" &&
              typeof res.data.form_error !== undefined &&
              typeof res.data.form_error !== "undefined"
            ) {
              errormsg = res.data.form_error;
            } else {
              errormsg = res.data.message;
            }
            showAlert("Alert", errormsg);
            $.magnificPopup.open({
              items: {
                src: ".alert_popup",
              },
              type: "inline",
            });
          }
        });
    } else {
      setTimeout(() => {
        if ($(".error:first").length > 0) {
          $(".error:first").focus();
          $("html, body").animate(
            {
              scrollTop: $(".error:first").offset().top - 150,
            },
            500
          );
        }
      }, 500);
    }
  }

  isNumber(evt) {
    evt = evt ? evt : window.event;
    var charCode = evt.which ? evt.which : evt.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;
  }

  sateValChange() {}
  render() {
    return (
      <div className="myacc-main-div">
        <Header sateValChange={this.sateValChange} />
        <div className="fluid-container">
          <div className="fluid-content">
            <h1>
              Utilize our kitchen to capture
              <br />
              new markets and grow your food business
            </h1>
            <p>
              Start generating thousands in cremental revenue
              <br />
              With muslim-friendly, delivery-only F&B concepts in as quick as 30
              days
            </p>
            <div className="fluid-form">
              <div className="form-group">
                <div className="focus-out">
                  <label>First Name*</label>
                  <input
                    type="text"
                    name="firstname"
                    value={this.state.firstname}
                    onChange={this.handleChange.bind(this)}
                    className="form-control input-focus"
                  />
                  {this.state.error_firstname === 1 && (
                    <span class="error">This field is required.</span>
                  )}
                </div>
              </div>
              <div className="form-group">
                <div className="focus-out">
                  <label>Last Name*</label>
                  <input
                    type="text"
                    name="lastname"
                    value={this.state.lastname}
                    onChange={this.handleChange.bind(this)}
                    className="form-control input-focus"
                  />
                  {this.state.error_lastname === 1 && (
                    <span class="error">This field is required.</span>
                  )}
                </div>
              </div>
              <div className="form-group">
                <div className="focus-out">
                  <label>Email*</label>
                  <input
                    type="email"
                    name="email"
                    value={this.state.email}
                    onChange={this.handleChange.bind(this)}
                    className="form-control input-focus"
                  />
                  {this.state.error_email === 1 && (
                    <span class="error">This field is required.</span>
                  )}
                  {this.state.error_email === "" &&
                    this.state.invalid_error_email === 1 && (
                      <span class="error">Invalid Email.</span>
                    )}
                </div>
              </div>
              <div className="form-group">
                <div className="focus-out">
                  <label>Phone*</label>
                  <input
                    type="tel"
                    name="phone"
                    value={this.state.phone}
                    maxLength="8"
                    onChange={this.handleChange.bind(this)}
                    className="form-control input-focus"
                  />
                  {this.state.error_phone === 1 && (
                    <span class="error">This field is required.</span>
                  )}
                </div>
              </div>
              <div className="form-group fluid-radio">
                <label className="label-head">
                  Do you currently own a restaurant brand?*
                </label>
                <label>
                  <input
                    type="radio"
                    className="form-control restaurant_brand"
                    name="restaurant_brand"
                    value="Yes"
                    onChange={this.handleChange.bind(this)}
                  />{" "}
                  Yes
                </label>
                <label>
                  <input
                    type="radio"
                    className="form-control restaurant_brand"
                    name="restaurant_brand"
                    value="No"
                    onChange={this.handleChange.bind(this)}
                  />{" "}
                  No
                </label>
                {this.state.error_restaurant_brand === 1 && (
                  <span class="error">This field is required.</span>
                )}
              </div>
              <div className="form-group">
                <div className="focus-out">
                  <label>Your Restaurant Name*</label>
                  <input
                    type="text"
                    name="restaurant_name"
                    value={this.state.restaurant_name}
                    onChange={this.handleChange.bind(this)}
                    className="form-control input-focus"
                  />
                  {this.state.error_restaurant_name === 1 && (
                    <span class="error">This field is required.</span>
                  )}
                </div>
              </div>
              <p className="kk-join">
                Join the surging industry that’s here to stay!
              </p>
              <div className="form-group btn-button">
                <button
                  className="btn btn-center food_business"
                  onClick={this.sendSchedule.bind(this)}
                >
                  Schedule on-site tour
                </button>
              </div>
            </div>
          </div>
        </div>
        <section className="fluidpods-container">
          <div className="container">
            <div className="fluidpods-head">
              <img src={blackLogo} />
              <h2>
                Leverage the <span>#powerofkitchenpod</span>
              </h2>
            </div>
            <div className="fluidpods-descrip">
              <p className="txt-red">
                What is an optimized-for-delivery kitchenpod?
              </p>
              <h3>
                Optimized to capture <br /> the rise of food delivery
              </h3>
              <p>
                Our optimized-for-delivery F&B kitchen stall or kitchenpod is
                pre-approved and designed for <br />
                muslim-friendly F&B businesses to capitalize the surging food
                delivery market.
              </p>
            </div>
            <div className="fluoidpods-grid">
              <div className="fluid-item">
                <div className="fluid-round">Icon</div>
                <h5>
                  Your turnkey <br />
                  solutions
                </h5>
                <p>
                  You can customize & setup your kitchenpod quickly within 30
                  days* and power on your business.
                </p>
              </div>
              <div className="fluid-item">
                <div className="fluid-round">Icon</div>
                <h5>
                  Minimize upfront cost to
                  <br /> maximize profit
                </h5>
                <p>
                  A typical pre-built kichenpod space is ready-fitted with basic
                  essential kitchen equipments.{" "}
                </p>
              </div>
              <div className="fluid-item">
                <div className="fluid-round">Icon</div>
                <h5>
                  Focus on <br />
                  crafting your food{" "}
                </h5>
                <p>
                  All you need to do is then create, cook and sell under your
                  Foodshop licence. We’ll handle the rest.
                </p>
              </div>
            </div>
            <div className="fluidpods-descrip">
              <p className="txt-red">
                Leverage the power of optimized-for-delivery kitchenpod
              </p>
              <h3>
                Get in the action and expand your <br />
                market reach & revenue
              </h3>
              <p className="description-tick">
                <span>
                  <img src={smallTick} /> Strategic central location
                </span>
                <span>
                  <img src={smallTick} /> Lowest commission = Higher margins
                </span>
                <span>
                  <img src={smallTick} />
                  Muslim-friendly & Halal segment
                </span>
              </p>
            </div>
          </div>
          <div className="container">
            <div className="fluidpods-art">
              <p>Illustration placeholder</p>
            </div>
          </div>
          <div className="container">
            <div className="fluidpods-descrip">
              <p className="txt-red">
                Traditional restaurant vs. kloudkitchen® kitchenpod
              </p>
              <h3>
                Futureproof your food business <br />
                with minimal upfront cost
              </h3>
            </div>
            <div className="fluidpods-flex-table">
              <div className="fludipods-table-item">
                <div className="fluidpods-table table-red">
                  <div className="fluidpods-head-txt">
                    Traditional Restaurant
                  </div>
                  <div className="fluidpods-inner-table">
                    <p>More than $200k</p>
                    <p>6-8 months</p>
                    <p>Up to 4 years</p>
                    <p>10+ people</p>
                    <p>Single channel</p>
                  </div>
                </div>
              </div>
              <div className="fludipods-table-item fludipods-table-center">
                <div className="fluidpods-table">
                  <div className="fludipods-table-red">Vs.</div>
                  <div className="fluidpods-inner">
                    <p>Cost to build</p>
                    <p>Time to launch</p>
                    <p>Amortization</p>
                    <p>Staff on rotation</p>
                    <p>Customer segment</p>
                  </div>
                </div>
              </div>
              <div className="fludipods-table-item">
                <div className="fluidpods-table table-black">
                  <div className="fluidpods-head-txt">
                    <img src={whiteLogo} />
                  </div>
                  <div className="fluidpods-inner-table">
                    <p>More than $200k</p>
                    <p>6-8 months</p>
                    <p>Up to 4 years</p>
                    <p>10+ people</p>
                    <p>Single channel</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="fludipods-footer">
            <div className="fludipods-form-txt fludipods-footer-item">
              <p>Join the growing community of delivery folks</p>
              <h5>Sign up to drive or ride for delivery</h5>
            </div>
            <div className="fludipods-form fludipods-footer-item">
              <div className="fludipods-inner-form">
                <div className="form-group">
                  <div className="focus-out">
                    <label>Full Name*</label>
                    <input
                      type="text"
                      name="full_name"
                      value={this.state.full_name}
                      onChange={this.handleChange.bind(this)}
                      className="form-control input-focus"
                    />
                    {this.state.error_full_name === 1 && (
                      <span class="error">This field is required.</span>
                    )}
                  </div>
                </div>
                <div className="form-group">
                  <div className="focus-out">
                    <label>Mobile Number*</label>
                    <input
                      type="tel"
                      name="mobile_number"
                      value={this.state.mobile_number}
                      onChange={this.handleChange.bind(this)}
                      className="form-control input-focus"
                    />
                    {this.state.error_mobile_number === 1 && (
                      <span class="error">This field is required.</span>
                    )}
                  </div>
                </div>
                <div className="form-group btn-button">
                  <button
                    className="btn btn-center signupride"
                    onClick={this.joinNow.bind(this)}
                  >
                    Join Now
                  </button>
                </div>
              </div>
            </div>
          </div>
        </section>
        <Footer />
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {};
};

const mapDispatchToProps = (dispatch) => {
  return {};
};

Kitchenpods.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }),
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(Kitchenpods)
);
