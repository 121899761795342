/* eslint-disable */
import React, { Component } from "react";
import { appId, apiUrl } from "../Helpers/Config";
import axios from "axios";
import Header from "../Layout/Header";
import Footer from "../Layout/Footer";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
var Parser = require("html-react-parser");
class Faq extends Component {
  constructor(props) {
    super(props);
    this.state = { FaqList: [], FaqDisplay: "" };
  }
  componentDidMount() {
    var urlShringTxt = apiUrl + "cms/faq?status=A&app_id=" + appId;
    axios.get(urlShringTxt).then((res) => {
      var FaqList = "";
      $("#dvLoading").hide();
      if (res.data.status === "ok") {
        var FaqList = res.data.result_set;
      }
      this.setState({
        FaqList: FaqList,
      });
    });
  }

  sateValChange() {}


  render() {
    return (
      <div className="myacc-main-div">
        <Header sateValChange={this.sateValChange} />
        <div className="common-inner-blckdiv">
          <div className="common-inner-banner">
            <h1>FAQ</h1>
            {/*<p>Sample text line will be here in this section</p>*/}
          </div>
        </div>
        <div className="innersection_wrap myadmin_wrap">
          <div className="mainacc_menusec">
            {/* container div - start */}
            <div className="container">
              {this.state.FaqList.length > 0 && (
                <div {...{ className: "wrapper" }}>
                  <ul {...{ className: "accordion-list" }}>
                    {this.state.FaqList.map((item, index) => {
                      if (Object.keys(item.faqs).length > 0) {
                        var faqListNew = item.faqs;
                        return Object.keys(item.faqs).map((item1, index1) => {
                          var faqListCurrent = faqListNew[item1];
                          if (
                            faqListCurrent.faq_title !== "" &&
                            faqListCurrent.faq_title !== null
                          ) {
                            return (
                              <li
                                {...{
                                  className: "accordion-list__item",
                                  index1,
                                }}
                              >
                                <AccordionItem {...faqListCurrent} />
                              </li>
                            );
                          }
                        });
                      }
                    })}
                  </ul>
                </div>
              )}
            </div>
            {/* container div - end */}
          </div>
        </div>
        <div id="dvLoading" className="dvLoadrCls"></div>
        <Footer />
      </div>
    );
  }
}

class AccordionItem extends Component {
  state = {
    opened: false,
  };
  render() {
    const {
      props: { faq_description, faq_title },
      state: { opened },
    } = this;

    return (
      <div
        {...{
          className: `accordion-item ${opened && "accordion-item--opened"}`,
          onClick: () => {
            this.setState({ opened: !opened });
          },
        }}
      >
        <div {...{ className: "accordion-item__line" }}>
          <h3 {...{ className: "accordion-item__title" }}>
            {Parser(faq_title)}
          </h3>
          <span {...{ className: "accordion-item__icon" }} />
        </div>
        <div {...{ className: "accordion-item__inner" }}>
          <div {...{ className: "accordion-item__content" }}>
            <p {...{ className: "accordion-item__paragraph" }}>
              {Parser(faq_description)}
            </p>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {};
};

const mapDispatchToProps = (dispatch) => {
  return {};
};

Faq.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }),
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Faq));
