/* eslint-disable */
import React, { Component } from "react";
import { connect } from "react-redux";
import axios from "axios";
import Header from "../Layout/Header";
import Footer from "../Layout/Footer";
import { appId, apiUrl } from "../Helpers/Config";
import { removePromoCkValue, showAlert } from "../Helpers/SettingHelper";
import cookie from "react-cookies";
var qs = require("qs");
class Placeorder extends Component {
  constructor(props) {
    super(props);

    this.state = {
      cartTriggerFlg: "",
    };
    var urlParams = new URLSearchParams(this.props.location.search);
    if (urlParams.get("two_c_two_p_order_id") != "") {
      /*this.showSuccessPage(urlParams.get('local_order_no'));*/
      this.checkTwoCTwoP(urlParams.get("two_c_two_p_order_id"));

      //  this.postOrder(3, "No", "", "2C2P");
    }
  }

  checkTwoCTwoP(two_c_two_p_order_id) {
    console.log(two_c_two_p_order_id, "two_c_two_p_order_id");
    /*   this.setState({ twoCtwoPPayment_loading: true }, function () {
      $.magnificPopup.open({
        items: {
          src: "#processing-popup",
        },
        type: "inline",
        showCloseBtn: false,
        midClick: true,
        closeOnBgClick: false,
      });
    }); */

    var cabtureObjects = {
      app_id: appId,
      order_id: two_c_two_p_order_id,
    };
    axios
      .post(
        apiUrl + "paymentv1/checkTwoCTwoPStatus",
        qs.stringify(cabtureObjects)
      )
      .then((captureRes) => {
        console.log(captureRes.data.status, captureRes.data, "captureRes");
        if (captureRes.data.status === "ok") {
          this.showSuccessPage(captureRes.data.common.local_order_no);
          return;
        } else if (captureRes.data.status === "error") {
          var magnfPopup = $.magnificPopup.instance;
          var omsMsgTxt =
            captureRes.data.message != ""
              ? captureRes.data.message
              : "Your order was not successful.";
          showAlert("Error", omsMsgTxt, "No");
          $.magnificPopup.open({
            items: {
              src: ".alert_popup",
            },
            type: "inline",
          });
          this.props.history.push("/");
          return false;
        } else {
        }
      })
      .catch((error) => {
        var magnfPopup = $.magnificPopup.instance;
        showAlert("Error", error, "No");
        $.magnificPopup.open({
          items: {
            src: ".alert_popup",
          },
          type: "inline",
        });
        this.props.history.push("/");
        return false;
      });
  }

  /* sucess page */
  showSuccessPage(localOrderNo) {
    this.deleteOrderCookie();
    cookie.save("ChkOrderid", localOrderNo, { path: "/" });
    const { history } = this.props;
    $.magnificPopup.close();
    history.push("/thankyou/" + localOrderNo);
  }

  /* this function used to  delete all cookie values */
  deleteOrderCookie() {
    removePromoCkValue();

    cookie.remove("orderPaymentMode", { path: "/" });
    cookie.remove("orderTableNo", { path: "/" });
    cookie.remove("product_remarks", { path: "/" });
    /* cookie.remove("orderOutletName"); */
    //cookie.remove('orderOutletId');
    cookie.remove("carttotalitems", { path: "/" });
    cookie.remove("cartsubtotal", { path: "/" });
    cookie.remove("cartid", { path: "/" });

    /* Delivery avilablity */
    /*  cookie.remove("deliveryDate");
    cookie.remove("deliveryTime"); */
    cookie.remove("unitNoOne", { path: "/" });
    cookie.remove("unitNoTwo", { path: "/" });
    cookie.remove("firstNavigation", { path: "/" });

    cookie.remove("promotion_id", { path: "/" });
    cookie.remove("promotion_applied", { path: "/" });
    cookie.remove("promotion_code", { path: "/" });
    cookie.remove("promotion_delivery_charge_applied", { path: "/" });
    cookie.remove("promotion_amount", { path: "/" });
    cookie.remove("promotion_category", { path: "/" });
    cookie.remove("prmo_type", { path: "/" });
    cookie.remove("promoOutlet", { path: "/" });
    cookie.remove("promoType", { path: "/" });
    cookie.remove("promoPercentage", { path: "/" });

    /*Remove voucher*/
    cookie.remove("voucher_applied", { path: "/" });
    cookie.remove("voucher_code", { path: "/" });
    cookie.remove("voucher_amount", { path: "/" });

    cookie.remove("points_redeemed", { path: "/" });
    cookie.remove("points_used", { path: "/" });
    cookie.remove("points_amount", { path: "/" });
    cookie.remove("prmo_type", { path: "/" });
  }

  sateValChange = (field, value) => {};

  render() {
    return (
      <div className="checkout-main-div">
        {/* Header start */}
        <Header
          cartTriggerFlg={this.state.cartTriggerFlg}
          sateValChange={this.sateValChange}
        />
        {/* Header End */}

        <div className="common-inner-blckdiv">
          <div className="common-inner-banner">
            <p>Placing Order</p>
          </div>
        </div>

        <div id="dvLoading"></div>

        {/* Footer section */}
        <Footer />
        {/* Donate popup - end */}
      </div>
    );
  }
}

const mapStateTopProps = (state) => {
  return {};
};

const mapDispatchToProps = (dispatch) => {
  return {};
};
export default connect(mapStateTopProps, mapDispatchToProps)(Placeorder);
