/* eslint-disable */
import React, { Component } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { GET_STATIC_BLOCK } from "../../actions";


import whiteLogo from "../../common/images/logo_white.png";
import facebook from "../../common/images/fb_icon.png";
import instagram from "../../common/images/insta_icon.png";
import american_card from "../../common/images/american_express_card.png";
import master_card from "../../common/images/master_card.png";
import visa_card from "../../common/images/visa_card.png";
import paynow from "../../common/images/paynow.png";

import {
  stripslashes,
} from "../Helpers/SettingHelper";


var Parser = require("html-react-parser");

class Footer extends Component {
  constructor(props) {
    super(props);
    this.state = { footerBlocks: "", footerlink: "" };
  }

  componentDidMount() {
    this.props.getStaticBlock();

    var btn = $("#scrollbutton");

    $(window).scroll(function () {
      if ($(window).scrollTop() > 600) {
        btn.addClass("show");
      } else {
        btn.removeClass("show");
      }
    });

    btn.on("click", function (e) {
      e.preventDefault();
      $("html, body").animate({ scrollTop: 0 }, "600");
    });
  }

  componentWillReceiveProps(PropsData) {
    if (PropsData.staticblack !== this.state.footerBlocks) {
      var footerPas = "";
      if (Object.keys(PropsData.staticblack).length > 0) {
        PropsData.staticblack.map((data, index) => {
          if (data.staticblocks_slug === "footer-content") {
            footerPas = data.staticblocks_description;
            return "";
          }
        });
      }
      footerPas = footerPas !== "" ? Parser(footerPas) : footerPas;
      this.setState({
        footerBlocks: PropsData.staticblack,
        footerlink: footerPas,
      });
    }
  }
  render() {
    var todayTimeSp = new Date();
    var yearSp = todayTimeSp.getFullYear();

    return (
      <>
        <div className="scrolltop" id="scrollbutton">
          <a href="/" className="disbl_href_action">
            <span>
              <i className="fa fa-angle-double-up ars"></i>
              <i className="fa fa-angle-up ars1"></i>
              <i className="fa fa-angle-up ars2"></i>
            </span>
          </a>
        </div>
        <footer>
          <div className="kloud-container">
            <div className="kloud-row">
              <div className="kloud-cols">
                { (this.state.footerlink!=="") && ( <section className="footer-inner">
                  { this.state.footerlink }
                  {/* <div className="footer-left">
                    <img src={whiteLogo} alt="" className="footer-logo" />
                    <p>We accept</p>
                    <ul>
                      <li>
                        <a href="/">
                          <img src={visa_card} alt="" />
                        </a>
                      </li>
                      <li>
                        <a href="/">
                          <img src={master_card} alt="" />
                        </a>
                      </li>
                      <li>
                        <a href="/">
                          <img src={american_card} alt="" />
                        </a>
                      </li>
                      <li>
                        <a href="/">
                          <img src={paynow} alt="" />
                        </a>
                      </li>
                    </ul>
                  </div>
                  <div className="footer-right">
                    <ul className="footer-menu">
                      <li>
                        <Link to={"/market-place"}>Marketplace</Link>
                      </li>
                      <li>
                        <Link to={"/kitchenpods"}>Kitchenpods</Link>
                      </li>
                      <li>
                        <Link to={"/myaccount"}>My Account</Link>
                      </li>
                      <li>
                        <a href="/privacy-policy">Privacy Policy</a>
                      </li>
                      <li>
                        <a href="/terms-conditions">Terms & Condition</a>
                      </li>
                    </ul>
                    <ul className="footer-social">
                      <li>
                        <a href="https://www.facebook.com/kloudkitchen.sg/" target="_blank">
                          <img src={facebook} alt="" />
                        </a>
                      </li>
                      <li>
                        <a href="https://www.instagram.com/kloudkitchen.sg/" target="_blank">
                          <img src={instagram} alt="" />
                        </a>
                      </li>
                    </ul>
                    <div className="footer-addr">
                      <p>
                        45 Science Park Road #01-05
                        <br />
                        Sparkle Singapore Science Park IT Singapore 117407
                      </p>
                      <p className="mt-30">
                        For enquiries about our kitchen pods, please contact our
                        sales team at <Link to={"/kitchenpods"}>here</Link>
                      </p>
                    </div>
                  </div> */}
                </section>)}
              </div>
            </div>
          </div>
        </footer>
      </>
    );
  }
}

const mapStateTopProps = (state) => {
  var blacksArr = Array();
  if (Object.keys(state.staticblack).length > 0) {
    if (state.staticblack[0].status === "ok") {
      blacksArr = state.staticblack[0].result_set;
    }
  }
  return {
    staticblack: blacksArr,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getStaticBlock: () => {
      dispatch({ type: GET_STATIC_BLOCK });
    },
  };
};

export default connect(mapStateTopProps, mapDispatchToProps)(Footer);
