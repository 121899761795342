/* eslint-disable */
import React, { Component } from "react";
import { Link } from "react-router-dom";
import cookie from "react-cookies";
import { connect } from "react-redux";
import Slider from "react-slick";
import update from "immutability-helper";
import axios from "axios";
import Header from "../Layout/Header";
import Footer from "../Layout/Footer";
import MenuNavigation from "./MenuNavigation";
import ProductList from "./ProductList";
import ProductDetail from "./ProductDetail";
import {
  appId,
  apiUrl,
  apiUrlV2,
  deliveryId,
  pickupId,
  mediaUrl,
  siteURL,
} from "../Helpers/Config";
import {
  getAliasName,
  stripslashes,
  showLoader,
  hideLoader,
  getReferenceID,
  showAlert,
  capitalize_Words
} from "../Helpers/SettingHelper";
import {
  GET_GLOBAL_SETTINGS,
  GET_MENU_NAVIGATION,
  GET_ALL_OUTLETS,
  GET_ZONE_DETAIL,
  GET_RESTAURANTTYPE,
} from "../../actions";
var base64 = require("base-64");
import noimage from "../../common/images/no-img-product.png";
import searchIcon from "../../common/images/loupe.png";
import breadcrumbside from "../../common/images/breadcrum-side-img-1.jpg";
import filterImg from "../../common/images/filter.png";
import closeBtn from "../../common/images/close-block.png";
import CartSideBar from "../Layout/CartSideBar";
import { type } from "jquery";
const isEqual = require("react-fast-compare");
var Parser = require("html-react-parser");
var monthArr = [
  "Jan",
  "Feb",
  "Mar",
  "Apr",
  "May",
  "Jun",
  "Jul",
  "Aug",
  "Sep",
  "Oct",
  "Nov",
  "Dec",
];

class Index extends Component {
  constructor(props) {
    super(props);

    this.state = {
      filterActive: false,
    };
    this.productToggleClass = this.productToggleClass.bind(this);

    var avilablityIdTxt =
      cookie.load("defaultAvilablityId") !== "" &&
      typeof cookie.load("defaultAvilablityId") !== undefined &&
      typeof cookie.load("defaultAvilablityId") !== "undefined"
        ? cookie.load("defaultAvilablityId")
        : "";
    var currentPageUrl = this.props.location.pathname.split("/");
    let currentPageUrll =
      currentPageUrl[1] !== "" &&
      typeof currentPageUrl[1] !== undefined &&
      typeof currentPageUrl[1] !== "undefined"
        ? currentPageUrl[1]
        : "";
    var current_Page_Url = "";
    var resbreadcrumb = "";
    if (currentPageUrll == "food") {
      current_Page_Url = "food";
      resbreadcrumb = "Restaurants";
    } else if (currentPageUrll == "retail") {
      current_Page_Url = "retail";
      resbreadcrumb = "Retail";
    } else {
      current_Page_Url = "events";
      resbreadcrumb = "Events";
    }

    const deliveryPostalCode =
      cookie.load("deliveryPostalCode") !== "" &&
      typeof cookie.load("deliveryPostalCode") !== undefined &&
      typeof cookie.load("deliveryPostalCode") !== "undefined"
        ? cookie.load("deliveryPostalCode")
        : "";
    this.state = {
      settings: [],
      currentPageUrl: current_Page_Url,
      slugEvent:
        this.props.match.params.slugEvent !== "" &&
        typeof this.props.match.params.slugEvent !== undefined &&
        typeof this.props.match.params.slugEvent != "undefined"
          ? this.props.match.params.slugEvent
          : "",
      deliveryPostalCode: deliveryPostalCode,
      resbreadcrumb: resbreadcrumb,
      navigateMenu: [],
      selectedCategoryName: "",
      selectedNavigation: "",
      selectedSlugType: "",
      searchSubCat: "",
      searchProVal: "",
      viewProductSlug: "",
      catNavIndex: 0,
      catslugType: "",
      catslugValue: "",
      cartTriggerFlg: "No",
      outlet_slug: this.props.match.params.slugRestaurant,
      defaultAvilTy: avilablityIdTxt,
      outletslist: [],
      alloutletlist: [],
      tag: [],
      promotion: [],
      restaurantdetails: "",
      order_date: new Date(),
      cartOutletList: [],
      load_product: "Yes",
      searchProResult: [],
      selectedProResult: [],
      totalCartCount: 0,
      orderOutletId:
        cookie.load("orderOutletId") !== "" &&
        typeof cookie.load("orderOutletId") !== undefined
          ? cookie.load("orderOutletId")
          : "",
      shopeClosed: 0,
      shareGroupUrl: "",
      cart_group_id:
        cookie.load("cart_group_id") !== "" &&
        typeof cookie.load("cart_group_id") !== undefined &&
        typeof cookie.load("cart_group_id") !== "undefined"
          ? cookie.load("cart_group_id")
          : "",
      cartDetails: "",
      cartGroups: "",
      cartClearTriggerFlg: "no",
      selectPickupTime: "No",
      outletTagList: [],
      filterTag: [],
      displayTagFilter: [],
      restauranttype: [],
      filterResType: [],
      displayResFilter: [],
    };

    var orderOutletId = cookie.load("orderOutletId");
    if (orderOutletId === "" || orderOutletId === undefined) {
      //cookie.save("orderPopuptrigger", "Yes");
      this.props.history.push("/");
      return;
    }
    showLoader("restaurant-main", "class");

    this.props.getSettings();
    this.props.getMenuNavigationList(
      this.props.match.params.slugRestaurant,
      avilablityIdTxt,
      this.state.currentPageUrl,
      this.state.slugEvent
    );
    var zoneIdTxt =
      typeof cookie.load("orderZoneId") === "undefined"
        ? ""
        : cookie.load("orderZoneId");

    if (avilablityIdTxt === deliveryId && zoneIdTxt !== "") {
      this.props.getZoneDetail("", zoneIdTxt, 1);
    }
    this.props.getRestauranttype();
  }

  componentWillReceiveProps(nextProps) {
    /*if(this.state.selectedCategoryName!==nextProps.selectedCatry){
			this.setState({selectedCategoryName: nextProps.selectedCatry});
		}*/

    let slugType =
      typeof this.props.match.params.slugType !== "undefined"
        ? this.props.match.params.slugType
        : "";
    let slugValue =
      typeof this.props.match.params.slugValue !== "undefined"
        ? this.props.match.params.slugValue
        : "";
    let proValue =
      typeof this.props.match.params.proValue !== "undefined"
        ? this.props.match.params.proValue
        : "";
    let selectedNavigation = nextProps.selectedNavigation;
    let navigateMenu = nextProps.navigateMenu;

    let selectedSlugType = nextProps.selectedSlugType;
    var searchSubCat = "";
    if (!isEqual(this.props.match.params, nextProps.match.params)) {
      slugType =
        typeof nextProps.match.params.slugType !== "undefined"
          ? nextProps.match.params.slugType
          : "";
      slugValue =
        typeof nextProps.match.params.slugValue !== "undefined"
          ? nextProps.match.params.slugValue
          : "";
      proValue =
        typeof nextProps.match.params.proValue !== "undefined"
          ? nextProps.match.params.proValue
          : "";
    }

    if (slugValue !== "") {
      if (slugType !== "category" && slugType !== "subcategory") {
        searchSubCat = slugValue;
        slugValue = slugType;
        slugType = "category";
        /*$('.search_result').hide();*/
        var tmpVl = "";
        $("#productsearch").val(tmpVl);
        $("#clearSearch").hide();
        $(".hsearch_sec").removeClass("open");
        $(".hsearch_trigger").removeClass("active");
        setTimeout(function () {
          if ($("." + proValue).length > 0) {
            $("html, body").animate(
              {
                scrollTop: $("." + proValue).offset().top - 150,
              },
              500
            );
          }
        }, 1000);
      }
      selectedNavigation = slugValue;
    }

    if (slugType === "") {
      slugType = selectedSlugType;
    }

    if (
      selectedNavigation !== this.state.selectedNavigation ||
      this.state.selectedCategoryName !== nextProps.selectedCatry
    ) {

      showLoader("productlist-main-div", "class");
      if (proValue === "") {
        /*  setTimeout(function () {
          $(window).scrollTo($(".common-inner-banner"), 800);
        }, 3000); */
      }
      if (nextProps.navigateMenu.length === 0) {
        hideLoader("productlist-main-div", "class");
      }
      if (slugType === "subcategory") {
        var navIndex = nextProps.navigateMenu.findIndex(
          (p) => p.pro_subcate_slug == selectedNavigation
        );
        var categoryNameTxt =
          Object.keys(nextProps.navigateMenu).length > 0
            ? nextProps.navigateMenu[navIndex].subcategory_name
            : nextProps.selectedCatry;
      } else {
        var navIndex = nextProps.navigateMenu.findIndex(
          (p) => p.pro_cate_slug == selectedNavigation
        );
        var categoryNameTxt =
          Object.keys(nextProps.navigateMenu).length > 0
            ? nextProps.navigateMenu[navIndex].category_name
            : nextProps.selectedCatry;
      }
      $("#dvLoading").fadeOut(2000);
      this.setState({
        selectedNavigation: selectedNavigation,
        catNavIndex: navIndex,
        catslugType: slugType,
        catslugValue: slugValue,
        navigateMenu: navigateMenu,
        selectedCategoryName: categoryNameTxt,
        searchSubCat: searchSubCat,
        searchProVal: proValue,
        loadproduct: "Yes",
      });
    }
    if (this.state.load_product === "No") {
      $("#dvLoading").fadeOut(2000);
      showLoader("productlist-main-div", "class");
      if (nextProps.navigateMenu.length === 0) {
        hideLoader("productlist-main-div", "class");
      }
      if (slugType === "subcategory") {
        var navIndex = nextProps.navigateMenu.findIndex(
          (p) => p.pro_subcate_slug == selectedNavigation
        );
        var categoryNameTxt =
          Object.keys(nextProps.navigateMenu).length > 0
            ? nextProps.navigateMenu[navIndex].subcategory_name
            : nextProps.selectedCatry;
      } else {
        var navIndex = nextProps.navigateMenu.findIndex(
          (p) => p.pro_cate_slug == selectedNavigation
        );
        var categoryNameTxt =
          Object.keys(nextProps.navigateMenu).length > 0
            ? nextProps.navigateMenu[navIndex].category_name
            : nextProps.selectedCatry;
      }
      this.setState({
        selectedNavigation: selectedNavigation,
        catNavIndex: navIndex,
        catslugType: slugType,
        catslugValue: slugValue,
        navigateMenu: navigateMenu,
        selectedCategoryName: categoryNameTxt,
        searchSubCat: searchSubCat,
        searchProVal: proValue,
        loadproduct: "Yes",
        load_product: "No",
      });
    }

    if (nextProps.alloutlets !== this.state.outletslist) {
      if (nextProps.alloutlets.length > 0) {
        this.setState(
          {
            outletslist: nextProps.alloutlets[0].result_set,
            tag: nextProps.alloutlets[0].tag,
            promotion: nextProps.alloutlets[0].promotion,
          },
          function () {
            this.displayOutletDetails();
          }
        );
      }
    }
    if (nextProps.globalsettings !== this.state.globalsettings) {
      if (
        nextProps.globalsettings[0] !== "" &&
        typeof nextProps.globalsettings[0] !== undefined &&
        typeof nextProps.globalsettings[0] !== "undefined"
      ) {
        this.setState({ settings: nextProps.globalsettings[0].result_set });
      }
    }
    if (nextProps.restauranttype !== this.state.restauranttype) {
      if (nextProps.restauranttype.length > 0) {
        if (nextProps.restauranttype[0].status === "success") {
          $("#dvLoading").fadeOut(2000);
          this.setState({
            restauranttype: nextProps.restauranttype[0].result_set,
          });
        }
      }
    }
  }

  componentDidMount() {
    /*SCROLL FIXED PRODUCT PAGE*/
    $(window).scroll(function () {
      var productlist_height = $(".productlist-main-div").offset();
      if (productlist_height !== undefined) {
        var mycustomscroll = $(".productlist-main-div").offset().top - 122,
          wind_toptxt = $(window).scrollTop();

        if (wind_toptxt > mycustomscroll) {
          $(".bakery_row").addClass("catogry_row_fixed");
        } else {
          $(".bakery_row").removeClass("catogry_row_fixed");
        }
      }
    });
    this.props.getAllOutlets(this.state.defaultAvilTy, this.state.outlet_slug);
    this.getSearchProductList();

    this.checkTimeAvilablity();

    var postalCodeA = "";
    if (
      this.state.deliveryPostalCode !== "" &&
      this.state.defaultAvilTy === deliveryId
    ) {
      postalCodeA = this.state.deliveryPostalCode;
    }
    var defaultAvilTyN =
      this.state.defaultAvilTy !== "" ? this.state.defaultAvilTy : deliveryId;
    axios
      .get(
        apiUrlV2 +
          "outlets/getAllOutles?app_id=" +
          appId +
          "&availability_id=" +
          defaultAvilTyN +
          "&shop_type=1&postal_code=" +
          postalCodeA
      )
      .then((response) => {
        if (response.data.status === "ok") {
          this.setState({ alloutletlist: response.data.result_set });
        } else {
        }
      });

    axios
      .get(
        apiUrlV2 +
          "products/outlet_tags?app_id=" +
          appId +
          "&outlet_slug=" +
          this.state.outlet_slug
      )
      .then((response) => {
        if (response.data.status === "ok") {
          this.setState(
            { outletTagList: response.data.result_set },
            function () {
              this.displayTagList();
            }
          );
        } else {
        }
      });

    $("#dvLoading").fadeIn();
  }

  displayTagList() {
    var displayTagList = "";
    if (this.state.outletTagList.length > 0) {
      displayTagList = this.state.outletTagList.map((item, index) => {
        return (
          <option key={index} value={item.pro_tag_id}>
            {stripslashes(item.pro_tag_name)}
          </option>
        );
      });
    }
    this.setState({ displayTagList: displayTagList });
  }

  checkTimeAvilablity() {
    if (this.state.orderOutletId !== "") {
      var orderDate =
        cookie.load("deliveryDate") !== "" &&
        typeof cookie.load("deliveryDate") !== undefined &&
        typeof cookie.load("deliveryDate") !== "undefined"
          ? cookie.load("deliveryDate")
          : "";
      var deliveryTime =
        cookie.load("deliveryTime") !== "" &&
        typeof cookie.load("deliveryTime") !== undefined &&
        typeof cookie.load("deliveryTime") !== "undefined"
          ? cookie.load("deliveryTime")
          : "";
      if (deliveryTime !== "") {
        axios
          .get(
            apiUrlV2 +
              "settings/chkOutletTimesAvaiable?app_id=" +
              appId +
              "&availability_id=" +
              this.state.defaultAvilTy +
              "&outletID=" +
              this.state.orderOutletId +
              "&orderDateTime=" +
              orderDate +
              " " +
              deliveryTime
          )
          .then((response) => {
            if (response.data.status === "success") {
              this.setState({ shopeClosed: 0 });
            } else {
              this.setState({ shopeClosed: 1 });
            }
          });
      }
    }
  }

  sateValChange = (field, value) => {
    if (field === "cartflg") {
      this.setState({ cartTriggerFlg: value });
    }
    if (field === "cartclearflg") {
      this.setState({ cartClearTriggerFlg: value });
    }
    if (field === "view_pro_data" && value !== "") {
      this.setState(
        { viewProductSlug: value },
        function () {
          this.openProDetailPopup();
        }.bind(this)
      );
    }
    if (field === "cartOutletList" && value !== "") {
      this.setState({ cartOutletList: value });
    }
    if (field === "loadproduct" && value !== "") {
      this.setState({ loadproduct: "No" });
    }
    if (field === "totalCartCount" && value !== "") {
      this.setState({ totalCartCount: value }, function () {
        this.displayOutletDetails();
      });
    }
    if (field === "cartDetails" && value !== "") {
      if (
        value.cart_id !== "" &&
        typeof value.cart_id !== undefined &&
        typeof value.cart_id !== "undefined"
      ) {
        var cart_id = base64.encode(value.cart_id);
        this.setState({
          cartDetails: value,
          shareGroupUrl:
            siteURL +
            "grouporder/" +
            this.state.currentPageUrl +
            "/" +
            this.state.outlet_slug +
            "/" +
            cart_id,
        });
      }
    }
    if (field === "cartGroups" && value !== "") {
      this.setState({ cartGroups: value });
    }
  };

  productFlageChange = (field, value) => {
    this.setState(
      update(this.state, { productflage: { [field]: { $set: value } } })
    );
  };

  openProDetailPopup() {
    showLoader("comboPro-" + this.state.viewProductSlug, "Idtext");
    $("#ProductDetailMdl").modal({ backdrop: "static", keyboard: false });
  }
  openGropShare(event) {
    event.preventDefault();
    $("#sharegroup-popup").modal({ backdrop: "static", keyboard: false });
  }
  copyGropurl(event) {
    event.preventDefault();
    var copyText = document.getElementById("shareGroupUrl");
    copyText.select();
    copyText.setSelectionRange(0, 99999);
    document.execCommand("copy");
    $("#coppy-success-msg").fadeIn();
    setTimeout(function () {
      $("#coppy-success-msg").fadeOut();
    }, 3000);
  }
  cancelGrouporder(event) {
    event.preventDefault();
    showLoader("cancel_order", "class");
    var qs = require("qs");
    var postObject = {
      app_id: appId,
      cart_group_id: cookie.load("cart_group_id"),
      cart_id: cookie.load("cart_id"),
    };
    axios
      .post(apiUrl + "cart/cancel_group", qs.stringify(postObject))
      .then((response) => {
        hideLoader("cancel_order", "class");
        if (response.data.status === "ok") {
          this.setState({ cartTriggerFlg: "yes" });
          cookie.remove("cart_group_id", { path: "/" });
          cookie.remove("cart_id", { path: "/" });
          cookie.remove("group_order_confirm", { path: "/" });
          this.handleShowAlertFun("success", response.data.message);
        } else {
          this.handleShowAlertFun("Error", response.data.message);
        }
        return false;
      });
  }
  cancelorder() {
    event.preventDefault();
    this.setState({ cartClearTriggerFlg: "yes" });
  }
  handleShowAlertFun(header, msg) {
    var magnfPopup = $.magnificPopup.instance;
    showAlert(header, msg, magnfPopup);
    $.magnificPopup.open({
      items: {
        src: ".alert_popup",
      },
      type: "inline",
    });
  }
  displayOutletDetails() {
    if (
      this.state.outletslist[0] !== "" &&
      typeof this.state.outletslist[0] !== undefined &&
      typeof this.state.outletslist[0] !== "undefined"
    ) {
      let outlet = this.state.outletslist[0];
      let restaurantdetails = (
        <div>
          <div className="breadcrumb-sec">
            {/* <img src={breadcrumbside} /> */}
            <img
                src={
                  (  outlet.outlet_banner_image !== "" && outlet.outlet_banner_image !== null && outlet.outlet_banner_image !== undefined && outlet.outlet_banner_image !== "undefined" )
                    ? mediaUrl + "outlet/" + outlet.outlet_banner_image
                    : breadcrumbside
                }
              />
          </div>
          <div
            className="restaurant-info"
            style={{ backgroundColor: outlet.outlet_color_code }}
          >
            <div className="restaurant-info-lhs">
              <img
                src={
                  outlet.outlet_image !== ""
                    ? mediaUrl + "outlet/" + outlet.outlet_image
                    : noimage
                }
                alt={outlet.outlet_name}
                title={outlet.outlet_name}
              />

              <h3>{outlet.outlet_name}</h3>
              {outlet.outlet_tag_id !== "" && (
                <div className="address">
                  <p> {this.loadTag(outlet.outlet_tag_id)}</p>
                </div>
              )}
              <div className="social-media">
                {outlet.outlet_website && (
                  <span>
                    <i className="fa fa-desktop"></i>{" "}
                    <a
                      href={outlet.outlet_website}
                      target="_blank"
                      title={outlet.outlet_website}
                    >
                      {outlet.outlet_website}
                    </a>
                  </span>
                )}
                {outlet.outlet_fb && (
                  <a href={outlet.outlet_fb} target="_blank">
                    <i className="fa fa-facebook-square"></i>
                  </a>
                )}
                {outlet.outlet_twitter && (
                  <a href={outlet.outlet_twitter} target="_blank">
                    <i className="fa fa-twitter"></i>
                  </a>
                )}
                {outlet.outlet_instagram && (
                  <a href={outlet.outlet_instagram} target="_blank">
                    <i className="fa fa-instagram"></i>
                  </a>
                )}
              </div>

              <div className="working-hr">
                <strong>Opening Hours &nbsp;</strong> :{" "}
                {outlet.outlet_open_time}
              </div>
              <div>{Parser(outlet.outlet_informations)}</div>
              <div className="restaurant-other-info">
                <div className="restaurant-tat">
                  <span>
                    Around{" "}
                    <strong>
                      {this.state.defaultAvilTy === deliveryId
                        ? outlet.outlet_delivery_tat
                        : ""}
                      {this.state.defaultAvilTy === pickupId
                        ? outlet.outlet_pickup_tat
                        : ""}{" "}
                      MIN
                    </strong>
                  </span>
                </div>
                <div className="restaurant-date">
                  <span>
                    Earliest Order
                    <strong>
                      {this.state.order_date.getDate()}{" "}
                      {monthArr[this.state.order_date.getMonth()]}{" "}
                      {this.state.order_date.getFullYear()}
                    </strong>
                  </span>
                </div>
                {this.state.defaultAvilTy === deliveryId &&
                  outlet.distance !== "" &&
                  typeof outlet.distance !== undefined &&
                  outlet.distance > 0 && (
                    <div className="distance">
                      <strong>
                        <span>
                          {parseFloat(outlet.distance).toFixed(1)} KM AWAY
                        </span>
                      </strong>
                    </div>
                  )}
                <div className="distance">
                  <strong>
                    <span>5 KM AWAY</span>
                  </strong>
                </div>
              </div>
            </div>
          </div>
        </div>
      );
      this.setState({ restaurantdetails: restaurantdetails });
    }
  }
  loadTag(tag_id) {
    if (tag_id !== "" && tag_id !== null) {
      let tagID = tag_id.split(",");
      let tagList = this.state.tag;
      if (tagID.length > 0) {
        return tagID.map(function (item, index) {
          if (
            tagList[item] !== "" &&
            typeof tagList[item] !== undefined &&
            typeof tagList[item] !== "undefined"
          ) {
            return (
              <span key={index}>
                {tagList[item]}
                {tagID.length - 1 != index ? ", " : ""}{" "}
              </span>
            );
          }
        });
      }
    }
  }

  changeAvailability(availability, event) {
    event.preventDefault();
    if (availability === deliveryId && this.state.deliveryPostalCode === "") {
      this.setState({ selectPostalCode: "Yes" });
      $.magnificPopup.open({
        items: {
          src: "#delevery-postcode-popup",
        },
        type: "inline",
      });
      return false;
    }

    var orderDateTime = cookie.load("orderDateTime");
    if (availability === pickupId) {
      if (
        orderDateTime === "" ||
        typeof orderDateTime === undefined ||
        typeof orderDateTime === "undefined"
      ) {
        cookie.save("defaultAvilablityId", pickupId, { path: "/" });
        this.setState({ selectPickupTime: "Yes" });
        return false;
      }
    }

    if (this.state.defaultAvilTy !== availability) {
      axios
        .get(
          apiUrlV2 +
            "outlets/checkOutletAvailability?app_id=" +
            appId +
            "&availability_id=" +
            availability +
            "&outlet_slug=" +
            this.state.outlet_slug
        )
        .then((response) => {
          if (response.data.status === "ok") {
            cookie.save("defaultAvilablityId", availability, { path: "/" });
            this.setState(
              {
                defaultAvilTy: availability,
                load_product: "No",
                cartTriggerFlg: "yes",
              },
              function () {
                axios
                  .get(
                    apiUrl +
                      "cart/update_availability?app_id=" +
                      appId +
                      "&reference_id=" +
                      getReferenceID() +
                      "&customer_id=" +
                      cookie.load("UserId") +
                      "&availability_id=" +
                      availability
                  )
                  .then((res) => {});

                this.props.getMenuNavigationList(
                  this.props.match.params.slugRestaurant,
                  availability,
                  this.state.currentPageUrl,
                  this.state.slugEvent
                );
              }
            );
          } else {
            $.magnificPopup.open({
              items: {
                src: "#warning-not-deliver",
              },
              type: "inline",
            });
            this.props.history.push("/" + this.state.currentPageUrl);
          }
        });
    }
  }

  getSearchProductList() {
    var searchResult = [];
    return axios
      .get(
        apiUrlV2 +
          "products/search_products?app_id=" +
          appId +
          "&status=A&availability=" +
          this.state.defaultAvilTy +
          "&outletSlug=" +
          this.state.outlet_slug
      )
      .then((response) => {
        if (response.data.status === "ok") {
          var resultData = response.data.result_set;
          for (var key in resultData) {
            var subcatListArr = !("product_list" in resultData[key])
              ? Array()
              : resultData[key].product_list;

            if (Object.keys(subcatListArr).length > 0) {
              if (Object.keys(subcatListArr[0]).length > 0) {
                var subCatArr = !("subcategorie" in subcatListArr[0][0])
                  ? Array()
                  : subcatListArr[0][0].subcategorie;
                for (var sctkey in subCatArr) {
                  var productsArr = !("products" in subCatArr[sctkey])
                    ? Array()
                    : subCatArr[sctkey].products;
                  for (var prokey in productsArr) {
                    var proNameTxt = getAliasName(
                      productsArr[prokey].product_alias,
                      productsArr[prokey].product_name
                    );
                    searchResult.push({
                      cate_slug: subCatArr[sctkey].pro_cate_slug,
                      subcate_slug: subCatArr[sctkey].pro_subcate_slug,
                      value: productsArr[prokey].product_slug,
                      label: stripslashes(proNameTxt),
                    });
                  }
                }
              }
            }
          }
        }
        this.setState({ searchProResult: searchResult });
      });
  }

  searchProKeyPress = (event) => {
    $(".search_result").show();
    var value = event.target.value.toLowerCase();
    let matches = "";
    if (value !== "") {
      matches = this.state.searchProResult.filter(function (item) {
        if (item.label.toLowerCase().indexOf(value) >= 0) {
          return item.label;
        }
      });
    }
    this.setState({ selectedProResult: matches, searchKeyWord: value });
  };
  loadProSearchList() {
    if (Object.keys(this.state.selectedProResult).length > 0) {
      return this.state.selectedProResult.map((loaddata, index) => (
        <li key={index}>
          <Link
            to={
              "/" +
              this.state.currentPageUrl +
              "/" +
              this.state.outlet_slug +
              "/" +
              loaddata.cate_slug +
              "/" +
              loaddata.subcate_slug +
              "/" +
              loaddata.value
            }
            onClick={this.openProductLink.bind(
              this,
              "/" +
                this.state.currentPageUrl +
                "/" +
                this.state.outlet_slug +
                "/" +
                loaddata.cate_slug +
                "/" +
                loaddata.subcate_slug +
                "/" +
                loaddata.value
            )}
            title="Product Details"
          >
            {loaddata.label}
          </Link>
        </li>
      ));
    } else {
      if (this.state.searchKeyWord !== "") {
        return <li className="no-pro-found">No Product found</li>;
      } else {
        return "";
      }
    }
  }

  openProductLink(plink, event) {
    event.preventDefault();
    $("#searchKeyWord").val("");
    this.setState({ selectedProResult: [], searchKeyWord: "" }, function () {
      this.props.history.push(plink);
    });
  }
  cart_Outlet_List = (field, value) => {
    if (
      this.props.location.pathname !== "/myaccount" &&
      this.props.location.pathname !== "/myorders" &&
      this.props.location.pathname !== "/mypromotions" &&
      this.props.match.path !== "/thankyou/:orderId"
    ) {
      if (field !== "") {
        if (field === "cartOutletList" && this.state.slugRestaurant !== "") {
          // this.props.sateValChange("cartOutletList", value);
        }
        if (field === "totalCartCount") {
          if (this.state.slugRestaurant !== "") {
            //  this.props.sateValChange("totalCartCount", value);
          }
        }
        if (field === "cartDetails" && value !== "") {
          //this.props.sateValChange("cartDetails", value);
        }
        if (field === "cartGroups" && value !== "") {
          // this.props.sateValChange("cartGroups", value);
        }
      }
    }
  };
  handleChangeTag(event) {
    if (event.target.name === "filter_tag") {
      let filterTag = this.state.filterTag;
      if (filterTag.indexOf(event.target.value) >= 0) {
        var index = filterTag.indexOf(event.target.value);
        filterTag.splice(index, 1);
      } else {
        filterTag.push(event.target.value);
      }
      this.setState({ filterTag: filterTag }, function () {
        $("#filter_tag").prop("selectedIndex", 0);
        this.displayTagFilter();
      });
    } else if (event.target.name === "restaurant_type") {
      let filterResType = this.state.filterResType;
      if (filterResType.indexOf(event.target.value) >= 0) {
        var index = filterResType.indexOf(event.target.value);
        filterResType.splice(index, 1);
      } else {
        filterResType.push(event.target.value);
      }
      this.setState({ filterResType: filterResType }, function () {
        $("#restaurant_type").prop("selectedIndex", 0);
        this.displayResFilter();
      });
    }
  }
  displayTagFilter() {
    var displayTagFilter = "";
    if (this.state.filterTag.length > 0 && this.state.outletTagList.length) {
      displayTagFilter = this.state.outletTagList.map((item, index) => {
        if (this.state.filterTag.indexOf(item.pro_tag_id) >= 0) {
          return (
            <div class="product-inn-tag" key={index}>
              {item.pro_tag_name}{" "}
              <span onClick={this.removeTagFilter.bind(this, item.pro_tag_id)}>
                X
              </span>
            </div>
          );
        }
      });
    }
    this.setState({ displayTagFilter: displayTagFilter });
  }
  removeTagFilter(removedID) {
    let filterTag = this.state.filterTag;
    var index = filterTag.indexOf(removedID);
    filterTag.splice(index, 1);

    this.setState({ filterTag: filterTag }, function () {
      this.displayTagFilter();
    });
  }

  displayResFilter() {
    var displayResFilter = "";
    if (
      this.state.filterResType.length > 0 &&
      this.state.restauranttype.length
    ) {
      displayResFilter = this.state.restauranttype.map((item, index) => {
        if (this.state.filterResType.indexOf(item) >= 0) {
          return (
            <div className="product-inn-tag" key={index}>
              {item}{" "}
              <span onClick={this.removeResFilter.bind(this, item)}>X</span>
            </div>
          );
        }
      });
    }
    this.setState({ displayResFilter: displayResFilter });
  }

  removeResFilter(res) {
    let filterResType = this.state.filterResType;
    var index = filterResType.indexOf(res);
    filterResType.splice(index, 1);

    this.setState({ filterResType: filterResType }, function () {
      this.displayResFilter();
    });
  }

  productToggleClass() {
    const currentState = this.state.filterActive;
    this.setState({ filterActive: !currentState });
  }
  handleSelectChange(event) {
    var outletslist = this.state.alloutletlist;
    var selectedOutlet = outletslist[event.target.value];
    cookie.remove("orderOutletId", { path: "/" });
    cookie.remove("outlet_location_id", { path: "/" });
    cookie.save("orderOutletId", selectedOutlet.oa_outlet_id, { path: "/" });
    cookie.save("outletLocationId", selectedOutlet.outlet_location_id, {
      path: "/",
    });

    location.href = siteURL + "food/" + selectedOutlet.outlet_slug;
  }

  goCategoryProducts(slug, type, cat_id, event) {
    event.preventDefault();

    $(".mobile_slider").removeClass("active");
    
    if(slug !==""){
      $("#slider_"+slug).addClass("active");
    }
    
    $(".product-menu li").removeClass("active");
    $(".product-menu #" + type + "_" + cat_id).addClass("active");
    $("html, body").animate(
      {
        scrollTop: $("#" + slug).offset().top - 150,
      },
      1000
    );
  }

  mobileMenuNavigation() {
    let navigateMenuArr = this.props.navigateMenu;
    var selectedNav = this.props.selectedNavigation;

   // console.log(navigateMenuArr);

    if (Object.keys(navigateMenuArr).length > 0) {
      const mainMenu = navigateMenuArr.map((loaddata, index) => {
        if (loaddata.menu_type == "main") {
          return (
            <li
              key={index + 1}
              className="menu-list-intvl"
              id={"c_" + loaddata.pro_cate_primary_id}
            >
              <Link
                to={"/#" + loaddata.pro_cate_slug}
                onClick={this.goCategoryProducts.bind(
                  this,
                  loaddata.pro_cate_slug,
                  "c",
                  loaddata.pro_cate_primary_id
                )}
                 className= "menu-list-intvl mobile_slider"
                id={"slider_"+ loaddata.pro_cate_slug}
                title={loaddata.menu_custom_title}
              >
                <span className="menu-title-txt" >
                  {capitalize_Words(
                    stripslashes(loaddata.menu_custom_title.toLowerCase())
                  )}
                </span>
              </Link>
            </li>
          );
        }
      });
      setTimeout(function () {
        $(window).scroll(function () {
          var scrollPos = $(document).scrollTop();
          $(".product-mobile-menu li a")
            .each(function () {
              var currLink = $(this);
              var refElement = $(currLink.attr("href").replace(/\//g, ""));
              if (
                refElement.position() !== "" &&
                typeof refElement.position() !== undefined &&
                typeof refElement.position() !== "undefined"
              ) {
                var toppos = refElement.position().top - 200;
                if (
                  toppos <= scrollPos &&
                  toppos + refElement.height() > scrollPos
                ) {
                  $(".product-mobile-menu-nav ul li").removeClass("active");
                  currLink.parents("li").addClass("active");
                } else {
                  currLink.parents("li").removeClass("active");
                }
              }
            })
            .promise()
            .done(function () {
              if (
                $(".product-mobile-menu-nav .menu-list-intvl.active").length > 0
              ) {
                $(".product-mobile-menu-nav .mobile-menu-title").html(
                  $(
                    ".product-mobile-menu-nav .menu-list-intvl.active .menu-title-txt"
                  ).html()
                );
              }
            });
        });
      }, 100);

      return mainMenu;
    } else {
      return;
    }
  }

  render() {
    let completGroupOrder = "";
    if (this.state.cart_group_id !== "") {
      if (
        this.state.cartGroups[this.state.cart_group_id] !== "" &&
        typeof this.state.cartGroups[this.state.cart_group_id] !== undefined &&
        typeof this.state.cartGroups[this.state.cart_group_id] !== "undefined"
      ) {
        if (
          this.state.cartGroups[this.state.cart_group_id].cart_group_status ===
          "C"
        ) {
          completGroupOrder = "Y";
        }
      }
    }
    var totalGroup = 0;
    if (Object.keys(this.state.cartGroups).length) {
      for (var groupO in this.state.cartGroups) {
        if (this.state.cartGroups[groupO].cart_group_status === "C") {
          totalGroup++;
        }
      }
      /* totalGroup =
        parseInt(Object.keys(this.state.cartGroups).length) - 1 + parseInt(1); */
    }

    var settingsMenu = {
      infinite: false,
      dots: false,
      arrows: true,    
      slidesToShow: 6,
      slidesToScroll: 1,
      responsive: [
        {
          breakpoint: 1191,
          settings: {
            slidesToShow: 6,
            slidesToScroll: 3,
          },
        },
        {
          breakpoint: 850,
          settings: {
            slidesToShow: 3,
            slidesToScroll: 2,
          },
        },
        {
          breakpoint: 580,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 1,
          },
        },
                {
          breakpoint: 420,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
          },
        },  
      ],
    };

    return (
      <>
        <div className="productpage-main-div restpage-main-div">
          {/* Header start */}
          <Header
            cartTriggerFlg={this.state.cartTriggerFlg}
            cartClearTriggerFlg={this.state.cartClearTriggerFlg}
            sateValChange={this.sateValChange}
            showCatryName={this.state.selectedCategoryName}
            selectPostalCode={this.state.selectPostalCode}
            selectPickupTime={this.state.selectPickupTime}
          />
          {/* Header End */}
          <section className="product-menu-listing home-bg">
            <div className="restaurant-main">
              {this.state.restaurantdetails}
            </div>

            <div className="container-full">
              <div className="product-filter product-mob-filter product-filter-sec product-fixed-header">
                <div className="product-filter-selecct">
                  <div className="product-cart-side">
                    <button
                      className={
                        this.state.defaultAvilTy === deliveryId
                          ? "btn btn-procart pro-cart-bike active"
                          : "btn btn-procart pro-cart-bike"
                      }
                      onClick={this.changeAvailability.bind(this, deliveryId)}
                    >
                      Delivery
                    </button>
                    <button
                      className={
                        this.state.defaultAvilTy === pickupId
                          ? "btn btn-procart pro-cart-bag active"
                          : "btn btn-procart pro-cart-bag"
                      }
                      onClick={this.changeAvailability.bind(this, pickupId)}
                    >
                      Takeway
                    </button>
                  </div>
                  <div
                    className="custom_alertcls alert-danger sec-alter-danger required_availability mobile_error"
                    style={{ display: "none" }}
                  >
                    <p>Please select any once availability</p>
                  </div>
                </div>
                <div className="product-filter-search">
                  <div className="form-group product-inner-search">
                    <img src={searchIcon} />
                    <input
                      type="text"
                      className="from-control"
                      placeholder="Search"
                      onKeyUp={this.searchProKeyPress}
                    />
                    <ul
                      className="search_result"
                      id="search_result"
                      style={{ display: "none" }}
                    >
                      {this.loadProSearchList()}
                    </ul>
                  </div>
                  <button
                    className="product-inner-filter"
                    onClick={this.productToggleClass}
                  >
                    <img src={this.state.filterActive ? closeBtn : filterImg} />
                  </button>
                </div>
                <div
                  className={
                    "product-filter-select-group" +
                    (this.state.filterActive ? " filterShow" : "")
                  }
                >
                  <div className="product-filter-select">
                    <div className="form-group">
                      <label>Choose Merchants</label>
                      <select
                        className="form-control"
                        onChange={this.handleSelectChange.bind(this)}
                      >
                        {this.state.alloutletlist.length > 0 &&
                          this.state.alloutletlist.map((item, index) => {
                            return (
                              <option
                                key={index}
                                selected={
                                  this.state.outlet_slug === item.outlet_slug
                                    ? true
                                    : false
                                }
                                value={index}
                              >
                                {item.outlet_name}
                              </option>
                            );
                          })}
                      </select>
                    </div>
                    <div className="form-group rev-margin">
                      <label className="form-group-catg">
                        <span>Categories</span>
                      </label>
                      {this.props.navigateMenu.length > 0 ? (
                        <MenuNavigation
                          {...this.props}
                          productState={this.state}
                        />
                      ) : (
                        ""
                      )}
                    </div>
                  </div>
                  <div className="product-filter-select">
                    <div className="form-group">
                      <label>Choose Cuisine Type</label>
                      <select
                        className="form-control"
                        id="restaurant_type"
                        name="restaurant_type"
                        onChange={this.handleChangeTag.bind(this)}
                      >
                        <option value="">Choose Here</option>
                        {this.state.restauranttype.length > 0 &&
                          this.state.restauranttype.map((item, index) => {
                            return (
                              <option key={index} value={item}>
                                {item}
                              </option>
                            );
                          })}
                      </select>
                      <div className="profil-select-tag">
                        {this.state.displayResFilter}
                      </div>
                    </div>
                    <div className="form-group">
                      <label>Popular Items</label>
                      <select
                        className="form-control"
                        onChange={this.handleChangeTag.bind(this)}
                        id="filter_tag"
                        name="filter_tag"
                      >
                        <option value="">Choose Here</option>
                        {this.state.displayTagList}
                      </select>
                      <div className="profil-select-tag">
                        {this.state.displayTagFilter}
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="choose-and-filter">
                <div className="form-group">
                  <label>Choose Restaurant</label>
                  <select
                    className="form-control"
                    onChange={this.handleSelectChange.bind(this)}
                  >
                    {this.state.alloutletlist.length > 0 &&
                      this.state.alloutletlist.map((item, index) => {
                        return (
                          <option
                            key={index}
                            selected={
                              this.state.outlet_slug === item.outlet_slug
                                ? true
                                : false
                            }
                            value={index}
                          >
                            {item.outlet_name}
                          </option>
                        );
                      })}
                  </select>
                </div>
               </div>
             <div className="slider-and-filter">
                {Object.keys(this.props.navigateMenu).length > 0 && (
                    <Slider {...settingsMenu}>
                      {this.mobileMenuNavigation()}
                    </Slider>
                )}
                
              </div>
              
              {this.props.navigateMenu.length > 0 ? (
                <ProductList
                  {...this.props}
                  productState={this.state}
                  sateValChange={this.sateValChange}
                  cartOutletList={this.state.cartOutletList}
                />
              ) : (
                <div className="no-product productlist-main-div productlist-main-detail">
                  <div className="container">
                    <h2>No product found</h2>
                    <p>
                      Sorry, we can't find the type of products you are looking
                      for. Try widening your search to see what’s available.
                    </p>
                  </div>
                </div>
              )}
              <div className="product-cart">
                <button type="button" className="hcart_close">
                  X
                </button>
                <div className="prodsel-cart" id="availability_div">
                  <button
                    className={
                      this.state.defaultAvilTy === deliveryId
                        ? "btn btn-procart pro-cart-bike active"
                        : "btn btn-procart pro-cart-bike"
                    }
                    onClick={this.changeAvailability.bind(this, deliveryId)}
                  >
                    Delivery
                  </button>
                  <button
                    className={
                      this.state.defaultAvilTy === pickupId
                        ? "btn btn-procart pro-cart-bag active"
                        : "btn btn-procart pro-cart-bag"
                    }
                    onClick={this.changeAvailability.bind(this, pickupId)}
                  >
                    Takeaway
                  </button>
                </div>
                {$("body").width() >= 1200 && (
                  <div className="product-cart-item-list">
                    <div
                      className="custom_alertcls alert-danger alert-avali required_availability desktop_error"
                      style={{ display: "none" }}
                    >
                      {" "}
                      <a
                        href="/"
                        type="button"
                        className="custom_close"
                        data-dismiss="alert"
                        aria-label="Close"
                      >
                        {" "}
                        <span aria-hidden="true">×</span>{" "}
                      </a>{" "}
                      <p>Please select any once availability</p>
                    </div>
                    <CartSideBar
                      {...this.props}
                      headerState={this.state}
                      prpSateValChange={this.sateValChange}
                      cartTriggerFlg={this.state.cartTriggerFlg}
                      cartClearTriggerFlg={this.state.cartClearTriggerFlg}
                      cart_Outlet_List={this.cart_Outlet_List}
                      currentPage="product"
                    />
                  </div>
                )}
              </div>
            </div>
          </section>
          <div id="dvLoading"></div>

          {/* Footer section */}
          <Footer />
          <ProductDetail
            productState={this.state}
            sateValChange={this.sateValChange}
            cartOutletList={this.state.cartOutletList}
          />
        </div>
      </>
    );
  }
}

const mapStateTopProps = (state) => {
  var tempArr = Array();
  var menu_slug = "";
  var menu_type = "";
  var navigateRst = Array();
  var navigateCmn = Array();
  var selectedCatry = "";
  if (Object.keys(state.product).length > 0) {
    var tempArr = !("menuNavigation" in state.product[0])
      ? Array()
      : state.product[0].menuNavigation;
    if (Object.keys(tempArr).length > 0) {
      if (tempArr[0].status === "ok") {
        navigateRst = tempArr[0].result_set;
        navigateCmn = tempArr[0].common;
        if (tempArr[0].result_set[0].menu_type == "main") {
          selectedCatry = tempArr[0].result_set[0].category_name;
          menu_slug = tempArr[0].result_set[0].pro_cate_slug;
          menu_type = "category";
        } else {
          selectedCatry = tempArr[0].result_set[0].subcategory_name;
          menu_slug = tempArr[0].result_set[0].pro_subcate_slug;
          menu_type = "subcategory";
        }
      }
    }
  }

  var zonedetailArr = Array();
  if (Object.keys(state.zonedetail).length > 0) {
    if (state.zonedetail[0].status === "ok") {
      zonedetailArr = state.zonedetail[0].result_set;
    }
  }

  return {
    globalsettings: state.settings,
    navigateMenu: navigateRst,
    navigateCommon: navigateCmn,
    selectedCatry: selectedCatry,
    selectedNavigation: menu_slug,
    selectedSlugType: menu_type,
    alloutlets: state.alloutlets,
    zonedetails: zonedetailArr,
    restauranttype: state.restauranttype,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getSettings: () => {
      dispatch({ type: GET_GLOBAL_SETTINGS });
    },
    getAllOutlets: (availability, outlet_slug) => {
      dispatch({ type: GET_ALL_OUTLETS, availability, outlet_slug });
    },
    getMenuNavigationList: (
      outlet_slug,
      availability,
      currentPageUrl,
      slugEvent
    ) => {
      dispatch({
        type: GET_MENU_NAVIGATION,
        outlet_slug,
        availability,
        currentPageUrl,
        slugEvent,
      });
    },
    getZoneDetail: (outletId, zoneId, shop_type) => {
      dispatch({ type: GET_ZONE_DETAIL, outletId, zoneId, shop_type });
    },
    getRestauranttype: () => {
      dispatch({ type: GET_RESTAURANTTYPE });
    },
  };
};
export default connect(mapStateTopProps, mapDispatchToProps)(Index);
