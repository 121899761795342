/* eslint-disable */
import React, { Component } from "react";
import cookie from "react-cookies";
import { connect } from "react-redux";
import axios from "axios";
import Header from "../Layout/Header";
import Footer from "../Layout/Footer";
import Slider from "react-slick";
import {
  apiUrl,
  apiUrlV2,
  appId,
  mediaUrl,
  deliveryId,
  pickupId,
  siteURL,
} from "../Helpers/Config";
import {
  showLoader,
  hideLoader,
  getReferenceID,
  searchFilters,
} from "../Helpers/SettingHelper";
import {
  GET_GLOBAL_SETTINGS,
  GET_STATIC_BLOCK,
  GET_ALL_OUTLETS,
  GET_EVENTS,
  GET_ZONE_DETAIL,
  GET_RESTAURANTTYPE,
} from "../../actions";
import noimage from "../../common/images/no-img-product.png";
import searchIcon from "../../common/images/loupe.png";
import filterImg from "../../common/images/filter.png";
import closeBtn from "../../common/images/close-block.png";
import CartSideBar from "../Layout/CartSideBar";
import { Link } from "react-router-dom";
import { type } from "jquery";
var Parser = require("html-react-parser");

class Marketplace extends Component {
  constructor(props) {
    super(props);
    this.state = {
      filterActive: false,
    };
    this.productToggleClass = this.productToggleClass.bind(this);

    let defaultAvilTy =
      cookie.load("defaultAvilablityId") !== "" &&
      typeof cookie.load("defaultAvilablityId") !== undefined &&
      typeof cookie.load("defaultAvilablityId") !== "undefined"
        ? cookie.load("defaultAvilablityId")
        : "";
    var deliveryDate = cookie.load("deliveryDate");
    var currentPageUrl = this.props.location.pathname.split("/");
    let currentPageUrll = "";
    if (this.props.location.pathname === "/") {
      currentPageUrll = "food";
    } else {
      currentPageUrll =
        currentPageUrl[1] !== "" &&
        typeof currentPageUrl[1] !== undefined &&
        typeof currentPageUrl[1] !== "undefined"
          ? currentPageUrl[1]
          : "";
    }
    if (currentPageUrll === "events") {
      if (
        defaultAvilTy === "" ||
        typeof defaultAvilTy === undefined ||
        typeof defaultAvilTy === "undefined"
      ) {
        var deliveryDate1 = new Date();

        cookie.save("defaultAvilablityId", pickupId, { path: "/" });
        cookie.save(
          "deliveryDate",
          deliveryDate1.getDate() +
            "/" +
            deliveryDate1.getMonth() +
            "/" +
            deliveryDate1.getFullYear(),
          { path: "/" }
        );
        cookie.save(
          "deliveryTime",
          deliveryDate1.getHours() +
            ":" +
            deliveryDate1.getMinutes() +
            ":" +
            deliveryDate1.getSeconds(),
          { path: "/" }
        );
        var orderDateTime = new Date().toISOString();
        cookie.save("orderDateTime", orderDateTime, { path: "/" });

        defaultAvilTy = pickupId;
        this.loadPickupOutlet();
      }
    }

    const orderPostalCode =
      cookie.load("orderPostalCode") !== "" &&
      typeof cookie.load("orderPostalCode") !== undefined &&
      typeof cookie.load("orderPostalCode") !== "undefined"
        ? cookie.load("orderPostalCode")
        : "";
    const deliveryPostalCode =
      cookie.load("deliveryPostalCode") !== "" &&
      typeof cookie.load("deliveryPostalCode") !== undefined &&
      typeof cookie.load("deliveryPostalCode") !== "undefined"
        ? cookie.load("deliveryPostalCode")
        : "";
    this.state = {
      defaultAvilTy: defaultAvilTy,
      orderPostalCode: orderPostalCode,
      deliveryPostalCode: deliveryPostalCode,
      outletslist: [],
      searchoutletslist: [],
      outletsMainlist: [],
      displayOutlets: "",
      settings: [],
      tag: [],
      tagdisplay: "",
      promotion: [],
      order_date: new Date(),
      selectPostalCode: "No",
      filterTag: [],
      filterResType: [],
      eventList: [],
      eventImagePath: "",
      event_List: "",
      urltype: this.props.match.path,
      eventSlug:
        this.props.match.params.slugEvent !== "" &&
        typeof this.props.match.params.slugEvent !== undefined &&
        typeof this.props.match.params.slugEvent !== "undefined"
          ? this.props.match.params.slugEvent
          : "",
      staticblack: [],
      restaurantcontent: [],
      retailcontent: [],
      tagFilterDisplay: "",
      ResFilterDisplay: [],
      restauranttype: [],
    };
    /* if (defaultAvilTy === "" || defaultAvilTy === undefined) {
      this.props.history.push("/");
      return;
    } */
    /* if (defaultAvilTy !== "" && defaultAvilTy !== undefined) {
      if (defaultAvilTy === deliveryId) {
        if (deliveryDate === "" || deliveryDate === undefined) {
          this.props.history.push("/");
          return;
        }
      }
    } */
    this.handleChange = this.handleChange.bind(this);
    this.props.getSettings();
    this.props.getStaticBlock();
    /* this.loadResturentInfo(); */
    var shop_type = "";
    if (
      this.props.match.path === "/food" ||
      this.props.match.path === "/" ||
      this.props.match.path === "/market-place"
    ) {
      shop_type = 1;
    } else if (this.props.match.path === "/retail") {
      shop_type = 2;
    } else {
      shop_type = 3;
    }
    this.props.getAllOutlets(
      defaultAvilTy,
      this.state.eventSlug,
      shop_type,
      deliveryPostalCode
    );

    this.props.getEvents();

    var zoneIdTxt =
      typeof cookie.load("orderZoneId") === "undefined"
        ? ""
        : cookie.load("orderZoneId");

    if (defaultAvilTy === deliveryId && zoneIdTxt !== "") {
      this.props.getZoneDetail("", zoneIdTxt, 1);
    }
    this.props.getRestauranttype();
  }

  componentWillReceiveProps(PropsDt) {
    if (PropsDt.alloutlets !== this.props.alloutlets) {
      if (PropsDt.alloutlets[0].status === "ok") {
        this.setState(
          {
            outletslist: PropsDt.alloutlets[0].result_set,
            eventOutletsList: PropsDt.alloutlets[0].result_set,
            searchoutletslist: PropsDt.alloutlets[0].result_set,
            outletsMainlist: PropsDt.alloutlets[0].result_set,
            tag: PropsDt.alloutlets[0].tag,
            promotion: PropsDt.alloutlets[0].promotion,
          },
          function () {
            this.displayOutlets();
            this.displaytag();
          }
        );
      } else {
        this.setState(
          {
            outletslist: [],
            eventOutletsList: [],
            searchoutletslist: [],
            outletsMainlist: [],
            tag: [],
            promotion: [],
          },
          function () {
            this.displayOutlets();
            this.displaytag();
          }
        );
      }
    }
    if (PropsDt.eventList !== this.props.eventList) {
      if (PropsDt.eventList[0].status === "ok") {
        this.setState(
          {
            eventList: PropsDt.eventList[0].result_set,
            eventImagePath: PropsDt.eventList[0].imagepath,
          },
          function () {
            this.displayEvents();
          }
        );
      } else {
        this.setState(
          {
            eventList: [],
            eventImagePath: "",
          },
          function () {
            this.displayEvents();
          }
        );
      }
    }
    if (PropsDt.restauranttype !== this.props.restauranttype) {
      if (PropsDt.restauranttype[0].status === "success") {
        this.setState({
          restauranttype: PropsDt.restauranttype[0].result_set,
        });
      }
    }
    if (PropsDt.match.url !== this.props.match.url) {
      var pageslug = PropsDt.match.path;

      var eventSlug =
        PropsDt.match.params.slugEvent !== "" &&
        typeof PropsDt.match.params.slugEvent !== undefined &&
        typeof PropsDt.match.params.slugEvent !== "undefined"
          ? PropsDt.match.params.slugEvent
          : "";
      this.setState({ urltype: pageslug, eventSlug: eventSlug }, function () {
        this.displayEvents();
        var shop_type = "";
        if (
          pageslug === "/food" ||
          pageslug === "/" ||
          pageslug === "/market-place"
        ) {
          shop_type = 1;
        } else if (pageslug === "/retail") {
          shop_type = 2;
        } else {
          shop_type = 3;
        }
        this.props.getAllOutlets(
          this.state.defaultAvilTy,
          eventSlug,
          shop_type,
          this.state.deliveryPostalCode
        );
      });
    }
    if (PropsDt.staticblack !== this.state.staticblack) {
      this.setState({ staticblack: PropsDt.staticblack });
      var resPas = "";
      var retailPas = "";
      if (Object.keys(PropsDt.staticblack).length > 0) {
        PropsDt.staticblack.map((data) => {
          if (data.staticblocks_slug === "restaurants-content") {
            resPas = data;
          }
          if (data.staticblocks_slug === "retail-content") {
            retailPas = data;
          }
        });
      }
      this.setState({ restaurantcontent: resPas, retailcontent: retailPas });
    }
    if (PropsDt.globalsettings !== this.state.settings) {
      if (PropsDt.globalsettings.length > 0) {
        if (PropsDt.globalsettings[0].status === "ok") {
          this.setState({ settings: PropsDt.globalsettings[0].result_set });
        }
      }
    }
  }

  componentDidMount() {
    showLoader("innerproduct", "class");
    $(".outlet-list")
      .mouseout(function () {
        $(this).find(".outlet-logo").css("background-color", "#FFFFFF");
      })
      .mouseover(function () {
        var bgcolor = $(this).attr("data-color");
        console.log(bgcolor);
        $(this).find(".outlet-logo").css("background-color", bgcolor);
      });
  }

  loadPickupOutlet() {
    axios
      .get(
        apiUrl +
          "outlets/pickup_outlets?app_id=" +
          appId +
          "&availability=" +
          pickupId
      )
      .then((res) => {
        if (res.data.status === "ok") {
          var pickupOutlet = res.data.result_set[0];
          cookie.save("orderOutletId", pickupOutlet.outlet_id, {
            path: "/",
          });
          cookie.save("orderOutletName", pickupOutlet.outlet_name, {
            path: "/",
          });

          cookie.save("orderPostalCode", pickupOutlet.outlet_postal_code, {
            path: "/",
          });

          // cookie.save("orderTAT", pickupOutlet.outlet_pickup_tat, {
          //   path: "/",
          // });
          cookie.save(
            "orderHandled",
            pickupOutlet.outlet_name + "," + pickupOutlet.outlet_address_line1,
            {
              path: "/",
            }
          );
          cookie.save(
            "orderHandledByText",
            pickupOutlet.outlet_name + "," + pickupOutlet.outlet_address_line1,
            {
              path: "/",
            }
          );
        }
      });
  }
  displayOutlets() {
    /*  no-res-pro */
    let outlet,
      eventOutlet = "";
    let totalRes = 0;
    if (
      this.state.outletslist !== "" &&
      typeof this.state.outletslist !== undefined &&
      typeof this.state.outletslist !== "undefined" &&
      this.state.outletslist.length > 0
    ) {
      outlet = this.state.outletslist.map((item, index) => {
        totalRes++;
        this.checkTimeAvilablity(item.oa_outlet_id);
        var modulous = index % 4;
        return (
          <li
            className={
              modulous === 2 || modulous === 3
                ? "outlet-list ol-swap"
                : "outlet-list"
            }
            data-color={item.outlet_color_code}
            key={index}
            data-imgavil={item.outlet_image !== "" ? "0" : "1"}
            onClick={this.viewProducts.bind(
              this,
              item.oa_outlet_id,
              item.outlet_slug,
              item.outlet_location_id
            )}
          >
            <div
              className="outlet-logo"
              style={{
                background: item.outlet_image === "" && item.outlet_color_code,
              }}
            >
              <img
                src={
                  item.outlet_image !== ""
                    ? mediaUrl + "outlet/" + item.outlet_image
                    : noimage
                }
                alt=""
              />
              <h3>{item.outlet_name}</h3>
              {item.outlet_image === "" && (
                <p>
                  {item.outlet_tag_id !== "" &&
                    this.loadTagList(item.outlet_tag_id)}
                  <br />
                  Opening Hours : {item.outlet_open_time} 
                  {/* -{" "}
                  {item.outlet_close_time} */}
                </p>
              )}
            </div>
            {item.outlet_image !== "" && (
              <div
                className="outlet-dish"
                style={{ background: item.outlet_color_code }}
              >
                <img
                  src={mediaUrl + "outlet/" + item.outlet_extra_image}
                  alt=""
                />
              </div>
            )}
          </li>
        );
      });
    }
    if (totalRes === 0) {
      outlet = (
        <li className="no_restaurants">
          <h2>No restaurants found</h2>
          <p>
            Sorry, we can't find the type of restaurant you are looking for. Try
            widening your search to see what’s available.
          </p>
        </li>
      );
    }
    hideLoader("innerproduct", "class");
    this.setState({ displayOutlets: outlet }, function () {
      $(".outlet-list")
        .mouseout(function () {
          $(".outlet-list").removeClass("outlet-gray");
          if ($(this).attr("data-imgavil") === "0") {
            $(this).find(".outlet-logo").css("background-color", "#FFFFFF");
          }
        })
        .mouseover(function () {
          var bgcolor = $(this).attr("data-color");
          $(".outlet-list").addClass("outlet-gray");
          $(this).removeClass("outlet-gray");
          $(this).find(".outlet-logo").css("background-color", bgcolor);
        });
    });
  }

  loadTagList(tagList) {
    var tagFilterDisplay = "";
    if (tagList && this.state.tag) {
      var tag_List = tagList.split(",");
      var tagListNew = this.state.tag;
      return (tagFilterDisplay = tag_List.map((item, index) => {
        if (
          this.state.tag[item] !== "" &&
          typeof this.state.tag[item] !== undefined &&
          typeof this.state.tag[item] !== "undefined"
        ) {
          return (
            <>
              {this.state.tag[item]}
              {parseInt(index) + 1 !== tag_List.length && ", "}
            </>
          );
        }
      }));
    }
  }

  checkTimeAvilablity(outletID) {
    if (outletID !== "") {
      var orderDate =
        cookie.load("deliveryDate") !== "" &&
        typeof cookie.load("deliveryDate") !== undefined &&
        typeof cookie.load("deliveryDate") !== "undefined"
          ? cookie.load("deliveryDate")
          : "";
      var deliveryTime =
        cookie.load("deliveryTime") !== "" &&
        typeof cookie.load("deliveryTime") !== undefined &&
        typeof cookie.load("deliveryTime") !== "undefined"
          ? cookie.load("deliveryTime")
          : "";
      if (deliveryTime !== "") {
        axios
          .get(
            apiUrlV2 +
              "settings/chkOutletTimesAvaiable?app_id=" +
              appId +
              "&availability_id=" +
              this.state.defaultAvilTy +
              "&outletID=" +
              outletID +
              "&orderDateTime=" +
              orderDate +
              " " +
              deliveryTime
          )
          .then((response) => {
            if (response.data.status === "success") {
              $("#res_" + outletID).removeClass("no-res-pro");
              $("#res_" + outletID + " .shop_closed")
                .hide()
                .html("");
            } else {
              $("#res_" + outletID).addClass("no-res-pro");
              $("#res_" + outletID + " .shop_closed")
                .show()
                .html("Closed");
            }
          });
      }
    }
  }
  loadTag(tag_id) {
    if (tag_id !== "" && tag_id !== null) {
      let tagID = tag_id.split(",");
      let tagList = this.state.tag;
      if (tagID.length > 0) {
        return tagID.map(function (item, index) {
          if (
            tagList[item] !== "" &&
            typeof tagList[item] !== undefined &&
            typeof tagList[item] !== "undefined"
          ) {
            return (
              <span key={index}>
                {tagList[item]}
                {tagID.length - 1 != index ? ", " : ""}
              </span>
            );
          }
        });
      }
    }
  }

  loadPromo(outlet_id) {
    let promotion = this.state.promotion;
    if (promotion !== "") {
      if (
        promotion[outlet_id] !== "" &&
        typeof promotion[outlet_id] !== undefined &&
        typeof promotion[outlet_id] !== "undefined"
      ) {
        return promotion[outlet_id].map(function (item, index) {
          return <span key={index}>{item}</span>;
        });
      }
    }
  }

  displaytag() {
    var tagdisplay = "";
    var tags = this.state.tag;
    if (tags !== "" && tags !== null) {
      var current1 = this;
      tagdisplay = Object.keys(tags).map(function (item, index) {
        return <option value={item}>{tags[item]}</option>;
      });
    }
    this.setState({ tagdisplay: tagdisplay });
  }
  handleChange(event) {
    if (event.target.name === "filter_tag") {
      let filterTag = this.state.filterTag;
      if (filterTag.indexOf(event.target.value) >= 0) {
        var index = filterTag.indexOf(event.target.value);
        filterTag.splice(index, 1);
      } else {
        filterTag.push(event.target.value);
      }

      this.setState({ filterTag: filterTag }, function () {
        this.applyFilter();
        $("#filter_tag").prop("selectedIndex", 0);
      });
    } else if (event.target.name === "restaurant_type") {
      let filterResType = this.state.filterResType;
      if (filterResType.indexOf(event.target.value) >= 0) {
        var index = filterResType.indexOf(event.target.value);
        filterResType.splice(index, 1);
      } else {
        filterResType.push(event.target.value);
      }
      this.setState({ filterResType: filterResType }, function () {
        this.applyFilter();
        $("#restaurant_type").prop("selectedIndex", 0);
      });
    }
  }

  removeTagFilter(removedID) {
    let filterTag = this.state.filterTag;
    var index = filterTag.indexOf(removedID);
    filterTag.splice(index, 1);

    this.setState({ filterTag: filterTag }, function () {
      this.applyFilter();
    });
  }

  removeResFilter(removedID) {
    let filterResType = this.state.filterResType;
    var index = filterResType.indexOf(removedID);
    filterResType.splice(index, 1);
    this.setState({ filterResType: filterResType }, function () {
      this.applyFilter();
    });
  }

  viewProducts(outlet_id, outlet_slug, outlet_location_id) {
    var shopType = "food";
    if (
      this.state.urltype === "/food" ||
      this.state.urltype === "/" ||
      this.state.urltype === "/market-place"
    ) {
      shopType = "food";
    } else if (this.state.urltype === "/retail") {
      shopType = "retail";
    } else {
      shopType = "events/" + this.state.eventSlug;
    }
    cookie.remove("orderOutletId", { path: "/" });
    cookie.remove("outlet_location_id", { path: "/" });
    cookie.save("orderOutletId", outlet_id, { path: "/" });
    cookie.save("outletLocationId", outlet_location_id, { path: "/" });
    location.href = siteURL + shopType + "/" + outlet_slug;
    //this.props.history.push("/" + shopType + "/" + outlet_slug);
  }

  handleSelectChange(event) {
    /* outlet_id, outlet_slug, outlet_location_id */
    var outletslist = this.state.outletslist;
    var selectedOutlet = outletslist[event.target.value];
    var shopType = "food";
    if (
      this.state.urltype === "/food" ||
      this.state.urltype === "/" ||
      this.state.urltype === "/market-place"
    ) {
      shopType = "food";
    } else if (this.state.urltype === "/retail") {
      shopType = "retail";
    } else {
      shopType = "events/" + this.state.eventSlug;
    }
    cookie.remove("orderOutletId", { path: "/" });
    cookie.remove("outlet_location_id", { path: "/" });
    cookie.save("orderOutletId", selectedOutlet.oa_outlet_id, { path: "/" });
    cookie.save("outletLocationId", selectedOutlet.outlet_location_id, {
      path: "/",
    });
    location.href = siteURL + shopType + "/" + selectedOutlet.outlet_slug;
    //this.props.history.push("/" + shopType + "/" + outlet_slug);
  }

  sateValChange = (field, value) => {
    if (field === "cartflg") {
      this.setState({ cartTriggerFlg: value });
    }
    if (field === "selectPostalCode") {
      if (value == "Yes") {
        cookie.save("defaultAvilablityId", deliveryId, { path: "/" });
        this.setState(
          { defaultAvilTy: deliveryId, selectPostalCode: "No" },
          function () {
            showLoader("innerproduct", "class");
            var shop_type = "";
            if (
              this.props.match.path === "/food" ||
              this.props.match.path === "/" ||
              this.props.match.path === "/market-place"
            ) {
              shop_type = 1;
            } else if (this.props.match.path === "/retail") {
              shop_type = 2;
            } else {
              shop_type = 3;
            }
            this.props.getAllOutlets(
              deliveryId,
              this.state.eventSlug,
              shop_type,
              this.state.deliveryPostalCode
            );
          }
        );
      }
    }
    if (field === "postalcode") {
      this.setState({ orderPostalCode: value });
      if (this.state.defaultAvilTy === deliveryId) {
        this.setState({ deliveryPostalCode: value });
      }
    }
  };

  searchProKeyPress = (event) => {
    var value = event.target.value.toLowerCase();
    var matches = this.state.outletsMainlist.filter(function (item) {
      if (item.outlet_name.toLowerCase().indexOf(value) >= 0) {
        return item;
      }
    });
    this.setState(
      { outletslist: matches, searchoutletslist: matches },
      function () {
        this.displayOutlets();
      }
    );
  };

  applyFilter() {
    var current2 = this;
    var matches = [];
    if (this.state.filterTag.length > 0) {
      matches = this.state.searchoutletslist.filter(function (item) {
        if (item.outlet_tag_id !== "" && item.outlet_tag_id !== null) {
          let outlet_tag_id = item.outlet_tag_id.split(",");
          let result = searchFilters(outlet_tag_id, current2.state.filterTag);
          if (result === true) {
            return item;
          }
        }
      });
    } else {
      matches = this.state.searchoutletslist;
    }

    if (this.state.filterResType.length > 0) {
      matches = matches.filter(function (item) {
        if (item.restaurant_type !== "" && item.restaurant_type.length > 0) {
          let result = searchFilters(
            item.restaurant_type,
            current2.state.filterResType
          );
          if (result === true) {
            return item;
          }
        }
      });
    }

    this.setState({ outletslist: matches }, function () {
      this.displayOutlets();
      this.loadTagFilter();
      this.loadResFilter();
    });
  }
  loadTagFilter() {
    var tagFilterDisplay = "";

    if (this.state.filterTag.length > 0 && this.state.tag) {
      var tagListNew = this.state.tag;
      tagFilterDisplay = Object.keys(tagListNew).map((item) => {
        if (this.state.filterTag.indexOf(item) >= 0) {
          return (
            <div class="product-inn-tag">
              {tagListNew[item]}{" "}
              <span onClick={this.removeTagFilter.bind(this, item)}>X</span>
            </div>
          );
        }
      });
    }
    this.setState({ tagFilterDisplay: tagFilterDisplay });
  }
  loadResFilter() {
    var ResFilterDisplay = "";
    if (this.state.filterResType.length > 0 && this.state.restauranttype) {
      var ResListNew = this.state.restauranttype;
      ResFilterDisplay = ResListNew.map((item) => {
        if (this.state.filterResType.indexOf(item) >= 0) {
          return (
            <div class="product-inn-tag">
              {item}{" "}
              <span onClick={this.removeResFilter.bind(this, item)}>X</span>
            </div>
          );
        }
      });
    }
    this.setState({ ResFilterDisplay: ResFilterDisplay });
  }

  clearFilter(event) {
    event.preventDefault();
    $(".restaurants_tag").prop("checked", false);
    this.setState(
      { outletslist: this.state.searchoutletslist, filterTag: [] },
      function () {
        this.displayOutlets();
      }
    );
  }

  changeAvailability(availability, event) {
    event.preventDefault();

    if (availability === deliveryId && this.state.deliveryPostalCode === "") {
      this.setState({ selectPostalCode: "Yes" });
      $.magnificPopup.open({
        items: {
          src: "#delevery-postcode-popup",
        },
        type: "inline",
      });
      return false;
    }
    var orderDateTime = cookie.load("orderDateTime");
    if (availability === pickupId) {
      if (
        orderDateTime === "" ||
        typeof orderDateTime === undefined ||
        typeof orderDateTime === "undefined"
      ) {
        cookie.save("defaultAvilablityId", pickupId, { path: "/" });
        this.setState({ selectPickupTime: "Yes" });
        return false;
      }
    }
    if (this.state.defaultAvilTy !== availability) {
      cookie.remove("defaultAvilablityId", { path: "/" });
      cookie.save("defaultAvilablityId", availability, { path: "/" });
      this.setState({ defaultAvilTy: availability }, function () {
        showLoader("innerproduct", "class");
        axios
          .get(
            apiUrl +
              "cart/update_availability?app_id=" +
              appId +
              "&reference_id=" +
              getReferenceID() +
              "&customer_id=" +
              cookie.load("UserId") +
              "&availability_id=" +
              availability
          )
          .then((res) => {
            this.setState({ cartTriggerFlg: "yes" });
          });
        var shop_type = "";
        if (
          this.props.match.path === "/food" ||
          this.props.match.path === "/" ||
          this.props.match.path === "/market-place"
        ) {
          shop_type = 1;
        } else if (this.props.match.path === "/retail") {
          shop_type = 2;
        } else {
          shop_type = 3;
        }
        this.props.getAllOutlets(
          availability,
          this.state.eventSlug,
          shop_type,
          this.state.deliveryPostalCode
        );
      });
    }
  }
  displayEvents() {
    var event_List = "";
    var ecurrent = this;
    if (this.state.eventList.length > 0) {
      event_List = this.state.eventList.map(function (item, index) {
        return (
          <li
            key={index}
            className={
              ecurrent.state.urltype === "/events/:slugEvent/" &&
              ecurrent.state.eventSlug === item.event_slug
                ? "active"
                : ""
            }
          >
            <Link to={"/events/" + item.event_slug}>{item.event_name}</Link>
          </li>
        );
      });
    }
    this.setState({ event_List: event_List });
  }

  loadResturentInfo() {
    var settingsGallery = {
      infinite: false,
      dots: true,
      arrows: false,
      slidesToShow: 1,
      slidesToScroll: 1,
    };

    /* restaurantcontent: [],
      retailcontent: [], */
    let title = "";
    let content = "";
    let resSlider = "";
    let imagePath = "";
    if (
      this.props.match.path === "/food" ||
      this.props.match.path === "/" ||
      this.props.match.path === "/market-place"
    ) {
      if (
        this.state.restaurantcontent !== "" &&
        typeof this.state.restaurantcontent !== undefined &&
        typeof this.state.restaurantcontent !== "undefined" &&
        this.state.restaurantcontent.staticblocks_description !== "" &&
        typeof this.state.restaurantcontent.staticblocks_description !==
          undefined &&
        typeof this.state.restaurantcontent.staticblocks_description !==
          "undefined"
      ) {
        content = this.state.restaurantcontent.staticblocks_description;
        if (this.state.restaurantcontent.gallery_images.length > 0) {
          imagePath = this.state.restaurantcontent.gallery_image_path;
          resSlider = this.state.restaurantcontent.gallery_images.map(function (
            item,
            index
          ) {
            return (
              <div key={index}>
                <img src={imagePath + item} alt="" />
              </div>
            );
          });
        }
      }
    } else if (this.props.match.path === "/retail") {
      if (
        this.state.retailcontent !== "" &&
        typeof this.state.retailcontent !== undefined &&
        typeof this.state.retailcontent !== "undefined" &&
        this.state.retailcontent.staticblocks_description !== "" &&
        typeof this.state.retailcontent.staticblocks_description !==
          undefined &&
        typeof this.state.retailcontent.staticblocks_description !== "undefined"
      ) {
        content = this.state.retailcontent.staticblocks_description;
        if (this.state.retailcontent.gallery_images.length > 0) {
          imagePath = this.state.retailcontent.gallery_image_path;
          resSlider = this.state.retailcontent.gallery_images.map(function (
            item,
            index
          ) {
            return (
              <div key={index}>
                <img src={imagePath + item} alt="" />
              </div>
            );
          });
        }
      }
    } else {
      if (
        this.state.eventSlug !== "" &&
        this.state.eventList !== "" &&
        typeof this.state.eventList !== undefined &&
        typeof this.state.eventList !== "undefined"
      ) {
        var current3 = this;
        if (this.state.eventList.length > 0) {
          this.state.eventList.map(function (item, index) {
            if (current3.state.eventSlug === item.event_slug) {
              title = <h3>{item.event_name}</h3>;
              content = item.event_description;
              imagePath = current3.state.eventImagePath;
              if (item.image.length > 0) {
                resSlider = item.image.map(function (item, index) {
                  return (
                    <div key={index}>
                      <img src={imagePath + item} alt="" />
                    </div>
                  );
                });
              }
            }
          });
        }
      }
    }
    return (
      <section className="resturant-banner-listing">
        <div className="container">
          <div
            className={
              resSlider !== ""
                ? "serving-passionately-main flex"
                : "serving-passionately-main flex fullw"
            }
          >
            {resSlider !== "" && (
              <div className="serving-passionately-left">
                <Slider {...settingsGallery}>{resSlider}</Slider>
              </div>
            )}
            <div className="serving-passionately-right">
              {title}
              {content !== "" ? Parser(content) : ""}
            </div>
          </div>
        </div>
      </section>
    );
  }
  productToggleClass() {
    const currentState = this.state.filterActive;
    this.setState({ filterActive: !currentState });
  }
  cart_Outlet_List = (field, value) => {
    if (
      this.props.location.pathname !== "/myaccount" &&
      this.props.location.pathname !== "/myorders" &&
      this.props.location.pathname !== "/mypromotions" &&
      this.props.match.path !== "/thankyou/:orderId"
    ) {
      if (field !== "") {
        if (field === "cartOutletList" && this.state.slugRestaurant !== "") {
          // this.props.sateValChange("cartOutletList", value);
        }
        if (field === "totalCartCount") {
          if (this.state.slugRestaurant !== "") {
            //  this.props.sateValChange("totalCartCount", value);
          }
        }
        if (field === "cartDetails" && value !== "") {
          //this.props.sateValChange("cartDetails", value);
        }
        if (field === "cartGroups" && value !== "") {
          // this.props.sateValChange("cartGroups", value);
        }
      }
    }
  };

  render() {
    return (
      <div className="productpage-main-div restpage-main-div">
        {/* Header start */}
        <Header
          cartTriggerFlg={this.state.cartTriggerFlg}
          sateValChange={this.sateValChange}
          changeavail={this.state.defaultAvilTy}
          selectPostalCode={this.state.selectPostalCode}
          selectPickupTime={this.state.selectPickupTime}
        />
        {/* Header End */}
        <section className="product-menu-listing home-bg">
          <div className="product-brd" style={{ background: (this.state.settings.client_marketplace_image !== undefined && this.state.settings.client_marketplace_image!=="undefined" && this.state.settings.client_marketplace_image!=="" && this.state.settings.client_marketplace_image!==null)?"url("+ mediaUrl+"banners/"+this.state.settings.client_marketplace_image +")":"" }}>
            <h1>Marketplace</h1>
           {/* <p>Sample text line will be here in this section</p>*/}
          </div>
          <div className="container-full">
            <div className="product-filter market-mob-filter">
              <div className="product-filter-search">
                <div className="form-group product-inner-search">
                  <img src={searchIcon} />
                  <input
                    type="text"
                    className="from-control"
                    placeholder="Search"
                    onKeyUp={this.searchProKeyPress}
                  />
                </div>
                <button
                  className="product-inner-filter"
                  onClick={this.productToggleClass}
                >
                  <img src={this.state.filterActive ? closeBtn : filterImg} />
                </button>
              </div>
              <div
                className={
                  "product-filter-select-group" +
                  (this.state.filterActive ? " filterShow" : "")
                }
              >
                <div className="product-filter-select">
                  <div className="form-group">
                    <label>Choose Restaurant</label>
                    <select
                      className="form-control"
                      onChange={this.handleSelectChange.bind(this)}
                    >
                      <option value="">Choose Here</option>
                      {this.state.outletslist.length > 0 &&
                        this.state.outletslist.map((item, index) => {
                          return (
                            <option key={index} value={index}>
                              {item.outlet_name}
                            </option>
                          );
                        })}
                    </select>
                  </div>
                </div>
                <div className="product-filter-select">
                  <div className="form-group">
                    <label>Choose Cuisine Type</label>
                    <select
                      className="form-control"
                      id="restaurant_type"
                      name="restaurant_type"
                      onChange={this.handleChange}
                    >
                      <option value="">Choose Here</option>
                      {this.state.restauranttype.length > 0 &&
                        this.state.restauranttype.map((item, index) => {
                          return (
                            <option key={index} value={item}>
                              {item}
                            </option>
                          );
                        })}
                    </select>
                    <div className="profil-select-tag">
                      {this.state.ResFilterDisplay}
                    </div>
                  </div>
                  <div className="form-group">
                    <label>Popular Tags</label>
                    <select
                      className="form-control"
                      onChange={this.handleChange}
                      id="filter_tag"
                      name="filter_tag"
                    >
                      <option value="">Choose Here</option>
                      {this.state.tagdisplay}
                    </select>
                    <div className="profil-select-tag">
                      {this.state.tagFilterDisplay}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="productlist-main-div productlist-market-div">
              <div className="innerproduct_row">
                <div className="innerproduct">
                  <ul className="products-list-ulmain outlet-wrapper">
                    {this.state.displayOutlets}
                  </ul>
                </div>
              </div>
            </div>
            <div className="product-cart">
              <div className="prodsel-cart">
                <button
                  className={
                    this.state.defaultAvilTy === deliveryId
                      ? "btn btn-procart pro-cart-bike active"
                      : "btn btn-procart pro-cart-bike"
                  }
                  onClick={this.changeAvailability.bind(this, deliveryId)}
                >
                  Delivery
                </button>
                <button
                  className={
                    this.state.defaultAvilTy === pickupId
                      ? "btn btn-procart pro-cart-bag active"
                      : "btn btn-procart pro-cart-bag"
                  }
                  onClick={this.changeAvailability.bind(this, pickupId)}
                >
                  Takeaway
                </button>
              </div>
              {$("body").width() >= 1200 && (
                <div className="product-cart-item-list">
                  <div
                    className="custom_alertcls alert-danger alert-avali required_availability desktop_error"
                    style={{ display: "none" }}
                  >
                    {" "}
                    <a
                      href="/"
                      type="button"
                      className="custom_close"
                      data-dismiss="alert"
                      aria-label="Close"
                    >
                      {" "}
                      <span aria-hidden="true">×</span>{" "}
                    </a>{" "}
                    <p>Please select any once availability</p>
                  </div>
                  <CartSideBar
                    {...this.props}
                    headerState={this.state}
                    prpSateValChange={this.sateValChange}
                    cartTriggerFlg={this.state.cartTriggerFlg}
                    cartClearTriggerFlg={this.state.cartClearTriggerFlg}
                    cart_Outlet_List={this.cart_Outlet_List}
                    currentPage="product"
                  />
                </div>
              )}
            </div>
          </div>
        </section>

        {/* Footer section */}
        <Footer />
      </div>
    );
  }
}

const mapStateTopProps = (state) => {
  var blacksArr = Array();
  if (Object.keys(state.staticblack).length > 0) {
    if (state.staticblack[0].status === "ok") {
      blacksArr = state.staticblack[0].result_set;
    }
  }

  var zonedetailArr = Array();
  if (Object.keys(state.zonedetail).length > 0) {
    if (state.zonedetail[0].status === "ok") {
      zonedetailArr = state.zonedetail[0].result_set;
    }
  }

  return {
    globalsettings: state.settings,
    alloutlets: state.alloutlets,
    eventList: state.events,
    staticblack: blacksArr,
    zonedetails: zonedetailArr,
    restauranttype: state.restauranttype,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getSettings: () => {
      dispatch({ type: GET_GLOBAL_SETTINGS });
    },
    getAllOutlets: (availability, eventslug, shop_type, zip_code) => {
      dispatch({
        type: GET_ALL_OUTLETS,
        availability,
        eventslug,
        shop_type,
        zip_code,
      });
    },
    getEvents: () => {
      dispatch({ type: GET_EVENTS });
    },
    getStaticBlock: () => {
      dispatch({ type: GET_STATIC_BLOCK });
    },
    getZoneDetail: (outletId, zoneId, shop_type) => {
      dispatch({ type: GET_ZONE_DETAIL, outletId, zoneId, shop_type });
    },
    getRestauranttype: () => {
      dispatch({ type: GET_RESTAURANTTYPE });
    },
  };
};
export default connect(mapStateTopProps, mapDispatchToProps)(Marketplace);
