/* eslint-disable */
import { takeEvery, call, put } from "redux-saga/effects";
import {
  GET_MENU_NAVIGATION,
  SET_MENU_NAVIGATION,
  GET_PRODUCT_LIST,
  SET_PRODUCT,
} from "../actions";
import {
  appId,
  apiUrl,
  apiUrlV2,
  deliveryId,
} from "../components/Helpers/Config";
import Axios from "axios";
import cookie from "react-cookies";

export const watchGetMenuNavigation = function* () {
  yield takeEvery(GET_MENU_NAVIGATION, workerGetMenuNavigation);
};

export const watchGetProducts = function* () {
  yield takeEvery(GET_PRODUCT_LIST, workerGetProducts);
};

function* workerGetMenuNavigation(slug) {
  var slugEvent = "";
  if (
    slug.slugEvent !== "" &&
    typeof slug.slugEvent !== undefined &&
    typeof slug.slugEvent !== "undefined"
  ) {
    slugEvent = "&event=" + slug.slugEvent;
  }
  try {
    var availability = "";
    if (
      slug.availability !== "" &&
      typeof slug.availability !== undefined &&
      typeof slug.availability !== "undefined"
    ) {
      availability = "&availability=" + slug.availability;
    }

    const uri =
      apiUrlV2 +
      "products/getMenuNavigation?app_id=" +
      appId +
      availability +
      "&outletSlug=" +
      slug.outlet_slug +
      "&shop_type=" +
      slug.currentPageUrl +
      slugEvent;
    const result = yield call(Axios.get, uri);
    var resultArr = [];
    resultArr.push(result.data);
    if (result.data.status == "ok" && result.data.result_set.length > 0) {
      cookie.save("firstNavigation", result.data.result_set[0].pro_cate_slug, {
        path: "/",
      });
    }
    yield put({ type: SET_MENU_NAVIGATION, value: resultArr });
  } catch (err) {
    console.log("Get Menu Navivation Failed", err);
  }
}

function* workerGetProducts({ catslug, subcatslug, outletid }) {
  try {
    var availabilityId =
      cookie.load("defaultAvilablityId") === undefined ||
      cookie.load("defaultAvilablityId") == ""
        ? deliveryId
        : cookie.load("defaultAvilablityId");

    var outletSlug =
      outletid !== undefined || outletid !== ""
        ? "&outletSlug=" + outletid
        : "";
    if (outletSlug === "") {
      var orderOutletId =
        cookie.load("orderOutletId") === undefined ||
        cookie.load("orderOutletId") == ""
          ? ""
          : "&outletId=" + cookie.load("orderOutletId");
    }

    const uri =
      apiUrlV2 +
      "products/getAllProducts?app_id=" +
      appId +
      "&availability=" +
      availabilityId +
      "&category_slug=" +
      catslug +
      "&subcate_slug=" +
      subcatslug +
      +orderOutletId +
      outletSlug;
    const result = yield call(Axios.get, uri);
    var resultArr = [];
    resultArr.push(result.data);
    yield put({ type: SET_PRODUCT, value: resultArr });
  } catch {
    console.log("Get Products Failed");
  }
}
