/* eslint-disable */
import React, { Component } from "react";
import { Link, withRouter } from "react-router-dom";
import { connect } from "react-redux";
import cookie from "react-cookies";
import axios from "axios";
import FacebookLogin from "react-facebook-login";
import { validated } from "react-custom-validation";
import update from "immutability-helper";
import { setMinutes, setHours, getDay, format } from "date-fns";
var dateFormat = require("dateformat");

import {
  appId,
  apiUrl,
  apiUrlV2,
  deliveryId,
  pickupId,
  reservationId,
  baseUrl,
  fbAppId,
  googleAppId,
} from "../Helpers/Config";

import {
  GET_GLOBAL_SETTINGS,
  GET_ZONE_DETAIL,
  GET_PICKUP_OUTLETS,
  GET_ALL_OUTLETS,
  DESTROY_CART_DETAIL,
  GET_LOGINDATA,
  GET_FBLOGINDATA,
  GET_GOOGLELOGINDATA,
  GET_FORGET_PASSWORD,
  GET_REGISTRATION,
  GET_MENUDATA,
  GET_ALLUSERSECADDRDATA,
} from "../../actions";
import {
  getReferenceID,
  showAlert,
  showLoader,
  hideLoader,
  getAliasName,
  stripslashes,
  removeOrderDateTime,
  removePromoCkValue,
  addressFormat,
  loadHeaderTime,
} from "../Helpers/SettingHelper";

import MenuNavigationmob from "./MenuNavigation/MenuNavigationmob";
import OrderdatetimeSlot from "./OrderdatetimeSlot";
import {
  Login,
  Forgotpassword,
  Signup,
  Guestcheckout,
} from "../../components/Myaccount/Index";

/* import images */
import mainLogo from "../../common/images/logo_white.png";
import iconUnhappy from "../../common/images/sad-smiley.png";
import iconWin from "../../common/images/icon-win.svg";
import warningImg from "../../common/images/warning.svg";

import deliveryWtImg from "../../common/images/food-delivery.svg";
import takeawayImg from "../../common/images/lunch.svg";
import takeawayWtImg from "../../common/images/takeaway-w.svg";
import greentick from "../../common/images/grenn-tick.png";
import usericon from "../../common/images/user.png";

import CartSideBar from "./CartSideBar";

class Header extends Component {
  constructor(props) {
    super(props);
    var availability = cookie.load("defaultAvilablityId");
    var shopType = "";
    if (
      cookie.load("shopType") !== "" &&
      typeof cookie.load("shopType") !== undefined &&
      typeof cookie.load("shopType") !== "undefined"
    ) {
      shopType = cookie.load("shopType");
    } else {
      /* shopType = cookie.save("shopType", 1); */
      shopType = 1;
    }
    this.state = {
      currentPageUrl: this.props.location.pathname,
      slugRestaurant:
        this.props.match.params.slugRestaurant !== "" &&
        typeof this.props.match.params.slugRestaurant !== undefined &&
        typeof this.props.match.params.slugRestaurant !== "undefined"
          ? this.props.match.params.slugRestaurant
          : "",
      defaultAvilTy: availability,
      shopType: shopType,
      seletedAvilablityId: "",
      seletedOutletId: "",
      order_tat_time: 0,
      globalsettings: [],
      secondaryaddresslist: [],
      deliveryOutlets: [],
      deliveryOutletsList: [],
      pickupOutlets: [],
      pickupOutletsList: [],
      delivery_outlet_id: "",
      orderHandled: "",
      orderDeliveryAddress: "",
      nextavail: "",
      logindata: "",
      getDateTimeFlg: "",
      deliveryInfo: [],
      pickupInfo: [],
      seleted_ord_date: "",
      seleted_ord_time: "",
      fields: {
        mobile: "",
        password: "",
      },
      fieldsfgtpassword: {
        email: "",
      },
      fieldscheckout: {
        check_email: "",
        check_firstname: "",
        check_phone: "",
      },
      fpstatus: "initiating",
      regstatus: "initiating",
      fieldssignup: {
        firstname: "",
        email: "",
        pin: "",
        rePin: "",
        mobile: "",
        pdpa_consent: "",
        terms: "",
      },
      menuData: [],
      googlelogin: "No",
      trigerGlogin: false,
      fbstatus: "ok",
      selectedAvilHome: "No",
      cartOutletList: [],
      selectPostalCode: "No",
      selectPickupTime: "No",
    };

    this.props.getGlobalSettings();
    this.props.getPickupOutlets();
    var zoneIdTxt =
      typeof cookie.load("orderZoneId") === "undefined"
        ? ""
        : cookie.load("orderZoneId");

    if (availability === deliveryId && zoneIdTxt !== "") {
      this.props.getZoneDetail("", zoneIdTxt, 1);
    }
    //this.props.getAllOutlets(deliveryId);
    this.props.getSecondaryAddress();
  }

  /*forget password  - start*/
  fieldforgot = (field, value) => {
    this.setState(
      update(this.state, { fieldsfgtpassword: { [field]: { $set: value } } })
    );
  };

  forgotpassword = () => {
    this.setState({ fpstatus: "loading" });
    const formPayload = this.state.fieldsfgtpassword;

    var qs = require("qs");
    var postObject = {
      app_id: appId,
      type: "web",
      email_address: formPayload.email,
      site_url: this.props.globalsettings[0].result_set.client_site_url,
    };

    showLoader("forgotpassword-cls", "class");
    this.props.getForgetPassword(qs.stringify(postObject));
  };
  /*forget password  - end*/

  /* signin - start*/
  fieldChange = (field, value) => {
    this.setState(update(this.state, { fields: { [field]: { $set: value } } }));
  };

  handleSignin = () => {
    const formPayload = this.state.fields;
    var qs = require("qs");
    var postObject = {
      app_id: appId,
      type: "web",
      logintype: "mobile",
      passwordtype: "pin",
      login_username: formPayload.mobile,
      login_password: formPayload.password,
    };
    showLoader("login_submit", "class");
    this.props.getLoginData(qs.stringify(postObject));
  };
  /* signin - end*/

  /* for signup - start*/
  fieldChangeSignup = (field, value) => {
    if (field == "terms") {
      value = $("#terms").prop("checked");
    }
    if (field == "pdpa_consent") {
      value = $("#pdpa_consent").prop("checked");
    }
    this.setState(
      update(this.state, { fieldssignup: { [field]: { $set: value } } })
    );
  };

  handleSignup = () => {
    const formPayload = this.state.fieldssignup;
    this.setState({ regstatus: "loading" });

    var pdpaConsent = formPayload.pdpa_consent === true ? "yes" : "no";

    var qs = require("qs");
    var postObject = {
      app_id: appId,
      type: "web",
      passwordtype: "pin",
      customer_first_name: formPayload.firstname,
      customer_email: formPayload.email,
      customer_pin: formPayload.pin,
      customer_phone: formPayload.mobile,
      customer_pdpa_consent: pdpaConsent,
      site_url: this.props.globalsettings[0].result_set.client_site_url,
    };

    showLoader("signup_submit", "class");
    this.props.getRegistration(qs.stringify(postObject));
  };
  /* for signup - end*/

  componentWillReceiveProps(PropsDt) {
    if (PropsDt.menudata !== this.props.menudata) {
      this.setState({ menudata: PropsDt.menudata[0].result_set });
    }

    if (PropsDt.selectedAvilHome !== this.props.selectedAvilHome) {
      this.setState(
        {
          seletedAvilablityId: PropsDt.selectedAvilHome,
          selectedAvil: PropsDt.selectedAvilHome,
        },
        function () {
          this.pickOutlet(this.state.pickupOutlets[0]);
        }
      );
    }

    if (PropsDt.outletslist !== this.state.pickupOutletsList) {
      this.setState({
        pickupOutlets: PropsDt.outletslist,
        pickupOutletsList: PropsDt.outletslist,
      });
    }

    if (PropsDt.alloutletslist !== this.state.deliveryOutletsList) {
      this.setState({
        deliveryOutlets: PropsDt.alloutletslist,
        deliveryOutletsList: PropsDt.outletslist,
      });
    }

    if (PropsDt.secondaryaddresslist !== this.state.secondaryaddresslist) {
      this.setState({ secondaryaddresslist: PropsDt.secondaryaddresslist });
    }

    if ("homePageState" in PropsDt) {
      if (
        PropsDt.homePageState.nextavail !== undefined &&
        PropsDt.homePageState.nextavail !== "" &&
        PropsDt.homePageState.nextavail !== this.state.nextavail
      ) {
        this.setState({ nextavail: PropsDt.homePageState.nextavail });
      }
    }

    if (this.state.fbstatus === "loading") {
      console.log(PropsDt.fblogin, "PropsDt.fbloginPropsDt.fblogin");
      if (PropsDt.fblogin !== undefined) {
        if (PropsDt.fblogin.length > 0) {
          this.setState({ fbstatus: "ok" }, function () {
            this.doLogin(PropsDt.fblogin[0]);
          });
        }
      }
    }

    if (this.state.googlestatus === "loading") {
      if (PropsDt.googlelogin !== undefined) {
        if (PropsDt.googlelogin.length > 0) {
          this.setState({ googlestatus: "ok" }, function () {
            this.doLogin(PropsDt.googlelogin[0]);
          });
        }
      }
    }

    if (PropsDt.logindata !== this.props.logindata) {
      this.doLogin(PropsDt.logindata[0]);
    }

    if (this.state.fpstatus === "loading") {
      if (PropsDt.forgetpassword !== undefined) {
        this.setState({ fpstatus: "ok" });
        this.showMessage(PropsDt.forgetpassword[0]);
      }
    }

    if (this.state.regstatus === "loading") {
      if (PropsDt.registration !== undefined) {
        this.setState({
          regstatus: "ok",
          /*  fieldssignup: {
            firstname: "",
            email: "",
            pin: "",
            rePin: "",
            mobile: "",
            pdpa_consent: "",
            terms: "",
          }, */
        });
        this.showMessage(PropsDt.registration[0]);
      }
    }

    if (this.state.defaultAvilTy !== PropsDt.changeavail) {
      if (PropsDt.changeavail !== "" && PropsDt.changeavail !== undefined) {
        this.setState({ defaultAvilTy: PropsDt.changeavail });
      }
    }
    if (this.state.selectPostalCode !== PropsDt.selectPostalCode) {
      if (
        PropsDt.selectPostalCode !== "" &&
        PropsDt.selectPostalCode !== undefined
      ) {
        this.setState({ selectPostalCode: PropsDt.selectPostalCode });
      }
    }
    if (this.state.selectPickupTime !== PropsDt.selectPickupTime) {
      if (
        PropsDt.selectPickupTime !== "" &&
        PropsDt.selectPickupTime !== undefined
      ) {
        this.findOutletBasedZone("", pickupId);
        this.setState({
          selectPickupTime: PropsDt.selectPickupTime,
          seletedAvilablityId: pickupId,
        });
      }
    }

    if (PropsDt.globalsettings !== this.state.globalsettings) {
      if (
        PropsDt.globalsettings[0] !== "" &&
        PropsDt.globalsettings[0] !== undefined
      ) {
        cookie.save("orderTAT", PropsDt.globalsettings[0].result_set.order_tat, { path: "/"} );
        this.setState({ globalsettings: PropsDt.globalsettings[0].result_set });
      }
    }
  }

  /* main - menu navigation -start*/

  createLink(menu) {
    if (menu.nav_type === "0" && menu.nav_parent_title == "") {
      return (
        <Link to={"/page/" + menu.nav_title_slug} title={menu.nav_title}>
          <span>{menu.nav_title}</span>
        </Link>
      );
    } else if (menu.nav_type === "3" && menu.nav_parent_title == "") {
      var pageUrlTxt = menu.nav_pages;
      if (pageUrlTxt.includes("http")) {
        return (
          <a
            href={menu.nav_pages}
            title={menu.nav_title}
            target={menu.nav_link_type == "blank" ? "_blank" : ""}
          >
            <span>{menu.nav_title}</span>
          </a>
        );
      } else {
        return (
          <Link
            to={menu.nav_pages != "#" ? "/" + menu.nav_pages : ""}
            title={menu.nav_title}
            target={menu.nav_link_type == "blank" ? "_blank" : ""}
          >
            <span>{menu.nav_title}</span>
          </Link>
        );
      }
    }
  }

  createSubmenu(menu, type) {
    if (menu.nav_parent_title === "") {
      if (this.state.menudata) {
        var checkIngVal = 0;
        var liTxt = this.state.menudata.map(function (menuparent, i) {
          if (menu.nav_id == menuparent.nav_parent_title) {
            checkIngVal = 1;
            if (menuparent.nav_type === "0") {
              return (
                <li key={i + 100}>
                  <Link
                    to={"/page/" + menuparent.nav_title_slug}
                    title={menuparent.nav_title}
                  >
                    <span>{menuparent.nav_title}</span>
                  </Link>
                </li>
              );
            } else {
              return (
                <li key={i + 100}>
                  <Link
                    to={
                      menuparent.nav_pages != "#"
                        ? "/" + menuparent.nav_pages
                        : ""
                    }
                    title={menuparent.nav_title}
                    target={menuparent.nav_link_type == "blank" ? "_blank" : ""}
                  >
                    <span>{menuparent.nav_title}</span>
                  </Link>
                </li>
              );
            }
          }
        }, this);

        if (type === "span" && checkIngVal === 1) {
          return <a href="/" className="submenu-arow disbl_href_action"></a>;
        } else if (type === "ul" && checkIngVal === 1) {
          return <ul className="submenu_list">{liTxt}</ul>;
        } else {
          return "";
        }
      }
    }
  }

  menuActiveCls(nav_pages) {
    var currenturl = window.location.href;
    var returnClsTx = "";
    if (nav_pages === "home" || nav_pages === "#") {
      returnClsTx =
        this.props.match.path === "/home" || this.props.match.path === "/"
          ? "active"
          : "";
    } else if (nav_pages === "products") {
      returnClsTx =
        this.props.match.path === "/products" ||
        this.props.match.path === "/checkout" ||
        this.props.match.path === "/thankyou"
          ? "active"
          : "";
    } else {
      returnClsTx = currenturl.includes(nav_pages) ? "active" : "";
    }
    return returnClsTx;
  }

  listMainNavigation() {
    if (this.state.menudata) {
      return this.state.menudata.map(function (menu, i) {
        return (
          <li key={i + 100} className={this.menuActiveCls(menu.nav_pages)}>
            {this.createLink(menu)}
            {this.createSubmenu(menu, "span")}
            {this.createSubmenu(menu, "ul")}
          </li>
        );
      }, this);
    }
  }

  /* menu navigation -end */

  /* show message */
  showMessage(response) {
    hideLoader("signup_submit", "class");
    hideLoader("forgotpassword-cls", "class");
    if (response.status === "ok") {
      showAlert("Success", response.message);
    } else {
      if (response.form_error) {
        showAlert("Error", response.form_error);
      } else {
        showAlert("Error", response.message);
      }
    }
    $.magnificPopup.open({
      items: {
        src: ".alert_popup",
      },
      type: "inline",
    });
  }

  chooseAvailabilityMbl() {
    var availability = this.state.defaultAvilTy;
    var showtext = "Order Now";
    if (availability === deliveryId) {
      showtext = "Delivery";
    } else if (availability === pickupId) {
      showtext = "Takeaway";
    }
    return showtext;
  }
  /* facebook login */
  responseFacebook = (response) => {
    console.log(response, "FBResponse");
    if (
      response.name !== "" &&
      response.email !== "" &&
      response.name !== undefined &&
      response.email !== undefined
    ) {
      var vSplitName = response.name.split(" ");
      var firstname = vSplitName[0];
      var lastname = vSplitName[1];
      var birthday = response.birthday;
      var qs = require("qs");
      var photo = response.picture.data.url;
      /* update gender field */
      var postGender = "";
      if (
        typeof response.gender !== "undefined" &&
        response.gender === "male"
      ) {
        postGender = "M";
      } else if (
        typeof response.gender !== "undefined" &&
        response.gender === "female"
      ) {
        postGender = "F";
      }
      /* update DOB */
      var dob = "";
      if (typeof birthday !== "undefined" && birthday !== "") {
        dob = dateFormat(response.birthday, "yyyy-mm-dd");
      }

      var postObject = {
        app_id: appId,
        type: "web",
        login_firstname: response.first_name,
        login_lastname: response.last_name,
        login_username: response.email,
        customer_fb_id: response.id,
        customer_gender: postGender,
        customer_photo: photo,
        customer_dob: dob,
      };
      this.setState({ fbstatus: "loading" });
      this.props.getFbLoginData(qs.stringify(postObject));
    }
  };
  /* for login and facebook login*/
  doLogin(fbloginData) {
    hideLoader("login_submit", "class");
    if (fbloginData.status === "ok") {
      $.magnificPopup.close();
      var mobileno = "",
        cust_birthdate = "";
      if (
        typeof fbloginData.result_set.customer_phone === "undefined" ||
        fbloginData.result_set.customer_phone === "null" ||
        fbloginData.result_set.customer_phone === ""
      ) {
        mobileno = "";
      } else {
        mobileno = fbloginData.result_set.customer_phone;
      }

      if (
        typeof fbloginData.result_set.customer_birthdate !== "undefined" &&
        fbloginData.result_set.customer_birthdate !== "null" &&
        fbloginData.result_set.customer_birthdate !== null &&
        fbloginData.result_set.customer_birthdate !== "" &&
        fbloginData.result_set.customer_birthdate !== "0000-00-00"
      ) {
        cust_birthdate = fbloginData.result_set.customer_birthdate;
      }

      cookie.save("UserId", fbloginData.result_set.customer_id, { path: "/" });
      cookie.save("UserEmail", fbloginData.result_set.customer_email, {
        path: "/",
      });
      cookie.save(
        "UserFname",
        fbloginData.result_set.customer_first_name !== ""
          ? fbloginData.result_set.customer_first_name
          : "",
        { path: "/" }
      );
      cookie.save(
        "UserLname",
        fbloginData.result_set.customer_last_name !== ""
          ? fbloginData.result_set.customer_last_name
          : "",
        { path: "/" }
      );
      cookie.save("UserMobile", mobileno, { path: "/" });
      cookie.save("UserBirthdate", cust_birthdate, { path: "/" });

      cookie.save(
        "UserDefaultAddress",
        fbloginData.result_set.customer_address_name,
        { path: "/" }
      );
      cookie.save(
        "UserDefaultUnitOne",
        fbloginData.result_set.customer_address_line1,
        { path: "/" }
      );
      cookie.save(
        "UserDefaultUnitTwo",
        fbloginData.result_set.customer_address_line2,
        { path: "/" }
      );
      cookie.save(
        "UserDefaultPostalCode",
        fbloginData.result_set.customer_postal_code,
        { path: "/" }
      );

      const { history } = this.props;
      var qs = require("qs");
      var postObject = {
        app_id: appId,
        reference_id: getReferenceID(),
        customer_id: fbloginData.result_set.customer_id,
        availability_id: this.state.defaultAvilTy,
      };

      axios
        .post(apiUrl + "cart/update_customer_info", qs.stringify(postObject))
        .then((res) => {
          showAlert("Success", "Logged in Successfully!");
          $.magnificPopup.open({
            items: {
              src: ".alert_popup",
            },
            type: "inline",
          });
          if (
            res.data.contents !== "" &&
            typeof res.data.contents !== undefined &&
            typeof res.data.contents !== "undefined" &&
            res.data.contents !== null
          ) {
            var cartDetails = res.data.contents.cart_details;
            cookie.save("shopType", cartDetails.cart_shop_type, {
              path: "/",
            });
            cookie.save("orderOutletId", cartDetails.outlet_id, {
              path: "/",
            });
            cookie.save("outletLocationId", cartDetails.cart_location_id, {
              path: "/",
            });
            cookie.save(
              "defaultAvilablityId",
              cartDetails.cart_availability_id,
              {
                path: "/",
              }
            );
            cookie.save("orderZoneId", cartDetails.outletzone_id, {
              path: "/",
            });
          }
          if (res.data.status === "ok") {
            if (cookie.load("loginpopupTrigger") === "fromcheckout") {
              cookie.remove("loginpopupTrigger", { path: "/" });
              history.push("/checkout");
            } else {
              history.push("/myaccount");
            }
          } else {
            history.push("/myaccount");
          }
        });
    } else {
      cookie.remove("loginpopupTrigger", { path: "/" });
      /*showAlert('Error', 'Invalid Login Credentials','trigger_login','#login-popup');*/
      showAlert("Error", "Invalid Login Credentials");
      $.magnificPopup.open({
        items: {
          src: ".alert_popup",
        },
        type: "inline",
      });
    }
  }

  /* Google Login */
  prepareLoginButton = () => {
    if (this.state.trigerGlogin == false) {
      this.setState({ trigerGlogin: true }, function () {
        setTimeout(function () {
          $(".loginBtn--google").trigger("click");
        }, 1000);
      });
    }
    var current = this;
    this.auth2.attachClickHandler(
      this.refs.googleLoginBtn,
      {},
      (googleUser) => {
        let profile = googleUser.getBasicProfile();
        var resultRes = {
          name: profile.getName(),
          email: profile.getEmail(),
          picture: profile.getImageUrl(),
          id: profile.getId(),
        };
        current.loginGoogle(resultRes);
      },
      (error) => {
        console.log(error, "googleerror");
        //alert(JSON.stringify(error, undefined, 2));
      }
    );
  };

  googleSDK() {
    console.log(googleAppId, "googleAppId");
    window["googleSDKLoaded"] = () => {
      window["gapi"].load("auth2", () => {
        this.auth2 = window["gapi"].auth2.init({
          client_id: googleAppId,
          cookiepolicy: "single_host_origin",
          scope: "profile email",
        });

        this.prepareLoginButton();
      });
    };

    (function (d, s, id) {
      var js,
        fjs = d.getElementsByTagName(s)[0];
      if (d.getElementById(id)) {
        return;
      }
      js = d.createElement(s);
      js.id = id;
      js.src = "https://apis.google.com/js/platform.js?onload=googleSDKLoaded";
      fjs.parentNode.insertBefore(js, fjs);
    })(document, "script", "google-jssdk");
  }

  loginGoogle(response) {
    if (
      response.name !== "" &&
      response.email !== "" &&
      response.name !== undefined &&
      response.email !== undefined
    ) {
      var vSplitName = response.name.split(" ");
      var firstname = vSplitName[0];
      var lastname = vSplitName[1];

      var qs = require("qs");
      var photo = response.picture;
      /* update gender field */

      var postObject = {
        app_id: appId,
        type: "web",
        login_firstname: firstname,
        login_lastname: lastname,
        login_username: response.email,
        customer_google_id: response.id,
        customer_photo: photo,
      };
      this.setState({ googlestatus: "loading" });
      this.props.getGoogleLoginData(qs.stringify(postObject));
    }
  }

  /*  Onchange  for Guest Checkout */
  fieldChangecheckout = (field, value) => {
    this.setState(
      update(this.state, { fieldscheckout: { [field]: { $set: value } } })
    );
  };

  handleCheckout = () => {
    const formPayload = this.state.fieldscheckout;
    if (
      document.getElementById("spn-email-error").innerHTML ===
      '<span class="error">This email already exists</span>'
    ) {
      return false;
    }
    if (
      document.getElementById("spn-mobile-error").innerHTML ===
      '<span class="error">This mobile number already exists</span>'
    ) {
      return false;
    }

    showLoader("login_in_guest", "class");

    var qs = require("qs");
    var postObject = {
      app_id: appId,
      type: "web",
      customer_first_name: formPayload.check_firstname,
      customer_email: formPayload.check_email,
      customer_phone: formPayload.check_phone,
      site_url: this.props.globalsettings[0].result_set.client_site_url,
    };

    axios
      .post(apiUrl + "guestaccount/create", qs.stringify(postObject))
      .then((response) => {
        hideLoader("login_in_guest", "class");
        if (response.data.status === "ok") {
          window.scrollTo(0, 0);
          $.magnificPopup.close();

          var cust_birthdate = "";
          if (
            typeof response.data.result_set.customer_birthdate !==
              "undefined" &&
            response.data.result_set.customer_birthdate !== "null" &&
            response.data.result_set.customer_birthdate !== null &&
            response.data.result_set.customer_birthdate !== "" &&
            response.data.result_set.customer_birthdate !== "0000-00-00"
          ) {
            cust_birthdate = response.data.result_set.customer_birthdate;
          }

          /* set User cookie values - Start */
          cookie.save("UserId", response.data.result_set.customer_id, {
            path: "/",
          });
          cookie.save(
            "UserFname",
            response.data.result_set.customer_first_name !== ""
              ? response.data.result_set.customer_first_name
              : "",
            { path: "/" }
          );
          cookie.save(
            "UserLname",
            response.data.result_set.customer_last_name != ""
              ? response.data.result_set.customer_last_name
              : "",
            { path: "/" }
          );
          cookie.save("UserMobile", response.data.result_set.customer_phone, {
            path: "/",
          });
          cookie.save("UserEmail", response.data.result_set.customer_email, {
            path: "/",
          });
          cookie.save(
            "UserDefaultAddress",
            response.data.result_set.customer_address_name,
            { path: "/" }
          );
          cookie.save(
            "UserDefaultUnitOne",
            response.data.result_set.customer_address_line1,
            { path: "/" }
          );
          cookie.save(
            "UserDefaultUnitTwo",
            response.data.result_set.customer_address_line2,
            { path: "/" }
          );
          cookie.save(
            "UserDefaultPostalCode",
            response.data.result_set.customer_postal_code,
            { path: "/" }
          );
          cookie.save("UserBirthdate", cust_birthdate, { path: "/" });
          cookie.save("userAccountType", 1, { path: "/" });
          const { history } = this.props;
          var qs = require("qs");
          var postObject = {
            app_id: appId,
            reference_id: getReferenceID(),
            customer_id: response.data.result_set.customer_id,
            availability_id: this.state.defaultAvilTy,
          };

          axios
            .post(
              apiUrl + "cart/update_customer_info",
              qs.stringify(postObject)
            )
            .then((res) => {
              showAlert("Success", "Logged in Successfully!");
              $.magnificPopup.open({
                items: {
                  src: ".alert_popup",
                },
                type: "inline",
              });
              if (res.data.status === "ok") {
                if (cookie.load("loginpopupTrigger") === "fromcheckout") {
                  cookie.remove("loginpopupTrigger", { path: "/" });
                  history.push("/checkout");
                } else {
                  history.push("/myaccount");
                }
              } else {
                if (cookie.load("redirectReservatin") === "Yes") {
                  cookie.remove("redirectReservatin", { path: "/" });
                  history.push("/reservation");
                } else {
                  history.push("/myaccount");
                }
              }
            });
        } else {
          if (response.data.form_error) {
            $(".guest-chk-error").html(
              '<div class="alert alert_danger" style="display:block">' +
                response.data.form_error +
                "</div>"
            );
          } else {
            $(".guest-chk-error").html(
              '<div class="alert alert_danger" style="display:block">' +
                response.data.message +
                "</div>"
            );
          }
        }
        return false;
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  closepopup(event) {
    event.preventDefault();

    $.magnificPopup.open({
      items: {
        src: "#order-popup",
      },
      type: "inline",
    });
  }

  close_popup(event) {
    event.preventDefault();
    $.magnificPopup.close();
  }

  gobckOutletpopup() {
    $.magnificPopup.open({
      items: {
        src: "#delevery-popup",
      },
      type: "inline",
    });
  }

  gobckPkupOutletpopup() {
    $.magnificPopup.open({
      items: {
        src: "#takeaway-popup",
      },
      type: "inline",
    });
  }

  changeAvailability() {
    var tempArr = [],
      tempVl = "";
    this.setState({
      seletedOutletId: tempVl,
      deliveryInfo: tempArr,
      pickupInfo: tempArr,
      seleted_ord_date: tempVl,
      seleted_ord_time: tempVl,
    });
    var change_shop_type = $("#change_shop_type").val();

    if (change_shop_type !== "") {
      this.destroyCartShopeType("");
      cookie.save("shopType", change_shop_type, { path: "/" });

      return false;
    } else {
      this.destroyCart("Yes");
    }

    var popupIdtxt = "";
    if (this.state.nextavail === deliveryId) {
      popupIdtxt = "#delevery-popup";
    } else if (this.state.nextavail === pickupId) {
      popupIdtxt = "#takeaway-popup";
    }
    if (popupIdtxt !== "") {
      $.magnificPopup.open({
        items: {
          src: popupIdtxt,
        },
        type: "inline",
      });
    }
  }

  destroyCart(clear = "No") {
    this.props.destroyCartDetail();
    this.deleteOrderCookie(clear);
  }

  destroyCartShopeType() {
    this.props.destroyCartDetail();
    cookie.remove("cartsubtotal", { path: "/" });
    cookie.remove("cartid", { path: "/" });

    /* Delivery avilablity */
    /* cookie.remove("orderDateTime", { path: "/" });
    cookie.remove("deliveryDate", { path: "/" });
    cookie.remove("deliveryTime", { path: "/" }); */
    cookie.remove("unitNoOne", { path: "/" });
    cookie.remove("unitNoTwo", { path: "/" });

    cookie.remove("promotion_id", { path: "/" });
    cookie.remove("promotion_applied", { path: "/" });
    cookie.remove("promotion_code", { path: "/" });
    cookie.remove("promotion_delivery_charge_applied", { path: "/" });
    cookie.remove("promotion_amount", { path: "/" });
    cookie.remove("promotion_category", { path: "/" });
    cookie.remove("prmo_type", { path: "/" });

    /*Remove voucher*/
    cookie.remove("voucher_applied", { path: "/" });
    cookie.remove("voucher_code", { path: "/" });
    cookie.remove("voucher_amount", { path: "/" });

    cookie.remove("points_redeemed", { path: "/" });
    cookie.remove("points_used", { path: "/" });
    cookie.remove("points_amount", { path: "/" });
    cookie.remove("prmo_type", { path: "/" });
    cookie.remove("paymentmodevalue", { path: "/" });
    cookie.remove("paypaymentType", { path: "/" });
    location.reload();
  }

  deleteOrderCookie(clear = "Yes") {
    if (clear == "Yes") {
      cookie.remove("orderZoneId", { path: "/" });
      cookie.remove("orderOutletId", { path: "/" });
    }

    removeOrderDateTime();
    removePromoCkValue();
    cookie.remove("orderPaymentMode", { path: "/" });
    cookie.remove("orderTableNo", { path: "/" });
    cookie.remove("product_remarks", { path: "/" });
    cookie.remove("orderOutletName", { path: "/" });
    cookie.remove("carttotalitems", { path: "/" });
    cookie.remove("cartsubtotal", { path: "/" });
    cookie.remove("cartid", { path: "/" });
    cookie.remove("firstNavigation", { path: "/" });

    /* Delivery avilablity */
    cookie.remove("orderDateTime", { path: "/" });
    cookie.remove("deliveryDate", { path: "/" });
    cookie.remove("deliveryTime", { path: "/" });
    cookie.remove("unitNoOne", { path: "/" });
    cookie.remove("unitNoTwo", { path: "/" });

    cookie.remove("promotion_id", { path: "/" });
    cookie.remove("promotion_applied", { path: "/" });
    cookie.remove("promotion_code", { path: "/" });
    cookie.remove("promotion_delivery_charge_applied", { path: "/" });
    cookie.remove("promotion_amount", { path: "/" });
    cookie.remove("promotion_category", { path: "/" });
    cookie.remove("prmo_type", { path: "/" });

    /*Remove voucher*/
    cookie.remove("voucher_applied", { path: "/" });
    cookie.remove("voucher_code", { path: "/" });
    cookie.remove("voucher_amount", { path: "/" });

    cookie.remove("points_redeemed", { path: "/" });
    cookie.remove("points_used", { path: "/" });
    cookie.remove("points_amount", { path: "/" });
    cookie.remove("prmo_type", { path: "/" });
    cookie.remove("paymentmodevalue", { path: "/" });
    cookie.remove("paypaymentType", { path: "/" });
  }

  /* find Zone*/
  findOutletBasedZone(first, availability) {
    var postalcode = "";

    if (first) {
      if (first == 2) {
        if (availability === deliveryId) {
          postalcode = cookie.load("deliveryPostalCode");
        } else {
          postalcode = cookie.load("orderPostalCode");
        }
      } else {
        postalcode = $("#postalcode").val();
      }
    } else {
      postalcode = $("#postalcode1").val();
    }
    if (
      postalcode !== "" &&
      typeof postalcode !== undefined &&
      typeof postalcode !== "undefined"
    ) {
      if (postalcode.length < 5) {
        $(".postal_error").html(
          '<span class="error">Please enter valid postal code.</span>'
        );
        return false;
      }
    } else {
      if (availability === deliveryId) {
        $.magnificPopup.open({
          items: {
            src: "#delevery-postcode-popup",
          },
          type: "inline",
        });
        return false;
      }
    }

    showLoader("delivery_submit_cls", "class");
    if (availability === deliveryId) {
      axios
        .all([
          axios.get(
            apiUrlV2 +
              "outlets/findOutletZone?app_id=" +
              appId +
              "&availability_id=" +
              availability +
              "&postal_code=" +
              postalcode +
              "&shop_type=" +
              this.state.shopType
          ),
          axios.get(
            apiUrlV2 +
              "settings/chkTimeslotIsAvaiable?app_id=" +
              appId +
              "&availability_id=" +
              availability /*  +
            "&outletId=" +
            outletIdTxt */
          ),
        ])
        .then(
          axios.spread((res, timeslt) => {
            hideLoader("delivery_submit_cls", "class");

            var deliveryInfo = [];
            /* Success response */
            if (res.data.status === "ok") {
              $.magnificPopup.close();

              var orderDeliveryAddress =
                res.data.result_set.postal_code_information.zip_buno +
                " " +
                res.data.result_set.postal_code_information.zip_sname;
              var orderHandled =
                stripslashes(res.data.result_set.outlet_name) +
                ", Crew will be seeing you in " +
                res.data.result_set.outlet_delivery_timing +
                " Minutes";

              deliveryInfo["orderZoneId"] = res.data.result_set.zone_id;
              deliveryInfo["orderOutletId"] = res.data.result_set.outlet_id;
              deliveryInfo["orderOutletName"] = stripslashes(
                res.data.result_set.outlet_name
              );
              deliveryInfo["orderPostalCode"] =
                res.data.result_set.postal_code_information.zip_code;
              deliveryInfo["orderTAT"] =
                res.data.result_set.outlet_delivery_timing;
              deliveryInfo["orderDeliveryAddress"] = orderDeliveryAddress;
              deliveryInfo["orderHandled"] = orderHandled;
              deliveryInfo["defaultAvilablityId"] = availability;

              var unitNum = this.showUnitNum(
                res.data.result_set.outlet_unit_number1,
                res.data.result_set.outlet_unit_number2
              );

              var orderHandledText =
                res.data.result_set.outlet_address_line1 +
                " " +
                res.data.result_set.outlet_address_line2 +
                ", " +
                unitNum +
                " Singapore " +
                postalcode;

              deliveryInfo["orderHandledByText"] = orderHandledText;

              this.setState({
                deliveryInfo: deliveryInfo,
                seletedAvilablityId: availability,
                defaultAvilTy: availability,
                seletedOutletId: res.data.result_set.outlet_id,
                order_tat_time: res.data.result_set.outlet_delivery_timing,
                orderHandled: orderHandled,
                orderDeliveryAddress:
                  orderDeliveryAddress +
                  " Singapore " +
                  res.data.result_set.postal_code_information.zip_code,
              });

              if (this.state.selectPostalCode === "Yes") {
                cookie.save("orderPostalCode", postalcode, { path: "/" });
                if (availability === deliveryId) {
                  cookie.save("deliveryPostalCode", postalcode, { path: "/" });
                  cookie.save("orderDeliveryAddress", orderDeliveryAddress, {
                    path: "/",
                  });
                  cookie.save("defaultAvilablityId", deliveryId, {
                    path: "/",
                  });
                } else {
                  cookie.save("defaultAvilablityId", pickupId, {
                    path: "/",
                  });
                }
                cookie.save("orderZoneId", deliveryInfo["orderZoneId"], {
                  path: "/",
                });

                this.props.sateValChange("selectPostalCode", "Yes");
                this.props.sateValChange("postalcode", postalcode);
                var orderDate =
                  cookie.load("deliveryDate") !== "" &&
                  typeof cookie.load("deliveryDate") !== undefined &&
                  typeof cookie.load("deliveryDate") !== "undefined"
                    ? cookie.load("deliveryDate")
                    : "";
                if (
                  this.props.match.params.slugRestaurant !== "" &&
                  typeof this.props.match.params.slugRestaurant !== undefined &&
                  typeof this.props.match.params.slugRestaurant !==
                    "undefined" &&
                  orderDate !== ""
                ) {
                  location.reload();
                }
                if (orderDate !== "") {
                  return false;
                }
              }
              /* Success time slot response */
              if (timeslt.data.status === "success") {
                this.setState({ getDateTimeFlg: "yes" });

                //removeOrderDateTime();
                removePromoCkValue();

                $.magnificPopup.open({
                  items: {
                    src: "#awesome-popup",
                  },
                  type: "inline",
                });
              } else {
                $.magnificPopup.open({
                  items: {
                    src: "#outlet-error-popup",
                  },
                  type: "inline",
                });
              }
            }

            /* Error response */
            if (res.data.status === "error") {
              this.setState({ deliveryInfo: deliveryInfo });

              $.magnificPopup.close();
              $.magnificPopup.open({
                items: {
                  src: "#error-postal-popup",
                },
                type: "inline",
              });

              if (first === 0) {
                var mgsTxt =
                  res.data.message !== ""
                    ? res.data.message
                    : "Please enter valid postal code.";
                $(".postal_error").html(
                  '<span class="error">' + mgsTxt + "</span>"
                );
              }
            }
          })
        );
    } else {
      axios
        .all([
          axios.get(
            apiUrl +
              "outlets/pickup_outlets?app_id=" +
              appId +
              "&availability=" +
              availability
          ),
          axios.get(
            apiUrlV2 +
              "settings/chkTimeslotIsAvaiable?app_id=" +
              appId +
              "&availability_id=" +
              availability /*  +
          "&outletId=" +
          outletIdTxt */
          ),
        ])
        .then(
          axios.spread((res, timeslt) => {
            hideLoader("delivery_submit_cls", "class");

            var pickupInfo = [];
            /* Success response */
            if (res.data.status === "ok") {
              $.magnificPopup.close();
              var resultSet = res.data.result_set[0];
              var orderDeliveryAddress =
                resultSet.outlet_unit_number1 +
                " " +
                resultSet.outlet_unit_number2;
              var orderHandled =
                stripslashes(resultSet.outlet_name) +
                ", Crew will be seeing you in " +
                resultSet.outlet_pickup_tat +
                " Minutes";

              pickupInfo["orderOutletId"] = resultSet.outlet_id;
              pickupInfo["orderOutletName"] = stripslashes(
                resultSet.outlet_name
              );
              pickupInfo["orderPostalCode"] = resultSet.outlet_postal_code;
              pickupInfo["orderTAT"] = resultSet.outlet_pickup_tat;

              pickupInfo["orderHandled"] = orderHandled;
              pickupInfo["defaultAvilablityId"] = availability;

              var unitNum = this.showUnitNum(
                resultSet.outlet_unit_number1,
                resultSet.outlet_unit_number2
              );

              var orderHandledText =
                resultSet.outlet_address_line1 +
                " " +
                resultSet.outlet_address_line2 +
                ", " +
                unitNum +
                " Singapore " +
                postalcode;

              pickupInfo["orderHandledByText"] = orderHandledText;

              this.setState({
                pickupInfo: pickupInfo,
                seletedAvilablityId: availability,
                defaultAvilTy: availability,
                seletedOutletId: res.data.result_set.oa_outlet_id,
                order_tat_time: resultSet.outlet_pickup_tat,
                orderHandled: orderHandled,
                orderDeliveryAddress:
                  orderDeliveryAddress +
                  " Singapore " +
                  resultSet.outlet_postal_code,
              });

              if (this.state.selectPostalCode === "Yes") {
                cookie.save("orderPostalCode", postalcode, { path: "/" });

                cookie.save("defaultAvilablityId", pickupId, {
                  path: "/",
                });

                this.props.sateValChange("selectPostalCode", "Yes");
                this.props.sateValChange("postalcode", postalcode);

                if (
                  this.props.match.params.slugRestaurant !== "" &&
                  typeof this.props.match.params.slugRestaurant !== undefined &&
                  typeof this.props.match.params.slugRestaurant !== "undefined"
                ) {
                  location.reload();
                }
                return false;
              }
              /* Success time slot response */
              if (timeslt.data.status === "success") {
                this.setState({ getDateTimeFlg: "yes" });

                //removeOrderDateTime();
                removePromoCkValue();

                $.magnificPopup.open({
                  items: {
                    src: "#awesome-popup",
                  },
                  type: "inline",
                });
              } else {
                $.magnificPopup.open({
                  items: {
                    src: "#outlet-error-popup",
                  },
                  type: "inline",
                });
              }
            }

            /* Error response */
            if (res.data.status === "error") {
              this.setState({ deliveryInfo: deliveryInfo });

              $.magnificPopup.close();
              $.magnificPopup.open({
                items: {
                  src: "#error-postal-popup",
                },
                type: "inline",
              });

              if (first === 0) {
                var mgsTxt =
                  res.data.message !== ""
                    ? res.data.message
                    : "Please enter valid postal code.";
                $(".postal_error").html(
                  '<span class="error">' + mgsTxt + "</span>"
                );
              }
            }
          })
        );
    }
  }

  /* Select outlet */
  selectOutlet(first, availability) {
    if (first) {
      var postalcode = $("#postalcode").val();
    } else {
      var postalcode = $("#postalcode1").val();
    }

    if (postalcode.length < 5) {
      $(".postal_error").html(
        '<span class="error">Please enter valid postal code.</span>'
      );
      return false;
    }

    showLoader("delivery_submit_cls", "class");

    axios
      .get(
        apiUrl +
          "outlets/find_outlet?skip_timing=Yes&app_id=" +
          appId +
          "&availability_id=" +
          availability +
          "&postal_code=" +
          postalcode
      )
      .then((res) => {
        hideLoader("delivery_submit_cls", "class");

        /* Success response */
        if (res.data.status === "ok") {
          $.magnificPopup.close();
          var orderDeliveryAddress =
            res.data.result_set.postal_code_information.zip_buno +
            " " +
            res.data.result_set.postal_code_information.zip_sname;
          var orderHandled =
            stripslashes(res.data.result_set.outlet_name) +
            ", Crew will be seeing you in " +
            res.data.result_set.outlet_delivery_timing +
            " Minutes";
          this.setState({
            orderDeliveryAddress:
              orderDeliveryAddress +
              " Singapore " +
              res.data.result_set.postal_code_information.zip_code,
          });
          this.setState({ orderHandled: orderHandled });

          cookie.save("orderOutletId", res.data.result_set.outlet_id, {
            path: "/",
          });
          cookie.save(
            "orderOutletName",
            stripslashes(res.data.result_set.outlet_name),
            { path: "/" }
          );
          cookie.save(
            "orderPostalCode",
            res.data.result_set.postal_code_information.zip_code,
            { path: "/" }
          );
          // cookie.save("orderTAT", res.data.result_set.outlet_delivery_timing, {
          //   path: "/",
          // });
          cookie.save("orderDeliveryAddress", orderDeliveryAddress, {
            path: "/",
          });
          cookie.save("orderHandled", orderHandled, { path: "/" });
          cookie.save("defaultAvilablityId", availability, { path: "/" });
          this.setState({ defaultAvilTy: availability });

          var orderHandledText =
            res.data.result_set.outlet_address_line1 +
            " " +
            res.data.result_set.outlet_address_line2 +
            ", Singapore " +
            postalcode;
          cookie.save("orderHandledByText", orderHandledText, { path: "/" });

          removeOrderDateTime();
          removePromoCkValue();

          $.magnificPopup.open({
            items: {
              src: "#awesome-popup",
            },
            type: "inline",
          });
        }

        /* Error response */
        if (res.data.status === "error") {
          $.magnificPopup.close();
          $.magnificPopup.open({
            items: {
              src: "#error-postal-popup",
            },
            type: "inline",
          });

          if (first === 0) {
            var mgsTxt =
              res.data.message !== ""
                ? res.data.message
                : "Please enter valid postal code.";
            $(".postal_error").html(
              '<span class="error">' + mgsTxt + "</span>"
            );
          }
        }
      });
  }

  gotoProducts() {
    if (
      cookie.load("orderOutletId") == undefined ||
      cookie.load("orderOutletId") == ""
    ) {
      $(".outlet_error").html(
        '<span class="error"> Please choose one outlet.</span>'
      );
    } else {
      $.magnificPopup.close();
      if (cookie.load("popuptriggerFrom") === "FeaturedPro") {
        cookie.remove("popuptriggerFrom", { path: "/" });
        this.props.history.push("/");
      } else {
        this.props.history.push("/food");
      }
    }
  }

  selectDatetm() {
    /*  var seletedOutletId = this.state.seletedOutletId;
    var pickupInfo = this.state.pickupInfo;
    if (seletedOutletId !== "" && Object.keys(pickupInfo).length > 0) { */
    showLoader("takeaway-btn-act", "class");

    axios
      .get(
        apiUrlV2 +
          "settings/chkTimeslotIsAvaiable?app_id=" +
          appId +
          "&availability_id=" +
          pickupId /*+
             "&outletId=" +
            seletedOutletId */
      )
      .then((res) => {
        hideLoader("takeaway-btn-act", "class");

        /* Success response */
        if (res.data.status === "success") {
          this.setState({ getDateTimeFlg: "yes" });

          removeOrderDateTime();
          removePromoCkValue();

          $.magnificPopup.open({
            items: {
              src: "#awesome-popup",
            },
            type: "inline",
          });
        } else {
          $.magnificPopup.open({
            items: {
              src: "#outlet-error-popup",
            },
            type: "inline",
          });
        }
      });
    /*  } else {
      $(".outlet_error").html(
        '<span class="error"> Please choose one outlet.</span>'
      );
    } */
  }

  chooseAvailabilityFun(availability) {
    var defaultAvilTy =
      cookie.load("defaultAvilablityId") !== "" &&
      typeof cookie.load("defaultAvilablityId") !== undefined &&
      typeof cookie.load("defaultAvilablityId") !== "undefined"
        ? cookie.load("defaultAvilablityId")
        : "";
    var orderPostalCode = cookie.load("orderPostalCode");
    var deliveryPostalCode = cookie.load("deliveryPostalCode");

    if (
      defaultAvilTy !== "" &&
      typeof defaultAvilTy !== undefined &&
      typeof defaultAvilTy !== "undefined"
    ) {
      if (availability === deliveryId) {
        if (
          deliveryPostalCode !== "" &&
          typeof deliveryPostalCode !== undefined &&
          typeof deliveryPostalCode !== "undefined"
        ) {
        } else {
          $.magnificPopup.open({
            items: {
              src: "#delevery-postcode-popup",
            },
            type: "inline",
          });
          return false;
        }
      }
      cookie.remove("defaultAvilablityId", { path: "/" });
      cookie.save("defaultAvilablityId", availability, { path: "/" });
      this.setState({ defaultAvilTy: availability }, function () {
        showLoader("innerproduct", "class");
        axios
          .get(
            apiUrl +
              "cart/update_availability?app_id=" +
              appId +
              "&reference_id=" +
              getReferenceID() +
              "&customer_id=" +
              cookie.load("UserId") +
              "&availability_id=" +
              availability
          )
          .then((res) => {});
      });
      this.props.history.push("/food");
      return false;
    }

    if (
      defaultAvilTy !== "" &&
      typeof defaultAvilTy !== undefined &&
      typeof defaultAvilTy !== "undefined" &&
      defaultAvilTy === availability
    ) {
      if (availability === deliveryId) {
        if (
          orderPostalCode === "" ||
          typeof orderPostalCode === undefined ||
          typeof orderPostalCode === "undefined"
        ) {
          $.magnificPopup.open({
            items: {
              src: "#delevery-postcode-popup",
            },
            type: "inline",
          });
          return false;
        }
      } else if (availability === pickupId) {
        if (
          orderPostalCode === "" ||
          typeof orderPostalCode === undefined ||
          typeof orderPostalCode === "undefined"
        ) {
          this.setState({ seletedAvilablityId: pickupId });
          return false;
        }
      }
      this.props.history.push("/food");
      return false;
    } else {
      if (this.state.cartitemcount > 0) {
        $.magnificPopup.open({
          items: {
            src: "#warning-popup",
          },
          type: "inline",
        });
        return false;
      }
    }

    var popupIdtxt = "";
    if (availability === deliveryId) {
      $(".delivery_outletpoup").find(".outlet_error").html("");
      popupIdtxt = "#delevery-postcode-popup";
    } else if (availability === pickupId) {
      this.setState({ seletedAvilablityId: pickupId });
      //popupIdtxt = "#takeaway-popup";
    }
    if (popupIdtxt !== "") {
      $.magnificPopup.open({
        items: {
          src: popupIdtxt,
        },
        type: "inline",
      });
    }
  }

  selectDlyOutlet() {
    if (this.state.delivery_outlet_id === "") {
      $(".delivery_outletpoup")
        .find(".outlet_error")
        .html('<span class="error"> Please choose one outlet.</span>');
    } else {
      $.magnificPopup.close();
      $.magnificPopup.open({
        items: {
          src: "#delevery-postcode-popup",
        },
        type: "inline",
      });
    }
  }

  handleKeyPress = (event) => {
    var value = event.target.value.toLowerCase(),
      matches = this.state.pickupOutletsList.filter(function (item) {
        return (
          item.outlet_address_line1.substring(0, value.length).toLowerCase() ===
            value ||
          item.outlet_postal_code.substring(0, value.length).toLowerCase() ===
            value ||
          stripslashes(item.outlet_name)
            .substring(0, value.length)
            .toLowerCase() === value
        );
      });

    this.setState({ pickupOutlets: matches });
  };

  /* load outlets  */
  loadOutletsList() {
    if (Object.keys(this.state.pickupOutlets).length > 0) {
      return this.state.pickupOutlets.map((loaddata, index) => (
        <li key={index} className={this.activeOutlet(loaddata.outlet_id)}>
          <a href="/" onClick={this.pickOutlet.bind(this, loaddata)}>
            {stripslashes(loaddata.outlet_name)},{" "}
            {loaddata.outlet_address_line1},{" "}
            {this.showUnitNum(
              loaddata.outlet_unit_number1,
              loaddata.outlet_unit_number2
            )}
            , Singapore {loaddata.outlet_postal_code}
          </a>
        </li>
      ));
    } else {
      return (
        <li>
          <a>No Outlet found</a>
        </li>
      );
    }
  }

  activeOutlet(outletID) {
    var seletedOutletId = this.state.seletedOutletId;
    var pickupInfo = this.state.pickupInfo;
    var actTxt =
      parseInt(seletedOutletId) === parseInt(outletID) &&
      Object.keys(pickupInfo).length > 0
        ? "active"
        : "";
    return actTxt;
  }

  handleKeyPressDly = (event) => {
    var value = event.target.value.toLowerCase(),
      matches = this.state.deliveryOutletsList.filter(function (item) {
        return (
          item.outlet_address_line1.substring(0, value.length).toLowerCase() ===
            value ||
          item.outlet_postal_code.substring(0, value.length).toLowerCase() ===
            value ||
          stripslashes(item.outlet_name)
            .substring(0, value.length)
            .toLowerCase() === value
        );
      });

    this.setState({ deliveryOutlets: matches });
  };

  showUnitNum(unit1, unit2) {
    unit1 = typeof unit1 !== "undefined" ? unit1 : "";
    unit2 = typeof unit2 !== "undefined" ? unit2 : "";

    if (unit1 !== "") {
      var unitNo = unit2 !== "" ? unit1 + " - " + unit2 : unit1;
    } else {
      var unitNo = unit2;
    }

    return unitNo !== "" ? "#" + unitNo : "";
  }

  /* load delivery outlets  */
  loadDeliveryOutletsList() {
    if (Object.keys(this.state.deliveryOutlets).length > 0) {
      return this.state.deliveryOutlets.map((loaddata, index) => (
        <li key={index} className={this.activeDlyOutlet(loaddata.outlet_id)}>
          <a href="/" onClick={this.deliveryOutlet.bind(this, loaddata)}>
            {stripslashes(loaddata.outlet_name)},{" "}
            {loaddata.outlet_address_line1},{" "}
            {this.showUnitNum(
              loaddata.outlet_unit_number1,
              loaddata.outlet_unit_number2
            )}
            , Singapore {loaddata.outlet_postal_code}
          </a>
        </li>
      ));
    } else {
      return (
        <li>
          <a>No Outlet found</a>
        </li>
      );
    }
  }

  activeDlyOutlet(outletID) {
    var orderOutletId =
      this.state.delivery_outlet_id !== ""
        ? this.state.delivery_outlet_id
        : cookie.load("orderOutletId");

    return orderOutletId === outletID ? "active" : "";
  }

  /* pick outlet */
  pickOutlet(loaddata, event) {
    if (event !== "" && event !== undefined) {
      event.preventDefault();
    }

    this.selectDatetm();
    var unitNum = this.showUnitNum(
      loaddata.outlet_unit_number1,
      loaddata.outlet_unit_number2
    );

    var orderHandled =
      stripslashes(loaddata.outlet_name) +
      " " +
      loaddata.outlet_address_line1 +
      " " +
      loaddata.outlet_address_line2 +
      ", " +
      unitNum +
      " Singapore " +
      loaddata.outlet_postal_code;

    var pickupInfo = [];
    pickupInfo["orderOutletId"] = loaddata.outlet_id;
    pickupInfo["orderOutletName"] = stripslashes(loaddata.outlet_name);
    pickupInfo["orderPostalCode"] = loaddata.outlet_postal_code;
    pickupInfo["orderTAT"] = loaddata.outlet_pickup_tat;
    pickupInfo["orderHandled"] = orderHandled;
    pickupInfo["orderHandledByText"] = orderHandled;
    pickupInfo["defaultAvilablityId"] = pickupId;

    this.setState(
      {
        pickupInfo: pickupInfo,
        seletedAvilablityId: pickupId,
        seletedOutletId: loaddata.outlet_id,
        order_tat_time: loaddata.outlet_pickup_tat,
        orderHandled: orderHandled,
      },
      function () {
        this.selectDatetm();
      }.bind(this)
    );
  }

  pickWithoutOutlet() {
    this.selectDatetm();
  }

  deliveryOutlet(loaddata, event) {
    event.preventDefault();
    $(".delivery_outletpoup").find(".outlet_error").html("");
    this.setState(
      { delivery_outlet_id: loaddata.outlet_id },
      function () {
        this.selectDlyOutlet();
      }.bind(this)
    );
  }

  trgContinuBtn(idTxt) {
    $("#" + idTxt).trigger("click");
  }

  componentDidMount() {
    if (
      cookie.load("loginpopupTrigger") !== "" &&
      typeof cookie.load("loginpopupTrigger") !== undefined &&
      typeof cookie.load("loginpopupTrigger") !== "undefined"
    ) {
      if (cookie.load("loginpopupTrigger") === "login") {
        $.magnificPopup.open({
          items: {
            src: "#login-popup",
          },
          type: "inline",
        });
        cookie.remove("loginpopupTrigger", { path: "/" });
      }
    }

    $(".custom_close").click(function (e) {
      e.preventDefault();
      $(".custom_alertcls, .custom_center_alertcls").hide();
    });
    $("html, body").animate(
      {
        scrollTop: $("body").offset().top,
      },
      100
    );
    $(".test-popup-link").magnificPopup({
      type: "image",
      showCloseBtn: true,
      verticalFit: true,
      callbacks: {
        change: function () {
          this.wrap.addClass("awardpopup");
        },
      },
      // other options
    });

    this.props.getMenuData("kloudkitchen-header-menu");
    if ($(".open-popup-link").length > 0) {
      $(".open-popup-link").magnificPopup({
        type: "inline",
        midClick: true,
      });
    }

    if ($(".trigger_login").length > 0) {
      $(".trigger_login").magnificPopup({
        type: "inline",
        midClick: true,
      });
    }

    /* Input lable animation */
    if ($(".input-focus").length > 0) {
      $(".input-focus").focus(function () {
        $(this).parents(".focus-out").addClass("focused");
      });
      $(".input-focus").blur(function () {
        var inputValue = $(this).val();
        if (inputValue == "") {
          $(this).removeClass("filled");
          $(this).parents(".focus-out").removeClass("focused");
        } else {
          $(this).addClass("filled");
        }
      });
    }

    if ($(".hsearch_trigger").length > 0) {
      $(".hsearch_trigger").click(function (e) {
        e.preventDefault();
        e.stopPropagation();
        $(".search_result").hide();
        $(this).toggleClass("active");
        $(".hsearch_sec").toggleClass("open");
        $(".hsearch_sec .form-control").focus();
      });
      $(document).click(function (e) {
        if (
          !$(e.target).is(".hsearch_trigger, .hsearch_sec, .hsearch_sec * ")
        ) {
          if ($(".hsearch_sec").is(":visible")) {
            $(".hsearch_sec").removeClass("open");
            $(".hsearch_trigger").removeClass("active");
            $(".hsearch_sec .form-control").blur();
          }
        }
      });
    }

    if ($(".hsearch_trigger_mbl").length > 0) {
      $(".hsearch_trigger_mbl").click(function (e) {
        e.preventDefault();
        e.stopPropagation();
        $(".search_result_mbl").hide();
        $(this).toggleClass("active");
        $(".hsearch_sec_mbl").toggleClass("open");
        $(".hsearch_sec_mbl .form-control").focus();
      });
      $(document).click(function (e) {
        if (
          !$(e.target).is(
            ".hsearch_trigger_mbl, .hsearch_sec_mbl, .hsearch_sec_mbl * "
          )
        ) {
          if ($(".hsearch_sec_mbl").is(":visible")) {
            $(".hsearch_sec_mbl").removeClass("open");
            $(".hsearch_trigger_mbl").removeClass("active");
            $(".hsearch_sec_mbl .form-control").blur();
          }
        }
      });
    }

    if ($(".mobile_mainacc_menutrigger").length > 0) {
      $(".mobile_mainacc_menutrigger").click(function (e) {
        e.stopPropagation();
        if ($(".mobile_mainacc_menulist").is(":visible")) {
          $(".mobile_mainacc_menulist").hide();
        } else {
          $(".mobile_mainacc_menulist").show();
        }
      });
      $(document).click(function (e) {
        if (!$(e.target).is(".mobile_account_item, .mobile_account_item * ")) {
          if ($(".mobile_mainacc_menulist").is(":visible")) {
            $(".mobile_mainacc_menulist").hide();
          }
        }
      });
    }

    /*NEW MENU SECTION*/
    if ($(".mobile_account_menu_click").length > 0) {
      $(".mobile_account_menu_click").click(function (e) {
        e.preventDefault();
        e.stopPropagation();
        $(".mobile_account_menu").slideToggle();
      });
      $(document).click(function (e) {
        if (!$(e.target).is(".arrow_myaccount, .mobile_account_menu_col")) {
          if ($(".mobile_account_menu").is(":visible")) {
            $(".mobile_account_menu").slideToggle();
          }
        }
      });
    }

    /* Mobile header menu */
    $("body").on("click", ".mobile-menu-more", function () {
      $(this).toggleClass("active");
      $(".product-mobile-menu").toggleClass("active");
    });
    $("body").on("click", ".product-mobile-menu", function () {
      $("mobile-menu-more").toggleClass("active");
      $(this).toggleClass("active");
    });

    $(document).click(function (e) {
      if (!$(e.target).is(".product-mobile-menu")) {
        if ($(".product-mobile-menu").hasClass("open")) {
          $(".hcategory_menu").removeClass("open");
          $(".mobile-menu-more").removeClass("active");
        }
      }
    });

    $(".disbl_href_action").click(function (e) {
      e.preventDefault();
    });

    /*this.getOptions.bind(this,"mill");*/
    //  this.getSearchProductList();
  }

  componentDidUpdate() {}

  getOptions = (input) => {
    var searchProResult = this.state.searchProResult;
    var searchResult = [];
    /*if (Object.keys(searchProResult).length > 0) {*/
    /*searchProResult.map((loadData) =>
			  searchResult.push({ value: loadData.value, label: loadData.label })
			 );*/
    searchResult.push({ value: "wqewrr", label: "fish cury" });
    searchResult.push({ value: "werew3", label: "fish cury2" });
    console.log("wlll");
    console.log(searchResult);
    console.log("input");
    console.log(input);
    return { options: searchResult };
    /*}*/
  };

  ViewProducts(event) {
    var productSlug = event.value;
    //this.props.history.push("/products/cat-ftrpro/slug-ftrpro/" + productSlug);
  }

  checkActiveDivHd(avlType) {
    var clsTxt = "ordericon_link ";
    var availability = this.state.defaultAvilTy;
    var orderOutletId = cookie.load("orderOutletId");
    if (
      availability == avlType &&
      orderOutletId !== "" &&
      orderOutletId !== undefined
    ) {
      clsTxt += "active";
    }
    return clsTxt;
  }

  myAccountAction() {
    var currenturl = window.location.href;
    var substringtxt = "myaccount";

    if (currenturl.includes(substringtxt) !== true) {
      return (
        <div>
          <Link to={"/myaccount"} className="myacunt_act" title="My Account">
            {" "}
            My Account{" "}
          </Link>
          <span className="mobile_mainacc_menutrigger"></span>
        </div>
      );
    } else {
      return (
        <div>
          <a className="myacunt_act disbl_href_action" href="/">
            {" "}
            My Account{" "}
          </a>
          <span className="mobile_mainacc_menutrigger"></span>
        </div>
      );
    }
  }

  changPostalValue(type, pstVl) {
    if (type === 1) {
      $("#postalcode").val(pstVl);
    } else {
      $("#postalcode1").val(pstVl);
    }
  }

  userAddressList(typeTxt) {
    if (this.state.secondaryaddresslist.length > 0) {
      var addListTxt = this.state.secondaryaddresslist.map((addr, index) => (
        <div className="address_linfo" key={typeTxt + "-" + index}>
          <div className="custom_radio">
            <input
              type="radio"
              name={"address_chk" + typeTxt}
              value={addr.postal_code}
              className="address_chk"
              onChange={this.changPostalValue.bind(
                this,
                typeTxt,
                addr.postal_code
              )}
            />
            <span>
              {addressFormat(
                addr.unit_code,
                addr.unit_code2,
                addr.address,
                addr.country,
                addr.postal_code
              )}
            </span>
          </div>
        </div>
      ));

      return (
        <div>
          {addListTxt}
          <div className="address_linfo" key={typeTxt + "dfl"}>
            <div className="custom_radio">
              <input
                type="radio"
                name={"address_chk" + typeTxt}
                value=""
                defaultChecked={true}
                className="address_chk"
                onChange={this.changPostalValue.bind(this, typeTxt, "")}
              />
              <span>Enter New Address</span>
            </div>
          </div>
        </div>
      );
    } else {
      return "";
    }
  }

  setdateTimeFlg = (field, value) => {
    if (field == "tmflg") {
      this.setState({ getDateTimeFlg: value });
    } else if (field == "ordDate") {
      var ordTime = "";
      $(".ordrdatetime_error").html("");
      this.setState({ seleted_ord_date: value, seleted_ord_time: ordTime });
    } else if (field == "ordTime") {
      $(".ordrdatetime_error").html("");
      this.setState({ seleted_ord_time: value });
    } else if (field == "triggerErrorPopup") {
      $.magnificPopup.open({
        items: {
          src: "#outlet-error-popup",
        },
        type: "inline",
      });
    }
  };

  setOrderOutletDateTimeData() {
    var seletedOrdDate = this.state.seleted_ord_date;
    var seletedOrdTime = this.state.seleted_ord_time;
    if (
      seletedOrdDate !== "" &&
      seletedOrdTime !== "" &&
      seletedOrdDate !== null &&
      seletedOrdTime !== null
    ) {
      var orderInfoData =
        this.state.seletedAvilablityId === pickupId
          ? this.state.pickupInfo
          : this.state.deliveryInfo;
      if (Object.keys(orderInfoData).length > 0) {
        var OrderDate = format(seletedOrdDate, "yyyy-MM-dd");
        var OrderHours = seletedOrdTime.getHours();
        var OrderMunts = seletedOrdTime.getMinutes();
        var OrderSecnd = seletedOrdTime.getSeconds();
        var orderDateTime = new Date(OrderDate);
        orderDateTime.setHours(OrderHours);
        orderDateTime.setMinutes(OrderMunts);
        orderDateTime.setSeconds(OrderSecnd);

        var deliveryDate = format(seletedOrdDate, "dd/MM/yyyy");
        var deliveryTime =
          this.convPad(OrderHours) +
          ":" +
          this.convPad(OrderMunts) +
          ":" +
          this.convPad(OrderSecnd);

        cookie.save("orderDateTime", orderDateTime, { path: "/" });
        cookie.save("deliveryDate", deliveryDate, { path: "/" });
        cookie.save("deliveryTime", deliveryTime, { path: "/" });

        /*if (this.state.seletedAvilablityId === deliveryId) {
          cookie.save("orderZoneId", orderInfoData["orderZoneId"], {
            path: "/",
          });
          cookie.save(
            "orderDeliveryAddress",
            orderInfoData["orderDeliveryAddress"],
            { path: "/" }
          );
        }
        
        cookie.save("orderOutletId", orderInfoData["orderOutletId"], {
          path: "/",
        });
        cookie.save("orderOutletName", orderInfoData["orderOutletName"], {
          path: "/",
        });

        cookie.save("orderPostalCode", orderInfoData["orderPostalCode"], {
          path: "/",
        });
        if (this.state.seletedAvilablityId === deliveryId) {
          cookie.save("deliveryPostalCode", orderInfoData["orderPostalCode"], {
            path: "/",
          });
        }
        // cookie.save("orderTAT", orderInfoData["orderTAT"], { path: "/" });
        cookie.save("orderHandled", orderInfoData["orderHandled"], {
          path: "/",
        });
        cookie.save(
          "defaultAvilablityId",
          orderInfoData["defaultAvilablityId"],
          { path: "/" }
        );
        this.setState({ defaultAvilTy: orderInfoData["defaultAvilablityId"] });
        cookie.save("orderHandledByText", orderInfoData["orderHandledByText"], {
          path: "/",
        });*/
        $.magnificPopup.close();
        if (
          this.props.match.params.slugRestaurant !== "" &&
          typeof this.props.match.params.slugRestaurant !== undefined &&
          typeof this.props.match.params.slugRestaurant !== "undefined"
        ) {
          location.reload();
          return false;
        }

        if (cookie.load("popuptriggerFrom") === "FeaturedPro") {
          cookie.remove("popuptriggerFrom", { path: "/" });
          this.props.history.push("/");
        } else {
          this.props.history.push("/food");
        }
      } else {
        $.magnificPopup.open({
          items: {
            src: "#outlet-error-popup",
          },
          type: "inline",
        });
      }
    } else {
      $(".ordrdatetime_error").html(
        '<span class="error"> Please select order date and time.</span>'
      );
    }
  }

  convPad(d) {
    return d < 10 ? "0" + d.toString() : d.toString();
  }

  cart_Outlet_List = (field, value) => {
    if (
      this.props.location.pathname !== "/myaccount" &&
      this.props.location.pathname !== "/myorders" &&
      this.props.location.pathname !== "/mypromotions" &&
      this.props.location.pathname !== "/rewards" &&
      this.props.location.pathname !== "/faq" &&
      this.props.match.path !== "/thankyou/:orderId"
    ) {
      if (field !== "") {
        if (field === "cartOutletList" && this.state.slugRestaurant !== "") {
          this.props.sateValChange("cartOutletList", value);
        }
        if (field === "totalCartCount") {
          if (this.state.slugRestaurant !== "") {
            this.props.sateValChange("totalCartCount", value);
          }
        }
        if (field === "cartDetails" && value !== "") {
          this.props.sateValChange("cartDetails", value);
        }
        if (field === "cartGroups" && value !== "") {
          this.props.sateValChange("cartGroups", value);
        }
      }
    }
  };
  changeDeate() {
    this.findOutletBasedZone(2, this.state.defaultAvilTy);
  }
  /* ViewProducts */

  render() {
    var currenturl = window.location.href;
    var substring = "products";
    var isCheckout = "checkout";

    var showCatryName =
      typeof this.props.showCatryName !== "undefined"
        ? this.props.showCatryName
        : "Category Name";

    return (
      <header>
        <div className="header-top-cls">
          <div className="container-full">
            <div className="logo-main-section">
              <div className="menu_icon trigger_menunav_act">
                <span className="icon-bar icon-bar1"></span>
                <span className="icon-bar icon-bar2"></span>
                <span className="icon-bar icon-bar3"></span>
              </div>
              <div className="mobile-menu">
                <div className="mobile-menu-header">
                  <div className="mobile-menu-close">
                    <span id="close_mobile_menu"></span>
                  </div>
                </div>
                <div className="mobile-menu-body">
                  <ul className="menu">
                  {this.listMainNavigation()}
                    {/* <li
                      className={
                        this.props.match.path === "/market-place"
                          ? "active"
                          : ""
                      }
                    >
                      <Link to={"/market-place"}>Marketplace</Link>
                    </li>
                    <li
                      className={
                        this.props.match.path === "/kitchenpods" ? "active" : ""
                      }
                    >
                      <Link to={"/kitchenpods"}>Kitchenpods</Link>
                    </li>
                    <li
                      className={
                        this.props.match.path === "/faq" ? "active" : ""
                      }
                    >
                      <Link to={"/faq"}>FAQ</Link>
                    </li> */}
                  </ul>
                </div>
              </div>
              <div className="logo">
                <Link to={"/"} title="Kloud Kitchen">
                  <img src={mainLogo} alt="Logo" />
                </Link>
              </div>
              <div className="menu-right">
                <div className="headerinfo-boxes header-menu">
                  <ul>
                    {this.listMainNavigation()}
                    {/* <li
                      className={
                        this.props.match.path === "/market-place"
                          ? "active"
                          : ""
                      }
                    >
                      <Link to={"/market-place"}>Marketplace</Link>
                    </li>
                    <li
                      className={
                        this.props.match.path === "/kitchenpods" ? "active" : ""
                      }
                    >
                      <Link to={"/kitchenpods"}>Kitchenpods</Link>
                    </li>
                    <li
                      className={
                        this.props.match.path === "/faq" ? "active" : ""
                      }
                    >
                      <Link to={"/faq"}>FAQ</Link>
                    </li> */}
                  </ul>
                </div>
                <div className="hmenu-login-section">
                  <ul className="hmenu-login-act">
                    <li>
                      <a href="/" className="controller-nav">
                        <img src={usericon} alt="" />
                      </a>
                      {!cookie.load("UserId") && (
                        <ul className="mobile-login-list">
                          <li>
                            <a
                              href="#login-popup"
                              data-effect="mfp-zoom-in"
                              className="open-popup-link htico_sign"
                              title="Login"
                            >
                              Login
                            </a>
                          </li>
                          <li>
                            <a
                              href="#signup-popup"
                              data-effect="mfp-zoom-in"
                              className="open-popup-link"
                              title="Sign up"
                            >
                              Register
                            </a>
                          </li>
                        </ul>
                      )}
                      {cookie.load("UserId") && (
                        <ul className="mobile-login-list">
                          <li>
                            <Link to="/myaccount" title="My Account">
                              <i /> <span>My Account</span>
                            </Link>
                          </li>
                          <li>
                            <Link to="/logout" title="Logout">
                              <i /> <span>Logout</span>
                            </Link>
                          </li>
                        </ul>
                      )}
                    </li>
                    <CartSideBar
                      {...this.props}
                      headerState={this.state}
                      prpSateValChange={this.props.sateValChange}
                      cartTriggerFlg={this.props.cartTriggerFlg}
                      cartClearTriggerFlg={this.props.cartClearTriggerFlg}
                      cart_Outlet_List={this.cart_Outlet_List}
                      currentPage="header"
                    />
                    {/*  <li className="cart_qty_list">
                      <a href="#" className="hcart_qty_list">
                        <img src={bagicon} alt="" />
                      </a>{" "}
                      <span className="count">0</span>
                    </li> */}
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>

        {currenturl.includes(substring) && (
          <div className="hcategory_sec">
            <a href="/" className="hcategory_selected_text disbl_href_action">
              {showCatryName} <span className="hcategory_trigger"></span>
            </a>
            <div className="hcategory_menu ">
              <MenuNavigationmob />
            </div>
          </div>
        )}

        <div
          className="custom_alertcls alert-success alert-dismissible1 alert_fixed success_hide"
          id="jquery-success-msg"
          style={{ display: "none" }}
        >
          {" "}
          <a
            href="/"
            type="button"
            className="custom_close"
            data-dismiss="alert"
            aria-label="Close"
          >
            {" "}
            <span aria-hidden="true">×</span>{" "}
          </a>{" "}
          <p className="jquery-success-msg">
            Great! Your item has been added to the cart
          </p>{" "}
        </div>

        <div
          className="custom_alertcls alert-danger single-danger alert-dismissible alert_fixed error_hide"
          id="jquery-error-msg"
          style={{ display: "none" }}
        >
          {" "}
          <a
            href="/"
            className="custom_close"
            data-dismiss="alert"
            aria-label="Close"
          >
            {" "}
            <span aria-hidden="true">×</span>{" "}
          </a>{" "}
          <p className="jquery-error-msg">Something went wrong</p>{" "}
        </div>

        <div
          className="custom_center_alertcls alert-success alert-dismissible1 alert_fixed success_hide"
          id="jquery-common-success-msg"
          style={{ display: "none" }}
        >
          {" "}
          <a
            href="/"
            type="button"
            className="custom_close"
            data-dismiss="alert"
            aria-label="Close"
          >
            {" "}
            <span aria-hidden="true">×</span>{" "}
          </a>{" "}
          <p className="jquery-common-success-msg">
            Great! Your item has been added to the cart
          </p>{" "}
        </div>

        <div
          className="custom_center_alertcls alert-danger single-danger alert-dismissible alert_fixed error_hide"
          id="jquery-common-error-msg"
          style={{ display: "none" }}
        >
          {" "}
          <a
            href="/"
            className="custom_close"
            data-dismiss="alert"
            aria-label="Close"
          >
            {" "}
            <span aria-hidden="true">×</span>{" "}
          </a>{" "}
          <p className="jquery-common-error-msg">Something went wrong</p>{" "}
        </div>

        {/* login popup */}

        <div
          id="login-popup"
          className="white-popup mfp-hide popup_sec login-popup"
        >
          <div className="pop-whole">
            <div className="pop-whole-back">
              <div className="popup-body">
                <span className="spn-error"></span>
                <div className="form-group">
                  <div className="controls two-links space-line">
                    <a
                      href="#login-popup"
                      className="open-popup-link btn-popup-active"
                    >
                      Sign in
                    </a>{" "}
                    <a
                      href="#signup-popup"
                      data-effect="mfp-zoom-in"
                      className="open-popup-link btn-popup-inactive"
                    >
                      {" "}
                      Register
                    </a>
                  </div>
                </div>
                <div className="popup-header textcenter">
                  <h4>Sign in With</h4>
                </div>

                <Login
                  fields={this.state.fields}
                  onChange={this.fieldChange}
                  onValid={this.handleSignin}
                  onInvalid={() => console.log("Form invalid!")}
                />
                <div className="form-group">
                  <div className="controls two-links">
                    <a
                      href="#forgot-password-popup"
                      className="open-popup-link"
                    >
                      Forgot PIN ?
                    </a>{" "}
                  </div>
                </div>
                <div className="popup-opt">
                  <div className="popup-header textcenter">
                    <h4>Or Sign in With</h4>
                  </div>
                  <div className="form-group">
                    <div className="login_pop_sub">
                      <FacebookLogin
                        appId={fbAppId}
                        fields="name,email,picture,first_name,last_name,birthday,gender"
                        callback={this.responseFacebook}
                        scope="public_profile,email,user_birthday"
                        cssclassName="btn btn_black btn_minwid login_submit_gray"
                        redirectUri={baseUrl}
                        icon="fa-facebook"
                        textButton="&nbsp; Log in with Facebook"
                      />
                    </div>
                  </div>
                  <div className="form-group">
                    <div className="login_pop_sub">
                      <button
                        className="btn btn_black btn_minwid login_submit_lign_gray loginBtn loginBtn--google"
                        onClick={this.googleSDK.bind(this)}
                        ref="googleLoginBtn"
                      >
                        {" "}
                        <i className="fa fa-google" aria-hidden="true"></i>{" "}
                        <span className="span_line"></span> Sign in with Google{" "}
                      </button>
                    </div>
                  </div>
                  <div className="popup-header textcenter txt_uline">
                    <a
                      href="#guest-popup"
                      data-effect="mfp-zoom-in"
                      className="open-popup-link"
                    >
                      No I want to Continue As Guest
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* Signup popup */}
        <div
          id="signup-popup"
          className="white-popup mfp-hide popup_sec signup-popup"
        >
          <div className="pop-whole">
            <div className="pop-whole-back"></div>
            <div className="popup-body">
              <div className="form-group">
                <div className="controls two-links space-line">
                  <a
                    href="#login-popup"
                    className="open-popup-link btn-popup-inactive"
                  >
                    Sign in
                  </a>{" "}
                  <a
                    href="#signup-popup"
                    data-effect="mfp-zoom-in"
                    className="open-popup-link btn-popup-active"
                  >
                    {" "}
                    Register
                  </a>
                </div>
              </div>
              <Signup
                fields={this.state.fieldssignup}
                onChange={this.fieldChangeSignup}
                onValid={this.handleSignup}
                onInvalid={() => console.log("Form invalid!")}
              />
              <div className="popup-opt">
                <div className="popup-header textcenter">
                  <h4>Or Sign Register With</h4>
                </div>
                <div className="form-group">
                  <div className="login_pop_sub">
                    <FacebookLogin
                      appId={fbAppId}
                      fields="name,email,picture,first_name,last_name,birthday,gender"
                      callback={this.responseFacebook}
                      scope="public_profile,email,user_birthday"
                      cssclassName="btn btn_black btn_minwid login_submit_gray"
                      redirectUri={baseUrl}
                      icon="fa-facebook"
                      textButton="&nbsp; Log in with Facebook"
                    />
                  </div>
                </div>
                <div className="form-group">
                  <div className="login_pop_sub">
                    <button
                      className="btn btn_black btn_minwid login_submit_lign_gray loginBtn loginBtn--google"
                      onClick={this.googleSDK.bind(this)}
                      ref="googleLoginBtn"
                    >
                      {" "}
                      <i className="fa fa-google" aria-hidden="true"></i>{" "}
                      <span className="span_line"></span> Sign in with Google{" "}
                    </button>
                  </div>
                </div>
                <div className="popup-header textcenter txt_uline">
                  <a
                    href="#guest-popup"
                    data-effect="mfp-zoom-in"
                    className="open-popup-link"
                  >
                    No I want to Continue As Guest
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* Guest Checkout */}
        <div
          id="guest-popup"
          className="white-popup mfp-hide popup_sec guest-popup"
        >
          <div className="pop-whole">
            <div className="pop-whole-back">
              <div className="popup-header textcenter">
                <h4>Continue as guest</h4>
              </div>
              <Guestcheckout
                {...this.props}
                fields={this.state.fieldscheckout}
                onChange={this.fieldChangecheckout}
                onValid={this.handleCheckout}
                onInvalid={() => console.log("Form invalid!")}
              />
              <div className="back_click_mbl">
                <a href="#login-popup" className="open-popup-link">
                  Back
                </a>
              </div>
            </div>
          </div>
        </div>

        {/* Forgot Password Popup */}

        <div
          id="forgot-password-popup"
          className="white-popup mfp-hide popup_sec login-popup forgot-password-popup"
        >
          <div className="pop-whole">
            <div className="pop-whole-back">
              <div className="popup-header textcenter">
                <h4>Forgot your PIN?</h4>
                <p>You can reset your PIN here.</p>
              </div>
            </div>
            <Forgotpassword
              fields={this.state.fieldsfgtpassword}
              onChange={this.fieldforgot}
              onValid={this.forgotpassword}
              onInvalid={() => console.log("Form invalid!")}
            />
          </div>
        </div>

        {/* Delivery Popup - Start */}
        <div
          id="delevery-popup"
          className="white-popup mfp-hide popup_sec delivery_outletpoup self_popup"
        >
          <div className="order-body">
            <div className="self_popup_hea_row">
              <div className="self_popup_hea_col_left">
                <img className="outlet-scooter-img" src={deliveryWtImg} />
              </div>
              <div className="self_popup_hea_col">
                <h2>Please Select</h2>
                <p>Your Delivery Outlet</p>
              </div>
            </div>

            <div className="self_pop_row">
              <div className="self_pop_col self_pop_col_right">
                <div className="self_pop_item">
                  <div className="self_pop_locbx">
                    <div className="form-group">
                      <div className="focus-out">
                        <label>Search Outlet</label>
                        <input
                          type="input"
                          className="form-control input-focus"
                          onKeyUp={this.handleKeyPressDly}
                        />
                        <div className="outlet_error"></div>
                      </div>
                    </div>
                  </div>

                  <div className="self_outlet">
                    <h2>Nearby Outlets</h2>
                    <ul className="self_outlet_inner">
                      {this.loadDeliveryOutletsList()}
                    </ul>
                    <a
                      className="button disbl_href_action"
                      id="delivery-continue-link"
                      href="/"
                      onClick={this.selectDlyOutlet.bind(this)}
                    >
                      Continue
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* Delivery Popup - end */}

        {/*  Delivery Postal code Popup - start */}
        <div
          id="delevery-postcode-popup"
          className="white-popup mfp-hide popup_sec delevery_popup"
        >
          <div className="popup_equalrw">
            <div className="popup_ttsec">
              <div className="innervmid_in">
                <div className="pop_title">
                  <img className="pop-scooter-img" src={deliveryWtImg} />
                  <h2 className="text-uppercase">Let us know</h2>
                  <small>Your Delivery Location</small>
                </div>
              </div>
            </div>
            <div className="popup_right">
              <div className="innervmid_in">
                {cookie.load("UserId") && (
                  <div className="address-list-cls address-list-main">
                    {this.userAddressList(1)}
                  </div>
                )}

                <div className="form-group">
                  <div
                    className={
                      this.state.secondaryaddresslist.length > 0
                        ? "focus-out focused"
                        : "focus-out"
                    }
                  >
                    <h4 className="uper-light">Enter your postal code</h4>
                    <input
                      type="text"
                      id="postalcode"
                      pattern="\d*"
                      maxLength="6"
                      className="form-control input-focus"
                    />
                    <div className="postal_error"></div>
                  </div>
                </div>

                <div className="btn_sec">
                  <div className="two-button-row">
                    <div className="go_second">
                      <a
                        href="/"
                        onClick={this.close_popup.bind(this)}
                        className="button button-left disbl_href_action"
                        title="Close"
                      >
                        Close
                      </a>
                    </div>
                    <div className="con_first delivery_submit_cls">
                      <input
                        type="button"
                        onClick={this.findOutletBasedZone.bind(
                          this,
                          1,
                          deliveryId
                        )}
                        className="button button-right delivery_submit"
                        value="Continue"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* Delevery Popup - end */}

        {/* Delevery Popup error - start */}
        <div
          id="error-postal-popup"
          className="white-popup mfp-hide popup_sec error_postal_popup"
        >
          <div className="popup_equalrw">
            <div className="popup_ttsec">
              <div className="innervmid_in">
                <div className="pop_title poptt_icontop text-center">
                  <img src={iconUnhappy} />
                  <h2 className="text-uppercase">Sorry </h2>
                  <small>We cant find your postal code</small>
                </div>
              </div>
            </div>
            <div className="popup_right">
              <div className="innervmid_in">
                {/*<h4>Enter your postal code</h4>*/}

                {cookie.load("UserId") && (
                  <div className="address-list-cls address-list-error">
                    {this.userAddressList(2)}
                  </div>
                )}

                <form className="form_sec">
                  <div className="form-group">
                    <div
                      className={
                        this.state.secondaryaddresslist.length > 0
                          ? "focus-out focused"
                          : "focus-out"
                      }
                    >
                      <label>Enter your postal code</label>
                      <input
                        type="text"
                        id="postalcode1"
                        pattern="\d*"
                        maxLength="6"
                        className="form-control input-focus"
                      />
                      <div className="postal_error"></div>
                    </div>
                  </div>
                  <div className="btn_sec delivery_submit_cls delivery_submit_div">
                    {/*<input type="button" onClick={this.selectOutlet.bind(this, 0, deliveryId)} className="button delivery_submit" value="Continue" />*/}
                    <input
                      type="button"
                      onClick={this.findOutletBasedZone.bind(
                        this,
                        0,
                        deliveryId
                      )}
                      className="button delivery_submit"
                      value="Continue"
                    />
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
        {/* Delevery Popup error - Start */}

        {/* success popup - Start */}
        <div
          id="awesome-popup"
          className="white-popup mfp-hide popup_sec delevery_popup delevery_popup_datetime"
        >
          <div className="popup_equalrw">
            <div className="popup_ttsec">
              <div className="innervmid_in">
                <div className="pop_title">
                  <img
                    className="pop-bag-img"
                    src={
                      this.state.seletedAvilablityId === deliveryId
                        ? iconWin
                        : takeawayImg
                    }
                  />
                  <h2 className="text-uppercase">
                    Awesome
                    {this.state.seletedAvilablityId === pickupId && (
                      <span>
                        <br />
                        You Can Pickup
                      </span>
                    )}
                  </h2>
                  {this.state.seletedAvilablityId === deliveryId && (
                    <small>We Can Deliver To Your Location !</small>
                  )}

                  {this.state.seletedAvilablityId === pickupId && (
                    <small>Let Us Know Pickup Date & Time !</small>
                  )}
                </div>
              </div>
            </div>
            <div className="popup_right">
              <div className="innervmid_in">
                {this.state.seletedAvilablityId === deliveryId && (
                  <div className="form-group">
                    <h4 className="uper-light">Your Deliver Location</h4>
                    <div
                      className={
                        this.state.deliveryInfo.orderPostalCode.length > 0
                          ? "focus-out focused"
                          : "focus-out"
                      }
                    >
                      <input
                        type="text"
                        id="postalcode"
                        pattern="\d*"
                        maxLength="6"
                        className="form-control input-focus"
                        readOnly={true}
                        defaultValue={this.state.deliveryInfo.orderPostalCode}
                      />
                      <div className="postal_error"></div>
                    </div>
                    {this.state.seletedAvilablityId === deliveryId && (
                      <div className="greenwith-add">
                        <h5>
                          <img src={greentick} />
                          {this.state.orderDeliveryAddress}
                        </h5>
                      </div>
                    )}
                  </div>
                )}
                <div className="datetime_selt_sec">
                  <div className="datetime_selt_lbl">
                    Choose Date &amp; Time
                  </div>

                  {!currenturl.includes(isCheckout) && (
                    <OrderdatetimeSlot
                      {...this.props}
                      hdrState={this.state}
                      setdateTimeFlg={this.setdateTimeFlg}
                    />
                  )}

                  <div className="ordrdatetime_error"></div>
                </div>

                <div className="btn_sec">
                  <input
                    type="button"
                    onClick={this.setOrderOutletDateTimeData.bind(this)}
                    className="button"
                    value="Continue"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* success popup - end */}

        {/* success popup - Start */}
        <div
          id="awesome-popup-old"
          className="white-popup mfp-hide awesome_popup"
        >
          <div className="popup_equalrw">
            <div className="popup_ttsec">
              <div className="innervmid_in">
                <div className="pop_title poptt_icontop text-center">
                  <img src={iconWin} />
                  <h2 className="text-uppercase">Awesome</h2>
                  <small>We can Deliver !</small>
                </div>
                <div className="awesome_del">
                  <h5>Your Delivery Address :</h5>
                  <h2>{this.state.orderDeliveryAddress}</h2>
                </div>
                <div className="btn_sec">
                  <input
                    type="button"
                    onClick={this.gotoProducts.bind(this)}
                    className="button"
                    value="Continue"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* success popup - end */}

        {/* error Popup - start */}
        <div
          id="outlet-error-popup"
          className="white-popup mfp-hide popup_sec warning_popup outlet_error_popup"
        >
          <div className="custom_alert">
            <div className="custom_alertin">
              <div className="alert_height">
                <div className="alert_body">
                  <img className="warning-popup-img" src={warningImg} />
                  <h2 className="text-uppercase">Sorry</h2>
                  <p>{"We can`t Deliver At the Moment!"}</p>
                  <p>Please come back again.</p>
                  <div className="alt_btns">
                    {this.state.seletedAvilablityId === pickupId ? (
                      <a
                        href="/"
                        onClick={this.gobckPkupOutletpopup.bind(this)}
                        className="button button-right popup-modal-dismiss disbl_href_action"
                      >
                        change outlet
                      </a>
                    ) : (
                      <a
                        href="/"
                        onClick={this.gobckOutletpopup.bind(this)}
                        className="button button-right popup-modal-dismiss disbl_href_action"
                      >
                        change address
                      </a>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* error Popup - end */}

        {/* Takeaway Popup - Start */}
        <div
          id="takeaway-popup"
          className="white-popup mfp-hide popup_sec self_popup"
        >
          <div className="order-body">
            <div className="self_popup_hea_row">
              <div className="self_popup_hea_col_left">
                <img src={takeawayWtImg} />
              </div>
              <div className="self_popup_hea_col">
                <h2>Please Select</h2>
                <p>Your Self Collection Outlet</p>
              </div>
            </div>

            <div className="self_pop_row">
              <div className="self_pop_col self_pop_col_right">
                <div className="self_pop_item">
                  <div className="self_pop_locbx">
                    {/*<h4>Search Cedele Outlet.</h4>*/}
                    <div className="form-group">
                      <div className="focus-out">
                        <label>Search Outlet</label>
                        <input
                          type="input"
                          className="form-control input-focus"
                          onKeyUp={this.handleKeyPress}
                        />
                        <div className="outlet_error"></div>
                      </div>
                    </div>
                  </div>

                  <div className="self_outlet">
                    <h2>Nearby Outlets</h2>
                    <ul className="self_outlet_inner">
                      {this.loadOutletsList()}
                    </ul>
                    <a
                      className="button takeaway-btn-act disbl_href_action"
                      id="takeaway-continue-link"
                      href="/"
                      onClick={this.selectDatetm.bind(this)}
                    >
                      Continue
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* Takeaway Popup - end */}

        {/*  Coming Soon Popup - start */}
        <div
          id="comingsoon-popup"
          className="white-popup mfp-hide popup_sec comingsoon_popup"
        >
          <div className="order-body">
            <div className="comingsoon_popup_hea_row">
              <div className="comingsoon_popup_hea_col">
                <h2>COMING SOON.</h2>
              </div>
            </div>
            <div className="comingsoon_pop_row">
              <p> can you select another availability.</p>
              <a
                href="/"
                onClick={this.closepopup.bind(this)}
                className="button disbl_href_action"
                title="Go Back"
              >
                Go Back
              </a>
            </div>
          </div>
        </div>
        {/* Coming Soon Popup - end */}

        {/* Warning Popup - start */}
        <div
          id="warning-popup"
          className="white-popup mfp-hide popup_sec warning_popup"
        >
          <div className="custom_alert">
            <div className="custom_alertin">
              <div className="alert_height">
                <div className="alert_header">Warning</div>
                <div className="alert_body">
                  <img className="warning-popup-img" src={warningImg} />
                  <p>By switching you are about to clear your cart.</p>
                  <p>Do you wish to proceed ?</p>
                  <div className="alt_btns">
                    <a
                      href="/"
                      className="popup-modal-dismiss button button-left disbl_href_action"
                    >
                      No
                    </a>
                    <input type="hidden" value="" id="change_shop_type" />
                    <a
                      href="/"
                      onClick={this.changeAvailability.bind(this)}
                      className="button button-right popup-modal-dismiss disbl_href_action"
                    >
                      Yes
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* Warning Popup - end */}

        {/* Warning Maximum Order Popup - start */}
        <div
          id="warning-max-orderpopup"
          className="white-popup mfp-hide popup_sec warning_popup"
        >
          <div className="custom_alert">
            <div className="custom_alertin">
              <div className="alert_height">
                <div className="alert_body">
                  <h3>Sorry ! Max Limit Reached</h3>
                  <p>
                    We are only able to handle orders from maximum{" "}
                    {this.state.globalsettings !== "" &&
                      this.state.globalsettings.max_order_handle}{" "}
                    restaurant
                  </p>
                  <div className="alt_btns">
                    <a
                      href="/"
                      className="popup-modal-dismiss button button-left disbl_href_action"
                    >
                      CANCEL
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* Warning Maximum Order Popup - end */}

        {/* Warning Maximum Order Popup - start */}
        <div
          id="warning-not-deliver"
          className="white-popup mfp-hide popup_sec warning_popup"
        >
          <div className="custom_alert">
            <div className="custom_alertin">
              <div className="alert_height">
                <div className="alert_body">
                  <h3>Sorry!</h3>
                  <p>We are only able to handle orders from this restaurant</p>
                  <div className="alt_btns">
                    <a
                      href="/"
                      className="popup-modal-dismiss button button-left disbl_href_action"
                    >
                      CANCEL
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* Warning Maximum Order Popup - end */}

        <div className="mbl-menu-overly"></div>
      </header>
    );
  }
}

const mapStateTopProps = (state) => {
  var zonedetailArr = Array();
  if (Object.keys(state.zonedetail).length > 0) {
    if (state.zonedetail[0].status === "ok") {
      zonedetailArr = state.zonedetail[0].result_set;
    }
  }

  var outletsArr = Array();
  if (Object.keys(state.outlets).length > 0) {
    if (state.outlets[0].status === "ok") {
      outletsArr = state.outlets[0].result_set;
    }
  }

  var alloutletsArr = Array();
  if (Object.keys(state.alloutlets).length > 0) {
    if (state.alloutlets[0].status === "ok") {
      alloutletsArr = state.alloutlets[0].result_set;
    }
  }

  var secondarydataArr = Array();
  if (Object.keys(state.secondaryaddress).length > 0) {
    if (state.secondaryaddress[0].status === "ok") {
      secondarydataArr = state.secondaryaddress[0].result_set;
    }
  }

  return {
    globalsettings: state.settings,
    zonedetails: zonedetailArr,
    outletslist: outletsArr,
    alloutletslist: alloutletsArr,
    logindata: state.login,
    fblogin: state.fblogin,
    googlelogin: state.googlelogin,
    forgetpassword: state.forgetpassword,
    registration: state.registration,
    menudata: state.menudata,
    secondaryaddresslist: secondarydataArr,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getGlobalSettings: () => {
      dispatch({ type: GET_GLOBAL_SETTINGS });
    },
    getZoneDetail: (outletId, zoneId, shop_type) => {
      dispatch({ type: GET_ZONE_DETAIL, outletId, zoneId, shop_type });
    },
    getPickupOutlets: () => {
      dispatch({ type: GET_PICKUP_OUTLETS });
    },
    getAllOutlets: (availability) => {
      dispatch({ type: GET_ALL_OUTLETS, availability });
    },
    destroyCartDetail: () => {
      dispatch({ type: DESTROY_CART_DETAIL });
    },
    getLoginData: (formPayload) => {
      dispatch({ type: GET_LOGINDATA, formPayload });
    },
    getFbLoginData: (formPayload) => {
      console.log(formPayload);
      dispatch({ type: GET_FBLOGINDATA, formPayload });
    },
    getGoogleLoginData: (formPayload) => {
      dispatch({ type: GET_GOOGLELOGINDATA, formPayload });
    },
    getRegistration: (formPayload) => {
      dispatch({ type: GET_REGISTRATION, formPayload });
    },
    getForgetPassword: (formPayload) => {
      dispatch({ type: GET_FORGET_PASSWORD, formPayload });
    },
    getMenuData: (menuslug) => {
      dispatch({ type: GET_MENUDATA, menuslug });
    },
    getSecondaryAddress: () => {
      dispatch({ type: GET_ALLUSERSECADDRDATA });
    },
  };
};
export default connect(
  mapStateTopProps,
  mapDispatchToProps
)(withRouter(Header));
