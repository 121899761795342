import { SET_EVENTS } from "../actions";

const events = (state = [], action) => {
  switch (action.type) {
    case SET_EVENTS:
      return [...action.value];
    default:
      return state;
  }
};

export default events;
