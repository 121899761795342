/* eslint-disable */
import React, { Component } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import Slider from "react-slick";
import cookie from "react-cookies";

import Homeavailability from "../../Layout/Homeavailability";

import { GET_BANNER_LIST } from "../../../actions";
import homeBannerImg from "../../../common/images/banner-bg.jpg";
import { deliveryId } from "../../Helpers/Config";

var Parser = require("html-react-parser");

function SampleNextArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div className={className} onClick={onClick}>
      <i className="fa fa-angle-right" aria-hidden="true"></i>
    </div>
  );
}

function SamplePrevArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div className={className} onClick={onClick}>
      <i className="fa fa-angle-left" aria-hidden="true"></i>
    </div>
  );
}

class HomeBanner extends Component {
  constructor(props) {
    super(props);
  }

  UNSAFE_componentWillMount() {
    var availability_id = cookie.load("defaultAvilablityId") !== "" && typeof cookie.load("defaultAvilablityId") !== undefined && typeof cookie.load("defaultAvilablityId") !== "undefined" ? cookie.load("defaultAvilablityId") : deliveryId;
    this.props.getBannerList(availability_id);
  }
  
  changeAvailabilitybasedBanner(availability_id) {
    this.props.getBannerList(availability_id);
  }

  render() {
    let bannerArr = this.props.banner;
    let bannerlist = [];
    let bannerimagesource = "";

    if (Object.keys(bannerArr).length > 0) {
      if (bannerArr[0].status === "ok") {
        bannerlist = bannerArr[0].result_set;
        bannerimagesource = bannerArr[0].common.image_source;
      }
    }

    var settingsGallery = {
      infinite: true,
      slidesToShow: 1,
      slidesToScroll: 1,
      dots: false,
      arrows: true,
      nextArrow: <SampleNextArrow />,
      prevArrow: <SamplePrevArrow />,
    };

    return (
      <div className="home-banner">
        <div className="inner-home-banner">
          <div className="hb-slider-section">
            {Object.keys(bannerlist).length > 0 ? (
              <Slider {...settingsGallery}>
                {bannerlist.map((banner, index) => {
                  return (
                    <div key={index}>
                      <img
                        src={bannerimagesource + banner.banner_image}
                        alt="Banner"
                      />
                    </div>
                  );
                })}
              </Slider>
            ) : (
              <div className="loader-main-cls">
                <img src={homeBannerImg} alt="Banner" />
                <div className="loader-sub-div"></div>
              </div>
            )}
          </div>
          <div className="hb-form-section">
            <Homeavailability  changeAvailabilitybasedBanner = { this.changeAvailabilitybasedBanner.bind(this) }   />
          </div>
        </div>
      </div>
    );
  }
}

const mapStateTopProps = (state) => {
  return {
    banner: state.banner,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getBannerList: (availability_id) => {
      dispatch({ type: GET_BANNER_LIST, availability_id });
    },
  };
};

export default connect(mapStateTopProps, mapDispatchToProps)(HomeBanner);
