import { SET_RESTAURANTTYPE } from "../actions";

const restauranttype = (state = [], action) => {
  switch (action.type) {
    case SET_RESTAURANTTYPE:
      return [...action.value];
    default:
      return state;
  }
};

export default restauranttype;
