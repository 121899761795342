/* eslint-disable */
import { takeEvery, call, put } from "redux-saga/effects";
import { GET_EVENTS, SET_EVENTS } from "../actions";
import { appId, apiUrl, deliveryId } from "../components/Helpers/Config";
import Axios from "axios";
import cookie from "react-cookies";

export const watchGetEvents = function* () {
  yield takeEvery(GET_EVENTS, workerGetEvents);
};

function* workerGetEvents({ slug }) {
  var eslug = "";
  if (slug !== "" && typeof slug !== undefined && typeof slug !== "undefined") {
    eslug = "&event_slug=" + slug;
  }
  try {
    const uri = apiUrl + "settings/eventList?app_id=" + appId + eslug;
    const result = yield call(Axios.get, uri);
    var resultArr = [];
    resultArr.push(result.data);
    yield put({ type: SET_EVENTS, value: resultArr });
  } catch {
    console.log("Get Events Failed");
  }
}
