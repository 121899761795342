/* eslint-disable */
import React, { Component } from "react";
import { validated } from "react-custom-validation";
import validator from "validator";
const isEmpty = (value) => (value === "" ? "This field is required." : null);

function validationConfig(props) {
  const { mobile, password } = props.fields;

  return {
    fields: ["mobile", "password"],

    validations: {
      mobile: [
        [isEmpty, mobile],
        [isMobile, mobile],
      ],
      password: [[isEmpty, password]],
    },
  };
}

const phonenumberPattern = /^[0-9]{6,14}$/;
const isMobile = (mobile) =>
  mobile.match(phonenumberPattern) ? null : "Please enter valid Mobile Number.";

class Login extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    const {
      fields,
      onChange,
      onValid,
      onInvalid,
      $field,
      $validation,
    } = this.props;
    let errMsgMobile, errMsgPassword;

    if ($validation.mobile.error.reason !== undefined) {
      errMsgMobile = $validation.mobile.show && (
        <span className="error">{$validation.mobile.error.reason}</span>
      );
    }
    if ($validation.password.error.reason !== undefined) {
      errMsgPassword = $validation.password.show && (
        <span className="error">{$validation.password.error.reason}</span>
      );
    }

    return (
      <>
        <div className="form-group">
          <div className="focus-out">
            <label>Enter Mobile Number</label>
            <input
              type="text"
              className="form-control input-focus"
              value={fields.mobile}
              maxLength={8}
              {...$field("mobile", (e) => onChange("mobile", e.target.value))}
            />
            {errMsgMobile}
          </div>
        </div>
        <div className="form-group">
          <div className="focus-out">
            <label>Enter Your PIN</label>

            <input
              type="password"
              className="form-control input-focus"
              value={fields.password}
              {...$field("password", (e) =>
                onChange("password", e.target.value)
              )}
            />
            {errMsgPassword}
          </div>
        </div>
        <div className="form-group">
          <div className="login_pop_sub">
            <button
              className="btn btn_black btn_minwid login_submit"
              onClick={(e) => {
                e.preventDefault();
                this.props.$submit(onValid, onInvalid);
              }}
            >
              {" "}
              Login{" "}
            </button>
          </div>
        </div>
      </>
    );
  }
}
Login = validated(validationConfig)(Login);

export default Login;
