/* eslint-disable */
import { push } from 'react-router-redux';
import { takeEvery, call, put } from 'redux-saga/effects';
import { GET_PRODUCT_DETAIL, SET_PRODUCT_DETAIL } from '../actions';
import { appId, apiUrl, apiUrlV2, deliveryId } from "../components/Helpers/Config";
import Axios from 'axios';
import cookie from 'react-cookies';
import { format } from "date-fns";

export const watchGetProductDetail = function* () {
  yield takeEvery(GET_PRODUCT_DETAIL, workerGetProductDetail);
}

function* workerGetProductDetail({ proSlug }) {
  try {
	var availabilityId = (cookie.load("defaultAvilablityId") === undefined || cookie.load("defaultAvilablityId") == '' )?'':cookie.load("defaultAvilablityId");
	var deliveryDate = "";
	var deliveryDateTxt = cookie.load("deliveryDate") !== undefined ? cookie.load("deliveryDate") : "";
	if (deliveryDateTxt != "") {
		  var deliveryDateArr = deliveryDateTxt.split("/");
		  deliveryDate =
			deliveryDateArr[2] +
			"-" +
			deliveryDateArr[1] +
			"-" +
			deliveryDateArr[0];
	} else {
			deliveryDate = format(new Date(), "yyyy-MM-dd");
	}
    const uri = apiUrl+'products/products_list?app_id='+appId+'&product_slug='+proSlug+'&availability='+availabilityId+
      "&specialprice_applicable=orderdate"+"&order_datetxt="+deliveryDate;
    const result = yield call(Axios.get, uri);
	var resultArr = [];
		resultArr.push(result.data);
    /*yield all([put({ type: SET_PRODUCT_DETAIL, value: resultArr }), put(push(`/products`))]);*/	
    yield put({ type: SET_PRODUCT_DETAIL, value: resultArr });
	/*yield put(push('/products'));*/
  } 
  catch {
    console.log('Get Products Failed');
  }
}
 
