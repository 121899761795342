/* eslint-disable */
import React, { Component } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";

import { stripslashes, capitalize_Words } from "../Helpers/SettingHelper";

class MenuNavigation extends Component {
  constructor(props) {
    super(props);
    this.state = {
      navigateMenu: [],
      outlet_slug: this.props.match.params.slugRestaurant,
    };
  }

  componentWillReceiveProps(pdtProps) {
    if (Object.keys(this.props.navigateMenu).length > 0) {
      setTimeout(function () {
        if ($(".menu-section-inner").length > 0) {
          $(".menu-section-inner").mCustomScrollbar();
        }
      }, 800);
    }
  }

  load_mcustScrl() {
    let navigateMenuArr = this.props.navigateMenu;
    if (Object.keys(navigateMenuArr).length > 0) {
      setTimeout(function () {
        if ($(".menu-section-inner").length > 0) {
          $(".menu-section-inner").mCustomScrollbar();
        }
      }, 2000);
    }
  }

  goCategoryProducts(slug, type, cat_id, event) {
    event.preventDefault();
    $(".product-menu li").removeClass("active");
    $(".product-menu #" + type + "_" + cat_id).addClass("active");
    $("html, body").animate(
      {
        scrollTop: $("#" + slug).offset().top - 150,
      },
      1000
    );
  }

  navigateMenuList() {
    let navigateMenuArr = this.props.navigateMenu;
    var selectedNav = this.props.productState.selectedNavigation;

    if (Object.keys(navigateMenuArr).length > 0) {
      const mainMenu = navigateMenuArr.map((loaddata, index) => {
        if (index <= 7) {
          if (loaddata.menu_type == "main") {
            return (
              <li
                key={index + 1}
                className="menu-list-intvl"
                id={"c_" + loaddata.pro_cate_primary_id}
              >
                <Link
                  to={"/#" + loaddata.pro_cate_slug}
                  onClick={this.goCategoryProducts.bind(
                    this,
                    loaddata.pro_cate_slug,
                    "c",
                    loaddata.pro_cate_primary_id
                  )}
                  className="menu-title-link"
                  title={loaddata.menu_custom_title}
                >
                  <span className="menu-title-txt">
                    {capitalize_Words(
                      stripslashes(loaddata.menu_custom_title.toLowerCase())
                    )}
                  </span>
                </Link>
              </li>
            );
          } else if (loaddata.menu_type == "sub") {
            return (
              <li
                key={index + 1}
                id={"s_" + loaddata.pro_subcate_id}
                className={
                  selectedNav === loaddata.pro_subcate_slug
                    ? "menu-list-intvl active"
                    : "menu-list-intvl"
                }
              >
                <Link
                  to="#"
                  className="menu-title-link"
                  onClick={this.goCategoryProducts.bind(
                    this,
                    loaddata.pro_subcate_slug,
                    "c",
                    loaddata.pro_subcate_id
                  )}
                  title={loaddata.menu_custom_title}
                >
                  <span className="menu-title-txt">
                    {stripslashes(loaddata.menu_custom_title.toLowerCase())}
                  </span>
                </Link>
              </li>
            );
          }
        }
      });
      setTimeout(function () {
        $(window).scroll(function () {
          var scrollPos = $(document).scrollTop();
          $(".product-menu li a")
            .each(function () {
              var currLink = $(this);
              var refElement = $(currLink.attr("href").replace(/\//g, ""));
              if (
                refElement.position() !== "" &&
                typeof refElement.position() !== undefined &&
                typeof refElement.position() !== "undefined"
              ) {
                var toppos = parseFloat(refElement.position().top) + 200;
                if (
                  toppos <= scrollPos &&
                  toppos + refElement.height() > scrollPos
                ) {
                  $(".product-menu ul li").removeClass("active");
                  currLink.parents("li").addClass("active");
                } else {
                  currLink.parents("li").removeClass("active");
                }
              }
            })
            .promise()
            .done(function () {
              if ($(".menu-list-intvl.active").length === 0) {
                $(".product-menu li:first-child").addClass("active");
              }
            });
        });
      }, 100);

      return mainMenu;
    } else {
      return;
    }
  }

  mobileNavigateMenuList() {
    let navigateMenuArr = this.props.navigateMenu;
    var selectedNav = this.props.productState.selectedNavigation;

    if (Object.keys(navigateMenuArr).length > 0) {
      const mainMenu = navigateMenuArr.map((loaddata, index) => {
        /* if (index <= 7) { */
        if (loaddata.menu_type == "main") {
          return (
            <li
              key={index + 1}
              className="menu-list-intvl"
              id={"c_" + loaddata.pro_cate_primary_id}
            >
              <Link
                to={"/#" + loaddata.pro_cate_slug}
                onClick={this.goCategoryProducts.bind(
                  this,
                  loaddata.pro_cate_slug,
                  "c",
                  loaddata.pro_cate_primary_id
                )}
                className="menu-title-link"
                title={loaddata.menu_custom_title}
              >
                <span className="menu-title-txt">
                  {capitalize_Words(
                    stripslashes(loaddata.menu_custom_title.toLowerCase())
                  )}
                </span>
              </Link>
            </li>
          );
        } /* else if (loaddata.menu_type == "sub") {
          return (
            <li
              key={index + 1}
              id={"s_" + loaddata.pro_subcate_id}
              className={
                selectedNav === loaddata.pro_subcate_slug
                  ? "menu-list-intvl active"
                  : "menu-list-intvl"
              }
            >
              <Link
                to="#"
                className="menu-title-link"
                onClick={this.goCategoryProducts.bind(
                  this,
                  loaddata.pro_subcate_slug,
                  "c",
                  loaddata.pro_subcate_id
                )}
                title={loaddata.menu_custom_title}
              >
                <span className="menu-title-txt">
                  {stripslashes(loaddata.menu_custom_title.toLowerCase())}
                </span>
              </Link>
            </li>
          );
        } */
        /* } */
      });
      setTimeout(function () {
        $(window).scroll(function () {
          var scrollPos = $(document).scrollTop();
          $(".product-mobile-menu li a")
            .each(function () {
              var currLink = $(this);
              var refElement = $(currLink.attr("href").replace(/\//g, ""));
              if (
                refElement.position() !== "" &&
                typeof refElement.position() !== undefined &&
                typeof refElement.position() !== "undefined"
              ) {
                var toppos = refElement.position().top - 200;
                if (
                  toppos <= scrollPos &&
                  toppos + refElement.height() > scrollPos
                ) {
                  $(".product-mobile-menu-nav ul li").removeClass("active");
                  currLink.parents("li").addClass("active");
                } else {
                  currLink.parents("li").removeClass("active");
                }
              }
            })
            .promise()
            .done(function () {
              if (
                $(".product-mobile-menu-nav .menu-list-intvl.active").length > 0
              ) {
                $(".product-mobile-menu-nav .mobile-menu-title").html(
                  $(
                    ".product-mobile-menu-nav .menu-list-intvl.active .menu-title-txt"
                  ).html()
                );
              }
            });
        });
      }, 100);

      return mainMenu;
    } else {
      return;
    }
  }

  navigateMenuListHidden() {
    let navigateMenuArr = this.props.navigateMenu;
    var selectedNav = this.props.productState.selectedNavigation;
    var i = 0;
    if (Object.keys(navigateMenuArr).length > 0) {
      const mainMenu = navigateMenuArr.map((loaddata, index) => {
        if (index > 7) {
          i++;
          if (loaddata.menu_type == "main") {
            return (
              <li
                key={index + 1}
                className="menu-list-intvl"
                id={"c_" + loaddata.pro_cate_primary_id}
              >
                <Link
                  to={"/#" + loaddata.pro_cate_slug}
                  onClick={this.goCategoryProducts.bind(
                    this,
                    loaddata.pro_cate_slug,
                    "c",
                    loaddata.pro_cate_primary_id
                  )}
                  className="menu-title-link"
                  title={loaddata.menu_custom_title}
                >
                  <span className="menu-title-txt">
                    {capitalize_Words(
                      stripslashes(loaddata.menu_custom_title.toLowerCase())
                    )}
                  </span>
                </Link>
              </li>
            );
          } else if (loaddata.menu_type == "sub") {
            return (
              <li
                key={index + 1}
                id={"s_" + loaddata.pro_subcate_id}
                className={
                  selectedNav === loaddata.pro_subcate_slug
                    ? "menu-list-intvl active"
                    : "menu-list-intvl"
                }
                title={loaddata.menu_custom_title}
              >
                <Link
                  to="#"
                  className="menu-title-link"
                  onClick={this.goCategoryProducts.bind(
                    this,
                    loaddata.pro_subcate_slug,
                    "c",
                    loaddata.pro_subcate_id
                  )}
                  title={loaddata.menu_custom_title}
                >
                  <span className="menu-title-txt">
                    {capitalize_Words(
                      stripslashes(loaddata.menu_custom_title.toLowerCase())
                    )}
                  </span>
                </Link>
              </li>
            );
          }
        }
      });
      setTimeout(function () {
        $(window).scroll(function () {
          var scrollPos = $(document).scrollTop();
          $(".more-menu ul li a")
            .each(function () {
              var currLink = $(this);
              var refElement = $(currLink.attr("href").replace(/\//g, ""));
              if (
                refElement.position() !== "" &&
                typeof refElement.position() !== undefined &&
                typeof refElement.position() !== "undefined"
              ) {
                var toppos = refElement.position().top - 230;
                if (
                  toppos <= scrollPos &&
                  parseFloat(toppos) + parseFloat(refElement.height()) >
                    scrollPos
                ) {
                  $(".more-menu ul li").removeClass("active");
                  currLink.parents("li").addClass("active");
                } else {
                  currLink.parents("li").removeClass("active");
                }
              }
            })
            .promise()
            .done(function () {
              if ($(".more-menu .menu-list-intvl.active a span").length > 0) {
                $(".more-menu .more-menu-name").html(
                  $(".more-menu .menu-list-intvl.active a span").text()
                );
              } else {
                $(".more-menu .more-menu-name").html("More");
              }
            });
        });
      }, 100);
      if (i > 0) {
        $(".more-menu").show();
      } else {
        $(".more-menu").hide();
      }
      return mainMenu;
    } else {
      return;
    }
  }

  componentDidMount() {
    /*const script = document.createElement("script");
		script.src = "/js/jquery.mCustomScrollbar.concat.min.js";
		script.async = true;
		document.body.appendChild(script);*/
  }

  render() {
    return (
    
      <div className="menu-top-section-inner">
        <div className="container">
          <ul className="product-menu">{this.navigateMenuList()}</ul>
          <div className="product-mobile-menu-nav">
            <div className="flexway">
              {" "}
              <strong className="mobile-menu-more">
              <span className="mobile-menu-title"></span> <i className="fa fa-angle-down"></i>
              </strong>{" "}
            </div>
            <ul className="product-mobile-menu">
              <span className="mobile-menu-title"></span>
              {this.mobileNavigateMenuList()}
            </ul>
          </div>
          {this.load_mcustScrl()}
          <div className="more-menu" style={{ display: "none" }}>
            <div className="more-menu-parent">
              <span className="more-menu-name">More</span>
              <label className="open_more_category">
                {" "}
                <i className="fa fa-angle-double-down"></i>{" "}
              </label>
            </div>
            <ul style={{ display: "none" }} className="more_categor_info">
              {this.navigateMenuListHidden()}
            </ul>
          </div>
        </div>
      </div>
    );
  }
}

export default MenuNavigation;
