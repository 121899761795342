/* eslint-disable */
import React, { Component } from "react";
import { Link } from "react-router-dom";
import Axios from "axios";
import cookie from "react-cookies";
import { connect } from "react-redux";
import {
  appId,
  apiUrl,
  apiUrlV2,
  deliveryId,
  timThumpUrl,
} from "../Helpers/Config";
import {
  getReferenceID,
  stripslashes,
  showPriceValue,
  callImage,
  showLoader,
  hideLoader,
  showCustomAlert,
  showCartLst,
  showAlert,
  removePromoCkValue,
} from "../Helpers/SettingHelper";
import noimage from "../../common/images/no-img-product.png";

import { GET_PRODUCT_LIST } from "../../actions";
import { th } from "date-fns/locale";
var Parser = require("html-react-parser");
var qs = require("qs");
import { format } from "date-fns";

class ProductList extends Component {
  constructor(props) {
    super(props);
    var shopType =
      cookie.load("shopType") !== "" &&
      typeof cookie.load("shopType") !== undefined &&
      typeof cookie.load("shopType") !== "undefined"
        ? cookie.load("shopType")
        : "";
    var pathname = this.props.location.pathname.split("/");
    var currentShopType = "";
    if (pathname[1] == "food") {
      currentShopType = "1";
    } else if (pathname[1] == "retail") {
      currentShopType = "2";
    } else if (pathname[1] == "events") {
      currentShopType = "3";
    }
    var avilablityIdTxt = cookie.load("defaultAvilablityId");
    var outletLocationId =
      typeof cookie.load("outletLocationId") === "undefined"
        ? ""
        : cookie.load("outletLocationId");
    var orderZoneId =
      cookie.load("orderZoneId") !== "" &&
      typeof cookie.load("orderZoneId") !== undefined &&
      typeof cookie.load("orderZoneId") !== "undefined"
        ? cookie.load("orderZoneId")
        : "";

    this.state = {
      selectedNavigation: "",
      settings: [],
      shopType: shopType,
      outletLocationId: outletLocationId,
      currentShopType: currentShopType,
      orderZoneId: orderZoneId,
      selectedslugType: "",
      productSubcatlist: [],
      productCommon: [],
      outlet_slug: this.props.match.params.slugRestaurant,
      allProductList: "",
      imagesource: "",
      cartOutletList: [],
      outletslist: [],
      totalCartCount: 0,
      orderOutletId:
        cookie.load("orderOutletId") !== "" &&
        typeof cookie.load("orderOutletId") !== undefined
          ? cookie.load("orderOutletId")
          : "",
      shopeClosed: 0,
      defaultAvilTy: avilablityIdTxt,
      filterTag: [],
      updateproductList: [],
      filterTagCount: 0,
      filterResType: [],
      filterResTypeCount: 0,
    };
  }

  componentDidMount() {}

  componentWillReceiveProps(nextProps) {
    let selectedNavigation = nextProps.productState.selectedNavigation;
    let slugType = nextProps.productState.catslugType;
    let outletslist = nextProps.productState.outletslist;
    let totalCartCount = nextProps.productState.totalCartCount;
    let filterTagList = nextProps.productState.filterTag;
    let filterResTypeList = nextProps.productState.filterResType;
    /* console.log(
      filterTagList,
      filterTagList.length,
      "filterTagListfilterTagList"
    ); */
    if (totalCartCount != this.state.totalCartCount) {
      this.setState({ totalCartCount: totalCartCount });
    }
    if (nextProps.productState.loadproduct === "Yes") {
      //if (selectedNavigation !== this.state.selectedNavigation) {
      if (selectedNavigation !== "") {
        var catSlug = selectedNavigation;
        if (slugType === "subcategory") {
          catSlug = "";
          subcatSlug = selectedNavigation;
        }
        this.props.sateValChange("loadproduct", "No");
        this.checkTimeAvilablity(
          nextProps.productState.navigateMenu,
          this.state.outlet_slug
        );

        //this.props.getProductList(catSlug, subcatSlug, this.state.outlet_slug);
      }

      this.setState({
        selectedNavigation: selectedNavigation,
        selectedslugType: slugType,
      });
    }
    if (outletslist !== this.state.outletslist) {
      var outlets_list = [];
      if (outletslist !== "") {
        outlets_list = outletslist[0];
      }
      this.setState({ outletslist: outlets_list });
    }
    if (nextProps.productState.settings !== this.state.settings) {
      if (
        nextProps.productState.settings !== "" &&
        typeof nextProps.productState.settings !== undefined &&
        typeof nextProps.productState.settings !== "undefined"
      ) {
        this.setState({ settings: nextProps.productState.settings });
      }
    }
    if (this.state.cartOutletList !== nextProps.cartOutletList) {
      this.setState({ cartOutletList: nextProps.cartOutletList });
    }
    if (this.state.filterTagCount !== filterTagList.length) {
      this.setState(
        { filterTag: filterTagList, filterTagCount: filterTagList.length },
        function () {
          this.productsubcatlist(
            this.state.updateproductList,
            nextProps.productState.navigateMenu
          );
        }
      );
    }
    if (this.state.filterResTypeCount !== filterResTypeList.length) {
      this.setState(
        {
          filterResType: filterResTypeList,
          filterResTypeCount: filterResTypeList.length,
        },
        function () {
          this.productsubcatlist(
            this.state.updateproductList,
            nextProps.productState.navigateMenu
          );
        }
      );
    }
  }

  checkTimeAvilablity(navigateMenu, outlet_slug) {
    if (this.state.orderOutletId !== "") {
      var orderDate =
        cookie.load("deliveryDate") !== "" &&
        typeof cookie.load("deliveryDate") !== undefined &&
        typeof cookie.load("deliveryDate") !== "undefined"
          ? cookie.load("deliveryDate")
          : "";
      var deliveryTime =
        cookie.load("deliveryTime") !== "" &&
        typeof cookie.load("deliveryTime") !== undefined &&
        typeof cookie.load("deliveryTime") !== "undefined"
          ? cookie.load("deliveryTime")
          : "";

      if (deliveryTime !== "") {
        Axios.get(
          apiUrlV2 +
            "settings/chkOutletTimesAvaiable?app_id=" +
            appId +
            "&availability_id=" +
            this.state.defaultAvilTy +
            "&outletID=" +
            this.state.orderOutletId +
            "&orderDateTime=" +
            orderDate +
            " " +
            deliveryTime
        ).then((response) => {
          var shopeClosed = 0;
          if (response.data.status === "error") {
            shopeClosed = 1;
          }
          this.setState({ shopeClosed: shopeClosed }, function () {
            this.getCateProductList(navigateMenu, outlet_slug);
          });
        });
      } else {
        this.getCateProductList(navigateMenu, outlet_slug);
      }
    }
  }
  getCateProductList(navigateMenu, outlet_slug) {
    if (navigateMenu !== "" && navigateMenu.length > 0) {
	  var deliveryDate = "";
	  var deliveryDateTxt = cookie.load("deliveryDate") !== undefined ? cookie.load("deliveryDate") : "";
	  if (deliveryDateTxt != "") {
		  var deliveryDateArr = deliveryDateTxt.split("/");
		  deliveryDate =
			deliveryDateArr[2] +
			"-" +
			deliveryDateArr[1] +
			"-" +
			deliveryDateArr[0];
	  } else {
			deliveryDate = format(new Date(), "yyyy-MM-dd");
	  }
      var updateproductList = [];
      var current = this;
      var addSlug = "";
      if (
        this.props.productState.currentPageUrl !== "" &&
        typeof this.props.productState.currentPageUrl !== undefined &&
        typeof this.props.productState.currentPageUrl !== "undefined"
      ) {
        addSlug += "&shop_type=" + this.props.productState.currentPageUrl;
      }
      if (
        this.props.productState.slugEvent !== "" &&
        typeof this.props.productState.slugEvent !== undefined &&
        typeof this.props.productState.slugEvent !== "undefined"
      ) {
        addSlug += "&event=" + this.props.productState.slugEvent;
      }
      navigateMenu.map(function (item, index) {
        var availabilityId =
          cookie.load("defaultAvilablityId") === undefined ||
          cookie.load("defaultAvilablityId") == ""
            ? deliveryId
            : cookie.load("defaultAvilablityId");
        Axios.get(
          apiUrlV2 +
            "products/getAllProducts?app_id=" +
            appId +
            "&availability=" +
            availabilityId +
            "&category_slug=" +
            item.pro_cate_slug +
            "&outletSlug=" +
            outlet_slug +
            addSlug +
      "&specialprice_applicable=orderdate" +
      "&order_datetxt=" +
      deliveryDate
        )
          .then(function (response) {
            if (response.data.status === "ok") {
              if (current.state.imagesource === "") {
                current.setState({ imagesource: response.data.common });
              }
              var result = response.data.result_set;
              updateproductList[item.pro_cate_slug] = result;
              current.setState({ updateproductList: updateproductList });
              current.productsubcatlist(updateproductList, navigateMenu);
            } else {
            }
          })
          .catch(function (error) {
            console.log(error);
          });
      });
    }
  }

  productsubcatlist(subcatlistArr, navigateMenu) {
    // var subcatlistArr = this.props.productSubcatlist;
    var current = this;

    if (navigateMenu !== "" && navigateMenu.length > 0) {
      hideLoader("productlist-main-div", "class");
      const productDetails = navigateMenu.map(function (item, index) {
        if (
          subcatlistArr[item.pro_cate_slug] !== "" &&
          typeof subcatlistArr[item.pro_cate_slug] != undefined &&
          typeof subcatlistArr[item.pro_cate_slug] != "undefined"
        ) {
          return (
            <div
              key={index}
              id={item.pro_cate_slug}
              className="category-product-list"
              title={item.category_name}
            >
              <h3 id={item.pro_cate_slug}>
                {stripslashes(item.pro_cate_name)}
              </h3>

              {subcatlistArr[item.pro_cate_slug].map(
                (subcatlistArr1, index) => {
                  return subcatlistArr1["subcategorie"].map(
                    (categories, categoryIndex) => {
                      if (categories.products.length > 0) {
                        return (
                          <div
                            className="innerproduct"
                            id={"subcat-" + categories.pro_subcate_slug}
                            key={categoryIndex}
                          >
                            <h5 id={categories.pro_subcate_slug}>
                              {" "}
                              {stripslashes(categories.pro_subcate_name)}
                            </h5>
                            <ul className="products-list-ulmain">
                              {current.productList(
                                categories.products,
                                item.pro_cate_slug,
                                categories.pro_subcate_slug
                              )}
                            </ul>
                            <input type="hidden" className="subcattotalPro" />
                          </div>
                        );
                      }
                    }
                  );
                }
              )}
            </div>
          );
        }
      });
      this.setState({ allProductList: productDetails });
      return "";
    } else {
      hideLoader("productlist-main-div", "class");
      return "";
    }

    /* if (Object.keys(subcatlistArr).length > 0) {
      hideLoader("productlist-main-div", "class");
      const productDetails = subcatlistArr.map((categories, categoryIndex) => {
        if (categories.products.length > 0) {
          return (
            <div className="innerproduct" key={categoryIndex}>
              <h3 id={categories.pro_subcate_slug}>
                {stripslashes(categories.pro_subcate_name)}
              </h3>
              <ul className="products-list-ulmain">
                {this.productList(
                  categories.products,
                  categories.products.length
                )}
              </ul>
            </div>
          );
        }
      });
      return productDetails;
    } else {
      return "";
    } */
  }

  chkProStockCls(proSlug, Stock) {
    var searchProVal = this.props.productState.searchProVal;
    var actClstxt = searchProVal === proSlug ? " active" : "";

    var returnText =
      "products-single-li no-stock-product " + proSlug + actClstxt;
    if (Stock > 0) {
      returnText = "products-single-li " + proSlug + actClstxt;
    }

    return returnText;
  }

  /* Products List */
  productList(list, cate_slug, subcate_slug) {
    var outletslist = this.state.outletslist;
    var totalProducts = 0;
    const listProduct = list.map((productDetail, index) => {
      var description =
        productDetail.product_short_description !== null &&
        productDetail.product_short_description !== ""
          ? Parser(stripslashes(productDetail.product_short_description))
          : "";

      var prodivId = "proIndex-" + productDetail.product_primary_id;
      var comboProId = "comboPro-" + productDetail.product_slug;
      var checkFilter = this.checkFilter(
        productDetail.product_tag,
        productDetail.restaurant_type
      );
      if (checkFilter === true) {
        totalProducts++;
        return (
          <li
            className={this.chkProStockCls(
              productDetail.product_slug,
              productDetail.product_stock
            )}
            id={prodivId}
            key={index}
          >
            <div
              className="products-image-div"
              //style={{ background: outletslist.outlet_color_code }}
            >
              {productDetail.product_thumbnail !== "" ? (
                <img
                // style={outletslist.outlet_color_code ? {objectFit: "scale-down"} : {objectFit: "cover"}}
                //style={outletslist.outlet_color_code ? {objectFit: "cover"} : {objectFit: "cover"}}
                  src={
                    this.state.imagesource.product_image_source +
                    "/" +
                    productDetail.product_thumbnail
                  }
                />
              ) : (
                <img src={noimage} className="no_product" />
              )}
            </div>

            <div className="product-info-div">
              <div className="product-title-maindiv">
                <div className="product-title">
              <span>{ outletslist.outlet_name }</span>
                  <h3>
                    {productDetail.product_alias !== ""
                      ? stripslashes(productDetail.product_alias)
                      : stripslashes(productDetail.product_name)}
                  </h3>
                </div>
                {description !== "" && (
                  <div className="product-short-description">
                    <p>{description !== "" ? description : Parser("&nbsp;")}</p>
                  </div>
                )}
                <div className="product-tag-list">
                  {Object.keys(productDetail.product_tag).length > 0 ? (
                    <ul>
                      {productDetail.product_tag.map((producttag, index1) => {
                        return (
                          <li key={index1}>
                            {producttag.pro_tag_name !== ""
                              ? producttag.pro_tag_name
                              : ""}
                          </li>
                        );
                      })}
                    </ul>
                  ) : (
                    ""
                  )}
                </div>
              </div>
              <div className="product-footer-main">
                <div className="product-footer">
                  <div className="product-price">
					{productDetail.product_specialprice_applicable === "yes" && <span className="price_disc">
                            {showPriceValue(productDetail.product_price)}
				    </span>}
                    <h3>
					{productDetail.product_specialprice_applicable === "yes"
                        ? showPriceValue(productDetail.product_special_price)
                        : showPriceValue(productDetail.product_price)}
					</h3>
                  </div>
                  <div className="products-ordernow-action">
                    {productDetail.product_stock > 0 ||
                    productDetail.product_stock === null ? (
                      productDetail.product_type === "1" ? (
                        this.state.shopeClosed === 0 && (
                          // <a
                          //   className="button order_nowdiv smiple_product_lk disbl_href_action"
                          //   href="/"
                          //   onClick={this.addToCartSimple.bind(
                          //     this,
                          //     productDetail,
                          //     "initial"
                          //   )}
                          // >
                          <a
                            href="/"
                            onClick={this.viewProDetail.bind(this, productDetail)}
                            title="Product Details"
                            id={comboProId}
                            className="button order_nowdiv compo_product_lk"
                          >
                            Order Now
                          </a>
                        )
                      ) : (
                        this.state.shopeClosed === 0 && (
                          <a
                            href="/"
                            onClick={this.viewProDetail.bind(this, productDetail)}
                            title="Product Details"
                            id={comboProId}
                            className="button order_nowdiv compo_product_lk"
                          >
                            Order Now
                          </a>
                        )
                      )
                    ) : (
                      this.state.shopeClosed === 0 && (
                        <a
                          className="button order_nowdiv disabled disbl_href_action"
                          href="/"
                        >
                          Sold Out
                        </a>
                      )
                    )}

                    <div className="addcart_row addcart_done_maindiv">
                      <div className="addcart_row_inner">
                        <div className="qty_bx">
                          <span
                            className="qty_minus"
                            onClick={this.proQtyAction.bind(
                              this,
                              productDetail.product_primary_id,
                              "decr"
                            )}
                          >
                            -
                          </span>
                          <input
                            type="text"
                            className="proqty_input"
                            readOnly
                            value="1"
                          />
                          <span
                            className="qty_plus"
                            onClick={this.proQtyAction.bind(
                              this,
                              productDetail.product_primary_id,
                              "incr"
                            )}
                          >
                            +
                          </span>
                        </div>
                        <button
                          className="btn btn_black"
                          onClick={this.addToCartSimple.bind(
                            this,
                            productDetail,
                            "done"
                          )}
                        >
                          Done
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </li>
        );
      }
    });
    $("#subcat-" + subcate_slug + " .subcattotalPro").val(totalProducts);
    if (totalProducts > 0) {
      $("#subcat-" + subcate_slug).show();
    } else {
      $("#subcat-" + subcate_slug).hide();
    }
    var totalPro = 0;
    $("#" + cate_slug + " .subcattotalPro").each(function () {
      if ($(this).val() > 0) {
        totalPro = parseInt(totalPro) + parseInt($(this).val());
      }
    });
    if (totalPro > 0) {
      $("#" + cate_slug).show();
    } else {
      $("#" + cate_slug).hide();
    }
    $("#no_product_error").hide();
    setTimeout(function () {
      // console.log(
      //   $(".category-product-list:visible").length,
      //   '$(".category-product-list:visible")'
      // );
      if ($(".category-product-list:visible").length > 0) {
        $("#no_product_error").hide();
      } else {
        $("#no_product_error").show();
      }
    }, 100);

    return listProduct;
  }

  checkFilter(tag, resType) {
    var filter = false;
    var FiltertagList = this.state.filterTag;
    var filterResTypeList = this.state.filterResType;

    if (FiltertagList.length > 0) {
      if (tag.length > 0) {
        tag.map((item) => {
          FiltertagList.map((item1) => {
            if (item.tag_id === item1) {
              filter = true;
            }
          });
        });
      }
    } else {
      filter = true;
    }
    if (filterResTypeList.length > 0 && filter === true) {
      if (resType.length > 0) {
        var filterCount = 0;
        resType.map((item) => {
          filterResTypeList.map((item1) => {
            if (item === item1) {
              filter = true;
              filterCount++;
            }
          });
        });
        if (filterCount === 0) {
          filter = false;
        }
      } else {
        filter = false;
      }
    }
    return filter;
  }

  proQtyAction(indxFlg, actionFlg) {
    var proqtyInput = $("#proIndex-" + indxFlg)
      .find(".proqty_input")
      .val();
    proqtyInput = parseInt(proqtyInput);
    if (actionFlg === "decr") {
      proqtyInput = proqtyInput > 1 ? proqtyInput - 1 : proqtyInput;
    } else {
      proqtyInput = proqtyInput + 1;
    }
    $("#proIndex-" + indxFlg)
      .find(".proqty_input")
      .val(proqtyInput);
  }

  /* add to cart */
  addToCartSimple(productDetail, actionFlg, event) {
    event.preventDefault();
    if (
      cookie.load("defaultAvilablityId") === "" ||
      typeof cookie.load("defaultAvilablityId") === undefined ||
      typeof cookie.load("defaultAvilablityId") === "undefined"
    ) {
      if ($("body").width() <= 1200) {
        $(".mobile_error.required_availability").show();
      } else {
        $(".desktop_error.required_availability").show();
      }

      $("html, body").animate(
        {
          scrollTop: $("#availability_div").offset().top - 100,
        },
        100
      );
      setTimeout(function () {
        if ($("body").width() <= 1200) {
          $(".mobile_error.required_availability").hide();
        } else {
          $(".desktop_error.required_availability").hide();
        }
      }, 3000);
      return false;
    }

    if (
      this.state.shopType !== "" &&
      typeof this.state.shopType !== undefined &&
      typeof this.state.shopType !== "undefined"
    ) {
      if (this.state.currentShopType !== this.state.shopType) {
        $.magnificPopup.open({
          items: {
            src: "#warning-popup",
          },
          type: "inline",
        });
        $("#change_shop_type").val(this.state.currentShopType);
        return false;
      }
    } else {
      this.setState({ shopType: this.state.currentShopType }, function () {
        cookie.save("shopType", this.state.currentShopType, { path: "/" });
      });
    }
    var outlet_id =
      typeof cookie.load("orderOutletId") === "undefined"
        ? ""
        : cookie.load("orderOutletId");
    var outletLocationId =
      typeof cookie.load("outletLocationId") === "undefined"
        ? ""
        : cookie.load("outletLocationId");

    var cartOutletList = this.state.cartOutletList;
    if (cartOutletList.length >= this.state.settings.max_order_handle) {
      if (cartOutletList.indexOf(outlet_id) < 0) {
        $.magnificPopup.open({
          items: {
            src: "#warning-max-orderpopup",
          },
          type: "inline",
        });
        return false;
      }
    }

    var IndexFlg = productDetail.product_primary_id;

    if (actionFlg === "initial") {
      $("#proIndex-" + IndexFlg).addClass("active");
      $("#proIndex-" + IndexFlg)
        .find(".smiple_product_lk")
        .hide();
      $("#proIndex-" + IndexFlg)
        .find(".addcart_done_maindiv")
        .show();
      return false;
    } else {
      showLoader("proIndex-" + IndexFlg, "Idtext");
      var availabilityId = cookie.load("defaultAvilablityId");
      /*var availabilityId = deliveryId;*/
      var availabilityName =
        availabilityId === deliveryId ? "Delivery" : "Pickup";
      var isAddonProduct = "No";
      var productId = productDetail.product_id;
      var customerId =
        typeof cookie.load("UserId") === "undefined"
          ? ""
          : cookie.load("UserId");
      var proqtyQty = $("#proIndex-" + IndexFlg)
        .find(".proqty_input")
        .val();

      var postObject = {};
      postObject = {
        app_id: appId,
        product_id: productId,
        product_qty: proqtyQty,
        outletLocationId: outletLocationId,
        outlet_id: outlet_id,
        product_type: 1,
        availability_id: availabilityId,
        availability_name: availabilityName,
        isAddonProduct: isAddonProduct,
        reference_id: customerId === "" ? getReferenceID() : "",
        customer_id: customerId,
        shopType: this.state.shopType,
        outletzone_id: this.state.orderZoneId,
        cart_id:
          cookie.load("cart_id") !== "" &&
          typeof cookie.load("cart_id") !== undefined &&
          typeof cookie.load("cart_id") !== "undefined"
            ? cookie.load("cart_id")
            : "",
        cart_group_id:
          cookie.load("cart_group_id") !== "" &&
          typeof cookie.load("cart_group_id") !== undefined &&
          typeof cookie.load("cart_group_id") !== "undefined"
            ? cookie.load("cart_group_id")
            : "",
      };

      Axios.post(
        apiUrlV2 + "cart/simpleCartInsert",
        qs.stringify(postObject)
      ).then((res) => {
        $("#proIndex-" + IndexFlg).removeClass("active");
        hideLoader("proIndex-" + IndexFlg, "Idtext");
        $("#proIndex-" + IndexFlg)
          .find(".addcart_done_maindiv")
          .hide();
        $("#proIndex-" + IndexFlg)
          .find(".smiple_product_lk")
          .show();
        if (res.data.status === "ok") {
          this.props.sateValChange("cartflg", "yes");
          removePromoCkValue();
          showCustomAlert(
            "success",
            "Great! Your item has been added to the cart",
            "cart-success-msg"
          );
          $(".htico_cart").addClass("hmenu_sucess");
          setTimeout(function () {
            $(".htico_cart").removeClass("hmenu_sucess");
          }, 6000);
          /*showCartLst();*/
          /* this.handleShowAlertFun(
            "success",
            "Great choice! Item added to your cart."
          ); */
        } else if (res.data.status === "error") {
          var errMsgtxt =
            res.data.message !== ""
              ? res.data.message
              : "Sorry! Products can`t add your cart.";
          showCustomAlert("error", errMsgtxt);
          //this.handleShowAlertFun("Error", errMsgtxt);

          $(".htico_cart").addClass("hmenu_failed");
          setTimeout(function () {
            $(".htico_cart").removeClass("hmenu_failed");
          }, 6000);
        }

        return false;
      });
    }
  }

  viewProDetail(productDetail, event) {
    event.preventDefault();
    if (
      cookie.load("defaultAvilablityId") === "" ||
      typeof cookie.load("defaultAvilablityId") === undefined ||
      typeof cookie.load("defaultAvilablityId") === "undefined"
    ) {
      if ($("body").width() <= 980) {
        $(".mobile_error.required_availability").show();
      } else {
        $(".desktop_error.required_availability").show();
      }

      $("html, body").animate(
        {
          scrollTop: $("#availability_div").offset().top - 100,
        },
        100
      );
      setTimeout(function () {
        if ($("body").width() <= 980) {
          $(".mobile_error.required_availability").hide();
        } else {
          $(".desktop_error.required_availability").hide();
        }
      }, 3000);
      return false;
    }
    if (
      this.state.shopType !== "" &&
      typeof this.state.shopType !== undefined &&
      typeof this.state.shopType !== "undefined"
    ) {
      if (this.state.currentShopType !== this.state.shopType) {
        $.magnificPopup.open({
          items: {
            src: "#warning-popup",
          },
          type: "inline",
        });
        $("#change_shop_type").val(this.state.currentShopType);
        return false;
      }
    } else {
      this.setState({ shopType: this.state.currentShopType }, function () {
        cookie.save("shopType", this.state.currentShopType, { path: "/" });
      });
    }
    var productSlug = productDetail.product_slug;
    if (productSlug !== "") {
      $("#proIndex-" + productDetail.product_primary_id).addClass("active");
      this.props.sateValChange("view_pro_data", productSlug);
    }
    return false;
  }

  handleShowAlertFun(header, msg) {
    var magnfPopup = $.magnificPopup.instance;
    showAlert(header, msg, magnfPopup);
    $.magnificPopup.open({
      items: {
        src: ".alert_popup",
      },
      type: "inline",
    });
  }

  componentDidMount() {}

  render() {
    return (
      <div className="productlist-main-div productlist-main-detail">
        <div className="innerproduct_row">
          {this.state.allProductList}
          {/* this.productsubcatlist() */}
        </div>
        <div
          className="no-product"
          id="no_product_error"
          style={{ display: "none" }}
        >
          <div className="container">
            <h2>No product found</h2>
            <p>
              Sorry, we can't find the type of products you are looking for. Try
              widening your search to see what’s available.
            </p>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateTopProps = (state) => {
  var tempArr = Array();
  var productSubcat = Array();
  var productCmn = Array();
  if (Object.keys(state.product).length > 0) {
    var tempArr = !("productlist" in state.product[0])
      ? Array()
      : state.product[0].productlist;
    if (Object.keys(tempArr).length > 0) {
      if (tempArr[0].status === "ok") {
        productSubcat = tempArr[0].result_set[0].subcategorie;
        productCmn = tempArr[0].common;
      }
    }
  }

  return {
    productSubcatlist: productSubcat,
    productCommon: productCmn,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getProductList: (catslug, subcatslug, outletid) => {
      dispatch({ type: GET_PRODUCT_LIST, catslug, subcatslug, outletid });
    },
  };
};

export default connect(mapStateTopProps, mapDispatchToProps)(ProductList);
