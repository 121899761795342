/* eslint-disable */
import React, { Component } from "react";
import { Link, withRouter } from "react-router-dom";
import { connect } from "react-redux";
import cookie from "react-cookies";
import axios from "axios";
import { validated } from "react-custom-validation";
import update from "immutability-helper";
import { setMinutes, setHours, getDay, format } from "date-fns";
var dateFormat = require("dateformat");
var Parser = require("html-react-parser");

import {
  appId,
  apiUrl,
  siteURL,
  apiUrlV2,
  apiUrlPro,
  deliveryId,
  pickupId,
  reservationId,
  baseUrl,
  fbAppId,
} from "../Helpers/Config";

import {
  GET_GLOBAL_SETTINGS,
  GET_ZONE_DETAIL,
  GET_PICKUP_OUTLETS,
  GET_ALL_OUTLETS,
  DESTROY_CART_DETAIL,
  GET_LOGINDATA,
  GET_FORGET_PASSWORD,
  GET_REGISTRATION,
  GET_MENUDATA,
  GET_ALLUSERSECADDRDATA,
  GET_STATIC_BLOCK
} from "../../actions";
import {
  getReferenceID,
  showAlert,
  showLoader,
  hideLoader,
  getAliasName,
  stripslashes,
  removeOrderDateTime,
  removePromoCkValue,
  addressFormat,
  loadHeaderTime,
} from "../Helpers/SettingHelper";

import MenuNavigationmob from "./MenuNavigation/MenuNavigationmob";
import OrderdatetimeSlot from "./OrderdatetimeSlot";
import {
  Login,
  Forgotpassword,
  Signup,
  Guestcheckout,
} from "../../components/Myaccount/Index";

/* import images */
import mainLogo from "../../common/images/logo_white.png";
import blackLogo from "../../common/images/black_logo.png";
import footerLogo from "../../common/images/logo_white.png";
import iconUnhappy from "../../common/images/sad-smiley.png";
import iconWin from "../../common/images/icon-win.svg";
import warningImg from "../../common/images/warning.svg";

import deliveryImg from "../../common/images/delivery.svg";
import deliveryWtImg from "../../common/images/food-delivery.svg";
import takeawayImg from "../../common/images/lunch.svg";
import takeawayWtImg from "../../common/images/takeaway-w.svg";
import greentick from "../../common/images/grenn-tick.png";
import loginav from "../../common/images/login-av.png";
import mainav from "../../common/images/av-icon.png";

import bagicon from "../../common/images/bagicon.png";
import usericon from "../../common/images/user.png";

import takeAway from "../../common/images/take_away.png";
import postalCode from "../../common/images/kk-placeholder-black.svg";
import deliveryIcon from "../../common/images/kk-delivery-black.svg";
import takeawayIcon from "../../common/images/kk-pick-up-black.svg";

import CartSideBar from "./CartSideBar";
import { pick } from "underscore";

class Header extends Component {
  constructor(props) {
    super(props);

    var availability =
      cookie.load("defaultAvilablityId") !== "" &&
      typeof cookie.load("defaultAvilablityId") !== "undefined" &&
      typeof cookie.load("defaultAvilablityId") !== undefined
        ? cookie.load("defaultAvilablityId")
        : "";

    var shopType = "";
    if (
      cookie.load("shopType") !== "" &&
      typeof cookie.load("shopType") !== undefined &&
      typeof cookie.load("shopType") !== "undefined"
    ) {
      shopType = cookie.load("shopType");
    } else {
      /* shopType = cookie.save("shopType", 1); */
      shopType = 1;
    }
    var showTimeSlot = "no";
    var getDateTimeFlg = "no";
    if (availability !== "") {
      showTimeSlot = "yes";
      //getDateTimeFlg = "yes";
    }

    var orderDeliveryAddress =
      cookie.load("orderDeliveryAddress") !== "" &&
      typeof cookie.load("orderDeliveryAddress") !== "undefined" &&
      typeof cookie.load("orderDeliveryAddress") !== undefined
        ? cookie.load("orderDeliveryAddress")
        : "";

    this.state = {
      orderPostalCode:
        cookie.load("orderPostalCode") !== "" &&
        typeof cookie.load("orderPostalCode") !== "undefined" &&
        typeof cookie.load("orderPostalCode") !== undefined
          ? cookie.load("orderPostalCode")
          : "",
      currentPageUrl: this.props.location.pathname,
      slugRestaurant:
        this.props.match.params.slugRestaurant !== "" &&
        typeof this.props.match.params.slugRestaurant !== undefined &&
        typeof this.props.match.params.slugRestaurant !== "undefined"
          ? this.props.match.params.slugRestaurant
          : "",
      defaultAvilTy: availability,
      seletedAvilablityId: availability,
      urltype: this.props.match.path,
      shopType: shopType,
      seletedOutletId: "",
      globalsettings: [],
      secondaryaddresslist: [],
      deliveryOutlets: [],
      deliveryOutletsList: [],
      pickupOutlets: [],
      pickupOutletsList: [],
      delivery_outlet_id: "",
      orderHandled: "",
      orderDeliveryAddress: orderDeliveryAddress,
      nextavail: "",
      logindata: "",
      getDateTimeFlg: getDateTimeFlg,
      deliveryInfo: [],
      pickupInfo: [],
      seleted_ord_date: "",
      seleted_ord_time: "",
      fields: {
        mobile: "",
        password: "",
      },
      fieldsfgtpassword: {
        email: "",
      },
      fieldscheckout: {
        check_email: "",
        check_firstname: "",
        check_phone: "",
      },
      fpstatus: "initiating",
      regstatus: "initiating",
      fieldssignup: {
        firstname: "",
        email: "",
        pin: "",
        rePin: "",
        mobile: "",
        pdpa_consent: "",
        terms: "",
      },
      menuData: [],
      selectedAvilHome: "No",
      cartOutletList: [],

      selectPostalCode: "No",
      selectPickupTime: "No",
      showTimeSlot: showTimeSlot,
      order_tat_time: "",
      openOutletSearch: 0,
      opentavilablity: false,
      searchOutletKey: "",
      puckupOutletList: "",
      kitchenpodBlock: ""
    };

    this.props.getGlobalSettings();
    this.props.getPickupOutlets();
    var zoneIdTxt =
      typeof cookie.load("orderZoneId") === "undefined"
        ? ""
        : cookie.load("orderZoneId");

    if (availability === deliveryId && zoneIdTxt !== "") {
      this.props.getZoneDetail("", zoneIdTxt, 1);
    }
    //this.props.getAllOutlets(deliveryId);
    this.props.getSecondaryAddress();
    this.loadcart();
  }

  loadcart() {
    var customerParam;
    if (
      cookie.load("cart_id") !== "" &&
      typeof cookie.load("cart_id") !== "undefined" &&
      typeof cookie.load("cart_id") !== undefined
    ) {
      customerParam =
        "&cart_id=" +
        cookie.load("cart_id") +
        "&cart_group_id=" +
        cookie.load("cart_group_id");
    } else {
      if (typeof cookie.load("UserId") === "undefined") {
        customerParam = "&reference_id=" + getReferenceID();
      } else {
        customerParam = "&customer_id=" + cookie.load("UserId");
      }
    }
    customerParam +=
      "&availability_id_cheking=" + cookie.load("defaultAvilablityId");
    axios
      .get(apiUrl + "cart/contents?status=A&app_id=" + appId + customerParam)
      .then((res) => {
        if (
          res.data.result_set !== "" &&
          typeof res.data.result_set !== undefined &&
          typeof res.data.result_set !== "undefined"
        ) {
          this.setState({
            order_tat_time: res.data.result_set.outletTat,
            getDateTimeFlg: "yes",
          });
        } else {
          if (
            this.props.globalsettings !== "" &&
            typeof this.props.globalsettings !== undefined &&
            typeof this.props.globalsettings !== "undefined"
          ) {
            this.setState({
              order_tat_time: this.props.globalsettings[0].result_set.order_tat,
              getDateTimeFlg: "yes",
            });
          }
        }
      });
  }

  /*forget password  - start*/
  fieldforgot = (field, value) => {
    this.setState(
      update(this.state, { fieldsfgtpassword: { [field]: { $set: value } } })
    );
  };

  forgotpassword = () => {
    this.setState({ fpstatus: "loading" });
    const formPayload = this.state.fieldsfgtpassword;

    var qs = require("qs");
    var postObject = {
      app_id: appId,
      type: "web",
      email_address: formPayload.email,
      site_url: this.props.globalsettings[0].result_set.client_site_url,
    };

    showLoader("forgotpassword-cls", "class");
    this.props.getForgetPassword(qs.stringify(postObject));
  };
  /*forget password  - end*/

  /* signin - start*/
  fieldChange = (field, value) => {
    this.setState(update(this.state, { fields: { [field]: { $set: value } } }));
  };

  handleSignin = () => {
    const formPayload = this.state.fields;
    var qs = require("qs");
    var postObject = {
      app_id: appId,
      type: "web",
      logintype: "mobile",
      passwordtype: "pin",
      login_username: formPayload.mobile,
      login_password: formPayload.password,
    };
    showLoader("login_submit", "class");
    this.props.getLoginData(qs.stringify(postObject));
  };
  /* signin - end*/

  /* for signup - start*/
  fieldChangeSignup = (field, value) => {
    if (field == "terms") {
      value = $("#terms").prop("checked");
    }
    if (field == "pdpa_consent") {
      value = $("#pdpa_consent").prop("checked");
    }
    this.setState(
      update(this.state, { fieldssignup: { [field]: { $set: value } } })
    );
  };

  handleSignup = () => {
    const formPayload = this.state.fieldssignup;
    this.setState({ regstatus: "loading" });

    var pdpaConsent = formPayload.pdpa_consent === true ? "yes" : "no";

    var qs = require("qs");
    var postObject = {
      app_id: appId,
      type: "web",
      passwordtype: "pin",
      customer_first_name: formPayload.firstname,
      customer_email: formPayload.email,
      customer_pin: formPayload.pin,
      customer_phone: formPayload.mobile,
      customer_pdpa_consent: pdpaConsent,
      site_url: this.props.globalsettings[0].result_set.client_site_url,
    };

    showLoader("signup_submit", "class");
    this.props.getRegistration(qs.stringify(postObject));
  };
  /* for signup - end*/

  componentWillReceiveProps(PropsDt) {
    if (PropsDt.menudata !== this.props.menudata) {
      this.setState({ menudata: PropsDt.menudata[0].result_set });
    }

    if (PropsDt.selectedAvilHome !== this.props.selectedAvilHome) {
      this.setState(
        {
          seletedAvilablityId: PropsDt.selectedAvilHome,
          selectedAvil: PropsDt.selectedAvilHome,
        },
        function () {
          this.pickOutlet(this.state.pickupOutlets[0]);
        }
      );
    }

    if (PropsDt.outletslist !== this.state.pickupOutletsList) {
      this.setState({
        pickupOutlets: PropsDt.outletslist,
        pickupOutletsList: PropsDt.outletslist,
      });
    }

    if (PropsDt.alloutletslist !== this.state.deliveryOutletsList) {
      this.setState({
        deliveryOutlets: PropsDt.alloutletslist,
        deliveryOutletsList: PropsDt.outletslist,
      });
    }

    if (PropsDt.secondaryaddresslist !== this.state.secondaryaddresslist) {
      this.setState({ secondaryaddresslist: PropsDt.secondaryaddresslist });
    }

    if ("homePageState" in PropsDt) {
      if (
        PropsDt.homePageState.nextavail !== undefined &&
        PropsDt.homePageState.nextavail !== "" &&
        PropsDt.homePageState.nextavail !== this.state.nextavail
      ) {
        this.setState({ nextavail: PropsDt.homePageState.nextavail });
      }
    }

    if (PropsDt.fbloginData !== this.props.fbloginData) {
      this.doLogin(PropsDt.fbloginData);
    }

    if (PropsDt.logindata !== this.props.logindata) {
      this.doLogin(PropsDt.logindata[0]);
    }

    if (this.state.fpstatus === "loading") {
      if (PropsDt.forgetpassword !== undefined) {
        this.setState({ fpstatus: "ok" });
        this.showMessage(PropsDt.forgetpassword[0]);
      }
    }

    if (this.state.regstatus === "loading") {
      if (PropsDt.registration !== undefined) {
        this.setState({
          regstatus: "ok",
          /*  fieldssignup: {
            firstname: "",
            email: "",
            pin: "",
            rePin: "",
            mobile: "",
            pdpa_consent: "",
            terms: "",
          }, */
        });
        this.showMessage(PropsDt.registration[0]);
      }
    }

    if (this.state.defaultAvilTy !== PropsDt.changeavail) {
      if (PropsDt.changeavail !== "" && PropsDt.changeavail !== undefined) {
        this.setState({ defaultAvilTy: PropsDt.changeavail });
      }
    }
    if (this.state.selectPostalCode !== PropsDt.selectPostalCode) {
      if (
        PropsDt.selectPostalCode !== "" &&
        PropsDt.selectPostalCode !== undefined
      ) {
        this.setState({ selectPostalCode: PropsDt.selectPostalCode });
      }
    }
    if (this.state.selectPickupTime !== PropsDt.selectPickupTime) {
      if (
        PropsDt.selectPickupTime !== "" &&
        PropsDt.selectPickupTime !== undefined
      ) {
        this.findOutletBasedZone("", pickupId);
        this.setState({
          selectPickupTime: PropsDt.selectPickupTime,
          seletedAvilablityId: pickupId,
        });
      }
    }

    if (PropsDt.globalsettings !== this.state.globalsettings) {
      if (
        PropsDt.globalsettings[0] !== "" &&
        PropsDt.globalsettings[0] !== undefined
      ) {
        this.setState({ globalsettings: PropsDt.globalsettings[0].result_set });
      }
    }

    if(PropsDt.staticblocks !== this.props.staticblocks) {
      PropsDt.staticblocks.map((data, index) => {
        if (data.staticblocks_slug === "kitchenpod") {
          this.setState({ kitchenpodBlock: Parser(data.staticblocks_description) });
        }
      });
    }
  }

  /* main - menu navigation -start*/

  createLink(menu) {
    if (menu.nav_type === "0" && menu.nav_parent_title == "") {
      return (
        <Link to={"/page/" + menu.nav_title_slug} title={menu.nav_title}>
          <span>{menu.nav_title}</span>
        </Link>
      );
    } else if (menu.nav_type === "3" && menu.nav_parent_title == "") {
      var pageUrlTxt = menu.nav_pages;
      if (pageUrlTxt.includes("http")) {
        return (
          <a
            href={menu.nav_pages}
            title={menu.nav_title}
            target={menu.nav_link_type == "blank" ? "_blank" : ""}
          >
            <span>{menu.nav_title}</span>
          </a>
        );
      } else {
        return (
          <Link
            to={menu.nav_pages != "#" ? "/" + menu.nav_pages : ""}
            title={menu.nav_title}
            target={menu.nav_link_type == "blank" ? "_blank" : ""}
          >
            <span>{menu.nav_title}</span>
          </Link>
        );
      }
    }
  }

  createSubmenu(menu, type) {
    if (menu.nav_parent_title === "") {
      if (this.state.menudata) {
        var checkIngVal = 0;
        var liTxt = this.state.menudata.map(function (menuparent, i) {
          if (menu.nav_id == menuparent.nav_parent_title) {
            checkIngVal = 1;
            if (menuparent.nav_type === "0") {
              return (
                <li key={i + 100}>
                  <Link
                    to={"/page/" + menuparent.nav_title_slug}
                    title={menuparent.nav_title}
                  >
                    <span>{menuparent.nav_title}</span>
                  </Link>
                </li>
              );
            } else {
              return (
                <li key={i + 100}>
                  <Link
                    to={
                      menuparent.nav_pages != "#"
                        ? "/" + menuparent.nav_pages
                        : ""
                    }
                    title={menuparent.nav_title}
                    target={menuparent.nav_link_type == "blank" ? "_blank" : ""}
                  >
                    <span>{menuparent.nav_title}</span>
                  </Link>
                </li>
              );
            }
          }
        }, this);

        if (type === "span" && checkIngVal === 1) {
          return <a href="/" className="submenu-arow disbl_href_action"></a>;
        } else if (type === "ul" && checkIngVal === 1) {
          return <ul className="submenu_list">{liTxt}</ul>;
        } else {
          return "";
        }
      }
    }
  }

  menuActiveCls(nav_pages) {
    var currenturl = window.location.href;
    var returnClsTx = "";
    if (nav_pages === "home" || nav_pages === "#") {
      returnClsTx =
        this.props.match.path === "/home" || this.props.match.path === "/"
          ? "active"
          : "";
    } else if (nav_pages === "products") {
      returnClsTx =
        this.props.match.path === "/products" ||
        this.props.match.path === "/checkout" ||
        this.props.match.path === "/thankyou"
          ? "active"
          : "";
    } else {
      returnClsTx = currenturl.includes(nav_pages) ? "active" : "";
    }
    return returnClsTx;
  }

  listMainNavigation() {
    if (this.state.menudata) {
      return this.state.menudata.map(function (menu, i) {
        return (
          <li key={i + 100} className={this.menuActiveCls(menu.nav_pages)}>
            {this.createLink(menu)}
            {this.createSubmenu(menu, "span")}
            {this.createSubmenu(menu, "ul")}
          </li>
        );
      }, this);
    }
  }

  /* menu navigation -end */

  /* show message */
  showMessage(response) {
    hideLoader("signup_submit", "class");
    hideLoader("forgotpassword-cls", "class");
    if (response.status === "ok") {
      showAlert("Success", response.message);
    } else {
      if (response.form_error) {
        showAlert("Error", response.form_error);
      } else {
        showAlert("Error", response.message);
      }
    }
    $.magnificPopup.open({
      items: {
        src: ".alert_popup",
      },
      type: "inline",
    });
  }

  chooseAvailabilityMbl() {
    var availability = this.state.defaultAvilTy;
    var showtext = "Order now";
    if (availability === deliveryId) {
      showtext = "Delivery";
    } else if (availability === pickupId) {
      showtext = "Takeaway";
    }
    return showtext;
  }

  /* for login and facebook login*/
  doLogin(fbloginData) {}

  /*  Onchange  for Guest Checkout */
  fieldChangecheckout = (field, value) => {
    this.setState(
      update(this.state, { fieldscheckout: { [field]: { $set: value } } })
    );
  };

  handleCheckout = () => {
    const formPayload = this.state.fieldscheckout;
    if (
      document.getElementById("spn-email-error").innerHTML ===
      '<span class="error">This email already exists</span>'
    ) {
      return false;
    }
    if (
      document.getElementById("spn-mobile-error").innerHTML ===
      '<span class="error">This mobile number already exists</span>'
    ) {
      return false;
    }

    showLoader("login_in_guest", "class");

    var qs = require("qs");
    var postObject = {
      app_id: appId,
      type: "web",
      customer_first_name: formPayload.check_firstname,
      customer_email: formPayload.check_email,
      customer_phone: formPayload.check_phone,
      site_url: this.props.globalsettings[0].result_set.client_site_url,
    };

    axios
      .post(apiUrl + "guestaccount/create", qs.stringify(postObject))
      .then((response) => {
        hideLoader("login_in_guest", "class");
        if (response.data.status === "ok") {
          window.scrollTo(0, 0);
          $.magnificPopup.close();

          var cust_birthdate = "";
          if (
            typeof response.data.result_set.customer_birthdate !==
              "undefined" &&
            response.data.result_set.customer_birthdate !== "null" &&
            response.data.result_set.customer_birthdate !== null &&
            response.data.result_set.customer_birthdate !== "" &&
            response.data.result_set.customer_birthdate !== "0000-00-00"
          ) {
            cust_birthdate = response.data.result_set.customer_birthdate;
          }

          /* set User cookie values - Start */
          cookie.save("UserId", response.data.result_set.customer_id, {
            path: "/",
          });
          cookie.save(
            "UserFname",
            response.data.result_set.customer_first_name !== ""
              ? response.data.result_set.customer_first_name
              : "",
            { path: "/" }
          );
          cookie.save(
            "UserLname",
            response.data.result_set.customer_last_name != ""
              ? response.data.result_set.customer_last_name
              : "",
            { path: "/" }
          );
          cookie.save("UserMobile", response.data.result_set.customer_phone, {
            path: "/",
          });
          cookie.save("UserEmail", response.data.result_set.customer_email, {
            path: "/",
          });
          cookie.save(
            "UserDefaultAddress",
            response.data.result_set.customer_address_name,
            { path: "/" }
          );
          cookie.save(
            "UserDefaultUnitOne",
            response.data.result_set.customer_address_line1,
            { path: "/" }
          );
          cookie.save(
            "UserDefaultUnitTwo",
            response.data.result_set.customer_address_line2,
            { path: "/" }
          );
          cookie.save(
            "UserDefaultPostalCode",
            response.data.result_set.customer_postal_code,
            { path: "/" }
          );
          cookie.save("UserBirthdate", cust_birthdate, { path: "/" });
          cookie.save("userAccountType", 1, { path: "/" });
          const { history } = this.props;
          var qs = require("qs");
          var postObject = {
            app_id: appId,
            reference_id: getReferenceID(),
            customer_id: response.data.result_set.customer_id,
            availability_id: this.state.defaultAvilTy,
          };

          axios
            .post(
              apiUrl + "cart/update_customer_info",
              qs.stringify(postObject)
            )
            .then((res) => {
              showAlert("Success", "Logged in Successfully!");
              $.magnificPopup.open({
                items: {
                  src: ".alert_popup",
                },
                type: "inline",
              });
              if (res.data.status === "ok") {
                if (cookie.load("loginpopupTrigger") === "fromcheckout") {
                  cookie.remove("loginpopupTrigger", { path: "/" });
                  history.push("/checkout");
                } else {
                  history.push("/myaccount");
                }
              } else {
                history.push("/myaccount");
              }
            });
        } else {
          if (response.data.form_error) {
            $(".guest-chk-error").html(
              '<div class="alert alert_danger" style="display:block">' +
                response.data.form_error +
                "</div>"
            );
          } else {
            $(".guest-chk-error").html(
              '<div class="alert alert_danger" style="display:block">' +
                response.data.message +
                "</div>"
            );
          }
        }
        return false;
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  closepopup(event) {
    event.preventDefault();

    $.magnificPopup.open({
      items: {
        src: "#order-popup",
      },
      type: "inline",
    });
  }

  close_popup(event) {
    event.preventDefault();
    $.magnificPopup.close();
  }

  gobckOutletpopup() {
    $.magnificPopup.open({
      items: {
        src: "#delevery-popup",
      },
      type: "inline",
    });
  }

  gobckPkupOutletpopup() {
    $.magnificPopup.open({
      items: {
        src: "#takeaway-popup",
      },
      type: "inline",
    });
  }

  changeAvailability(avilablity, e) {
    e.preventDefault();
    this.setState({ opentavilablity: false });

    // Changing Banner based on availability
    this.props.changeAvailabilitybasedBanner(avilablity);
    // Changing Banner based on availability

    if (this.state.defaultAvilTy !== avilablity) {
      this.setState(
        { defaultAvilTy: avilablity, seletedAvilablityId: avilablity },
        function () {
          cookie.save("defaultAvilablityId", avilablity, { path: "/" });
          this.setState({ showTimeSlot: "yes" }, function () {
            this.setState({ getDateTimeFlg: "yes" });
          });
        }
      );
    }
  }

  destroyCart(clear = "No") {
    this.props.destroyCartDetail();
    this.deleteOrderCookie(clear);
  }

  destroyCartShopeType() {
    this.props.destroyCartDetail();
    cookie.remove("cartsubtotal", { path: "/" });
    cookie.remove("cartid", { path: "/" });

    /* Delivery avilablity */
    /* cookie.remove("orderDateTime", { path: "/" });
    cookie.remove("deliveryDate", { path: "/" });
    cookie.remove("deliveryTime", { path: "/" }); */
    cookie.remove("unitNoOne", { path: "/" });
    cookie.remove("unitNoTwo", { path: "/" });

    cookie.remove("promotion_id", { path: "/" });
    cookie.remove("promotion_applied", { path: "/" });
    cookie.remove("promotion_code", { path: "/" });
    cookie.remove("promotion_delivery_charge_applied", { path: "/" });
    cookie.remove("promotion_amount", { path: "/" });
    cookie.remove("promotion_category", { path: "/" });
    cookie.remove("prmo_type", { path: "/" });

    /*Remove voucher*/
    cookie.remove("voucher_applied", { path: "/" });
    cookie.remove("voucher_code", { path: "/" });
    cookie.remove("voucher_amount", { path: "/" });

    cookie.remove("points_redeemed", { path: "/" });
    cookie.remove("points_used", { path: "/" });
    cookie.remove("points_amount", { path: "/" });
    cookie.remove("prmo_type", { path: "/" });
    location.reload();
  }

  deleteOrderCookie(clear = "Yes") {
    if (clear == "Yes") {
      cookie.remove("orderZoneId", { path: "/" });
      cookie.remove("orderOutletId", { path: "/" });
    }

    removeOrderDateTime();
    removePromoCkValue();
    cookie.remove("orderPaymentMode", { path: "/" });
    cookie.remove("orderTableNo", { path: "/" });
    cookie.remove("product_remarks", { path: "/" });
    cookie.remove("orderOutletName", { path: "/" });
    cookie.remove("carttotalitems", { path: "/" });
    cookie.remove("cartsubtotal", { path: "/" });
    cookie.remove("cartid", { path: "/" });
    cookie.remove("firstNavigation", { path: "/" });

    /* Delivery avilablity */
    cookie.remove("orderDateTime", { path: "/" });
    cookie.remove("deliveryDate", { path: "/" });
    cookie.remove("deliveryTime", { path: "/" });
    cookie.remove("unitNoOne", { path: "/" });
    cookie.remove("unitNoTwo", { path: "/" });

    cookie.remove("promotion_id", { path: "/" });
    cookie.remove("promotion_applied", { path: "/" });
    cookie.remove("promotion_code", { path: "/" });
    cookie.remove("promotion_delivery_charge_applied", { path: "/" });
    cookie.remove("promotion_amount", { path: "/" });
    cookie.remove("promotion_category", { path: "/" });
    cookie.remove("prmo_type", { path: "/" });

    /*Remove voucher*/
    cookie.remove("voucher_applied", { path: "/" });
    cookie.remove("voucher_code", { path: "/" });
    cookie.remove("voucher_amount", { path: "/" });

    cookie.remove("points_redeemed", { path: "/" });
    cookie.remove("points_used", { path: "/" });
    cookie.remove("points_amount", { path: "/" });
    cookie.remove("prmo_type", { path: "/" });
  }

  /* find Zone*/
  findOutletBasedZone(first, availability) {
    var postalcode = "";

    if (first) {
      if (first == 2) {
        if (availability === deliveryId) {
          postalcode = cookie.load("deliveryPostalCode");
        } else {
          postalcode = cookie.load("orderPostalCode");
        }
      } else {
        postalcode = $("#postal_code").val();
      }
    } else {
      postalcode = $("#postal_code1").val();
    }
    console.log(postalcode, "postalcodepostalcode");
    if (
      postalcode !== "" &&
      typeof postalcode !== undefined &&
      typeof postalcode !== "undefined"
    ) {
      if (postalcode.length < 5) {
        $(".postal_error").html(
          '<span class="error">Please enter valid postal code.</span>'
        );
        return false;
      }
    } else {
      if (availability === deliveryId) {
        $.magnificPopup.open({
          items: {
            src: "#delevery-postcode-popup",
          },
          type: "inline",
        });
        return false;
      }
    }

    showLoader("delivery_submit_cls", "class");
    if (availability === deliveryId) {
      axios
        .all([
          axios.get(
            apiUrlV2 +
              "outlets/findOutletZone?app_id=" +
              appId +
              "&availability_id=" +
              availability +
              "&postal_code=" +
              postalcode +
              "&shop_type=" +
              this.state.shopType
          ),
          axios.get(
            apiUrlV2 +
              "settings/chkTimeslotIsAvaiable?app_id=" +
              appId +
              "&availability_id=" +
              availability /*  +
            "&outletId=" +
            outletIdTxt */
          ),
        ])
        .then(
          axios.spread((res, timeslt) => {
            hideLoader("delivery_submit_cls", "class");

            var deliveryInfo = [];
            /* Success response */
            if (res.data.status === "ok") {
              $.magnificPopup.close();

              var orderDeliveryAddress =
                res.data.result_set.postal_code_information.zip_buno +
                " " +
                res.data.result_set.postal_code_information.zip_sname;
              var orderHandled =
                stripslashes(res.data.result_set.outlet_name) +
                ", Crew will be seeing you in " +
                res.data.result_set.outlet_delivery_timing +
                " Minutes";

              deliveryInfo["orderZoneId"] = res.data.result_set.zone_id;
              deliveryInfo["orderOutletId"] = res.data.result_set.outlet_id;
              deliveryInfo["orderOutletName"] = stripslashes(
                res.data.result_set.outlet_name
              );
              deliveryInfo["orderPostalCode"] =
                res.data.result_set.postal_code_information.zip_code;
              deliveryInfo["orderTAT"] =
                res.data.result_set.outlet_delivery_timing;
              deliveryInfo["orderDeliveryAddress"] = orderDeliveryAddress;
              deliveryInfo["orderHandled"] = orderHandled;
              deliveryInfo["defaultAvilablityId"] = availability;

              var unitNum = this.showUnitNum(
                res.data.result_set.outlet_unit_number1,
                res.data.result_set.outlet_unit_number2
              );

              var orderHandledText =
                res.data.result_set.outlet_address_line1 +
                " " +
                res.data.result_set.outlet_address_line2 +
                ", " +
                unitNum +
                " Singapore " +
                postalcode;

              deliveryInfo["orderHandledByText"] = orderHandledText;
              /* +
                  " Singapore " +
                  res.data.result_set.postal_code_information.zip_code, */
              this.setState({
                deliveryInfo: deliveryInfo,
                seletedAvilablityId: availability,
                defaultAvilTy: availability,
                seletedOutletId: res.data.result_set.outlet_id,
                order_tat_time: res.data.result_set.outlet_delivery_timing,
                orderHandled: orderHandled,
                orderDeliveryAddress: orderDeliveryAddress,
                orderPostalCode:
                  res.data.result_set.postal_code_information.zip_code,
              });

              if (this.state.selectPostalCode === "Yes") {
                cookie.save("orderPostalCode", postalcode, { path: "/" });
                if (availability === deliveryId) {
                  cookie.save("deliveryPostalCode", postalcode, { path: "/" });
                  cookie.save("orderDeliveryAddress", orderDeliveryAddress, {
                    path: "/",
                  });
                  cookie.save("defaultAvilablityId", deliveryId, {
                    path: "/",
                  });
                } else {
                  cookie.save("defaultAvilablityId", pickupId, {
                    path: "/",
                  });
                }
                cookie.save("orderZoneId", deliveryInfo["orderZoneId"], {
                  path: "/",
                });

                var orderDate =
                  cookie.load("deliveryDate") !== "" &&
                  typeof cookie.load("deliveryDate") !== undefined &&
                  typeof cookie.load("deliveryDate") !== "undefined"
                    ? cookie.load("deliveryDate")
                    : "";
                if (
                  this.props.match.params.slugRestaurant !== "" &&
                  typeof this.props.match.params.slugRestaurant !== undefined &&
                  typeof this.props.match.params.slugRestaurant !==
                    "undefined" &&
                  orderDate !== ""
                ) {
                  location.reload();
                }
                if (orderDate !== "") {
                  return false;
                }
              }
              /* Success time slot response */
              if (timeslt.data.status === "success") {
                this.setState({ showTimeSlot: "yes" }, function () {
                  this.setState({ getDateTimeFlg: "yes" });
                });

                //removeOrderDateTime();
                removePromoCkValue();

                /*  $.magnificPopup.open({
                  items: {
                    src: "#awesome-popup",
                  },
                  type: "inline",
                }); */
              } else {
                $.magnificPopup.open({
                  items: {
                    src: "#outlet-error-popup",
                  },
                  type: "inline",
                });
              }
            }

            /* Error response */
            if (res.data.status === "error") {
              this.setState({ deliveryInfo: deliveryInfo });

              $.magnificPopup.close();
              $.magnificPopup.open({
                items: {
                  src: "#error-postal-popup",
                },
                type: "inline",
              });

              if (first === 0) {
                var mgsTxt =
                  res.data.message !== ""
                    ? res.data.message
                    : "Please enter valid postal code.";
                $(".postal_error").html(
                  '<span class="error">' + mgsTxt + "</span>"
                );
              }
            }
          })
        );
    } else {
      axios
        .all([
          axios.get(
            apiUrl +
              "outlets/pickup_outlets?app_id=" +
              appId +
              "&availability=" +
              availability
          ),
          axios.get(
            apiUrlV2 +
              "settings/chkTimeslotIsAvaiable?app_id=" +
              appId +
              "&availability_id=" +
              availability /*  +
          "&outletId=" +
          outletIdTxt */
          ),
        ])
        .then(
          axios.spread((res, timeslt) => {
            hideLoader("delivery_submit_cls", "class");

            var pickupInfo = [];
            /* Success response */
            if (res.data.status === "ok") {
              $.magnificPopup.close();
              var resultSet = res.data.result_set[0];
              var orderDeliveryAddress =
                resultSet.outlet_unit_number1 +
                " " +
                resultSet.outlet_unit_number2;
              var orderHandled =
                stripslashes(resultSet.outlet_name) +
                ", Crew will be seeing you in " +
                resultSet.outlet_pickup_tat +
                " Minutes";

              pickupInfo["orderOutletId"] = resultSet.outlet_id;
              pickupInfo["orderOutletName"] = stripslashes(
                resultSet.outlet_name
              );
              pickupInfo["orderPostalCode"] = resultSet.outlet_postal_code;
              pickupInfo["orderTAT"] = resultSet.outlet_pickup_tat;

              pickupInfo["orderHandled"] = orderHandled;
              pickupInfo["defaultAvilablityId"] = availability;

              var unitNum = this.showUnitNum(
                resultSet.outlet_unit_number1,
                resultSet.outlet_unit_number2
              );

              var orderHandledText =
                resultSet.outlet_address_line1 +
                " " +
                resultSet.outlet_address_line2 +
                ", " +
                unitNum +
                " Singapore " +
                postalcode;

              pickupInfo["orderHandledByText"] = orderHandledText;

              this.setState({
                pickupInfo: pickupInfo,
                seletedAvilablityId: availability,
                defaultAvilTy: availability,
                seletedOutletId: res.data.result_set.oa_outlet_id,
                order_tat_time: resultSet.outlet_pickup_tat,
                orderHandled: orderHandled,
                orderDeliveryAddress:
                  orderDeliveryAddress +
                  " Singapore " +
                  resultSet.outlet_postal_code,
              });

              if (this.state.selectPostalCode === "Yes") {
                cookie.save("orderPostalCode", postalcode, { path: "/" });

                cookie.save("defaultAvilablityId", pickupId, {
                  path: "/",
                });

                this.props.sateValChange("selectPostalCode", "Yes");
                this.props.sateValChange("postalcode", postalcode);

                if (
                  this.props.match.params.slugRestaurant !== "" &&
                  typeof this.props.match.params.slugRestaurant !== undefined &&
                  typeof this.props.match.params.slugRestaurant !== "undefined"
                ) {
                  location.reload();
                }
                return false;
              }
              /* Success time slot response */
              if (timeslt.data.status === "success") {
                this.setState({ getDateTimeFlg: "yes" });

                //removeOrderDateTime();
                removePromoCkValue();

                $.magnificPopup.open({
                  items: {
                    src: "#awesome-popup",
                  },
                  type: "inline",
                });
              } else {
                $.magnificPopup.open({
                  items: {
                    src: "#outlet-error-popup",
                  },
                  type: "inline",
                });
              }
            }

            /* Error response */
            if (res.data.status === "error") {
              this.setState({ deliveryInfo: deliveryInfo });

              $.magnificPopup.close();
              $.magnificPopup.open({
                items: {
                  src: "#error-postal-popup",
                },
                type: "inline",
              });

              if (first === 0) {
                var mgsTxt =
                  res.data.message !== ""
                    ? res.data.message
                    : "Please enter valid postal code.";
                $(".postal_error").html(
                  '<span class="error">' + mgsTxt + "</span>"
                );
              }
            }
          })
        );
    }
  }

  /* Select outlet */
  selectOutlet(first, availability) {
    if (first) {
      var postalcode = $("#postal_code").val();
    } else {
      var postalcode = $("#postal_code1").val();
    }

    if (postalcode.length < 5) {
      $(".postal_error").html(
        '<span class="error">Please enter valid postal code.</span>'
      );
      return false;
    }

    showLoader("delivery_submit_cls", "class");

    axios
      .get(
        apiUrl +
          "outlets/find_outlet?skip_timing=Yes&app_id=" +
          appId +
          "&availability_id=" +
          availability +
          "&postal_code=" +
          postalcode
      )
      .then((res) => {
        hideLoader("delivery_submit_cls", "class");

        /* Success response */
        if (res.data.status === "ok") {
          $.magnificPopup.close();
          var orderDeliveryAddress =
            res.data.result_set.postal_code_information.zip_buno +
            " " +
            res.data.result_set.postal_code_information.zip_sname;
          var orderHandled =
            stripslashes(res.data.result_set.outlet_name) +
            ", Crew will be seeing you in " +
            res.data.result_set.outlet_delivery_timing +
            " Minutes";
          this.setState({
            orderDeliveryAddress:
              orderDeliveryAddress +
              " Singapore " +
              res.data.result_set.postal_code_information.zip_code,
          });
          this.setState({ orderHandled: orderHandled });

          cookie.save("orderOutletId", res.data.result_set.outlet_id, {
            path: "/",
          });
          cookie.save(
            "orderOutletName",
            stripslashes(res.data.result_set.outlet_name),
            { path: "/" }
          );
          cookie.save(
            "orderPostalCode",
            res.data.result_set.postal_code_information.zip_code,
            { path: "/" }
          );
          // cookie.save("orderTAT", res.data.result_set.outlet_delivery_timing, {
          //   path: "/",
          // });
          cookie.save("orderDeliveryAddress", orderDeliveryAddress, {
            path: "/",
          });
          cookie.save("orderHandled", orderHandled, { path: "/" });
          cookie.save("defaultAvilablityId", availability, { path: "/" });
          this.setState({ defaultAvilTy: availability });

          var orderHandledText =
            res.data.result_set.outlet_address_line1 +
            " " +
            res.data.result_set.outlet_address_line2 +
            ", Singapore " +
            postalcode;
          cookie.save("orderHandledByText", orderHandledText, { path: "/" });

          removeOrderDateTime();
          removePromoCkValue();

          $.magnificPopup.open({
            items: {
              src: "#awesome-popup",
            },
            type: "inline",
          });
        }

        /* Error response */
        if (res.data.status === "error") {
          $.magnificPopup.close();
          $.magnificPopup.open({
            items: {
              src: "#error-postal-popup",
            },
            type: "inline",
          });

          if (first === 0) {
            var mgsTxt =
              res.data.message !== ""
                ? res.data.message
                : "Please enter valid postal code.";
            $(".postal_error").html(
              '<span class="error">' + mgsTxt + "</span>"
            );
          }
        }
      });
  }

  gotoProducts() {
    if (
      cookie.load("orderOutletId") == undefined ||
      cookie.load("orderOutletId") == ""
    ) {
      $(".outlet_error").html(
        '<span class="error"> Please choose one outlet.</span>'
      );
    } else {
      $.magnificPopup.close();
      if (cookie.load("popuptriggerFrom") === "FeaturedPro") {
        cookie.remove("popuptriggerFrom", { path: "/" });
        this.props.history.push("/");
      } else {
        this.props.history.push("/food");
      }
    }
  }

  selectDatetm() {
    /*  var seletedOutletId = this.state.seletedOutletId;
    var pickupInfo = this.state.pickupInfo;
    if (seletedOutletId !== "" && Object.keys(pickupInfo).length > 0) { */
    showLoader("takeaway-btn-act", "class");

    axios
      .get(
        apiUrlV2 +
          "settings/chkTimeslotIsAvaiable?app_id=" +
          appId +
          "&availability_id=" +
          pickupId /*+
             "&outletId=" +
            seletedOutletId */
      )
      .then((res) => {
        hideLoader("takeaway-btn-act", "class");

        /* Success response */
        if (res.data.status === "success") {
          this.setState({ getDateTimeFlg: "yes" });

          removeOrderDateTime();
          removePromoCkValue();

          $.magnificPopup.open({
            items: {
              src: "#awesome-popup",
            },
            type: "inline",
          });
        } else {
          $.magnificPopup.open({
            items: {
              src: "#outlet-error-popup",
            },
            type: "inline",
          });
        }
      });
    /*  } else {
      $(".outlet_error").html(
        '<span class="error"> Please choose one outlet.</span>'
      );
    } */
  }

  chooseAvailabilityFun(availability) {
    var defaultAvilTy =
      cookie.load("defaultAvilablityId") !== "" &&
      typeof cookie.load("defaultAvilablityId") !== undefined &&
      typeof cookie.load("defaultAvilablityId") !== "undefined"
        ? cookie.load("defaultAvilablityId")
        : "";
    var orderPostalCode = cookie.load("orderPostalCode");
    var deliveryPostalCode = cookie.load("deliveryPostalCode");

    if (
      defaultAvilTy !== "" &&
      typeof defaultAvilTy !== undefined &&
      typeof defaultAvilTy !== "undefined"
    ) {
      if (availability === deliveryId) {
        if (
          deliveryPostalCode !== "" &&
          typeof deliveryPostalCode !== undefined &&
          typeof deliveryPostalCode !== "undefined"
        ) {
        } else {
          $.magnificPopup.open({
            items: {
              src: "#delevery-postcode-popup",
            },
            type: "inline",
          });
          return false;
        }
      }
      cookie.remove("defaultAvilablityId", { path: "/" });
      cookie.save("defaultAvilablityId", availability, { path: "/" });
      this.setState({ defaultAvilTy: availability }, function () {
        showLoader("innerproduct", "class");
        axios
          .get(
            apiUrl +
              "cart/update_availability?app_id=" +
              appId +
              "&reference_id=" +
              getReferenceID() +
              "&customer_id=" +
              cookie.load("UserId") +
              "&availability_id=" +
              availability
          )
          .then((res) => {});
      });
      //this.props.history.push("events/xmas");
      this.props.history.push("/food");
      return false;
    }

    if (
      defaultAvilTy !== "" &&
      typeof defaultAvilTy !== undefined &&
      typeof defaultAvilTy !== "undefined" &&
      defaultAvilTy === availability
    ) {
      if (availability === deliveryId) {
        if (
          orderPostalCode === "" ||
          typeof orderPostalCode === undefined ||
          typeof orderPostalCode === "undefined"
        ) {
          $.magnificPopup.open({
            items: {
              src: "#delevery-postcode-popup",
            },
            type: "inline",
          });
          return false;
        }
      } else if (availability === pickupId) {
        if (
          orderPostalCode === "" ||
          typeof orderPostalCode === undefined ||
          typeof orderPostalCode === "undefined"
        ) {
          this.setState({ seletedAvilablityId: pickupId });
          return false;
        }
      }
      //this.props.history.push("events/xmas");
      this.props.history.push("/food");
      return false;
    } else {
      if (this.state.cartitemcount > 0) {
        $.magnificPopup.open({
          items: {
            src: "#warning-popup",
          },
          type: "inline",
        });
        return false;
      }
    }

    var popupIdtxt = "";
    if (availability === deliveryId) {
      $(".delivery_outletpoup").find(".outlet_error").html("");
      popupIdtxt = "#delevery-postcode-popup";
    } else if (availability === pickupId) {
      this.setState({ seletedAvilablityId: pickupId });
      //popupIdtxt = "#takeaway-popup";
    }
    if (popupIdtxt !== "") {
      $.magnificPopup.open({
        items: {
          src: popupIdtxt,
        },
        type: "inline",
      });
    }
  }

  selectDlyOutlet() {
    if (this.state.delivery_outlet_id === "") {
      $(".delivery_outletpoup")
        .find(".outlet_error")
        .html('<span class="error"> Please choose one outlet.</span>');
    } else {
      $.magnificPopup.close();
      $.magnificPopup.open({
        items: {
          src: "#delevery-postcode-popup",
        },
        type: "inline",
      });
    }
  }

  handleKeyPress = (event) => {
    var value = event.target.value.toLowerCase();
    let matches = "";
    let pickupOutletsList = this.state.pickupOutletsList;
    
    if (value !== "") {
      matches = pickupOutletsList.filter(function (item) {
        if (item.outlet_name.toLowerCase().indexOf(value) >= 0) {
          return item;
        }
      });
    } else {
      matches = pickupOutletsList;
    }

    
    this.setState(
      {
        pickupOutlets: matches,
        openOutletSearch: 1,
        searchOutletKey: 1,
      },
      function () {
        this.loadOutletsList();
      }
    );
  };
  clearOutletSearch = () => {
    /*  var value = event.target.value.toLowerCase(),
      matches = this.state.pickupOutletsList.filter(function (item) {
        return (
          item.outlet_address_line1.substring(0, value.length).toLowerCase() ===
            value ||
          item.outlet_postal_code.substring(0, value.length).toLowerCase() ===
            value ||
          stripslashes(item.outlet_name)
            .substring(0, value.length)
            .toLowerCase() === value
        );
      }); */
    $("#takeway_outlet").val("");
    this.setState({
      pickupOutlets: this.state.pickupOutletsList,
      openOutletSearch: 0,
      searchOutletKey: "",
    });
  };

  viewProducts(outlet_id, outlet_slug, outlet_location_id, event) {
    event.preventDefault();
    var shopType = "food";
    if (this.state.urltype === "/food" || this.state.urltype === "/") {
      shopType = "food";
    } else if (this.state.urltype === "/retail") {
      shopType = "retail";
    } else {
      shopType = "events/" + this.state.eventSlug;
    }
    cookie.remove("orderOutletId", { path: "/" });
    cookie.remove("outlet_location_id", { path: "/" });
    cookie.save("orderOutletId", outlet_id, { path: "/" });
    cookie.save("outletLocationId", outlet_location_id, { path: "/" });

    location.href = siteURL + shopType + "/" + outlet_slug;
    //this.props.history.push("/" + shopType + "/" + outlet_slug);
  }

  /* load outlets  */
  loadOutletsList() {
    var puckupOutletList = "";
    if (Object.keys(this.state.pickupOutlets).length > 0) {
      console.log(this.state.pickupOutlets, "pickupOutletspickupOutlets");
      puckupOutletList = this.state.pickupOutlets.map((loaddata, index) => (
        <li key={index} className={this.activeOutlet(loaddata.outlet_id)}>
          <a
            href="/"
            onClick={this.viewProducts.bind(
              this,
              loaddata.oa_outlet_id,
              loaddata.outlet_slug,
              loaddata.outlet_location_id
            )}
          >
            <p>
              <span>{stripslashes(loaddata.outlet_name)}</span>
              <span>Last Pickup at 12.30pm</span>
            </p>
          </a>
        </li>
      ));
    } else {
      puckupOutletList = (
        <li className="no-outlet-found">
          <a>No Outlet found</a>
        </li>
      );
    }
    this.setState({ puckupOutletList: puckupOutletList });
  }

  activeOutlet(outletID) {
    var seletedOutletId = this.state.seletedOutletId;
    var pickupInfo = this.state.pickupInfo;
    var actTxt =
      parseInt(seletedOutletId) === parseInt(outletID) &&
      Object.keys(pickupInfo).length > 0
        ? "active"
        : "";
    return actTxt;
  }

  handleKeyPressDly = (event) => {
    var value = event.target.value.toLowerCase(),
      matches = this.state.deliveryOutletsList.filter(function (item) {
        return (
          item.outlet_address_line1.substring(0, value.length).toLowerCase() ===
            value ||
          item.outlet_postal_code.substring(0, value.length).toLowerCase() ===
            value ||
          stripslashes(item.outlet_name)
            .substring(0, value.length)
            .toLowerCase() === value
        );
      });

    this.setState({ deliveryOutlets: matches });
  };

  showUnitNum(unit1, unit2) {
    unit1 = typeof unit1 !== "undefined" ? unit1 : "";
    unit2 = typeof unit2 !== "undefined" ? unit2 : "";

    if (unit1 !== "") {
      var unitNo = unit2 !== "" ? unit1 + " - " + unit2 : unit1;
    } else {
      var unitNo = unit2;
    }

    return unitNo !== "" ? "#" + unitNo : "";
  }

  /* load delivery outlets  */
  loadDeliveryOutletsList() {
    if (Object.keys(this.state.deliveryOutlets).length > 0) {
      return this.state.deliveryOutlets.map((loaddata, index) => (
        <li key={index} className={this.activeDlyOutlet(loaddata.outlet_id)}>
          <a href="/" onClick={this.deliveryOutlet.bind(this, loaddata)}>
            {stripslashes(loaddata.outlet_name)},{" "}
            {loaddata.outlet_address_line1},{" "}
            {this.showUnitNum(
              loaddata.outlet_unit_number1,
              loaddata.outlet_unit_number2
            )}
            , Singapore {loaddata.outlet_postal_code}
          </a>
        </li>
      ));
    } else {
      return (
        <li>
          <a>No Outlet found</a>
        </li>
      );
    }
  }

  activeDlyOutlet(outletID) {
    var orderOutletId =
      this.state.delivery_outlet_id !== ""
        ? this.state.delivery_outlet_id
        : cookie.load("orderOutletId");

    return orderOutletId === outletID ? "active" : "";
  }

  /* pick outlet */
  pickOutlet(loaddata, event) {
    if (event !== "" && event !== undefined) {
      event.preventDefault();
    }

    this.selectDatetm();
    var unitNum = this.showUnitNum(
      loaddata.outlet_unit_number1,
      loaddata.outlet_unit_number2
    );

    var orderHandled =
      stripslashes(loaddata.outlet_name) +
      " " +
      loaddata.outlet_address_line1 +
      " " +
      loaddata.outlet_address_line2 +
      ", " +
      unitNum +
      " Singapore " +
      loaddata.outlet_postal_code;

    var pickupInfo = [];
    pickupInfo["orderOutletId"] = loaddata.outlet_id;
    pickupInfo["orderOutletName"] = stripslashes(loaddata.outlet_name);
    pickupInfo["orderPostalCode"] = loaddata.outlet_postal_code;
    pickupInfo["orderTAT"] = loaddata.outlet_pickup_tat;
    pickupInfo["orderHandled"] = orderHandled;
    pickupInfo["orderHandledByText"] = orderHandled;
    pickupInfo["defaultAvilablityId"] = pickupId;

    this.setState(
      {
        pickupInfo: pickupInfo,
        seletedAvilablityId: pickupId,
        seletedOutletId: loaddata.outlet_id,
        order_tat_time: loaddata.outlet_pickup_tat,
        orderHandled: orderHandled,
      },
      function () {
        this.selectDatetm();
      }.bind(this)
    );
  }

  pickWithoutOutlet() {
    this.selectDatetm();
  }

  deliveryOutlet(loaddata, event) {
    event.preventDefault();
    $(".delivery_outletpoup").find(".outlet_error").html("");
    this.setState(
      { delivery_outlet_id: loaddata.outlet_id },
      function () {
        this.selectDlyOutlet();
      }.bind(this)
    );
  }

  trgContinuBtn(idTxt) {
    $("#" + idTxt).trigger("click");
  }

  componentDidMount() {
    this.props.getStaticBlock();

    $(".custom_close").click(function (e) {
      e.preventDefault();
      $(".custom_alertcls, .custom_center_alertcls").hide();
    });
    $("html, body").animate(
      {
        scrollTop: $("body").offset().top,
      },
      100
    );
    $(".test-popup-link").magnificPopup({
      type: "image",
      showCloseBtn: true,
      verticalFit: true,
      callbacks: {
        change: function () {
          this.wrap.addClass("awardpopup");
        },
      },
      // other options
    });

    this.props.getMenuData("kloudkitchen-header-menu");
    if ($(".open-popup-link").length > 0) {
      $(".open-popup-link").magnificPopup({
        type: "inline",
        midClick: true,
      });
    }

    if ($(".trigger_login").length > 0) {
      $(".trigger_login").magnificPopup({
        type: "inline",
        midClick: true,
      });
    }

    /* Input lable animation */
    if ($(".input-focus").length > 0) {
      $(".input-focus").focus(function () {
        $(this).parents(".focus-out").addClass("focused");
      });
      $(".input-focus").blur(function () {
        var inputValue = $(this).val();
        if (inputValue == "") {
          $(this).removeClass("filled");
          $(this).parents(".focus-out").removeClass("focused");
        } else {
          $(this).addClass("filled");
        }
      });
    }

    if ($(".hsearch_trigger").length > 0) {
      $(".hsearch_trigger").click(function (e) {
        e.preventDefault();
        e.stopPropagation();
        $(".search_result").hide();
        $(this).toggleClass("active");
        $(".hsearch_sec").toggleClass("open");
        $(".hsearch_sec .form-control").focus();
      });
      $(document).click(function (e) {
        if (
          !$(e.target).is(".hsearch_trigger, .hsearch_sec, .hsearch_sec * ")
        ) {
          if ($(".hsearch_sec").is(":visible")) {
            $(".hsearch_sec").removeClass("open");
            $(".hsearch_trigger").removeClass("active");
            $(".hsearch_sec .form-control").blur();
          }
        }
      });
    }

    if ($(".hsearch_trigger_mbl").length > 0) {
      $(".hsearch_trigger_mbl").click(function (e) {
        e.preventDefault();
        e.stopPropagation();
        $(".search_result_mbl").hide();
        $(this).toggleClass("active");
        $(".hsearch_sec_mbl").toggleClass("open");
        $(".hsearch_sec_mbl .form-control").focus();
      });
      $(document).click(function (e) {
        if (
          !$(e.target).is(
            ".hsearch_trigger_mbl, .hsearch_sec_mbl, .hsearch_sec_mbl * "
          )
        ) {
          if ($(".hsearch_sec_mbl").is(":visible")) {
            $(".hsearch_sec_mbl").removeClass("open");
            $(".hsearch_trigger_mbl").removeClass("active");
            $(".hsearch_sec_mbl .form-control").blur();
          }
        }
      });
    }

    if ($(".mobile_mainacc_menutrigger").length > 0) {
      $(".mobile_mainacc_menutrigger").click(function (e) {
        e.stopPropagation();
        if ($(".mobile_mainacc_menulist").is(":visible")) {
          $(".mobile_mainacc_menulist").hide();
        } else {
          $(".mobile_mainacc_menulist").show();
        }
      });
      $(document).click(function (e) {
        if (!$(e.target).is(".mobile_account_item, .mobile_account_item * ")) {
          if ($(".mobile_mainacc_menulist").is(":visible")) {
            $(".mobile_mainacc_menulist").hide();
          }
        }
      });
    }

    /*NEW MENU SECTION*/
    if ($(".mobile_account_menu_click").length > 0) {
      $(".mobile_account_menu_click").click(function (e) {
        e.preventDefault();
        e.stopPropagation();
        $(".mobile_account_menu").slideToggle();
      });
      $(document).click(function (e) {
        if (!$(e.target).is(".arrow_myaccount, .mobile_account_menu_col")) {
          if ($(".mobile_account_menu").is(":visible")) {
            $(".mobile_account_menu").slideToggle();
          }
        }
      });
    }

    /* Mobile header menu */
    $("body").on("click", ".mobile-menu-more", function () {
      $(this).toggleClass("active");
      $(".product-mobile-menu").toggleClass("active");
    });
    $("body").on("click", ".product-mobile-menu", function () {
      $("mobile-menu-more").toggleClass("active");
      $(this).toggleClass("active");
    });

    $(document).click(function (e) {
      if (!$(e.target).is(".product-mobile-menu")) {
        if ($(".product-mobile-menu").hasClass("open")) {
          $(".hcategory_menu").removeClass("open");
          $(".mobile-menu-more").removeClass("active");
        }
      }
    });

    $(".disbl_href_action").click(function (e) {
      e.preventDefault();
    });

    /*this.getOptions.bind(this,"mill");*/
    //  this.getSearchProductList();
  }

  componentDidUpdate() {}

  getOptions = (input) => {
    var searchProResult = this.state.searchProResult;
    var searchResult = [];
    /*if (Object.keys(searchProResult).length > 0) {*/
    /*searchProResult.map((loadData) =>
			  searchResult.push({ value: loadData.value, label: loadData.label })
			 );*/
    searchResult.push({ value: "wqewrr", label: "fish cury" });
    searchResult.push({ value: "werew3", label: "fish cury2" });
    console.log("wlll");
    console.log(searchResult);
    console.log("input");
    console.log(input);
    return { options: searchResult };
    /*}*/
  };

  ViewProducts(event) {
    var productSlug = event.value;
    //this.props.history.push("/products/cat-ftrpro/slug-ftrpro/" + productSlug);
  }

  checkActiveDivHd(avlType) {
    var clsTxt = "ordericon_link ";
    var availability = this.state.defaultAvilTy;
    var orderOutletId = cookie.load("orderOutletId");
    if (
      availability == avlType &&
      orderOutletId !== "" &&
      orderOutletId !== undefined
    ) {
      clsTxt += "active";
    }
    return clsTxt;
  }

  myAccountAction() {
    var currenturl = window.location.href;
    var substringtxt = "myaccount";

    if (currenturl.includes(substringtxt) !== true) {
      return (
        <div>
          <Link to={"/myaccount"} className="myacunt_act" title="My Account">
            {" "}
            My Account{" "}
          </Link>
          <span className="mobile_mainacc_menutrigger"></span>
        </div>
      );
    } else {
      return (
        <div>
          <a className="myacunt_act disbl_href_action" href="/">
            {" "}
            My Account{" "}
          </a>
          <span className="mobile_mainacc_menutrigger"></span>
        </div>
      );
    }
  }

  changPostalValue(type, pstVl) {
    if (type === 1) {
      $("#postal_code").val(pstVl);
    } else {
      $("#postal_code1").val(pstVl);
    }
  }

  userAddressList(typeTxt) {
    if (this.state.secondaryaddresslist.length > 0) {
      var addListTxt = this.state.secondaryaddresslist.map((addr, index) => (
        <div className="address_linfo" key={typeTxt + "-" + index}>
          <div className="custom_radio">
            <input
              type="radio"
              name={"address_chk" + typeTxt}
              value={addr.postal_code}
              className="address_chk"
              onChange={this.changPostalValue.bind(
                this,
                typeTxt,
                addr.postal_code
              )}
            />
            <span>
              {addressFormat(
                addr.unit_code,
                addr.unit_code2,
                addr.address,
                addr.country,
                addr.postal_code
              )}
            </span>
          </div>
        </div>
      ));

      return (
        <div>
          {addListTxt}
          <div className="address_linfo" key={typeTxt + "dfl"}>
            <div className="custom_radio">
              <input
                type="radio"
                name={"address_chk" + typeTxt}
                value=""
                defaultChecked={true}
                className="address_chk"
                onChange={this.changPostalValue.bind(this, typeTxt, "")}
              />
              <span>Enter New Address</span>
            </div>
          </div>
        </div>
      );
    } else {
      return "";
    }
  }

  setdateTimeFlg = (field, value) => {
    if (field == "tmflg") {
      this.setState({ getDateTimeFlg: value });
    } else if (field == "ordDate") {
      var ordTime = "";
      $(".ordrdatetime_error").html("");
      this.setState({ seleted_ord_date: value, seleted_ord_time: ordTime });
    } else if (field == "ordTime") {
      $(".ordrdatetime_error").html("");
      this.setState({ seleted_ord_time: value });
    } else if (field == "triggerErrorPopup") {
      $.magnificPopup.open({
        items: {
          src: "#outlet-error-popup",
        },
        type: "inline",
      });
    }
  };

  setOrderOutletDateTimeData() {
    var seletedOrdDate = this.state.seleted_ord_date;
    var seletedOrdTime = this.state.seleted_ord_time;
    if (
      seletedOrdDate !== "" &&
      seletedOrdTime !== "" &&
      seletedOrdDate !== null &&
      seletedOrdTime !== null
    ) {
      var orderInfoData =
        this.state.seletedAvilablityId === pickupId
          ? this.state.pickupInfo
          : this.state.deliveryInfo;
      /* if (Object.keys(orderInfoData).length > 0) { */
      var OrderDate = format(seletedOrdDate, "yyyy-MM-dd");
      var OrderHours = seletedOrdTime.getHours();
      var OrderMunts = seletedOrdTime.getMinutes();
      var OrderSecnd = seletedOrdTime.getSeconds();
      var orderDateTime = new Date(OrderDate);
      orderDateTime.setHours(OrderHours);
      orderDateTime.setMinutes(OrderMunts);
      orderDateTime.setSeconds(OrderSecnd);

      var deliveryDate = format(seletedOrdDate, "dd/MM/yyyy");
      var deliveryTime =
        this.convPad(OrderHours) +
        ":" +
        this.convPad(OrderMunts) +
        ":" +
        this.convPad(OrderSecnd);

      cookie.save("orderDateTime", orderDateTime, { path: "/" });
      cookie.save("deliveryDate", deliveryDate, { path: "/" });
      cookie.save("deliveryTime", deliveryTime, { path: "/" });

      /*  if (this.state.seletedAvilablityId === deliveryId) {
          cookie.save("orderZoneId", orderInfoData["orderZoneId"], {
            path: "/",
          });
          cookie.save(
            "orderDeliveryAddress",
            orderInfoData["orderDeliveryAddress"],
            { path: "/" }
          );
        }

        cookie.save("orderOutletId", orderInfoData["orderOutletId"], {
          path: "/",
        });
        cookie.save("orderOutletName", orderInfoData["orderOutletName"], {
          path: "/",
        });

        cookie.save("orderPostalCode", orderInfoData["orderPostalCode"], {
          path: "/",
        });
        if (this.state.seletedAvilablityId === deliveryId) {
          cookie.save("deliveryPostalCode", orderInfoData["orderPostalCode"], {
            path: "/",
          });
        }
        cookie.save("orderTAT", orderInfoData["orderTAT"], { path: "/" });
        cookie.save("orderHandled", orderInfoData["orderHandled"], {
          path: "/",
        });
        cookie.save(
          "defaultAvilablityId",
          orderInfoData["defaultAvilablityId"],
          { path: "/" }
        );
        this.setState({ defaultAvilTy: orderInfoData["defaultAvilablityId"] });
        cookie.save("orderHandledByText", orderInfoData["orderHandledByText"], {
          path: "/",
        }); */
      $.magnificPopup.close();
      if (
        this.props.match.params.slugRestaurant !== "" &&
        typeof this.props.match.params.slugRestaurant !== undefined &&
        typeof this.props.match.params.slugRestaurant !== "undefined"
      ) {
        location.reload();
        return false;
      }

      if (cookie.load("popuptriggerFrom") === "FeaturedPro") {
        cookie.remove("popuptriggerFrom", { path: "/" });
        this.props.history.push("/");
      } else {
        this.props.history.push("/market-place");
      }
      /* } else {
        $.magnificPopup.open({
          items: {
            src: "#outlet-error-popup",
          },
          type: "inline",
        });
      } */
    } else {
      $(".ordrdatetime_error").html(
        '<span class="error"> Please select order date and time.</span>'
      );
    }
  }

  convPad(d) {
    return d < 10 ? "0" + d.toString() : d.toString();
  }

  cart_Outlet_List = (field, value) => {
    if (
      this.props.location.pathname !== "/myaccount" &&
      this.props.location.pathname !== "/myorders" &&
      this.props.location.pathname !== "/mypromotions" &&
      this.props.match.path !== "/thankyou/:orderId"
    ) {
      if (field !== "") {
        if (field === "cartOutletList" && this.state.slugRestaurant !== "") {
          this.props.sateValChange("cartOutletList", value);
        }
        if (field === "totalCartCount") {
          if (this.state.slugRestaurant !== "") {
            this.props.sateValChange("totalCartCount", value);
          }
        }
        if (field === "cartDetails" && value !== "") {
          this.props.sateValChange("cartDetails", value);
        }
        if (field === "cartGroups" && value !== "") {
          this.props.sateValChange("cartGroups", value);
        }
      }
    }
  };
  changeDeate() {
    this.findOutletBasedZone(2, this.state.defaultAvilTy);
  }

  handleChangeTxt = (item, event) => {
    this.setState({ [item]: event.target.value }, function () {});
    if (event.target.value !== "" && event.target.value !== null) {
      if (event.target.value.length == 6) {
        this.setState({ selectPostalCode: "Yes" }, function () {
          this.findOutletBasedZone(1, deliveryId);
        });
      }
    }
  };
  opentavilablity() {
    var opentavilablity = true;
    if (this.state.opentavilablity === true) {
      opentavilablity = false;
    }
    this.setState({ opentavilablity: opentavilablity });
  }

  render() {
    return (
      <>
        <div>
          {(this.state.kitchenpodBlock !== "") && (<div className="banner-logo">
            {/* <Link to={"/"} title="Kloud Kitchen">
              <img src={blackLogo} alt="Logo" />
            </Link>
            <p>
              A virtual foodhall amenity for offices, residential, and hungry people who simply can't decide what to eat. Assemble a meal by mixing and matching items from multiple brands in one transaction.
            </p> */}
            { this.state.kitchenpodBlock }
          </div> )}
          <div className="delivery-wrap">
            <div className="select-delivery">
              <div onClick={this.opentavilablity.bind(this)}>
                {this.state.defaultAvilTy === deliveryId ? (
                  <div>
                    <img src={deliveryIcon} alt="" />
                    Delivery
                    <i className="fa fa-angle-down" aria-hidden="true"></i>
                  </div>
                ) : this.state.defaultAvilTy === pickupId ? (
                  <div>
                    <img src={takeawayIcon} alt="" />
                    Takeaway
                    <i className="fa fa-angle-down" aria-hidden="true"></i>
                  </div>
                ) : (
                  <div>
                    <img src={deliveryIcon} alt="" />
                    Availability
                    <i className="fa fa-angle-down" aria-hidden="true"></i>
                  </div>
                )}
              </div>
              {this.state.opentavilablity === true && (
                <ul>
                  <li>
                    <Link
                      to="#"
                      className={
                        this.state.defaultAvilTy === deliveryId ? "active" : ""
                      }
                      onClick={this.changeAvailability.bind(this, deliveryId)}
                    >
                      <img src={deliveryIcon} alt="" /> Delivery
                    </Link>
                  </li>
                  <li>
                    <Link
                      to="#"
                      className={
                        this.state.defaultAvilTy === deliveryId ? "active" : ""
                      }
                      onClick={this.changeAvailability.bind(this, pickupId)}
                    >
                      <img src={takeawayIcon} alt="" />
                      Takeaway
                    </Link>
                  </li>
                </ul>
              )}
            </div>
            <div className="search-outlet">
              <div className="form-group">
                {this.state.defaultAvilTy === deliveryId ? (
                  <div className="focus-out">
                    <img src={postalCode} alt="" />

                    <input
                      type="text"
                      id="postal_code"
                      pattern="\d*"
                      maxLength="6"
                      defaultValue={this.state.orderPostalCode}
                      onChange={this.handleChangeTxt.bind(this, "postalcode")}
                      className="form-control input-focus"
                      placeholder="Please Enter Your Postal Code"
                    />
                    {this.state.orderDeliveryAddress !== "" && (
                      <div className="success-delivery-addr">
                        <i className="fa fa-check" aria-hidden="true"></i>
                        {this.state.orderDeliveryAddress} Singapore{" "}
                        {this.state.orderPostalCode}
                      </div>
                    )}
                    <div className="postal_error"></div>
                  </div>
                ) : (
                  <div className="focus-out">
                    <div>
                      <img src={postalCode} alt="" />
                      <input
                        type="input"
                        className="form-control input-focus"
                        onKeyUp={this.handleKeyPress}
                        placeholder="Search Our Merchants"
                        id="takeway_outlet"
                      />
                      {this.state.searchOutletKey !== "" && (
                        <i
                          className="fa fa-times"
                          aria-hidden="true"
                          onClick={this.clearOutletSearch.bind(this)}
                        ></i>
                      )}
                    </div>
                    {this.state.openOutletSearch === 1 && (
                      <ul>
                        {this.state.puckupOutletList}
                        {/* this.loadOutletsList() */}
                      </ul>
                    )}
                    <div className="outlet_error"></div>
                  </div>
                )}
              </div>
            </div>
          </div>
          {this.state.defaultAvilTy !== "" &&
            this.state.showTimeSlot === "yes" && (
              <div>
                <div className="time-status">
                  <h6>
                    Choose{" "}
                    {this.state.defaultAvilTy === deliveryId
                      ? "Delivery"
                      : "Takeaway"}{" "}
                    Date & Time
                  </h6>
                </div>
                <div className="date-btn-wrap">
                  <OrderdatetimeSlot
                    {...this.props}
                    hdrState={this.state}
                    setdateTimeFlg={this.setdateTimeFlg}
                  />

                  <div className="btn_sec">
                    <input
                      type="button"
                      onClick={this.setOrderOutletDateTimeData.bind(this)}
                      className="button"
                      value="Order now"
                    />
                  </div>
                </div>
                {/* <p>Open daily from 9.30am - 8.30pm except public holidays.</p> */}
              </div>
            )}
        </div>

        {/* Delivery Popup - Start */}
        <div
          id="delevery-popup"
          className="white-popup mfp-hide popup_sec delivery_outletpoup self_popup"
        >
          <div className="order-body">
            <div className="self_popup_hea_row">
              <div className="self_popup_hea_col_left">
                <img className="outlet-scooter-img" src={deliveryWtImg} />
              </div>
              <div className="self_popup_hea_col">
                <h2>Please Select</h2>
                <p>Your Delivery Outlet</p>
              </div>
            </div>

            <div className="self_pop_row">
              <div className="self_pop_col self_pop_col_right">
                <div className="self_pop_item">
                  <div className="self_pop_locbx">
                    <div className="form-group">
                      <div className="focus-out">
                        <label>Search Outlet</label>
                        <input
                          type="input"
                          className="form-control input-focus"
                          onKeyUp={this.handleKeyPressDly}
                        />

                        <div className="outlet_error"></div>
                      </div>
                    </div>
                  </div>

                  <div className="self_outlet">
                    <h2>Nearby Outlets</h2>
                    <ul className="self_outlet_inner">
                      {this.loadDeliveryOutletsList()}
                    </ul>
                    <a
                      className="button disbl_href_action"
                      id="delivery-continue-link"
                      href="/"
                      onClick={this.selectDlyOutlet.bind(this)}
                    >
                      Continue
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* Delivery Popup - end */}
        {/*  Delivery Postal code Popup - start */}
        <div
          id="delevery-postcode-popup"
          className="white-popup mfp-hide popup_sec delevery_popup"
        >
          <div className="popup_equalrw">
            <div className="popup_ttsec">
              <div className="innervmid_in">
                <div className="pop_title">
                  <img className="pop-scooter-img" src={deliveryWtImg} />
                  <h2 className="text-uppercase">Let us know</h2>
                  <small>Your Delivery Location</small>
                </div>
              </div>
            </div>
            <div className="popup_right">
              <div className="innervmid_in">
                {cookie.load("UserId") && (
                  <div className="address-list-cls address-list-main">
                    {this.userAddressList(1)}
                  </div>
                )}

                <div className="form-group">
                  <div
                    className={
                      this.state.secondaryaddresslist.length > 0
                        ? "focus-out focused"
                        : "focus-out"
                    }
                  >
                    <img src={postalCode} alt="" />
                    {/*  <input
                      type="text"
                      id="postalcode"
                      pattern="\d*"
                      maxLength="6"
                      className="form-control input-focus"
                    /> */}
                    <div className="postal_error"></div>
                  </div>
                </div>

                <div className="btn_sec">
                  <div className="two-button-row">
                    <div className="go_second">
                      <a
                        href="/"
                        onClick={this.close_popup.bind(this)}
                        className="button button-left disbl_href_action"
                        title="Close"
                      >
                        Close
                      </a>
                    </div>
                    <div className="con_first delivery_submit_cls">
                      <input
                        type="button"
                        onClick={this.findOutletBasedZone.bind(
                          this,
                          1,
                          deliveryId
                        )}
                        className="button button-right delivery_submit"
                        value="Continue"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* Delevery Popup - end */}
        {/* Delevery Popup error - start */}
        <div
          id="error-postal-popup"
          className="white-popup mfp-hide popup_sec error_postal_popup"
        >
          <div className="popup_equalrw">
            <div className="popup_ttsec">
              <div className="innervmid_in">
                <div className="pop_title poptt_icontop text-center">
                  <img src={iconUnhappy} />
                  <h2 className="text-uppercase">Sorry </h2>
                  <small>We cant find your postal code</small>
                </div>
              </div>
            </div>
            <div className="popup_right">
              <div className="innervmid_in">
                {/*<h4>Enter your postal code</h4>*/}

                {cookie.load("UserId") && (
                  <div className="address-list-cls address-list-error">
                    {this.userAddressList(2)}
                  </div>
                )}

                <form className="form_sec">
                  <div className="form-group">
                    <div
                      className={
                        this.state.secondaryaddresslist.length > 0
                          ? "focus-out focused"
                          : "focus-out"
                      }
                    >
                      <label>Enter your postal code</label>
                      <input
                        type="text"
                        id="postal_code1"
                        pattern="\d*"
                        maxLength="6"
                        className="form-control input-focus"
                      />
                      <div className="postal_error"></div>
                    </div>
                  </div>
                  <div className="btn_sec delivery_submit_cls delivery_submit_div">
                    {/*<input type="button" onClick={this.selectOutlet.bind(this, 0, deliveryId)} className="button delivery_submit" value="Continue" />*/}
                    <input
                      type="button"
                      onClick={this.findOutletBasedZone.bind(
                        this,
                        0,
                        deliveryId
                      )}
                      className="button delivery_submit"
                      value="Continue"
                    />
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
        {/* Delevery Popup error - Start */}
        {/* success popup - Start */}
        <div
          id="awesome-popup"
          className="white-popup mfp-hide popup_sec delevery_popup delevery_popup_datetime"
        >
          <div className="popup_equalrw">
            <div className="popup_ttsec">
              <div className="innervmid_in">
                <div className="pop_title">
                  <img
                    className="pop-bag-img"
                    src={
                      this.state.seletedAvilablityId === deliveryId
                        ? iconWin
                        : takeawayImg
                    }
                  />
                  <h2 className="text-uppercase">
                    Awesome
                    {this.state.seletedAvilablityId === pickupId && (
                      <span>
                        <br />
                        You Can Pickup
                      </span>
                    )}
                  </h2>
                  {this.state.seletedAvilablityId === deliveryId && (
                    <small>We Can Deliver To Your Location !</small>
                  )}

                  {this.state.seletedAvilablityId === pickupId && (
                    <small>Let Us Know Pickup Date & Time !</small>
                  )}
                </div>
              </div>
            </div>
            <div className="popup_right">
              <div className="innervmid_in">
                {this.state.seletedAvilablityId === deliveryId && (
                  <div className="form-group">
                    <h4 className="uper-light">Your Deliver Location</h4>
                    <div
                    /* className={
                        this.state.deliveryInfo.orderPostalCode.length > 0
                          ? "focus-out focused"
                          : "focus-out"
                      } */
                    >
                      <input
                        type="text"
                        id="postal_code"
                        pattern="\d*"
                        maxLength="6"
                        className="form-control input-focus"
                        readOnly={true}
                        defaultValue={this.state.deliveryInfo.orderPostalCode}
                      />
                      <div className="postal_error"></div>
                    </div>
                    {this.state.seletedAvilablityId === deliveryId && (
                      <div className="greenwith-add">
                        <h5>
                          <img src={greentick} />
                          {this.state.orderDeliveryAddress}
                        </h5>
                      </div>
                    )}
                  </div>
                )}
                <div className="datetime_selt_sec">
                  <div className="datetime_selt_lbl">
                    Choose Date &amp; Time
                  </div>

                  {/* !currenturl.includes(isCheckout) && (
                    <OrderdatetimeSlot
                      {...this.props}
                      hdrState={this.state}
                      setdateTimeFlg={this.setdateTimeFlg}
                    />
                  ) */}

                  <div className="ordrdatetime_error"></div>
                </div>

                <div className="btn_sec">
                  <input
                    type="button"
                    onClick={this.setOrderOutletDateTimeData.bind(this)}
                    className="button"
                    value="Continue"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* success popup - end */}
        {/* success popup - Start */}
        <div
          id="awesome-popup-old"
          className="white-popup mfp-hide awesome_popup"
        >
          <div className="popup_equalrw">
            <div className="popup_ttsec">
              <div className="innervmid_in">
                <div className="pop_title poptt_icontop text-center">
                  <img src={iconWin} />
                  <h2 className="text-uppercase">Awesome</h2>
                  <small>We can Deliver !</small>
                </div>
                <div className="awesome_del">
                  <h5>Your Delivery Address :</h5>
                  <h2>{this.state.orderDeliveryAddress}</h2>
                </div>
                <div className="btn_sec">
                  <input
                    type="button"
                    onClick={this.gotoProducts.bind(this)}
                    className="button"
                    value="Continue"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* success popup - end */}
        {/* Takeaway Popup - Start */}
        <div
          id="takeaway-popup"
          className="white-popup mfp-hide popup_sec self_popup"
        >
          <div className="order-body">
            <div className="self_popup_hea_row">
              <div className="self_popup_hea_col_left">
                <img src={takeawayWtImg} />
              </div>
              <div className="self_popup_hea_col">
                <h2>Please Select</h2>
                <p>Your Self Collection Outlet</p>
              </div>
            </div>

            <div className="self_pop_row">
              <div className="self_pop_col self_pop_col_right">
                <div className="self_pop_item">
                  <div className="self_pop_locbx">
                    {/*<h4>Search Cedele Outlet.</h4>*/}
                    <div className="form-group">
                      <div className="focus-out">
                        <label>Search Outlet</label>
                        <input
                          type="input"
                          className="form-control input-focus"
                          onKeyUp={this.handleKeyPress}
                        />
                        <div className="outlet_error"></div>
                      </div>
                    </div>
                  </div>

                  <div className="self_outlet">
                    <h2>Nearby Outlets</h2>
                    <ul className="self_outlet_inner">
                      {/* this.loadOutletsList() */}
                    </ul>
                    <a
                      className="button takeaway-btn-act disbl_href_action"
                      id="takeaway-continue-link"
                      href="/"
                      onClick={this.selectDatetm.bind(this)}
                    >
                      Continue
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* Takeaway Popup - end */}
      </>
    );
  }
}

const mapStateTopProps = (state) => {
  var zonedetailArr = Array();
  if (Object.keys(state.zonedetail).length > 0) {
    if (state.zonedetail[0].status === "ok") {
      zonedetailArr = state.zonedetail[0].result_set;
    }
  }

  var outletsArr = Array();
  if (Object.keys(state.outlets).length > 0) {
    if (state.outlets[0].status === "ok") {
      outletsArr = state.outlets[0].result_set;
    }
  }

  var alloutletsArr = Array();
  if (Object.keys(state.alloutlets).length > 0) {
    if (state.alloutlets[0].status === "ok") {
      alloutletsArr = state.alloutlets[0].result_set;
    }
  }

  var secondarydataArr = Array();
  if (Object.keys(state.secondaryaddress).length > 0) {
    if (state.secondaryaddress[0].status === "ok") {
      secondarydataArr = state.secondaryaddress[0].result_set;
    }
  }

  var blacksArr = Array();
  if (Object.keys(state.staticblack).length > 0) {
    if (state.staticblack[0].status === "ok") {
      blacksArr = state.staticblack[0].result_set;
    }
  }

  return {
    globalsettings: state.settings,
    zonedetails: zonedetailArr,
    outletslist: outletsArr,
    alloutletslist: alloutletsArr,
    logindata: state.login,
    fblogin: state.fblogin,
    forgetpassword: state.forgetpassword,
    registration: state.registration,
    menudata: state.menudata,
    secondaryaddresslist: secondarydataArr,
    staticblocks: blacksArr
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getGlobalSettings: () => {
      dispatch({ type: GET_GLOBAL_SETTINGS });
    },
    getZoneDetail: (outletId, zoneId, shop_type) => {
      dispatch({ type: GET_ZONE_DETAIL, outletId, zoneId, shop_type });
    },
    getPickupOutlets: () => {
      dispatch({ type: GET_PICKUP_OUTLETS });
    },
    getAllOutlets: (availability) => {
      dispatch({ type: GET_ALL_OUTLETS, availability });
    },
    destroyCartDetail: () => {
      dispatch({ type: DESTROY_CART_DETAIL });
    },
    getLoginData: (formPayload) => {
      dispatch({ type: GET_LOGINDATA, formPayload });
    },
    getRegistration: (formPayload) => {
      dispatch({ type: GET_REGISTRATION, formPayload });
    },
    getForgetPassword: (formPayload) => {
      dispatch({ type: GET_FORGET_PASSWORD, formPayload });
    },
    getMenuData: (menuslug) => {
      dispatch({ type: GET_MENUDATA, menuslug });
    },
    getSecondaryAddress: () => {
      dispatch({ type: GET_ALLUSERSECADDRDATA });
    },
    getStaticBlock: () => {
      dispatch({ type: GET_STATIC_BLOCK });
    },
  };
};
export default connect(
  mapStateTopProps,
  mapDispatchToProps
)(withRouter(Header));
