/* eslint-disable */
import React, { Component } from "react";
import { Link, withRouter } from "react-router-dom";
import { connect } from "react-redux";
import axios from "axios";
import cookie from "react-cookies";
import moment from "moment";
import {
  getReferenceID,
  stripslashes,
  getCalculatedAmount,
  getoutletTax,
  callImage,
  showLoader,
  hideLoader,
  getOrderDateTime,
  getPromoCkValue,
  removeOrderDateTime,
  removePromoCkValue,
  resetCrtStyle,
  addressFormat,
} from "../Helpers/SettingHelper";
import { appId, apiUrl, deliveryId, pickupId } from "../Helpers/Config";
import OrderdatetimeSlot from "./OrderdatetimeSlot";
/* import images */
import thumbsimg from "../../common/images/icon-win.svg";
import midBag from "../../common/images/mid-bag.png";
import bagicon from "../../common/images/bagicon.png";
import {
  GET_CART_DETAIL,
  UPDATE_CART_DETAIL,
  DELETE_CART_DETAIL,
  DESTROY_CART_DETAIL,
} from "../../actions";
var Parser = require("html-react-parser");

class CartSideBar extends Component {
  constructor(props) {
    super(props);
    var avilablityIdTxt = cookie.load("defaultAvilablityId");
    var promotionType =
      cookie.load("promotionType") !== "" &&
      typeof cookie.load("promotionType") !== undefined
        ? cookie.load("promotionType")
        : "";
    var unitNoOne =
      typeof cookie.load("unitNoOne") === "undefined"
        ? ""
        : cookie.load("unitNoOne");
    var unitNoTwo =
      typeof cookie.load("unitNoTwo") === "undefined"
        ? ""
        : cookie.load("unitNoTwo");
    var currentPageUrl = this.props.location.pathname.split("/");
    let currentPageUrll =
      currentPageUrl[1] !== "" &&
      typeof currentPageUrl[1] !== undefined &&
      typeof currentPageUrl[1] !== "undefined"
        ? currentPageUrl[1]
        : "";
    var current_Page_Url = "";
    var resbreadcrumb = "";
    if (currentPageUrll == "food") {
      current_Page_Url = "food";
      resbreadcrumb = "Restaurants";
    } else if (currentPageUrll == "retail") {
      current_Page_Url = "retail";
      resbreadcrumb = "Retail";
    } else {
      current_Page_Url = "events";
      resbreadcrumb = "Events";
    }
    this.state = {
      currentPageUrl: current_Page_Url,
      slugEvent:
        this.props.match.params.slugEvent !== "" &&
        typeof this.props.match.params.slugEvent !== undefined &&
        typeof this.props.match.params.slugEvent != "undefined"
          ? this.props.match.params.slugEvent
          : "",
      defaultAvilTy: avilablityIdTxt,
      promotionType: promotionType,
      unitnumber1: unitNoOne,
      unitnumber2: unitNoTwo,
      cartlistdetail: [],
      overAllcart: [],
      cartItems: [],
      cartDetails: [],
      cartStatus: "",
      settings: [],
      cartTotalItmCount: 0,
      cartTotalAmount: 0,
      cartOutletList: [],
      getDateTimeFlg: "no",
      seletedAvilablityId: avilablityIdTxt,
      order_tat_time: "",
    };
  }

  UNSAFE_componentWillMount() {
    this.props.getCartDetail();
  }

  componentWillReceiveProps(headerProps) {
    if (headerProps.cartTriggerFlg === "yes") {
      if (headerProps.headerState.defaultAvilTy !== this.state.defaultAvilTy) {
        this.setState({ defaultAvilTy: headerProps.headerState.defaultAvilTy });
      }
      headerProps.prpSateValChange("cartflg", "no");
      this.props.getCartDetail();
      resetCrtStyle();
    }
    if (headerProps.cartClearTriggerFlg === "yes") {
      headerProps.prpSateValChange("cartclearflg", "no");
      this.clearCartItm();
    }
    hideLoader("product-details", "class");
    hideLoader("cart_body", "class");
    //hideLoader("cancel_order", "class");
    if (this.state.cartOutletList !== headerProps.cartOutletList) {
      this.setState(
        { cartOutletList: headerProps.cartOutletList },
        function () {
          this.props.cart_Outlet_List(
            "cartOutletList",
            headerProps.cartOutletList
          );
        }
      );
    }
    if (this.state.cartTotalItmCount !== headerProps.cartTotalItmCount) {
      this.setState(
        { cartTotalItmCount: headerProps.cartTotalItmCount },
        function () {
          this.props.cart_Outlet_List(
            "totalCartCount",
            headerProps.cartTotalItmCount
          );
        }
      );
    }
    if (this.state.cartDetails !== headerProps.cartDetails) {
      this.setState({ cartDetails: headerProps.cartDetails }, function () {
        this.props.cart_Outlet_List("cartDetails", headerProps.cartDetails);
        this.props.cart_Outlet_List("cartGroups", headerProps.cartGroups);
      });
    }
    if (this.state.cartDetails !== headerProps.cartStatus) {
      this.setState({ cartStatus: headerProps.cartStatus }, function () {
        if (
          cookie.load("cart_group_id") !== "" &&
          typeof cookie.load("cart_group_id") !== "undefined" &&
          typeof cookie.load("cart_group_id") !== "undefined" &&
          headerProps.cartStatus == "failure"
        ) {
          cookie.remove("cart_group_id", { path: "/" });
          cookie.remove("group_order_confirm", { path: "/" });
          cookie.remove("cart_id", { path: "/" });
        }
      });
    }
    if (this.state.order_tat_time !== headerProps.outletTat) {
      this.setState({
        order_tat_time: headerProps.outletTat,
        getDateTimeFlg: "yes",
      });
    }
  }

  componentDidMount() {
    /* var current = this;
    setInterval(function () {
      current.props.getCartDetail();
    }, 10000); */
  }

  removePromoFun(event) {
    event.preventDefault();
    removePromoCkValue();
    this.props.getCartDetail();
  }

  closeCartlist() {
    $("body").removeClass("cart-items-open");
    $(".hcartdd_trigger").removeClass("active");
    $(".hcart_dropdown").removeClass("open");
  }

  sendGroupOrder(event) {
    event.preventDefault();
    showLoader("send_gruop_order", "class");
    var qs = require("qs");
    var postObject = {
      app_id: appId,
      cart_group_id: cookie.load("cart_group_id"),
    };
    axios
      .post(apiUrl + "cart/confirm_group", qs.stringify(postObject))
      .then((response) => {
        hideLoader("send_gruop_order", "class");
        if (response.data.status === "ok") {
          this.props.getCartDetail();
          cookie.save("group_order_confirm", "Yes", { path: "/" });
        } else {
        }
        return false;
      });
  }
  setdateTimeFlg = (field, value) => {
    if (field == "tmflg") {
      this.setState({ getDateTimeFlg: value });
    } else if (field == "ordDate") {
      var ordTime = "";
      $(".ordrdatetime_error").html("");
      this.setState({ seleted_ord_date: value, seleted_ord_time: ordTime });
    } else if (field == "ordTime") {
      $(".ordrdatetime_error").html("");
      this.setState({ seleted_ord_time: value });
    } else if (field == "triggerErrorPopup") {
      $.magnificPopup.open({
        items: {
          src: "#outlet-error-popup",
        },
        type: "inline",
      });
    }
  };
  handleAddrChange(event) {
    if (event.target.name === "unit_no1") {
      cookie.save("unitNoOne", event.target.value, { path: "/" });
      this.setState({ unitnumber1: event.target.value });
    } else if (event.target.name === "unit_no2") {
      cookie.save("unitNoTwo", event.target.value, { path: "/" });
      this.setState({ unitnumber2: event.target.value });
    }
  }
  changeAvailability(availability, event) {
    event.preventDefault();
    if (availability === deliveryId && this.state.deliveryPostalCode === "") {
      this.setState({ selectPostalCode: "Yes" });
      $.magnificPopup.open({
        items: {
          src: "#delevery-postcode-popup",
        },
        type: "inline",
      });

      return false;
    }
    //this.props.destroyCartDetail();
    var orderDateTime = cookie.load("orderDateTime");
    if (availability === pickupId) {
      if (
        orderDateTime === "" ||
        typeof orderDateTime === undefined ||
        typeof orderDateTime === "undefined"
      ) {
        cookie.save("defaultAvilablityId", pickupId, { path: "/" });
        this.setState({ selectPickupTime: "Yes" });
        return false;
      }
    }
    this.setState({ defaultAvilTy: availability });
    cookie.save("defaultAvilablityId", availability, { path: "/" });
    /*this.props.history.push('/');
    if (this.state.defaultAvilTy !== availability) {
      axios
        .get(
          apiUrlV2 +
            "outlets/checkOutletAvailability?app_id=" +
            appId +
            "&availability_id=" +
            availability +
            "&outlet_slug=" +
            this.state.outlet_slug
        )
        .then((response) => {
          if (response.data.status === "ok") {
            cookie.save("defaultAvilablityId", availability, { path: "/" });
            this.setState(
              { defaultAvilTy: availability, load_product: "No" },
              function () {
                axios
                  .get(
                    apiUrl +
                      "cart/update_availability?app_id=" +
                      appId +
                      "&reference_id=" +
                      getReferenceID() +
                      "&customer_id=" +
                      cookie.load("UserId") +
                      "&availability_id=" +
                      availability
                  )
                  .then((res) => {});

              
              }
            );
          } else {
            $.magnificPopup.open({
              items: {
                src: "#warning-not-deliver",
              },
              type: "inline",
            });
           
          }
        });
    } */
  }
  gobckOutletpopup(event) {
    event.preventDefault();
    $("#cart-close-span").trigger("click");
    $.magnificPopup.open({
      items: {
        src: "#delevery-postcode-popup",
      },
      type: "inline",
    });
  }
  cartDetailsList() {
    var cartItemsArr = this.props.cartItems;
    var cartOutletTax = this.props.cartOutletTax;
    if (Object.keys(cartItemsArr).length > 0) {
      var cartDetailsArr = this.props.cartDetails;
      var promoTionArr = getPromoCkValue();

      var globalSettings = Array();
      if (Object.keys(this.props.globalsettings).length > 0) {
        if (this.props.globalsettings[0].status === "ok") {
          globalSettings = this.props.globalsettings[0].result_set;
        }
      }
      var zoneDetails = this.props.zonedetails;
      var calculatedAmount = getCalculatedAmount(
        globalSettings,
        zoneDetails,
        cartDetailsArr,
        promoTionArr,
        this.props.maxCart,
        cartItemsArr,
        cartOutletTax
      );
      var orderDateTime =
        typeof cookie.load("orderDateTime") === "undefined"
          ? ""
          : cookie.load("orderDateTime");
      var orderTAT =
        typeof cookie.load("orderTAT") === "undefined"
          ? ""
          : cookie.load("orderTAT");
      var orderDateTmTxt = getOrderDateTime(orderDateTime, orderTAT);
      let cart_group_id =
        cookie.load("cart_group_id") !== "" &&
        typeof cookie.load("cart_group_id") !== undefined &&
        typeof cookie.load("cart_group_id") !== "undefined"
          ? cookie.load("cart_group_id")
          : "";
      var completeGroup, cartGroups;
      var cartActive = "hcart_dropdown";
      if (
        Object.keys(this.props.cartGroups).length > 0 &&
        cart_group_id !== ""
      ) {
        cartGroups = this.props.cartGroups;
        if (
          cartGroups[cart_group_id] !== "" &&
          typeof cartGroups[cart_group_id] !== undefined &&
          typeof cartGroups[cart_group_id] !== "undefined"
        ) {
          if (cartGroups[cart_group_id].cart_group_status === "C") {
            completeGroup = "Y";
            cartActive += " only-group after-send-group";
          } else {
            cartActive += " only-group ";
          }
        }
      }
      if (this.props.currentPage === "product") {
        cartActive += " open";
      }
      return (
        <>
          <div className={cartActive}>
            <div className="hcart_tt">
              <div
                id="cart-close-span"
                onClick={this.closeCartlist.bind(this)}
              ></div>

              {cart_group_id !== "" ? (
                <h5>
                  {cartDetailsArr.customer_first_name}
                  {cartDetailsArr.customer_last_name !== ""
                    ? " " + cartDetailsArr.customer_last_name
                    : ""}
                  ’s Gruop Order
                </h5>
              ) : (
                <h3>My Cart</h3>
              )}
            </div>
            <div className="product-cart-side">
              <button
                className={
                  this.state.defaultAvilTy === deliveryId
                    ? "btn btn-procart pro-cart-bike active"
                    : "btn btn-procart pro-cart-bike"
                }
                onClick={this.changeAvailability.bind(this, deliveryId)}
              >
                Delivery
              </button>
              <button
                className={
                  this.state.defaultAvilTy === pickupId
                    ? "btn btn-procart pro-cart-bag active"
                    : "btn btn-procart pro-cart-bag"
                }
                onClick={this.changeAvailability.bind(this, pickupId)}
              >
                Takeway
              </button>
            </div>
            <div className="hcart_maiin_scrollarea">
              <div className="hcart_scrollarea">
                <div className="cart_table">
                  <div className="cart_body">
                    {cart_group_id === "" && (
                      <>
                        {this.state.defaultAvilTy === deliveryId ? (
                          <div className="new-header-first">
                            <h4>Delivery Location</h4>
                            <p>
                              {cookie.load("orderDeliveryAddress")}{" "}
                              <span>{cookie.load("orderPostalCode")}</span>
                            </p>
                            <div className="hcart-form-group">
                              <p>#</p>
                              <div className="form-group">
                                <input
                                  type="text"
                                  id="unit_no1_id"
                                  name="unit_no1"
                                  placeholder="Unit No"
                                  value={this.state.unitnumber1}
                                  className="form-control input-focus"
                                  onChange={this.handleAddrChange.bind(this)}
                                />
                              </div>
                              <div className="form-group">
                                <input
                                  type="text"
                                  id="unit_no2_id"
                                  name="unit_no2"
                                  placeholder="Unit No"
                                  value={this.state.unitnumber2}
                                  className="form-control input-focus"
                                  onChange={this.handleAddrChange.bind(this)}
                                />
                              </div>
                            </div>
                            <a
                              href="/"
                              onClick={this.gobckOutletpopup.bind(this)}
                              className="hcart-txt-line"
                            >
                              Change Address
                            </a>
                          </div>
                        ) : (
                          <div className="new-header-first">
                            <h4>Takeaway Location</h4>
                            {globalSettings !== "" &&
                              globalSettings.site_location !== "" &&
                              globalSettings.site_location !== undefined &&
                              typeof globalSettings.site_location !==
                                undefined &&
                              typeof globalSettings.site_location !==
                                "undefined" && (
                                <p>
                                  {
                                    globalSettings.site_location[
                                      this.props.siteLocID
                                    ].sl_name
                                  }
                                  ,{" "}
                                  {addressFormat(
                                    globalSettings.site_location[
                                      this.props.siteLocID
                                    ].sl_pickup_unit_number1,
                                    globalSettings.site_location[
                                      this.props.siteLocID
                                    ].sl_pickup_unit_number2,
                                    globalSettings.site_location[
                                      this.props.siteLocID
                                    ].sl_pickup_address_line1,
                                    globalSettings.site_location[
                                      this.props.siteLocID
                                    ].sl_pickup_address_line2,
                                    globalSettings.site_location[
                                      this.props.siteLocID
                                    ].sl_pickup_postal_code
                                  )}
                                </p>
                              )}
                          </div>
                        )}
                        <div className="new-header-second">
                          <div className="new-header-second-inner">
                            <OrderdatetimeSlot
                              {...this.props}
                              hdrState={this.state}
                              setdateTimeFlg={this.setdateTimeFlg}
                              labelDateName="Date"
                              labelTimeName="Time"
                            />
                          </div>
                        </div>
                      </>
                    )}
                    <div className="cart-outlet-name">
                      Your Items{" "}
                      <span>
                        <a
                          href="/"
                          onClick={this.clearCartItm.bind(this)}
                          className="hclear_cart"
                          title="CLEAR CART"
                        >
                          Clear cart
                        </a>
                      </span>
                    </div>
                    {this.cartItemList()}
                  </div>
                  {cart_group_id === "" && (
                    <div className="cart_footer">
                      <div className="cart_footer_inner_item">
                        <div className="cart_row">
                          <div className="row-replace bot-border">
                            <div className="col-sm-cls text-left">
                              <p className="text-capitalize">Sub Total</p>
                            </div>
                            <div className="col-sm-cls text-right">
                              <span>
                                ${calculatedAmount["cartSubTotalAmount"]}
                              </span>
                            </div>
                          </div>
                        </div>
                        {parseFloat(calculatedAmount["deliveryCharge"]) > 0 && (
                          <div className="cart_row">
                            <div className="row-replace">
                              <div className="col-sm-cls text-left">
                                <p className="text-uppercase">Delivery</p>
                              </div>
                              <div className="col-sm-cls text-right">
                                <span>
                                  $
                                  {parseFloat(
                                    calculatedAmount["deliveryCharge"]
                                  ).toFixed(2)}
                                </span>
                              </div>
                            </div>
                          </div>
                        )}
                        {parseFloat(calculatedAmount["additionalDelivery"]) >
                          0 && (
                          <div className="cart_row">
                            <div className="row-replace">
                              <div className="col-sm-cls text-left">
                                <p className="text-uppercase">
                                  Additional Delivery
                                </p>
                              </div>
                              <div className="col-sm-cls text-right">
                                <span>
                                  $
                                  {parseFloat(
                                    calculatedAmount["additionalDelivery"]
                                  ).toFixed(2)}
                                </span>
                              </div>
                            </div>
                          </div>
                        )}
                        {parseFloat(calculatedAmount["promotionAmount"]) >
                          0 && (
                          <div className="cart_row promotion-only-show">
                            <div className="row-replace">
                              <div className="col-sm-cls text-left">
                                <p className="text-uppercase">
                                  {this.state.promotionType !== "" &&
                                  this.state.promotionType === "points"
                                    ? "Discount"
                                    : "Promo Code"}
                                </p>
                              </div>
                              <div className="col-sm-cls text-right">
                                <span>
                                  $
                                  {parseFloat(
                                    calculatedAmount["promotionAmount"]
                                  ).toFixed(2)}
                                </span>
                                <a
                                  href="/"
                                  onClick={this.removePromoFun.bind(this)}
                                  className="cart_remove"
                                ></a>
                              </div>
                            </div>
                          </div>
                        )}
                        {calculatedAmount.additionalcharge.length > 0 &&
                          calculatedAmount.additionalcharge.map(
                            (item, index) => {
                              return (
                                <div className="cart_row">
                                  <div className="row-replace">
                                    <div className="col-sm-cls text-left">
                                      <p className="text-uppercase">
                                        {item.title}
                                      </p>
                                    </div>
                                    <div className="col-sm-cls text-right">
                                      <span>${item.amount}</span>
                                    </div>
                                  </div>
                                </div>
                              );
                            }
                          )}

                        {parseFloat(calculatedAmount["orderGstAmount"]) > 0 && (
                          <div className="cart_row">
                            <div className="row-replace">
                              <div className="col-sm-cls text-left">
                                <p className="text-uppercase">
                                  GST{" "}
                                  {/* ({calculatedAmount["orderDisplayGst"]} %) */}
                                </p>
                              </div>
                              <div className="col-sm-cls text-right">
                                <span>
                                  $
                                  {parseFloat(
                                    calculatedAmount["orderGstAmount"]
                                  ).toFixed(2)}
                                </span>
                              </div>
                            </div>
                          </div>
                        )}
                        <div className="cart_row grant-total-cls">
                          <div className="row-replace">
                            <div className="col-sm-cls text-left">
                              <p className="text-uppercase">Total</p>
                            </div>
                            <div className="col-sm-cls text-right">
                              <span>
                                ${calculatedAmount["grandTotalAmount"]}
                              </span>
                            </div>
                          </div>
                        </div>
                        {this.loadMinCartPercentage()}
                        {this.state.defaultAvilTy === deliveryId &&
                          this.loadDeliveryPercentage()}

                        {parseFloat(cartDetailsArr.cart_special_discount) >
                          0 && (
                          <div className="member-discount-total">
                            * {cartDetailsArr.cart_special_discount_type} $
                            {cartDetailsArr.cart_special_discount} Applied
                          </div>
                        )}
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
            {cart_group_id !== "" ? (
              <div className="cart_footer">
                <div className="cart_footer_inner_item">
                  <div class="cart_row grant-total-cls">
                    <div class="row-replace">
                      <div class="col-sm-cls text-left">
                        <p class="text-uppercase">Total</p>
                      </div>
                      <div class="col-sm-cls text-right">
                        <span>
                          <sup>$</sup>
                          {calculatedAmount["cartSubTotalAmount"]}
                        </span>
                      </div>
                    </div>
                  </div>
                  {completeGroup === "Y" ? (
                    <div className="cart_action">
                      <div className="after-cart-btm-text">
                        <img src={thumbsimg} />
                        <h5>
                          Items added to {cartDetailsArr.customer_first_name}
                          {cartDetailsArr.customer_last_name !== ""
                            ? " " + cartDetailsArr.customer_last_name
                            : ""}
                          ’s order
                        </h5>
                        <p>
                          We'll let you know once the order has been placed.
                          Item in your basket may be edited by{" "}
                          {cartDetailsArr.customer_first_name}
                          {cartDetailsArr.customer_last_name !== ""
                            ? " " + cartDetailsArr.customer_last_name
                            : ""}
                        </p>
                      </div>
                    </div>
                  ) : (
                    <div className="cart_action">
                      <div className="cartaction_bottom">
                        <Link
                          to={"#"}
                          className="btn btn_pink cartaction_checkout send_gruop_order"
                          title="Checkout Now"
                          onClick={this.sendGroupOrder.bind(this)}
                        >
                          Send To Order
                        </Link>
                      </div>
                      <div className="cart-btm-text">
                        <p>
                          You cant change your items once you
                          <br /> add them to Name order{" "}
                        </p>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            ) : (
              <div className="cart_action">
                <div className="cartaction_bottom">
                  <Link
                    to={"/checkout"}
                    className="btn btn_pink cartaction_checkout"
                    title="Checkout Now"
                  >
                    Checkout
                  </Link>
                </div>
              </div>
            )}
          </div>
        </>
      );
    } else {
      $("body").removeClass("cart-items-open");
      $(".hcartdd_trigger").removeClass("active");
      $(".hcart_dropdown").removeClass("open");

      return (
        <>
          {this.props.currentPage === "product" && (
            <>
              <img className="img-empty" src={midBag} />
              <h4 className="h4-empty">Your Cart is Empty Please Add Items</h4>
            </>
          )}
        </>
      );
    }
  }

  /* this  function used to load delivery percentage */
  loadDeliveryPercentage() {
    var freeDeliveryAmnt = 0;
    var DeliveryAmnt = 0;
    var remainAmnt = 0;
    var delPercentage = 0;

    var cartItemsArr = this.props.cartItems;

    if (Object.keys(cartItemsArr).length > 0) {
      var cartDetailsArr = this.props.cartDetails;
      var promoTionArr = getPromoCkValue();

      var globalSettings = Array();
      if (Object.keys(this.props.globalsettings).length > 0) {
        if (this.props.globalsettings[0].status === "ok") {
          globalSettings = this.props.globalsettings[0].result_set;
        }
      }
      var zoneDetails = this.props.zonedetails;
      var calculatedAmount = getCalculatedAmount(
        globalSettings,
        zoneDetails,
        cartDetailsArr,
        promoTionArr,
        this.props.maxCart
      );

      freeDeliveryAmnt = parseFloat(calculatedAmount["freeDeliveryAmnt"]);
      DeliveryAmnt = parseFloat(calculatedAmount["deliveryCharge"]);
      var subTotal = parseFloat(calculatedAmount["cartSubTotalAmount"]);
      var percentage = (subTotal * 100) / freeDeliveryAmnt;
      percentage = Math.round(percentage);
      delPercentage = percentage;
      remainAmnt = parseFloat(freeDeliveryAmnt - subTotal).toFixed(2);
    }

    if (DeliveryAmnt > 0 && freeDeliveryAmnt > 0 && remainAmnt > 0) {
      return (
        <div className="cart_row progress_bar_div free-delivery">
          <div className="indication">
            <div className="indication_progress">
              <span
                className="progress_bar"
                style={{ width: delPercentage + "%" }}
              />
            </div>
            <p className="help-block">${remainAmnt} more to FREE delivery!</p>
          </div>
        </div>
      );
    }
  }

  loadMinCartPercentage() {
    var delPercentage = 0;
    var min_amount = 0;
    var cartItemsArr = this.props.cartItems;

    if (Object.keys(cartItemsArr).length > 0) {
      var globalSettings = Array();
      if (Object.keys(this.props.globalsettings).length > 0) {
        if (this.props.globalsettings[0].status === "ok") {
          globalSettings = this.props.globalsettings[0].result_set;
        }
      }
      var cartDetailsArr = this.props.cartDetails;
      var subTotal = parseFloat(cartDetailsArr.cart_sub_total);
      min_amount = parseFloat(globalSettings.pos_min_amount);
      var percentage = (subTotal * 100) / min_amount;
      percentage = Math.round(percentage);
      var balance = parseFloat(min_amount - subTotal);
      if (
        balance > 0 &&
        min_amount > 0 &&
        min_amount > subTotal &&
        percentage > 0
      ) {
        balance = balance.toFixed(2);
        return (
          <div className="cart_row progress_bar_div minimum-order-amount">
            <div className="indication">
              <div className="indication_progress">
                <span
                  className="progress_bar"
                  style={{ width: percentage + "%" }}
                />
              </div>
              <p className="help-block">${balance} more to min order amount</p>
            </div>
          </div>
        );
      }
    }
  }

  cartItemList() {
    var cartItemsArr = this.props.cartItems;
    console.log(cartItemsArr,"cartItemsArr");
    var cartOutletArr = this.props.cartOutlet;
    console.log(cartOutletArr,"cartOutletArr");
    var cartOutlet_Tax = this.props.cartOutletTax;

    if (Object.keys(cartItemsArr).length > 0) {
      return Object.keys(cartItemsArr).map((index) => {
  console.log(index,"index")
        return (
          <div className="cart_outlet_row" key={index}>
            {Object.keys(cartItemsArr[index]).map((index2) => {
              /*let letOutleName =
                cartOutletArr[index2] !== "" &&
                typeof cartOutletArr[index2] !== undefined &&
                typeof cartOutletArr[index2] !== "undefined"
                  ? cartOutletArr[index2]
                  : "";*/

              let letOutleName = "";

                if(typeof cartItemsArr[index] !== undefined && typeof cartItemsArr[index][index2] !== undefined && typeof cartItemsArr[index][index2][0] !== undefined && typeof cartItemsArr[index][index2][0][0] !== undefined && typeof cartItemsArr[index][index2][0][0]['oultlet_name'] !== undefined && cartItemsArr[index][index2][0][0]['outlet_name'] !== "" ) {
                  letOutleName = cartItemsArr[index][index2][0][0]['outlet_name'];
                } else if (cartOutletArr[index2] !== "" && typeof cartOutletArr[index2] !== undefined && typeof cartOutletArr[index2] !== "undefined" ) {
                  letOutleName = cartOutletArr[index2];
                }

              var outletTotalAmt = 0;
              let OutletTax =
                cartOutlet_Tax[index2] !== "" &&
                typeof cartOutlet_Tax[index2] !== undefined &&
                typeof cartOutlet_Tax[index2] !== "undefined"
                  ? parseFloat(cartOutlet_Tax[index2])
                  : 0;
              return (
                <>
                  <p className="cart-oulet-title">
                    <strong>{letOutleName}</strong>
                  </p>
                  {Object.keys(cartItemsArr[index][index2]).map((index1) => {
                    let product = cartItemsArr[index][index2][index1];
                    if (Object.keys(product).length > 0 && product !== null) {
                      product.map((cproduct) => {
                        outletTotalAmt =
                          parseFloat(outletTotalAmt) +
                          parseFloat(cproduct.cart_item_total_price);
                      });
                    }
                    return (
                      <>
                        {this.checkAvailability(index2)}
                        {this.cartOutletItemList(product)}
                      </>
                    );
                  })}
                  {OutletTax > 0 && (
                    <div className="outlet-gst">
                      <span>GST ( {letOutleName} )</span>
                      <span className="outlet-gst-price">
                        ${getoutletTax(outletTotalAmt, OutletTax)}
                      </span>
                    </div>
                  )}
                </>
              );
            })}
          </div>
        );
      });
    }
  }

  checkAvailability(outletID) {
    let cartoutletavailability = this.props.cartoutletavailability;
    if (
      cartoutletavailability[outletID] !== "" &&
      typeof cartoutletavailability[outletID] !== undefined &&
      cartoutletavailability[outletID] !== "undefined"
    ) {
      if (
        cartoutletavailability[outletID].indexOf(this.state.defaultAvilTy) < 0
      ) {
        return (
          <span className="outlet-avilable">
            This restaurants not avilable. Remove this restaurants
          </span>
        );
      }
    }
  }

  cartOutletItemList(cartItemsArr) {
    let group_order_confirm =
      cookie.load("group_order_confirm") !== "" &&
      typeof cookie.load("group_order_confirm") !== undefined &&
      typeof cookie.load("group_order_confirm") !== "undefined"
        ? cookie.load("group_order_confirm")
        : "";
    if (Object.keys(cartItemsArr).length > 0 && cartItemsArr !== null) {
      return cartItemsArr.map((product, index) => (
        <div
          className="cart_row product-details"
          id={"rowcartid-" + product.cart_item_id}
          key={index}
        >
          <div className="row-replace">
            <div className="col-sm-cls cart_left">
              <div className="cart_info text-left">
                <h4>{stripslashes(product.cart_item_product_name)}</h4>

                {this.loadModifierItems(
                  product.cart_item_type,
                  product.modifiers,
                  product.set_menu_component
                )}

                {parseFloat(product.cart_item_promotion_discount) > 0 && (
                  <span className="member-discount-desc">
                    $ {product.cart_item_promotion_discount}{" "}
                    {product.cart_item_promotion_type} discount Applied
                  </span>
                )}
              </div>
            </div>
            <div className="col-sm-cls cart_right text-right">
              <div className="cart_price">
                <p>${product.cart_item_total_price}</p>
                {group_order_confirm === "" && (
                  <a
                    href="/"
                    onClick={this.deleteCartItm.bind(this, product)}
                    className="cart_remove"
                  ></a>
                )}
              </div>
              {group_order_confirm === "" && (
                <div className="qty_bx">
                  <span
                    className="qty_minus"
                    onClick={this.updateCartQty.bind(this, product, "decr")}
                  >
                    -
                  </span>
                  <input type="text" value={product.cart_item_qty} readOnly />
                  <span
                    className="qty_plus"
                    onClick={this.updateCartQty.bind(this, product, "incr")}
                  >
                    +
                  </span>
                </div>
              )}
            </div>
            {product.cart_item_special_notes !== "" && (
              <p className="help-block">
                {stripslashes(product.cart_item_special_notes)}
              </p>
            )}
          </div>
        </div>
      ));
    }
  }

  /* this function used to load modifer items */
  loadModifierItems(itemType, modifiers, combo) {
    var len = modifiers.length;
    var comboLen = combo.length;
    var html = '<div class="cart_extrainfo">';

    var temp_html = "";

    if (itemType === "Modifier" && len > 0) {
      for (var i = 0, length = len; i < length; i++) {
        var modName = modifiers[i]["cart_modifier_name"];
        var modval = modifiers[i]["modifiers_values"][0]["cart_modifier_name"];
        var modVlPrice =
          modifiers[i]["modifiers_values"][0]["cart_modifier_price"];
        var newModVlPrice = modVlPrice > 0 ? " (+" + modVlPrice + ")" : "";
        temp_html +=
          "<p>" + modName + ":</p> <p>" + modval + newModVlPrice + "</p> ";
      }

      html += temp_html + "</div>";
      var reactElement = Parser(html);
      return reactElement;
    } else if (itemType === "Component" && comboLen > 0) {
      for (var i = 0, length = comboLen; i < length; i++) {
        var comboName = combo[i]["menu_component_name"];
        var comboVal = this.showComboProducts(combo[i]["product_details"]);

        if (combo[i]["product_details"][0]["modifiers"].length) {
          html +=
            "<p>" +
            comboName +
            ": </p><p>" +
            comboVal +
            "  " +
            this.showComboModifiers(
              combo[i]["product_details"][0]["modifiers"]
            ) +
            "</p> ";
        } else {
          html +=
            "<p>" +
            comboName +
            ":</p><p>" +
            comboVal +
            " " +
            this.showComboModifiers(
              combo[i]["product_details"][0]["modifiers"]
            ) +
            "</p> ";
        }
      }
      html += "</div>";
      var reactElement = Parser(html);
      return reactElement;
    }
  }

  /* show combo products  list */
  showComboProducts(combos) {
    var lenCombo = combos.length;
    var html = " ";
    if (lenCombo > 0) {
      for (var r = 0, lengthCombo = lenCombo; r < lengthCombo; r++) {
        if (parseInt(combos[r]["cart_menu_component_product_qty"]) > 0) {
          var comboPro = combos[r]["cart_menu_component_product_name"];
          var comboQty = combos[r]["cart_menu_component_product_qty"];
          var comboPrice = combos[r]["cart_menu_component_product_price"];
          var newPrice = comboPrice > 0 ? " (+" + comboPrice + ")" : "";
          html += "<p>" + comboQty + " X " + comboPro + newPrice + " </p> ";
        }
      }
      return html;
    }
    return "";
  }

  /* this function used to show combo modifieirs list */
  showComboModifiers(modifiers) {
    var lenMod = modifiers.length;
    var html = "<div >";
    if (lenMod > 0) {
      for (var i = 0, length = lenMod; i < length; i++) {
        var modName = modifiers[i]["cart_modifier_name"];
        var modval = modifiers[i]["modifiers_values"][0]["cart_modifier_name"];
        var modValPrice =
          modifiers[i]["modifiers_values"][0]["cart_modifier_price"];
        var newModValPrice = modValPrice > 0 ? " (+" + modValPrice + ")" : "";
        html +=
          "<p><b>" +
          modName +
          ":</b> </p><p> " +
          modval +
          newModValPrice +
          "</p> ";
      }
      html += "</div>";

      return html;
    }

    return "";
  }

  updateCartQty(itemArr, type) {
    var productId = itemArr.cart_item_product_id;
    var cartItemId = itemArr.cart_item_id;
    var cartQty = itemArr.cart_item_qty;
    var totalItmCount = this.props.cartTotalItmCount;

    showLoader("rowcartid-" + cartItemId, "Idtext");

    if (type === "decr") {
      cartQty = parseInt(cartQty) - 1;
      if (parseInt(totalItmCount) === 0) {
      } else if (parseInt(cartQty) === 0) {
        this.props.deleteCartDetail(cartItemId);
      } else {
        this.props.updateCartDetail(productId, cartItemId, cartQty);
      }
    } else {
      cartQty = parseInt(cartQty) + 1;
      this.props.updateCartDetail(productId, cartItemId, cartQty);
    }
    removePromoCkValue();
  }

  deleteCartItm(itemArr, event) {
    event.preventDefault();
    var cartItemId = itemArr.cart_item_id;
    showLoader("rowcartid-" + cartItemId, "Idtext");
    this.props.deleteCartDetail(cartItemId);
    removePromoCkValue();
  }

  clearCartItm(event) {
    event.preventDefault();
    showLoader("cart_body", "class");
    this.props.destroyCartDetail();
    removePromoCkValue();
  }

  loadSuccssMsg() {
    return (
      <div
        className="custom_alertcls alert-success alert-dismissible1 success_hide"
        id="cart-success-msg"
        style={{ display: "none" }}
      >
        {" "}
        <a
          href="/"
          type="button"
          className="custom_close"
          data-dismiss="alert"
          aria-label="Close"
        >
          {" "}
          <span aria-hidden="true">×</span>{" "}
        </a>{" "}
        <p className="jquery-success-msg">
          Great! Your item has been added to the cart
        </p>{" "}
      </div>
    );
  }

  render() {
    var currenturl = window.location.href;
    var substring1 = "checkout";
    return (
      <li className="htico_cart cart_qty_list">
        {this.props.currentPage !== "product" && (
          <a
            href="/"
            className={
              currenturl.includes(substring1) !== true
                ? "hcartdd_trigger"
                : "hcartdd_trigger_dis"
            }
            title="Cart"
            id="cart_count_details"
            data-totalitmcount={Object.keys(this.props.cartItems).length}
          >
            <img src={bagicon} alt="" />
            <input
              type="hidden"
              value={Object.keys(this.props.cartItems).length}
              id="totalitmcount"
            />

            <span className="count">
              {Object.keys(this.props.cartItems).length > 0
                ? this.props.cartTotalItmCount
                : 0}
            </span>
          </a>
        )}
        {this.props.currentPage === "product" && (
          <input
            type="hidden"
            value={this.props.currentPage}
            id="currentpage_cart"
          />
        )}
        {this.props.currentPage === "header" ? (
          this.props.match.path !== "/food/:slugRestaurant/" && (
            <>
              {this.loadSuccssMsg()}
              {this.cartDetailsList()}
            </>
          )
        ) : (
          <>
            {this.loadSuccssMsg()}
            {this.cartDetailsList()}
          </>
        )}
        {this.props.currentPage === "header"
          ? this.props.match.path === "/food/:slugRestaurant/" &&
            $("body").width() <= 980 && (
              <>
                {this.loadSuccssMsg()}
                {this.cartDetailsList()}
              </>
            )
          : ""}

        {/* this.props.match.path === "/food/:slugRestaurant/" ? (
          <>
            {this.props.currentPage === "product" &&
              currenturl.includes(substring1) !== true && (
                <>
                  {this.loadSuccssMsg()}
                  {this.cartDetailsList()}
                </>
              )}
          </>
        ) : (
          currenturl.includes(substring1) !== true && (
            <>
              {this.loadSuccssMsg()}
              {this.cartDetailsList()}
            </>
          )
        ) */}
      </li>
    );
  }
}

const mapStateTopProps = (state) => {
  var overAllcart = Array();
  var cartDetails = Array();
  var cartItems = Array();
  var cartOutletList = 0;
  var cartOutlet = Array();
  var cartOutletTax = Array();
  var cartTotalItmCount = 0;
  var cartTotalIAmount = 0;
  var cartoutletavailability = Array();
  var siteLocID = "";
  var maxCart = 0;
  var cartStatus = "";
  var cartGroups = Array();
  var outletTat = "";
  if (Object.keys(state.cartlistdetail).length > 0) {
    var resultSetArr = !("result_set" in state.cartlistdetail[0])
      ? Array()
      : state.cartlistdetail[0].result_set;
    if (
      state.cartlistdetail[0].status === "ok" &&
      Object.keys(resultSetArr).length > 0
    ) {
      overAllcart = resultSetArr;
      cartDetails = resultSetArr.cart_details;
      cartItems = resultSetArr.cart_items;
      cartOutletList = resultSetArr.cartoutlet;
      cartOutlet = resultSetArr.outlets;
      cartTotalItmCount = resultSetArr.cart_details.cart_total_items;
      cartTotalIAmount = resultSetArr.cart_details.cart_grand_total;
      cartoutletavailability = resultSetArr.cartoutletavailability;
      siteLocID = resultSetArr.siteLocID;
      maxCart = resultSetArr.maxCart;
      cartGroups = resultSetArr.cartGroups;
      cartOutletTax = resultSetArr.tax;
      outletTat = resultSetArr.outletTat;
      cartStatus = "success";
    } else {
      cartStatus = "failure";
    }
  }

  return {
    overAllcart: overAllcart,
    cartDetails: cartDetails,
    cartItems: cartItems,
    cartOutletList: cartOutletList,
    cartOutlet: cartOutlet,
    cartOutletTax: cartOutletTax,
    siteLocID: siteLocID,
    maxCart: maxCart,
    cartGroups: cartGroups,
    cartoutletavailability: cartoutletavailability,
    cartTotalItmCount: cartTotalItmCount,
    cartTotalIAmount: cartTotalIAmount,
    cartStatus: cartStatus,
    outletTat: outletTat,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getCartDetail: () => {
      dispatch({ type: GET_CART_DETAIL });
    },
    updateCartDetail: (productId, cartItemId, cartQty) => {
      dispatch({ type: UPDATE_CART_DETAIL, productId, cartItemId, cartQty });
    },
    deleteCartDetail: (cartItemId) => {
      dispatch({ type: DELETE_CART_DETAIL, cartItemId });
    },
    destroyCartDetail: () => {
      dispatch({ type: DESTROY_CART_DETAIL });
    },
  };
};
export default connect(mapStateTopProps, mapDispatchToProps)(withRouter(CartSideBar));
