import { takeEvery, call, put } from "redux-saga/effects";
import { GET_RESTAURANTTYPE, SET_RESTAURANTTYPE } from "../actions";
import { appId, apiUrlV2 } from "../components/Helpers/Config";
import Axios from "axios";

export const watchGetRestauranttype = function* () {
  yield takeEvery(GET_RESTAURANTTYPE, workerGetRestauranttype);
};

function* workerGetRestauranttype({ arg }) {
  try {
    const uri = apiUrlV2 + "settings/getRestaurantType?app_id=" + appId;
    const result = yield call(Axios.get, uri);
    var resultArr = [];
    resultArr.push(result.data);
    yield put({ type: SET_RESTAURANTTYPE, value: resultArr });
  } catch (e) {
    console.log(e, "Get All Outlets Failed");
  }
}
